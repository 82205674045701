import React, { useEffect, useRef, useState } from 'react'

import upload from '../../assets/imges/upload.png'
import cafe from '../../assets/imges/covercafe.png'
import termsCondation from '../../assets/imges/termsCondation.png'
import PhoneCover from '../../assets/imges/iPhone-cover.png'
import { useDispatch, useSelector } from 'react-redux';
import { setSumitCamgpaingState } from '../../redux/slices/userSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button } from '@mui/material';
import { RxCross1 } from "react-icons/rx";
import AvatarEditor from 'react-avatar-editor'
import { Modal } from 'react-bootstrap'
import { FiZoomIn, FiZoomOut } from 'react-icons/fi'
import { FaArrowRotateLeft, FaArrowRotateRight } from 'react-icons/fa6'
import { Link } from 'react-router-dom'

const StepOne = ({ childFunc, stepChecker }) => {
    const dispatch = useDispatch();
    const { submitCampaign } = useSelector((state) => state.user)
    const [mblTerms, setMblTerms] = useState(true);
    const [name, setName] = useState();
    const [offer, setOffer] = useState();
    const [url, setUrl] = useState();
    const [subHeading, setSubHeading] = useState();
    const [buisnessName, setBuisnessName] = useState();
    const [file, setFile] = useState(undefined);
    console.log(file)
    const [stuff, setStuff] = useState();
    const [userDetail, setuserDetail] = useState();
    const [startOfOrder, setStartOfOrder] = useState(false);
    const [endOfOrder, setEndOfOrder] = useState(false);
    const [never, setNever] = useState(false);
    const [anytime, setAnytime] = useState(false);
    const [newCustomers, setNewCustomers] = useState(false);
    const [returning, setReturning] = useState(false);
    const [all, setAll] = useState(false);
    // const [mblScreenToggle, setMblScreenToggle] = useState(0);
    // const handleSwitch = (e) => {
    //     setMblScreenToggle(e.target.checked ? 1 : 0)
    //     if (mblScreenToggle == 0) {
    //         setMblTerms(true);
    //     } else {
    //         setMblTerms(false);

    //     }
    // }

    //   ---------- image crop ----------
    const [editImgModal, setEditImgModal] = useState(false);
    const [image, setImage] = useState('')
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)
    const [cnvsImage, setCnvsImage] = useState("")
    const previewRef = useRef();
    console.log(cnvsImage)
    console.log(image)

    const handlePreview = () => {
        if (previewRef.current) {
            const cnvs = previewRef.current.getImage();
            console.log('canvas get image ', cnvs)
            cnvs.toBlob((blob) => {
                setFile(blob);

                console.log(typeof (blob), "------------blob image")
                const previousImg = URL.createObjectURL(blob);
                setCnvsImage(previousImg);
            });
            setEditImgModal(false)
            setImage('');
            console.log(cnvsImage, "-----final")
        }
    };

    // ------------
    useEffect(() => {
        setuserDetail(JSON.parse(localStorage.getItem('userDetails')))
    }, [])
    useEffect(() => {
        childFunc.current = handleSubmit
    }, [childFunc.current])
    useEffect(() => {
        console.log(submitCampaign.get('image'), 'image from redux ____________')
        console.log('submit campaign state in redux', submitCampaign);
        setName(submitCampaign.get('offer_name'))
        setOffer(submitCampaign.get('offer_heading'))
        setSubHeading(submitCampaign.get('offer_subheading'))
        setUrl(submitCampaign.get('website_url'))
        setStuff(submitCampaign.get('nitty_gritty_stuff'))
        // setFile(submitCampaign.get('image') ? submitCampaign.getAll('image') : undefined)
        // setFile(submitCampaign.get('image') ? URL.createObjectURL(submitCampaign.getAll('image')) : undefined)
    }, [])

    function handleSubmit() {
        console.log(file + '=========file')
        if (name?.length > 0 && offer?.length > 0 && subHeading?.length > 0 && stuff?.length > 0 && file) {
            name && dispatch(setSumitCamgpaingState({ key: 'offer_name', value: name }))
            offer && dispatch(setSumitCamgpaingState({ key: 'offer_heading', value: name }))
            subHeading && dispatch(setSumitCamgpaingState({ key: 'offer_subheading', value: subHeading }))
            url && dispatch(setSumitCamgpaingState({ key: 'website_url', value: url }))
            stuff && dispatch(setSumitCamgpaingState({ key: 'nitty_gritty_stuff', value: stuff }))
            file && dispatch(setSumitCamgpaingState({ key: 'image', value: file }))
            dispatch(setSumitCamgpaingState({
                key: 'when',
                value: JSON.stringify({
                    'startoforder': startOfOrder,
                    'endoforder': endOfOrder,
                    'anytime': anytime,
                    'middleoforder': newCustomers
                })
            }));
        } else {
            // alert('handle submit is called from parent')
            stepChecker();
            toast.error('oops! Fields marked with * are required!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
    return (
        <>
            {/* =========== STEP ONE ========= */}

            <form className="row" onSubmit={handleSubmit}>
                <div className="col-lg-8 col-12">
                    <div className="row">
                        <div className="col-12">
                            <div className="mb-3">
                                <label className="form-label font-15 font-500 inter colorgray">Offer Name* <i className="font-12 fa-solid fa-circle-exclamation" /></label>
                                <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="form-control inputgroup" placeholder />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <h6 className="font-15 font-500 inter colorblack text-center">Front Card</h6>
                            <div className="mb-3">
                                <label className="form-label font-15 font-500 inter colorgray">Offer Heading* <i className="font-12 fa-solid fa-circle-exclamation" /></label>
                                <input type="email" className="form-control inputgroup" value={offer} onChange={(e) => setOffer(e.target.value)} maxLength={35} minLength={35} placeholder="E.g. Store grand opening day!" />
                                <p className="font-10 fst-italic colorgray mt-1">{offer && offer.length}/35 characters</p>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlTextarea1" className="form-label font-15 font-500 inter colorgray">Sub-Heading* <i className="font-12 fa-solid fa-circle-exclamation" /></label>
                                <textarea className="form-control inputgroup" value={subHeading} onChange={(e) => setSubHeading(e.target.value)} maxLength={100} minLength={80} style={{ resize: 'none' }} id="exampleFormControlTextarea1" placeholder="E.g. Congratulations! Enjoy a free coffee at Donald's Cafe. Claim your discount now." rows={5} defaultValue={""} />
                                <p className="font-10 fst-italic colorgray mt-1">{subHeading && subHeading.length}/100 characters</p>
                            </div>
                            <div className>
                                <p htmlFor className="form-label font-15 font-500 inter colorgray">Upload Image*
                                    <i className="font-12 fa-solid fa-circle-exclamation" /></p>
                                <div className=" inputgroup1 p-3 text-center">
                                    <div role='button' onClick={() => setEditImgModal(true)}>
                                        <div className="px-3 py-3 mb-3 ">
                                            <img src={upload} width={60} alt />
                                            <h6 className="font-15 font-500 inter colorblack mt-2 point">Drag &amp; drop
                                                files or <span className="colorblue text-decoration-underline">Browse</span>
                                            </h6>
                                            <p className="mb-0 mt-2 font-12 colorgray inter point">Supported
                                                formates: <br />
                                                JPEG, PNG</p>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <h6 className="font-15 font-500 inter colorblack text-center">Card Details</h6>
                            <div className="mb-3">
                                <label className="form-label font-15 font-500 inter colorgray">Website URL <i className="font-12 fa-solid fa-circle-exclamation" /></label>
                                <input type="email" className="form-control inputgroup" value={url} onChange={(e) => setUrl(e.target.value)} placeholder="https://www.timelink.au" />
                            </div>
                            <div className="mb-3">
                                <label className="form-label font-15 font-500 inter colorgray">Business Name</label>
                                <input type="email" readOnly className="form-control inputgroup" value={userDetail?.business_name} onChange={(e) => setBuisnessName(e.target.value)} placeholder />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlTextarea1" className="form-label font-15 font-500 inter colorgray">Terms & Conditions*&nbsp;<i className="font-12 fa-solid fa-circle-exclamation" /></label>
                                <textarea className="form-control inputgroup" value={stuff} onChange={(e) => setStuff(e.target.value)} maxLength={350} minLength={300} style={{ resize: 'none' }} id="exampleFormControlTextarea1" placeholder rows={5} defaultValue={""} />
                                <p className="font-10 fst-italic colorgray mt-1">{stuff && stuff.length}/350 characters</p>
                            </div>
                            <div className="mt-2">
                                <p className="font-14 inter colorgray mb-0">Select When to dispaly your marketing camapign (Optional)</p>
                                <div className="row mt-2 loyalty_program">

                                    <p className="font-14 inter colorgray mb-0">When?</p>
                                    <div className="col-6">
                                        <div className="form-check">
                                            <label className="form-check-label font-12 Inter colorgray white-space-nowrap" htmlFor="Start" >
                                                <input checked={startOfOrder}
                                                    onChange={(e) => setStartOfOrder(!startOfOrder)} className="form-check-input" type="checkbox" id="Start" />
                                                Start of order
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <label className="form-check-label font-12 Inter colorgray white-space-nowrap" htmlFor="End">
                                                <input className="form-check-input "
                                                    checked={endOfOrder}
                                                    onChange={(e) => setEndOfOrder(!endOfOrder)} type="checkbox" id="End" />
                                                End of order
                                            </label>
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <div className="form-check">
                                            <label className="form-check-label font-12 Inter colorgray" htmlFor="customers">
                                                <input className="form-check-input" type="checkbox" checked={newCustomers}
                                                    onChange={(e) => setNewCustomers(!newCustomers)} id="customers" />
                                                Middle of Order
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <label className="form-check-label font-12 Inter colorgray" htmlFor="Anytime">
                                                <input className="form-check-input" type="checkbox" checked={anytime}
                                                    onChange={(e) => setAnytime(!anytime)} id="Anytime" />
                                                Anytime
                                            </label>
                                        </div>
                                        {/* <div className="form-check">
                                            <label className="form-check-label font-12 Inter colorgray" htmlFor="Returning">
                                                <input className="form-check-input" type="checkbox" checked={returning}
                                                    onChange={(e) => setReturning(e.target.checked)} id="Returning" />
                                                Returning
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <label className="form-check-label font-12 Inter colorgray" htmlFor="All">
                                                <input className="form-check-input" type="checkbox" checked={all}
                                                    onChange={(e) => setAll(e.target.checked)} id="All" />
                                                All
                                            </label>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-12 mt-lg-0 mt-md-3 mt-3">
                    <div className="d-flex justify-content-center">

                        <div className=' position-relative  pb-1 ' style={{ width: '80%' }}>

                            <img src={PhoneCover} className="w-100 h-100" alt="w8" />
                            {mblTerms ?
                                <div className="position-absolute marketing-mbl-cover-content w-100  "  >
                                    <div className='bg-white h-100 d-flex justify-content-between flex-wrap'>
                                        <div>

                                            <div className="d-flex justify-content-center align-items-center pt-3 px-2 ">
                                                {/* <span
                                                    className="poppins  text-dark fw-semibold  font-16"
                                                    style={{ visibility: 'hidden' }}
                                                >

                                                    <RxCross1 />

                                                </span> */}
                                                <p
                                                    className="inter text-center  mb-0 fw-bold font-10"

                                                >
                                                    Your Order Has been Completed
                                                </p>
                                                {/* <span role="button"
                                                    className="poppins text-dark fw-semibold  font-16"

                                                >
                                                    <RxCross1 />

                                                </span> */}
                                            </div>
                                            <div className="p-3 pb-0 pt-1 mt-1 container">
                                                <div className="row justify-content-center">
                                                    <div className="col-9">
                                                        <img src={cnvsImage ? cnvsImage : cafe} className="w-100 object-fit-cover rounded-4 shadow-sm " height={75} alt="" />
                                                    </div>
                                                </div>
                                                <div className="text-start  mt-2 mb-0">
                                                    <h6 className="font-10 mb-1 fw-bold inter">
                                                        {offer && offer.length > 1 ? offer : 'Thank you for using Time Link'}
                                                    </h6>
                                                    <p
                                                        className="font-10 fw-medium inter"
                                                        style={{ color: "#5B5B5B", wordBreak: "break-word" }}
                                                    >
                                                        {subHeading && subHeading.length > 1 ? subHeading : "Congratulations! Enjoy a free coffee at Donald's Cafe.Claim your discount now."} <u role='button'
                                                            onClick={() => setMblTerms(false)}>Terms & Conditions</u> apply.

                                                        Details will be applied to account +123456789.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container-fluid px-3 pb-3 align-self-center">
                                            <div className="row justify-content-center">
                                                <div className="col-9">

                                                    <Button
                                                        type="button"
                                                        className="btn-fill-blue p-2 helvetica-medium fw-medium w-100 rounded-5  text-white mx-auto font-14-69 "

                                                    >
                                                        Sure
                                                    </Button>
                                                </div>
                                                <div className="mt-2  col-7 text-center">

                                                    <Link
                                                        className="text-decoration-underline helvetica-light" style={{ color: "#636366", fontSize:10}}

                                                    >
                                                        Maybe later
                                                    </Link>
                                                </div>
                                                {/* <div className="col-10">
                                                    <Button
                                                        type="button"

                                                        className="btn-blue p-3 rounded-3 w-100 mb-2 text-white mx-auto font-10 montserrat"
                                                    >
                                                        Yes Please
                                                    </Button>
                                                    <Button
                                                        type="button"
                                                        className="btn-white-borderTwo p-3 rounded-3  w-100  mx-auto font-10 montserrat"
                                                    >
                                                        No Thanks
                                                    </Button>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="position-absolute marketing-mbl-cover-content w-100  "  >

                                    <div className='bg-white h-100'>

                                        <div className="d-flex justify-content-between align-items-start pt-3 px-3 ">
                                            <div>
                                                <h6 className='fw-bold mb-0'>Offer Conditions</h6>
                                                <p
                                                    className="inter text-center text-dark-gray mb-1 font-12"
                                                >
                                                    Terms & Conditions apply.
                                                </p>
                                            </div>

                                            <img role='button' src={termsCondation} onClick={() => setMblTerms(true)} alt="w8" width={25} />


                                        </div>
                                        <div className="p-3 pt-1 container">

                                            <div className="text-start  mt-2 mb-0">

                                                <p
                                                    className="font-12 pb-2 fw-medium inter"
                                                    style={{ color: "#5B5B5B", wordBreak: "break-word" }}
                                                >
                                                    {stuff && stuff.length > 1 ? stuff : 'Physical space is often conceived in three linear dimensions, although modern physicists usually consider it, with time, to be part of a boundless four-dimensional continuum known as spacetime. The concept of space is considered to be of fundamental importance to an understanding of the physical universe. However, disagreement continues between philosophers over whether it is itself an entity, a relationship between entities, or part of a conceptual framework.'}
                                                </p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className='d-flex justify-content-center my-3 align-items-center '>

                        <div className="container-stepOne">
                            <input type="checkbox" checked={mblTerms === false}
                                onChange={(e) => setMblTerms(e.target.checked ? false : true)}
                                className="checkbox" id="checkbox" />
                            <label className="switch" htmlFor="checkbox">
                                <span className="slider" />
                            </label>
                        </div>
                        <span className='ms-2 text-gray'>{mblTerms ? "Front" : "Back"}</span>
                    </div>

                    <p className="font-12 inter colorgray">**Please note: This is for illustrative purposes only,
                        and the real style, design may differ.</p>
                </div>
            </form>
            <ToastContainer />

            <Modal show={editImgModal} className='business_admin_main' centered onHide={() => setEditImgModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {image && (
                            <>
                                <div className="imag_crop_shadow d-flex justify-content-center ">

                                    <AvatarEditor
                                        width={250}
                                        height={120}
                                        ref={previewRef}
                                        border={50}
                                        borderRadius={14}
                                        color={[80, 76, 76, 0.6]}
                                        scale={scale}
                                        rotate={rotate}
                                        image={URL.createObjectURL(image)}
                                    />
                                </div>
                                <div className='mt-2'>
                                    <button role='button' className='btn-blue rounded-2 m-1' onClick={() => setScale(scale - 0.1)}><FiZoomOut /></button>
                                    <button role='button' className='btn-blue rounded-2 m-1' onClick={() => setScale(scale + 0.1)}><FiZoomIn /></button>

                                    <button role='button' className='btn-blue rounded-2 m-1' onClick={() => setRotate(rotate - 90)}><FaArrowRotateLeft /></button>
                                    <button role='button' className='btn-blue rounded-2 m-1' onClick={() => setRotate(rotate + 90)}><FaArrowRotateRight /></button>
                                </div>
                            </>
                        )}

                        <div className=" inputgroup1 p-3 mt-1 text-center ">

                            <label htmlFor="file" className='h-100 w-100 cursor-pointer' >
                                <div className="px-3 py-3 mb-3 ">
                                    <img src={upload} width={60} alt />
                                    <h6 className="font-15 font-500 inter colorblack mt-2 point">Drag &amp; drop
                                        files or <span className="colorblue text-decoration-underline">Browse</span>
                                    </h6>
                                    <p className="mb-0 mt-2 font-12 colorgray inter point">Supported
                                        formates: <br />
                                        JPEG, PNG</p>
                                </div>
                            </label>
                        </div>
                        <input type="file" id='file' onChange={(e) => setImage(e.target.files[0])} className="d-none" accept="image/*" />
                    </div>
                </Modal.Body>
                {image && <Modal.Footer>
                    <button role='button' className='btn-blue rounded-2 p-2 m-1' onClick={handlePreview}>Upload</button>

                </Modal.Footer>}
            </Modal>
        </>
    )
}

export default StepOne
