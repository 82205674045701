import React, { useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Button, FormControl, FormControlLabel, IconButton, Radio, RadioGroup } from '@mui/material';
// import { Input } from "reactstrap";
import { Modal } from 'react-bootstrap'
import Setting from "../../../assets/imges/DeviceTableSetting.png";
import link from "../../../assets/imges/DeviceTableLink.png";
import Toggle from "../../../assets/imges/DeviceTableToggle.png";
import DropDots from "../../../assets/imges/DeviceTableDropdown.png";
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { updateSurveyApprovalStatus } from '../../../redux/slices/userSlice';
import { FaAngleDown } from 'react-icons/fa';
import { GrFormClose } from 'react-icons/gr';

export default function SurveyListItem({ currentItems }) {
    const dispatch = useDispatch();
    const [showQuestionData, setShowQuestionData] = useState();
    const [showOptions, setShowOptions] = useState();
    const [showModalQuestion, setShowModalQuestion] = useState(false);
    function handleUpdateApprovalStatus(id, status) {
        var data = {
            survey_id: id,
            approval_status: status
        }
        dispatch(updateSurveyApprovalStatus(data));
    }

    function handleShowQuestion(data) {
        // console.log('options data to filter ' ,data);
        // var optionsData = data.options;
        // const pos = optionsData.map(e => e.is_text_field).indexOf(1);
        // console.log('postion' , pos)
        // optionsData.push(optionsData.splice(pos, 1)[0]);
        setShowOptions(data);
        setShowQuestionData(data)
        setShowModalQuestion(true)
    }
    return (

        <>
            {currentItems.map((index) => {
                return (
                    <div className="d-flex justify-content-between flex-wrap p-2 bg-white rounded-4 my-2" style={{ boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.10)', border: '1.5px solid rgba(255, 153, 0, 1)' }}>




                        <div className="inter d-flex align-items-center" style={{ whiteSpace: 'nowrap' }}>
                            {/* <div className="px-3">
                                <Input type="checkbox"
                                    style={{ transform: 'scale(1.3)', borderColor: '#C8C3C9', borderWidth: '1.5' }} />
                            </div> */}
                            <div>
                                <h3 className="font-18 " style={{ color: 'rgba(74, 74, 74, 1)' }}>{index?.business?.business_name}</h3>
                                <h3 className="font-16" style={{ color: 'rgba(83, 83, 83, 1)' }}>{index?.business?.email}</h3>
                                <h3 className="font-10" style={{ color: 'rgba(83, 83, 83, 1)' }}>Created: {moment(index?.created_at).format('DD MMM YYYY')}</h3>
                            </div>
                        </div>

                        <div className="d-flex align-items-center">

                            <Button className={`${index.approval_status == 'approved' ? 'btn-green' : index.approval_status == 'rejected' ? 'btn-red' : 'btn-orange'} poppins px-4 me-2  rounded-5 font-12 fw-semibold text-capitalize`} style={{ whiteSpace: 'nowrap' }}>{index.approval_status}</Button>
                        </div>

                        <div
                            className="d-flex align-items-center  ps-3 my-2 py-2"
                            style={{ borderLeft: "2px solid rgba(200, 195, 201, 1) ", whiteSpace: 'nowrap' }}
                        >
                            <div>
                                <div className=" form-switch black-switch d-flex text-start  ps-0">
                                    {/* <MdToggleOn className='grey' size={28} /> */}
                                    <img src={Toggle} width={22} height={22} alt="w8" />

                                    <p className="mb-0 font-12 fw-medium text-uppercase ms-2" style={{ color: 'rgba(170, 170, 170, 1)' }}>{index.offer_name}</p>
                                </div>
                                <div className="d-flex  mt-2 text-start">
                                    {/* <FaHandHoldingUsd size={28} className='grey' /> */}
                                    <img src={link} width={22} height={22} alt="w8" />
                                    <p className="mb-0 font-12 fw-medium  ms-2" style={{ color: 'rgba(170, 170, 170, 1)' }}>{index.offer_heading}</p>
                                </div>
                                <div className="d-flex  mt-2 text-start">
                                    {/* <AiFillSetting size={28} className='grey' /> */}
                                    <img src={Setting} width={22} height={22} alt="w8" />

                                    <p className="mb-0 font-12 fw-medium  ms-2" style={{ color: 'rgba(170, 170, 170, 1)' }}>Terms & Conditions apply.  </p>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center ms-2">

                            <Button className='btn-white-borderTwo text-capitalize fw-bold rounded-5 px-2 p-1  font-14' sx={{ minWidth: '100px' }} onClick={()=>{handleShowQuestion(index)}}>Detail</Button>

                            <div className="ms-3 d-flex flex-column">
                                <Button className='btn-blue-border text-capitalize fw-bold rounded-5 px-2 p-1  font-15' sx={{ minWidth: '110px' }} onClick={() => { handleUpdateApprovalStatus(index.id, 'rejected') }}>Reject</Button>
                                <Button className='btn-green-border mt-2 text-capitalize fw-bold rounded-5 px-2 p-1  font-15' sx={{ minWidth: '110px' }} onClick={() => { handleUpdateApprovalStatus(index.id, 'approved') }}>Approve</Button>
                            </div>
                        </div>
                        <div className="d-flex align-items-center">


                            <div className="dropdown">
                                <img
                                    src={DropDots}
                                    className="mx-1 dropdown-toggle"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    width={35}
                                    height={35}
                                    alt=""
                                />

                                <ul className="dropdown-menu mt-4">
                                    <li>
                                        <span className="dropdown-item font-14 fw-medium" >
                                            Edit
                                        </span>
                                    </li>
                                    <li>
                                        <span className="dropdown-item font-14 fw-medium" >
                                            Duplicate
                                        </span>
                                    </li>
                                    <li>
                                        <span className="dropdown-item font-14 fw-medium" >
                                            Reassign
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                )
            })}

            <Modal show={showModalQuestion} centered className='survey-page modals_scroll business_admin_main'  >

                <Modal.Body style={{ maxHeight: '85vh', overflowY: 'auto' }}>
                    <form className="row justify-content-center rounded-4 px-2 pb-3">
                        <div className='d-flex flex-row justify-content-between align-items-center mt-3'>
                            <span></span>
                            <h6 className="font-15 inter colorPrimary text-center">Question Name</h6>
                            <span onClick={() => setShowModalQuestion(false)} className=' cursor-pointer'><GrFormClose size={20} /></span>
                        </div>
                        <div className="col-lg-10 col-md-10 col-12">
                            <div className="row justify-content-center">
                                <div className="col-12">
                                    <Accordion className='sms_automation_accordion rounded-5 mt-3'>
                                        <AccordionSummary
                                            expandIcon={<FaAngleDown className='colorPrimary' />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <div>

                                                <p className='mb-0 font-12 fw-medium '>{showQuestionData?.q_name}</p>
                                                <p className='mb-0 font-15'>{showQuestionData?.question} </p>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails style={{ borderTop: "1.5px solid #D9D9D9" }}>
                                            <div>
                                                <p className=" text-dark mb-1 text-start font-13 fw-medium ">
                                                    Multiple Choice
                                                </p>
                                                <FormControl className='radio-form-control'>
                                                    <RadioGroup
                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                        name="radio-buttons-group">
                                                        {
                                                            showQuestionData?.options?.map((index) => {
                                                                return (
                                                                    index.is_text_field ?
                                                                        <form className=' d-flex flex-column'>
                                                                            <label htmlFor='otherTextField'>{index.option_value}</label>
                                                                            <textarea id='otherTextField' required cols="25" rows="3" className='rounded-4 mt-1' ></textarea>
                                                                        </form>
                                                                        :
                                                                        <FormControlLabel value={index.option_number}
                                                                            control={<Radio style={{ color: '#3461FD' }} />}
                                                                            label={index.option_value} />
                                                                )
                                                            })
                                                        }
                                                    </RadioGroup>
                                                </FormControl>

                                            </div>
                                        </AccordionDetails>
                                    </Accordion>

                                </div>
                            </div>
                            {/* <div className="d-flex justify-content-center mt-3">
                <div className="ms-2">
                    <button type="button" className="btn-blueTwo rounded-5 p-2 px-3 py-2 fw-medium font-14 text-capitalize" onClick={() => setShowModalQuestion(false)}>Complete</button>
                </div>
            </div> */}
                        </div>
                    </form>
                </Modal.Body>

            </Modal>

        </>

    )
}
