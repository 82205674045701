import React from 'react'
import LoyaltyPointsHeader from '../../../components/LoyaltyPointsHeader'
import { IoCheckbox } from 'react-icons/io5'

const Review = () => {
    function handleNext() {

    }
    return (
        <main className='bg-white p-3' style={{ minHeight: "100vh" }}>

            <LoyaltyPointsHeader handleNext={handleNext} />
            <div className="container inter customise-program mt-4">
                <div className="row justify-content-center ">
                    <div className="col-lg-6 col-md-8 col-12">
                        <p className='text-uppercase font-12 text-black-50'>Step 4 of 4</p>
                        <h3 className='font-30 fw-bold '>Review and subscribe</h3>
                        <span className='text-blue rounded-5 font-14 my-2 d-inline-block fw-bold p-2' style={{ background: "#E6F0FF" }}>Free for 30 days • Cancel Anytime</span>
                        <p className='font-14 mt-1'>Make sure all of your loyalty programme details are correct before starting your free trial and
                            making your programme available to your customers.</p>
                        <div className="p-3 rounded-3 d-flex mt-3 align-items-center justify-content-between" style={{ boxShadow: "0px 0px 4px 0px #0000001A" }}>
                            <div className=' w-100'>
                                <p className='mb-0 font-18 fw-bold '>1 location selected</p>
                                <p className='mb-0  '>‘Business_name’</p>
                                <p className='mb-0 font-14 text-black-50'>Enjoy transparent pricing with no hidden monthly fees, maintaining consistent costs as your business grows.</p>
                            </div>
                            <div className="d-flex align-items-center ">

                                <p className='mb-0 '>$49.00 </p><IoCheckbox className='text-black-50 ms-1' />
                            </div>

                        </div>
                        <div className="p-3 rounded-3 d-flex mt-3 align-items-center justify-content-between" style={{ boxShadow: "0px 0px 4px 0px #0000001A" }}>
                            <div className='w-100 '>
                                <div className="d-flex align-items-center justify-content-between  ">

                                    <p className='mb-0 font-18 fw-bold '>Rewards</p>
                                    <span role='button' className='mb-0 font-18 fw-bold text-blue '>Edit</span>
                                </div>
                                <p className='mb-0 mt-3 fw-bold'>A$10.00 off entire sale (70 Points)</p>
                                <p className='mb-0 font-14 text-black-50'>$10.00 discount on entire sale.</p>
                            </div>

                        </div>
                        <div className="p-3 rounded-3 d-flex mt-3 align-items-center justify-content-between" style={{ boxShadow: "0px 0px 4px 0px #0000001A" }}>
                            <div className=' w-100'>
                                <div className="d-flex align-items-center justify-content-between  ">

                                    <p className='mb-0 font-18 fw-bold '>Programme type</p>
                                    <span role='button' className='mb-0 font-18 fw-bold text-blue '>Edit</span>
                                </div>
                                <p className='mb-0 mt-3 fw-bold'>Visit based</p>
                                <p className='mb-0 font-14 text-black-50'>Customers earn 1 Point for each visit with no minimum purchase required.</p>
                            </div>

                        </div>
                        <div className="p-3 rounded-3 d-flex mt-3 align-items-center justify-content-between" style={{ boxShadow: "0px 0px 4px 0px #0000001A" }}>
                            <div className=' w-100'>
                                <div className="d-flex align-items-center justify-content-between  ">

                                    <p className='mb-0 font-18 fw-bold '>Programme customisation</p>
                                    <span role='button' className='mb-0 font-18 fw-bold text-blue '>Edit</span>
                                </div>
                                <p className='mb-0 mt-3 fw-bold'>Unit name</p>
                                <p className='mb-0 font-14 text-black-50'>Point/Points</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Review
