import { Button, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { Modal } from 'react-bootstrap';
import { BiCategory } from 'react-icons/bi';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FaArrowRotateLeft, FaArrowRotateRight } from 'react-icons/fa6';
import { FiZoomIn, FiZoomOut } from 'react-icons/fi';
import { GoDotFill, GoTriangleDown } from "react-icons/go";
import { GrGallery } from 'react-icons/gr';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { MdOutlineDriveFileRenameOutline } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Col, Container, Row, Spinner } from 'reactstrap';
import upload from '../../assets/imges/upload.png';
import LoginHeader from '../../components/LoginHeader';
import { setBusinessProfile } from '../../redux/slices/userSlice';
const SignUpProfile = () => {
    const dispatch = useDispatch();
    const { isLoading, getError } = useSelector((state) => state.user)
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [category, setCategory] = useState('');
    const [address, setAddress] = useState("");
    const [selectedOption, setSelectedOption] = useState(null);
    //   ---------- image crop ----------
    const [file, setFile] = useState(null);
    const [editImgModal, setEditImgModal] = useState(false);
    const [image, setImage] = useState('')
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)
    const [cnvsImage, setCnvsImage] = useState("")
    const [businessLogo, setBusinessLogo] = useState("");
    const previewRef = useRef();
    const [clientRadio, setClientRadio] = React.useState();
    const [client, setClient] = React.useState(false);
    const [isBtnDisable, setIsBtnDisable] = useState(true);  // Initially set to true

    useEffect(() => {
        // Check if all required fields are filled
        if (file && name && clientRadio) {
            setIsBtnDisable(false);
        } else {
            setIsBtnDisable(true);
        }
    }, [file, name, clientRadio]);  // Include clientRadio instead of selectedOption

    const handlePreview = () => {
        if (previewRef.current) {
            const cnvs = previewRef.current.getImage();
            console.log('canvas get image ', cnvs)
            cnvs.toBlob((blob) => {
                setFile(blob);

                console.log(typeof (blob), "------------blob image")
                const previousImg = URL.createObjectURL(blob);
                setCnvsImage(previousImg);
            });
            setEditImgModal(false)
            setImage('');
            console.log(cnvsImage, "-----final")
        }
    };

    const businessOption = [
        { label: "Fast Food / take Away Restaurants" },
        { label: "Cafe" },
        { label: "Burger Shop" },
        { label: "Pizzeria" },
        { label: "Food Trucks and Mobile Vendor" },
        { label: "Juice Bar and Smoothie Shops" },
        { label: "Bars and Pubs" },
        { label: "Pharmacy" },
        { label: "Bakeries or Dessert Shop" },
        { label: "Other" },
    ];
    useEffect(() => {
        if (getError && getError.length > 1) {
            toast.error(getError, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }, [getError])

    const handleOptionChange = (event, newValue) => {
        setSelectedOption(newValue);
        setAddress(newValue ? newValue.label : "");
    };
    var styleLocation = {
        border: selectedOption ? "1.5px solid rgba(37, 99, 235, 1)" : "1.5px solid rgba(226, 232, 240, 1)",
    };
    if (name.length > 0) {
        var style = {
            border: '1.5px solid rgba(37, 99, 235, 1)',
        }
    }
    else {
        var style = {
            border: '1.5px solid rgba(226, 232, 240, 1)',
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        var data = new FormData();
        data.append('business_name', name);
        if (!clientRadio) {
            toast.error('Please select a business category', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            data.append('business_category', clientRadio);
        }
        if (file) {
            data.append('images', file);
        }
        dispatch(setBusinessProfile(1, data, handleResponse))

    }

    function handleResponse(data) {
        navigate('/auth/signup/essentials')
    }

    return (
        <>
            <Container fluid className='signup-steps-pages pb-5'>
                <LoginHeader />
                <Row className='justify-content-center my-4 SignUp-progress-bar lato'>

                    <Col lg={6} md={6} sm={12} xs={12} >

                        <Row>
                            <Col lg={3} md={3} sm={3} xs={3} className='px-1'>
                                <div className='text-center lh-base'>
                                    <p className="font-10 mb-0 fw-semibold " style={{ color: '#6F6F6F' }}>Sign Up</p>
                                    <GoDotFill size={8} className='my-0 mb-2 blue' />
                                </div>
                                <div className="progress rounded-5" role="progressbar" aria-label="Basic example" aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}>
                                    <div className="progress-bar rounded-5" style={{ width: '100%' }} />
                                </div>

                            </Col>
                            <Col lg={6} md={6} sm={6} xs={6} className='px-1'>
                                <div className='text-center lh-base'>
                                    <p className="font-10 mb-0 fw-semibold " style={{ color: '#6F6F6F' }}>Business Profile</p>
                                    <GoDotFill size={8} className='my-0 mb-2 blue' />
                                </div>
                                <div className="progress  rounded-5" role="progressbar" aria-label="Basic example" aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}>
                                    <div className="progress-bar  rounded-5" style={{ width: '4%' }} />
                                </div>
                            </Col>
                            <Col lg={3} md={3} sm={3} xs={3} className='px-1'>
                                <div className='text-center lh-base'>
                                    <p className="font-10 mb-0 fw-semibold " style={{ color: '#6F6F6F' }}>Primary Contact</p>
                                    <GoDotFill size={8} color='#D0E2FF' className='my-0 mb-2 ' />
                                </div>
                                <div className="progress  rounded-5" role="progressbar" aria-label="Basic example" aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}>
                                    <div className="progress-bar  rounded-5" style={{ width: '0%' }} />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>


                <Row className='align-items-center justify-content-center d-flex login_height my-5' >
                    <Col lg={5} md={12} sm={12} xs={12} >

                        <form className='business-login-form rounded-4 d-flex justify-content-between flex-column p-3 inter pb-5 !h-auto' onSubmit={handleSubmit}>
                            <div>

                                <div className='inter text-center mt-2'>
                                    <h4 className="font-24 fw-bold  text-dak-blue">Start Building your Business Profile</h4>
                                    <p className="font-14 text-dark-gray ">Providing this information ensures the best TimeLink experience for your business and helps us understand and serve you better. </p>
                                </div>
                                <div className='login-form' >

                                    <div className="input-group my-3 rounded-4 bg-white" style={style}>
                                        <span className="input-group-text border-0 ps-3" id="basic-addon1"><MdOutlineDriveFileRenameOutline
                                            size={20} color='rgba(100, 116, 139, 1)' /></span>
                                        <input type="text" required className="form-control border-0 p-3 ps-0 rounded-4 fw-normal  " placeholder='Business name' value={name} onChange={(e) => setName(e.target.value)} />
                                    </div>
                                    <div className="position-relative">

                                        <div className="input-group my-3 rounded-4 d-flex flex-row flex-nowrap py-3 px-3" style={{ ...style, backgroundColor: '#ffffff' }}
                                            onClick={() => { setClient(!client); }}>
                                            <BiCategory
                                                size={20}
                                                color="rgba(100, 116, 139, 1)"
                                            />
                                            <div className='d-flex align-items-center justify-content-between w-100 ps-3'>

                                                {clientRadio ? clientRadio : 'Select Business Category'}
                                                <GoTriangleDown />
                                            </div>
                                        </div>
                                        {
                                            client &&
                                            <div className='position-absolute bg-white p-2 px-3 shadow w-100 top-100 start-0 rounded-4 z-1'>
                                                <FormControl>

                                                    <RadioGroup

                                                        // defaultValue="All-time"
                                                        onChange={(e) => {
                                                            setClientRadio(e.target.value);
                                                            setClient(false)
                                                        }
                                                        }
                                                        value={clientRadio}
                                                        name="radio-buttons-client"
                                                    >
                                                        <FormControlLabel value="Fast Food / take Away Restaurants" control={<Radio
                                                            sx={{
                                                                color: '#1D59F9',
                                                                '&.Mui-checked': {
                                                                    color: '#1D59F9   ',
                                                                },
                                                            }}
                                                        />} label="Fast Food / take Away Restaurants" />
                                                        <FormControlLabel value="Cafe" control={<Radio
                                                            sx={{
                                                                color: '#1D59F9',
                                                                '&.Mui-checked': {
                                                                    color: '#1D59F9   ',
                                                                },
                                                            }}
                                                        />} label="Cafe" />
                                                        <FormControlLabel value="Burger Shop" control={<Radio
                                                            sx={{
                                                                color: '#1D59F9',
                                                                '&.Mui-checked': {
                                                                    color: '#1D59F9   ',
                                                                },
                                                            }}
                                                        />} label="Burger Shop" />
                                                        <FormControlLabel value="Pizzeria" control={<Radio
                                                            sx={{
                                                                color: '#1D59F9',
                                                                '&.Mui-checked': {
                                                                    color: '#1D59F9   ',
                                                                },
                                                            }}
                                                        />} label="Pizzeria" />
                                                        <FormControlLabel value="Food Trucks and Mobile Vendor" control={<Radio
                                                            sx={{
                                                                color: '#1D59F9',
                                                                '&.Mui-checked': {
                                                                    color: '#1D59F9   ',
                                                                },
                                                            }}
                                                        />} label="Food Trucks and Mobile Vendor" />
                                                        <FormControlLabel value="Bars and Pubs" control={<Radio
                                                            sx={{
                                                                color: '#1D59F9',
                                                                '&.Mui-checked': {
                                                                    color: '#1D59F9   ',
                                                                },
                                                            }}
                                                        />} label="Bars and Pubs" />
                                                        <FormControlLabel value="Juice Bar and Smoothie Shops" control={<Radio
                                                            sx={{
                                                                color: '#1D59F9',
                                                                '&.Mui-checked': {
                                                                    color: '#1D59F9   ',
                                                                },
                                                            }}
                                                        />} label="Juice Bar and Smoothie Shops" />
                                                        <FormControlLabel value="Bars and Pubs" control={<Radio
                                                            sx={{
                                                                color: '#1D59F9',
                                                                '&.Mui-checked': {
                                                                    color: '#1D59F9   ',
                                                                },
                                                            }}
                                                        />} label="Bars and Pubs" />
                                                        <FormControlLabel value="Pharmacy" control={<Radio
                                                            sx={{
                                                                color: '#1D59F9',
                                                                '&.Mui-checked': {
                                                                    color: '#1D59F9   ',
                                                                },
                                                            }}
                                                        />} label="Pharmacy" />
                                                        <FormControlLabel value="Bakeries or Dessert Shop" control={<Radio
                                                            sx={{
                                                                color: '#1D59F9',
                                                                '&.Mui-checked': {
                                                                    color: '#1D59F9   ',
                                                                },
                                                            }}
                                                        />} label="Bakeries or Dessert Shop" />
                                                        <FormControlLabel value="Other" control={<Radio
                                                            sx={{
                                                                color: '#1D59F9',
                                                                '&.Mui-checked': {
                                                                    color: '#1D59F9   ',
                                                                },
                                                            }}
                                                        />} label="Other" />
                                                    </RadioGroup>
                                                </FormControl>

                                            </div>
                                        }
                                    </div>
                                    {/* <div
                                        className="select-Address my-2 px-3 d-flex align-items-center rounded-4 fw-semibold"
                                        style={styleLocation}
                                    >
                                        <BiCategory
                                            size={20}
                                            color="rgba(100, 116, 139, 1)"
                                        />
                                        <Autocomplete
                                            disablePortal
                                            options={businessOption}
                                            getOptionLabel={(option) => option.label}
                                            onChange={handleOptionChange}
                                            value={selectedOption}
                                            className="w-100"
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    value={address}
                                                    required
                                                    className="fw-normal"
                                                    onChange={(e) => setAddress(e.target.value)}
                                                    placeholder="Business category"
                                                />
                                                
                                            )}
                                        />
                                        <FaAngleDown    
                                            size={20}
                                            color="rgba(100, 116, 139, 1)"
                                        />
                                    </div> */}
                                    <div className="col-12 mt-2">
                                        <div className>
                                            <div className="  ">
                                                    <p className=" text-dark mb-1 text-start font-13 fw-medium ">
                                                        Logo
                                                    </p>
                                                <div className=' flex justify-center mb-3'>
                                                    <img height={180} width={180} className='rounded-4 mt-2' src={cnvsImage ? cnvsImage : upload} alt="loggo" />
                                                </div>
                                                <div>
                                                    <div className="flex justify-center">

                                                        <div className="bg-white d-flex justify-content-center rounded-5 p-2 !w-fit">

                                                            <ul className="nav nav-pills rounded-5 d-inline-flex" id="pills-tab" role="tablist">
                                                                <li className="nav-item" role="presentation">
                                                                    <button className="nav-link active rounded-5 fw-bold" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">New Upload</button>
                                                                </li>
                                                                <li className="nav-item" role="presentation">
                                                                    <button className="nav-link rounded-5 fw-bold" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Recent</button>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="tab-content mt-3" id="pills-tabContent">
                                                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex={0}>
                                                            <div className='compaign-detail-tab-img p-4' onClick={() => setEditImgModal(true)} style={{ borderBottomLeftRadius: "2rem", borderBottomRightRadius: "2rem" }}>
                                                                <div className='p-5  text-center rounded-5 ' style={{ border: '2px dashed #E2E6EA' }}>
                                                                    <p className='mb-0 text-dark-gray'>Click to browse or <br /> drag and drop your files</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade " id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab " tabIndex={0}>
                                                            <div className="p-3 bg-white rounded-2xl ">

                                                                <div className='' style={{ borderTop: "1.5px solid #EBEFF2" }}>
                                                                    <div className='  d-flex justify-content-between align-items-center p-2' style={{ borderBottom: "1.5px solid #EBEFF2" }}>

                                                                        <div className='d-flex '>

                                                                            <div>
                                                                                <GrGallery className='text-blue ' />
                                                                            </div>
                                                                            <div className='ms-2'>
                                                                                <p className='font-14 mb-0 fw-medium'>marketing-campaign-test-3.png</p>
                                                                                <p className='font-12 mb-0'>Just now</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='flex  gap-1 items-center'>
                                                                            <span className='p-2 rounded-2 font-12 fw-semibold ' style={{ border: "1px solid #EBEFF2" }}>
                                                                                929KB
                                                                            </span>
                                                                            <BsThreeDotsVertical />
                                                                        </div>
                                                                    </div>
                                                                    <div className='  d-flex justify-content-between align-items-center p-2' style={{ borderBottom: "1.5px solid #EBEFF2" }}>

                                                                        <div className='d-flex '>

                                                                            <div>
                                                                                <GrGallery className='text-blue ' />
                                                                            </div>
                                                                            <div className='ms-2'>
                                                                                <p className='font-14 mb-0 fw-medium'>marketing-campaign-test-3.png</p>
                                                                                <p className='font-12 mb-0'>Just now</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='flex  gap-1 items-center'>
                                                                            <span className='p-2 rounded-2 font-12 fw-semibold ' style={{ border: "1px solid #EBEFF2" }}>
                                                                                929KB
                                                                            </span>
                                                                            <BsThreeDotsVertical />
                                                                        </div>
                                                                    </div>
                                                                    <div className='text-center p-2 pb-0'>
                                                                        <button type='button' className='rounded-5 text-dark bg-transparent p-2 px-3 font-12 fw-semibold ' style={{ border: "1.5px solid #EBEFF2" }}>View all uploads</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className=" p-3 d-flex align-items-center" style={{ backgroundColor: "#F7F9FB  ", borderBottomLeftRadius: "2rem", borderBottomRightRadius: "2rem" }}>
                                                                <IoCheckmarkCircleOutline size={18} className='me-1' />
                                                                <p className='font-12 mb-0'>Last synced: 3 mins ago</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <input type="file" onChange={(e) => { setNewBusinessLogo(e.target.files[0]); setBusinessLogo(URL.createObjectURL(e.target.files[0])) }} id="file" className="d-none" /> */}
                                            </div>
                                        </div>
                                    </div>
                                    <p className="font-14 text-dark-gray inter mt-3">By continuing, you’re agreeing to these <u className='admin-text-blue cursor-pointer'>Terms of Service</u> and <u className='admin-text-blue  cursor-pointer'> Privacy Policy</u></p>
                                </div>
                            </div>
                            <Button disabled={isBtnDisable} type='submit' className={`${isBtnDisable ? "!bg-gray-300" : "btn-blueTwo"} rounded-4 inter px-3 mt-2 p-3 w-100 fw-bold font-16 text-capitalize`} sx={{ fontFamily: 'Inter, sans-serif !important' }}>{isLoading ? <Spinner color="light" /> : 'Continue'}</Button>

                        </form>


                    </Col>
                </Row>
                <ToastContainer />
            </Container>
            <Modal show={editImgModal} className='business_admin_main' centered onHide={() => setEditImgModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {image && (
                            <>
                                <div className="imag_crop_shadow d-flex justify-content-center ">

                                    <AvatarEditor
                                        width={150}
                                        height={150}
                                        ref={previewRef}
                                        border={50}
                                        borderRadius={14}
                                        color={[80, 76, 76, 0.6]}
                                        scale={scale}
                                        rotate={rotate}
                                        image={URL.createObjectURL(image)}
                                    />
                                </div>
                                <div className='mt-2'>
                                    <button role='button' className='btn-blue rounded-2 m-1' onClick={() => setScale(scale - 0.1)}><FiZoomOut /></button>
                                    <button role='button' className='btn-blue rounded-2 m-1' onClick={() => setScale(scale + 0.1)}><FiZoomIn /></button>

                                    <button role='button' className='btn-blue rounded-2 m-1' onClick={() => setRotate(rotate - 90)}><FaArrowRotateLeft /></button>
                                    <button role='button' className='btn-blue rounded-2 m-1' onClick={() => setRotate(rotate + 90)}><FaArrowRotateRight /></button>
                                </div>
                            </>
                        )}

                        <div className=" inputgroup1 p-3 mt-1 text-center ">

                            <label htmlFor="file" className='h-100 w-100 cursor-pointer' >
                                <div className="px-3 py-3 mb-3 ">
                                    <div className="flex justify-center">

                                        <img src={upload} width={60} alt="w8" />
                                    </div>
                                    <h6 className="font-15 font-500 inter colorblack mt-2 point">Drag &amp; drop
                                        files or <span className="colorblue text-decoration-underline">Browse</span>
                                    </h6>
                                    <p className="mb-0 mt-2 font-12 colorgray inter point">Supported
                                        formates: <br />
                                        JPEG, PNG</p>
                                </div>
                            </label>
                        </div>
                        <input type="file" id='file' onChange={(e) => setImage(e.target.files[0])} className="d-none" accept="image/*" />
                    </div>
                </Modal.Body>
                {image && <Modal.Footer>
                    <button role='button' className='btn-blue rounded-2 p-2 m-1' onClick={handlePreview}>Upload</button>

                </Modal.Footer>}
            </Modal>
        </>
    );
}

export default SignUpProfile;
// import React, { useState } from 'react';
// import Autocomplete from "react-google-autocomplete";

// const SignUpProfile = () => {
//   return (
//     <div>
//      <Autocomplete
//         apiKey="AIzaSyDBje1zQ4zWocNZzLc5zj4ol4khoiJ-PRs"
//         style={{ width: "90%" }}
//         onPlaceSelected={(place) => {
//           console.log(place);
//         }}
//         options={{
//           types: ["(regions)"],
//           componentRestrictions: { country: "ru" },
//         }}
//         defaultValue="Amsterdam"
//       />
//     </div>
//   );
// };

// export default SignUpProfile;
