import React from 'react'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import { IoMdCheckmark } from "react-icons/io";
import { Tooltip as ReactTooltip } from "react-tooltip";
const ComparePaymentTable = ({closeModal}) => {
  return (
    <>
      <div className="container">
        <div className="text-center">
          <div className='flex flex-row justify-between items-center'>
            <div>&nbsp;</div>
            <h1 className="text-4xl fw-bold blue-text">Compare TimeLink plans </h1>
            <p className='font-semibold text-base cursor-pointer' onClick={closeModal}>X</p>
          </div>
          <p className="text-secondary dm  font-18 blue-text fw-medium ">
            Discover the differences of each individual plan and learn more
            about our product
          </p>{" "}
        </div>
        <div className="redesign-table-main-section container overflow-x-auto  poppins my-5">
          <div className="redesign-table-inner w-full">
            <div className="row justify-content-end my-5">
              <div className="col-8">
                <div className="container">
                  <div className="row">
                    <div className="col-4 text-center">
                      <h3 className=" fw-bold font-26  ">Basic</h3>
                    </div>
                    <div className="col-4 text-center">
                      <h3 className=" fw-bold  font-26 blue-text">Premium</h3>
                    </div>
                    <div className="col-4 text-center">
                      <h3 className=" fw-bold  font-26 ">Growth</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ================ PRICING =================== */}
            <h5 className="fw-semibold mb-2">Pricing</h5>
            <div className="row g-0 p-3">
              <div className="col-4">
                <p className="mb-0 flex gap-2 items-center">
                  Price / month (billed annually){" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-1" />
                  </span>
                </p>
                <ReactTooltip
                  id="my-tooltip-1"
                  place="right"
                  content="billed monthly"
                />
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center">
                      <p className="mb-0 ">$46</p>
                    </div>
                    <div className="col-4 text-center">
                      <p className="mb-0 ">$75</p>
                    </div>
                    <div className="col-4 text-center">
                      <p className="mb-0 ">$90</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 bg-body-tertiary">
              <div className="col-4">
                <p className="mb-0  flex gap-2 items-center">
                  Price / month (billed annually){" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-2" />
                    <ReactTooltip
                      id="my-tooltip-2"
                      place="right"
                      style={{ maxWidth: '400px' }}
                      content="billed monthly"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center">
                      <p className="mb-0 ">$50</p>
                    </div>
                    <div className="col-4 text-center">
                      <p className="mb-0 ">$80</p>
                    </div>
                    <div className="col-4 text-center">
                      <p className="mb-0 ">$100</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ================= FEATURES ================ */}
            <h5 className="fw-semibold mt-5 mb-3">Features</h5>

            <div className="row g-0 p-3 bg-body-tertiary">
              <div className="col-4">
                <p className="mb-0  flex gap-2 items-center">
                  Digital Buzzer Feature{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-3" />
                    <ReactTooltip
                      id="my-tooltip-3"
                      place="right"
                      style={{ maxWidth: '400px' }}
                      content="Notifies customers digitally when their order is ready, enhancing the pickup experience and reducing wait times."
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3">
              <div className="col-4">
                <p className="mb-0  flex gap-2 items-center">
                  Marketing campaign{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-4" />
                    <ReactTooltip
                      id="my-tooltip-4"
                      place="right"
                      style={{ maxWidth: '400px' }}
                      content="Create and manage targeted marketing campaigns to engage customers and boost sales."
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center"></div>
                    <div className="col-4 text-center">
                      <p className="mb-0 ">1 per month</p>
                    </div>
                    <div className="col-4 text-center">
                      <p className="mb-0 ">2 per month</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 bg-body-tertiary">
              <div className="col-4">
                <p className="mb-0  flex gap-2 items-center">
                  Customer Loyalty Program{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-5" />
                    <ReactTooltip
                      id="my-tooltip-5"
                      place="right"
                      style={{ maxWidth: '400px' }}
                      // content="Reward repeat customers with points or discounts, fostering customer loyalty and increasing return visits"
                      content="Coming Soon"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center"></div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 ">
              <div className="col-4">
                <p className="mb-0  flex gap-2 items-center">
                  Survey Program{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-6" />
                    <ReactTooltip
                      id="my-tooltip-6"
                      place="right"
                      style={{ maxWidth: '400px' }}
                      content="Collect customer preferences through surveys to improve your services and understand customer needs"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center"></div>
                    <div className="col-4 text-center"></div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 bg-body-tertiary">
              <div className="col-4">
                <p className="mb-0  flex gap-2 items-center">
                  Request Google-Review
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-7" /><ReactTooltip
                      id="my-tooltip-7"
                      place="right"
                      style={{ maxWidth: '400px' }}
                      content="Easily prompt customers to leave a Google review, helping to build your online reputation
"
                    />

                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center"></div>
                    <div className="col-4 text-center"></div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 ">
              <div className="col-4">
                <p className="mb-0  flex gap-2 items-center">
                  Request Feedback{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-8" />
                    <ReactTooltip
                      id="my-tooltip-8"
                      place="right"
                      style={{ maxWidth: '400px' }}
                      content="Gather direct feedback from customers to enhance your products and services"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center"></div>
                    <div className="col-4 text-center"></div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 bg-body-tertiary">
              <div className="col-4">
                <p className="mb-0  flex gap-2 items-center">
                  Analytics & Insights{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-9" />
                    <ReactTooltip
                      id="my-tooltip-9"
                      place="right"
                      style={{ maxWidth: '400px' }}
                      content="Gain valuable insights into customer behaviour and sales performance with detailed analytics and reports"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center">
                      <p className="mb-0">Basic</p>
                    </div>
                    <div className="col-4 text-center">
                      <p className="mb-0">Comprehensive</p>
                    </div>
                    <div className="col-4 text-center">
                      <p className="mb-0">Comprehensive</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 ">
              <div className="col-4">
                <p className="mb-0  flex gap-2 items-center">
                  Business Console{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-10" />
                    <ReactTooltip
                      id="my-tooltip-10"
                      place="right"
                      style={{ maxWidth: '400px' }}
                      content="Centralised hub for managing all aspects of your business operations and TimeLink services"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 bg-body-tertiary">
              <div className="col-4">
                <p className="mb-0  flex gap-2 items-center">
                  SMS Notifications{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-11" />
                    <ReactTooltip
                      id="my-tooltip-11"
                      place="right"
                      style={{ maxWidth: '400px' }}
                      content="Automated SMS alerts to keep your customers informed about their order status in real-time"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row g-0 p-3 ">
              <div className="col-4">
                <p className="mb-0  flex gap-2 items-center">
                  Business Tablet Application{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-12" />
                    <ReactTooltip
                      id="my-tooltip-12"
                      place="right"
                      style={{ maxWidth: '400px' }}
                      content="Mobile application for managing orders, customer interactions, and business settings on the go"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 bg-body-tertiary">
              <div className="col-4">
                <p className="mb-0  flex gap-2 items-center">
                  Community{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-13" />
                    <ReactTooltip
                      id="my-tooltip-13"
                      place="right"
                      style={{ maxWidth: '400px' }}
                      content="Connect with other TimeLink users to share tips, best practices, and support (coming soon)."
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center"></div>
                    <div className="col-4 text-center"></div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ================= STATS & REPORT ================ */}
            <h5 className="fw-semibold mt-5 mb-3">STATS & REPORTS</h5>

            <div className="row g-0 p-3 bg-body-tertiary">
              <div className="col-4">
                <p className="mb-0  flex gap-2 items-center">
                  TimeLink Dashboard{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-14" />
                    <ReactTooltip
                      id="my-tooltip-14"
                      place="right"
                      style={{ maxWidth: '400px' }}
                      content="Overview of your business performance, including key metrics and insights"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3">
              <div className="col-4">
                <p className="mb-0  flex gap-2 items-center">
                  Daily Stats{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-15" />
                    <ReactTooltip
                      id="my-tooltip-15"
                      place="right"
                      style={{ maxWidth: '400px' }}
                      content="Summary of daily business performance, including sales and customer interactions"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 bg-body-tertiary">
              <div className="col-4">
                <p className="mb-0  flex gap-2 items-center">
                  Busiest Period{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-16" />
                    <ReactTooltip
                      id="my-tooltip-16"
                      place="right"
                      style={{ maxWidth: '400px' }}
                      content="Identify peak hours and busiest times to optimise staff and resources"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 ">
              <div className="col-4">
                <p className="mb-0  flex gap-2 items-center">
                  Daily Orders{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-17" />
                    <ReactTooltip
                      id="my-tooltip-17"
                      place="right"
                      style={{ maxWidth: '400px' }}
                      content="Track the number of orders processed each day to monitor business activity"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 bg-body-tertiary">
              <div className="col-4">
                <p className="mb-0  flex gap-2 items-center">
                  Customer Demographics{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-18" />
                    <ReactTooltip
                      id="my-tooltip-18"
                      place="right"
                      style={{ maxWidth: '400px' }}
                      content="Insights into customer profiles, including age, gender, and location"
                    />                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center"></div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 ">
              <div className="col-4">
                <p className="mb-0  flex gap-2 items-center">
                  Average wait-time{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-19" />
                    <ReactTooltip
                      id="my-tooltip-19"
                      place="right"
                      style={{ maxWidth: '400px' }}
                      content="Measure the average time customers wait for their orders to improve service efficiency
"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 bg-body-tertiary">
              <div className="col-4">
                <p className="mb-0  flex gap-2 items-center">
                  Age group{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-20" />
                    <ReactTooltip
                      id="my-tooltip-20"
                      place="right"
                      style={{ maxWidth: '400px' }}
                      content="Breakdown of customer age groups to better understand your audience"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center"></div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 ">
              <div className="col-4">
                <p className="mb-0  flex gap-2 items-center">
                  Repeat Customer Rate{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-21" />
                    <ReactTooltip
                      id="my-tooltip-21"
                      place="right"
                      style={{ maxWidth: '400px' }}
                      content="Track the percentage of customers who return for repeat purchases"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center"></div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 bg-body-tertiary">
              <div className="col-4">
                <p className="mb-0  flex gap-2 items-center">
                  Frequency of visits{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-22" />
                    <ReactTooltip
                      id="my-tooltip-22"
                      place="right"
                      style={{ maxWidth: '400px' }}
                      content="Monitor how often customers visit your business to gauge loyalty and satisfaction"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center"></div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 ">
              <div className="col-4">
                <p className="mb-0  flex gap-2 items-center">
                  Clicks{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-23" />
                    <ReactTooltip
                      id="my-tooltip-23"
                      place="right"
                      style={{ maxWidth: '400px' }}
                      content="Monitor the click-through-rate for marketing campaigns"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center"></div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 bg-body-tertiary">
              <div className="col-4">
                <p className="mb-0  flex gap-2 items-center">
                  Impressions{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-24" />
                    <ReactTooltip
                      id="my-tooltip-24"
                      place="right"
                      style={{ maxWidth: '400px' }}
                      content="The total number of times your content is displayed, regardless of clicks"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center"></div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 ">
              <div className="col-4">
                <p className="mb-0  flex gap-2 items-center">
                  Reach{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-25" />
                    <ReactTooltip
                      id="my-tooltip-25"
                      place="right"
                      style={{ maxWidth: '400px' }}
                      content="The number of unique users who see your content"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center"></div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 bg-body-tertiary">
              <div className="col-4">
                <p className="mb-0  flex gap-2 items-center">
                  Click-Through-Rate{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-26" />
                    <ReactTooltip
                      id="my-tooltip-26"
                      place="right"
                      style={{ maxWidth: '400px' }}
                      content="The percentage of users who click on your content after seeing it"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center"></div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 ">
              <div className="col-4">
                <p className="mb-0  flex gap-2 items-center">
                  Redeems{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-27" />
                    <ReactTooltip
                      id="my-tooltip-27"
                      place="right"
                      style={{ maxWidth: '400px' }}
                      content="Tracks how many promotional offers or loyalty rewards have been used by customers"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center"></div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ================= HARDWARE ================ */}
            <h5 className="fw-semibold mt-5 mb-3">HARDWARE</h5>

            <div className="row g-0 p-3 bg-body-tertiary">
              <div className="col-4">
                <p className="mb-0  flex gap-2 items-center">
                  TimeLink Connect Device{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-28" />
                    <ReactTooltip
                      id="my-tooltip-28"
                      place="right"
                      style={{ maxWidth: '400px' }}
                      content="The core hardware that enables seamless order tracking and customer notifications"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center">
                      <p className="mb-0">$5 p/m</p>
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 ">
              <div className="col-4">
                <p className="mb-0  flex gap-2 items-center">
                  Tablet (optional){" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-29" />
                    <ReactTooltip
                      id="my-tooltip-29"
                      place="right"
                      style={{ maxWidth: '400px' }}
                      content="An optional device for managing orders and customer interactions on the go"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center">
                      <p className="mb-0">$10 p/m</p>
                    </div>
                    <div className="col-4 text-center">
                      <p className="mb-0">$5 p/m</p>
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ================= SUPPORT ================ */}
            <h5 className="fw-semibold mt-5 mb-3">SUPPORT</h5>

            <div className="row g-0 p-3 bg-body-tertiary">
              <div className="col-4">
                <p className="mb-0  flex gap-2 items-center">
                  Priority support{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-30" />
                    <ReactTooltip
                      id="my-tooltip-30"
                      place="right"
                      style={{ maxWidth: '400px' }}
                      content="Fast-track assistance to resolve your issues quickly"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 ">
              <div className="col-4">
                <p className="mb-0  flex gap-2 items-center">
                  Access to help centre{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-31" />
                    <ReactTooltip
                      id="my-tooltip-31"
                      place="right"
                      style={{ maxWidth: '400px' }}
                      content="Comprehensive resources and guides to help you make the most of TimeLink"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="mt-5 text-center">

          <button type="button" className="btn-blue font-20 rounded-3 fw-bold " style={{ padding: "12px" }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            Start your free trial
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
        </div> */}
      </div>
    </>
  )
}

export default ComparePaymentTable
