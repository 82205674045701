import React from 'react'
import LoyaltyPointsHeader from '../../../components/LoyaltyPointsHeader'
import { useNavigate } from 'react-router-dom'

const CustomiseProgram = () => {
  const navigate = useNavigate();

  const handleNext =()=>{
navigate("/selectProgramType")
  }
  return (
    <>
      <main className='bg-white p-3' style={{ minHeight: "100vh" }}>

        <LoyaltyPointsHeader handleNext={handleNext}/>
        <div className="container inter customise-program mt-4">
          <div className="row justify-content-center ">
            <div className="col-lg-6 col-md-8 col-12">

              <p className='text-uppercase font-12 text-black-50'>Step 1 of 4</p>
              <h3 className='font-30 fw-bold '>Customise your program</h3>
              <p className='font-14 mt-3'>Specify if you want customers to earn Points.</p>

              <div >
                <div className="  mt-3">
                  <label role='button' className=" w-100 " htmlFor="point">
                    <input className=" d-none " defaultChecked type="radio" name="radioOptions" id="point" required />
                    <div className=" custom-radio w-100 p-14  rounded-3 ">
                     
                    <p className='font-14 mb-0'>Points</p>

                    </div>
                  </label>
                </div>
                {/* <div className="  mt-3">
                  <label role='button' className=" w-100 " htmlFor="stamp">
                    <input className=" d-none  " type="radio"  name="radioOptions" id="stamp" required />
                    <div className=" custom-radio w-100 p-14  rounded-3 ">
                      
                    <p className='font-14 mb-0'>Stamps</p>

                    </div>
                  </label>
                </div> */}
              </div>

            </div>
          </div>

        </div>

      </main>

    </>
  )
}

export default CustomiseProgram
