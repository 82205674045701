import React from 'react'
import { useNavigate } from 'react-router-dom'

const SurveysCard = (props) => {
    const Navigate = useNavigate();
    return (
        <>
            <div className="surveysone p-4">
                <div className="ps-4">
                    <h6 className="font-18 inter font-600 colorblack">{props.title}</h6>
                </div>
                <div className="d-flex justify-content-around pe-5 Created">
                    <div>
                        <p className="font-12 inter font-600 colorgray mb-0">Date
                            Created</p>
                        <h6 className="font-14 inter font-600 colorblack mt-3">{props.date}</h6>
                    </div>
                    <div className="Reach">
                        <p className="font-12 inter font-600 colorgray mb-0">Reach</p>
                        <h6 className="font-14 inter font-600 colorblack mt-3">
                            {props.reach}
                        </h6>
                    </div>
                    <div className="Reach">
                        <p className="font-12 inter font-600 colorgray mb-0">Completed
                            by:</p>
                        <h6 className="font-14 inter font-600 colorblack mt-3">{props.complete} </h6>
                    </div>
                </div>
                <div className=" ps-4 mt-3">
                    <h6 className="font-16 inter font-600 colorblack">Submissions</h6>
                    <button onClick={()=> props.isFeedBack? Navigate('/feedback') :Navigate('/surveyDetail',{ state: {surveyData:props.surveyData , isQtq : props.isQtq} })} className="btn-skywhite px-2 py-1 rounded-2">View
                        Submissions</button>
                </div>
            </div>

        </>
    )
}

export default SurveysCard
