import React from 'react'
import Header from '../components/Header'
import SideBar from '../components/SideBar'

const HelpCenter = () => {
    return (
        <section className='business_admin_main'>

            <div className="container-fluid setting-page">
                <div className="row order pt-3">

                    {/* <!-- ===========SID-BAR======= --> */}
                    <SideBar />
                    {/* <!-- ========= content body ======= --> */}

                    <div className="w-lg-80 px-0 inter h-screen overflow-y-scroll">
                    {/* <!-- ===========HEADER========== --> */}
                    <Header PageTitle="Help Center"/>
                    <div className="px-lg-4 px-md-3 px-2">

                        <div className="container-fluid py-3">
                            <div className="row justify-content-center">
                                <div className="col-lg-11 col-12 px-0  mt-3">
                                    <iframe className="w-100" height={500} src="https://www.knowledgebase.com/features/#knowledge-management" title="Newframe" frameBorder={0} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                                </div>
                            </div>
                        </div>
                    </div>

                    </div>

                </div>
            </div>
        </section>
    )
}

export default HelpCenter
