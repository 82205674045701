import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Button, TableHead, ThemeProvider, createTheme } from '@mui/material';
import ReactPaginate from 'react-paginate';
import Checkbox from '@mui/material/Checkbox';
import { BorderColor } from '@mui/icons-material';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};



function createData(name, customer, start, end, totalTime, status, dropdown) {
    return { name, customer, start, end, totalTime, status, dropdown };
}
const CompleteTableData = (props) => {
    const rows = [
        createData
            (
                <>
                    <div className='poppins d-flex align-items-center'>
                        <p className="font-14 mb-0" style={{ color: '#756F6F' }}>300</p>
                    </div>
                </>,
                <p className="mb-0 font-16 poppins">Yes<br />
                    1x this month</p>,
                <>
                    <p className="mb-0 font-16 poppins">January 20, 2023</p>
                    <p className="mb-0 font-16 poppins">12:15pm</p>
                </>,
                <p className="mb-0 font-16 poppins">-</p>,
                <p className="mb-0 font-16 poppins">00:02:30</p>,
                <p className="mb-0 font-16 poppins">Pending</p>,
                <div className="table-scroll">

                    <div className="dropdown">
                        <i className="fa-solid fa-ellipsis-vertical dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false" />
                        <ul className="dropdown-menu">
                            <li><a className="dropdown-item" href="#">delete</a></li>
                            <li><a className="dropdown-item" href="#">Edit</a></li>
                        </ul>
                    </div>
                </div>
            ),

    ].sort((a, b) => (a.customer < b.customer ? -1 : 1));
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [data, setData] = useState(rows.map(row => ({ ...row, selected: false }))); // Initialize selected state for each row
    const [selectAll, setSelectAll] = useState(false); // State for the master checkbox


    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    // -------------paginate---------------
    // Sample data (you can replace this with your actual data)
    // const data = Array.from({ length: 50 }, (_, i) => i + 1); // An array with numbers from 1 to 50
    const itemsPerPage = 5; // Number of items to show per page

    const [currentPage, setCurrentPage] = useState(0);

    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    const offset = currentPage * itemsPerPage;
    const currentPageData = rows.slice(offset, offset + itemsPerPage);
    console.log(currentPageData);

    const pageCount = Math.ceil(rows.length / itemsPerPage);

    const handleCheckboxChange = (name) => {
        setData((prevData) =>
            prevData.map((item) =>
                item.name === name ? { ...item, selected: !item.selected } : item
            )
        );
    };




    const handleMasterCheckboxChange = () => {
        const updatedData = data.map((item) => ({ ...item, selected: !selectAll }));
        setData(updatedData);
        setSelectAll((prevSelectAll) => !prevSelectAll);
    };



    const theme = createTheme({
        components: {
            MuiCheckbox: {
                styleOverrides: {
                    root: {
                        color: '#4318FF',


                    },
                },
            },
        },
    });
    //   const selectAll = data.every((item) => item.selected);

    return (
        <>


            <div className=" bg-white table-mui ProfileVisit mt-3" >
                <div className='' style={{ border: '1px solid #E9EDF7' }}>

                    <ThemeProvider theme={theme}>

                        <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
                            <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='pe-0' sx={{ width: "4%" }}>
                                            <Checkbox
                                                checked={selectAll}
                                                onChange={handleMasterCheckboxChange}
                                            />
                                        </TableCell>
                                        <TableCell className='ps-0'>Order</TableCell>
                                        <TableCell align='start'>Repeat<br />
                                            Customer?</TableCell>
                                        <TableCell align='start'>Start</TableCell>
                                        <TableCell align='start'>End</TableCell>
                                        <TableCell align='start'>Total Time	</TableCell>
                                        <TableCell align='start'>Status</TableCell>
                                        <TableCell align='start'></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {currentPageData.map((row) => (
                                        <TableRow key={row.name}>
                                            <TableCell className='pe-0' sx={{ width: "4%" }}>
                                                <Checkbox
                                                    checked={row.selected}
                                                    onChange={() => handleCheckboxChange(row.name)}
                                                />
                                            </TableCell>
                                            <TableCell className='ps-0 ' align='start' >
                                                {row.name}
                                            </TableCell>
                                            <TableCell align='start' verti>
                                                {row.customer}
                                            </TableCell>
                                            <TableCell align='start'>
                                                {row.start}
                                            </TableCell>
                                            <TableCell align='start'>
                                                {row.end}
                                            </TableCell><TableCell align='start'>
                                                {row.totalTime}
                                            </TableCell><TableCell align='start'>
                                                {row.status}
                                            </TableCell><TableCell align='start'>
                                                {row.dropdown}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </ThemeProvider>
                </div>
                <div className="container-fluid">

                <div className='d-flex justify-content-between align-content-center '>
                    <p className="mb-0 d-flex align-items-center">Showing 1 to 10 of 97 results</p>


                        <div className='reactPaginate px-3'>

                            <ReactPaginate
                                previousLabel={<FaAngleLeft />}
                                nextLabel={<FaAngleRight />}
                                breakLabel={'...'}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                // onRowsPerPageChange={handleChangeRowsPerPage}
                                onPageChange={handlePageChange}
                                containerClassName={'pagination'}
                                activeClassName={'active'}
                            />
                        </div>

                    </div>
                </div>
            </div>




        </>
    )
}

export default CompleteTableData 
