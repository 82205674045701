import React, { useEffect, useState } from 'react';
import LoyaltyPointsHeader from '../../../components/LoyaltyPointsHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { FaAngleDown } from 'react-icons/fa6';
import { RiErrorWarningLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createBusinessReward, getBusinessRewardDetails, seTgetErrorNull, setSumitCamgpaingState, updateBusinessReward } from '../../../redux/slices/userSlice';
const Rewards = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { getError, submitCampaign } = useSelector((state) => state.user)
  const [previousRewardId, setPreviousRewardId] = useState(null);
  useEffect(() => {
    if (submitCampaign) {
      if (submitCampaign.get('reward_id')) {
        dispatch(getBusinessRewardDetails(submitCampaign.get('reward_id'), function (data) {
          setPreviousRewardId(data.id)
          setDescription(data.description);
          setItemName(data.item_name);
          setRewardType(data.type.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase()));
          setDiscountType(data.discount_percentage ? 'percentage' : 'amount');
          setDiscountField(data.discount_percentage || data.discount_amount || 0)

        }))
      }
    }
  }, [submitCampaign])

  const [nextStep, setNextStep] = useState(false);
  const handleNext = () => {
    if (location?.state?.from === 'campaign') {
      const data = {
        type: rewardType?.split(' ').map(word => word.charAt(0).toLowerCase() + word.slice(1)).join('_'),
        description: description ? description : defaultText,
        item_name: itemName,
        discount_percentage: discountType === 'percentage' ? discountField : null,
        discount_amount: discountType === 'amount' ? discountField : null,
        program_type: 'campaign'
        // points_needed: selectedPoints === 'Custom' ? customPoints : selectedPoints
      }
      if (previousRewardId) {
        dispatch(updateBusinessReward(previousRewardId , data, handleResponse))

      } else {
        dispatch(createBusinessReward(data, handleResponse))
      }
    } else {


      const data = {
        type: rewardType?.split(' ').map(word => word.charAt(0).toLowerCase() + word.slice(1)).join('_'),
        description: description,
        item_name: itemName,
        discount_percentage: discountType === 'percentage' ? discountField : null,
        discount_amount: discountType === 'amount' ? discountField : null,
        points_needed: selectedPoints === 'Custom' ? customPoints : selectedPoints
      }

      if (previousRewardId) {
        dispatch(updateBusinessReward(previousRewardId , data, handleResponse))
      } else {
        dispatch(createBusinessReward(data, handleResponse))
      }
    }
  }

  function handleResponse(data) {
    if (location?.state?.from === 'campaign') {
      dispatch(setSumitCamgpaingState({ key: 'reward_id', value: data.id }))
      dispatch(setSumitCamgpaingState({ key: 'reward_name', value: data.description }))
      navigate('/custom/marketing/detail');
    }
    if (location?.state?.from === 'loyalty') {
      navigate(-1);
    }
    // navigate('/rewards')
  }

  const [createReward, setCreateReward] = useState(true);
  const [isFocused, setIsFocused] = useState(false);
  const [customPointsFocused, setCustomPointsFocused] = useState(false);
  const [description, setDescription] = useState(null);
  const [defaultText, setDefaultText] = useState("");
  const [isFocusedSelect, setIsFocusedSelect] = useState(false);
  const [rewardType, setRewardType] = useState("Discount on entire sale");
  const [itemName, setItemName] = useState();
  const [isFocusedDiscount, setIsFocusedDiscount] = useState(false);
  const [isFocusedItemName, setIsFocusedItemName] = useState(false);
  const [discountField, setDiscountField] = useState();
  const [discountType, setDiscountType] = useState('amount');
  const [selectedPoints, setSelectedPoints] = useState('500'); // Initial state
  const [customPoints, setCustomPoints] = useState(); // if custom option is selected for points

  const handleOptionSelect = () => {
    setIsFocusedSelect(!isFocusedSelect)
  };
  useEffect(() => {
    if (getError && getError.length > 1) {
      toast.error(getError, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(seTgetErrorNull());

    }
  }, [getError])
  return (
    <>
      <main className='bg-white p-3' style={{ minHeight: "100vh" }}>
        <LoyaltyPointsHeader handleNext={handleNext} />
        <div className="container inter customise-program mt-4 p-0 ">
          {nextStep ?
            <div className="row justify-content-center ">
              <div className="col-lg-6 col-md-8 col-12">
                <p className='text-uppercase font-12 text-black-50'>Step 3 of 4</p>
                <h3 className='font-30 fw-bold '>Set up your rewards</h3>
                <p className='font-14 mt-3'>Create up to 10 rewards that your customers can redeem.</p>
                <div className="p-3 rounded-3 d-flex align-items-center justify-content-between" style={{ boxShadow: "0px 0px 4px 0px #0000001A" }}>
                  <div className=' '>
                    <p className='mb-0 font-14 fw-bold '>A$10.00 off entire sale (100 Points)</p>
                    <p className='mb-0 font-14'>Discount on entire sale</p>
                  </div>
                  {/* <button type='button' className="btn-poin-gray rounded-3 text-black p-2"
                  ><RiDeleteBin6Line size={18} /></button> */}
                </div>
              </div>
            </div>
            :
            <div className={`row justify-content-${rewardType ? !createReward ? "center" : "end" : "center "} `}>
              <div className="col-lg-6 col-md-8 col-12">
                <p className='text-uppercase font-12 text-black-50'>Step 3 of 4</p>
                <h3 className='font-30 fw-bold '>Set up your rewards</h3>
                <p className='font-14 mt-3'>Create up to 10 rewards that your customers can redeem.</p>
                {createReward &&
                  <div className="p-3 rounded-3" style={{ boxShadow: "0px 0px 4px 0px #0000001A" }}>

                    <div className="custom-field">
                      <div className='d-flex align-items-center justify-content-between mt-2'>
                        <p className='mb-0'>Reward 1</p>
                        {/* <button type='button' className="btn-poin-gray rounded-3 text-black p-2"
                          onClick={() => setCreateReward(false)}><RiDeleteBin6Line size={18} /></button> */}
                      </div>

                      <div role="button" onClick={handleOptionSelect}
                        className={`focus-field p-2 mt-3 pb w-100 rounded-3 position-relative  ${isFocusedSelect ? 'active' : ''}`} >
                        <div className="w-100 h-100  m-0 rounded-3 d-flex align-items-center justify-content-between ">
                          <div>

                            <p className="font-14 mb-0 text-black-50">Reward Type?</p>
                            {rewardType && <p className="font-14 mb-0 ">{rewardType}</p>}
                          </div>
                          <FaAngleDown className='text-black-50' />
                        </div>
                        {isFocusedSelect && <div className="position-absolute  bottom-0 bg-white w-100 shadow start-0 rounded-3 p-2">

                          <div className="option" onClick={() => { setRewardType("Discount on entire sale"); setIsFocusedSelect(!isFocusedSelect) }}>Discount on entire sale</div>
                          <div className="option" onClick={() => { setRewardType("Free Item"); setIsFocusedSelect(!isFocusedSelect) }}>Free Item</div>
                        </div>}

                      </div>

                      {rewardType && (
                        <>
                          {
                            rewardType === 'Discount on entire sale' &&
                            <div className={`focus-field rounded-3 mt-3 d-flex align-items-center ${isFocusedDiscount ? "active" : ""}`} onFocus={() => setIsFocusedDiscount(true)}
                              onBlur={() => setIsFocusedDiscount(false)}>
                              <div className="d-flex justify-content-between align-items-center w-100 p-2">
                                <input type="text" className='' placeholder={`${discountType === 'amount' ? "Discount Dollar" : "Discount Percentege"}`} value={discountField}
                                  onChange={(e) => {
                                    setDiscountField(e.target.value);
                                    if (discountType === 'percentage') {
                                      setDefaultText(e.target.value + '% Off entire sale')
                                    } else if (discountType === 'amount') {
                                      setDefaultText('$' + e.target.value + ' Off entire sale')
                                    }
                                  }} />
                                <div class="btn-group-reward rounded-3 "  >
                                  <button type="button" class={`p-2 rounded-start ${discountType === 'amount' ? "active" : ""}`} onClick={() => { setDiscountType('amount'); setDefaultText('$' + discountField + ' Off entire sale') }}>$</button>
                                  <button type="button" class={`p-2 rounded-end  ${discountType === 'percentage' ? "active" : ""}`} onClick={() => { setDiscountType('percentage'); setDefaultText(discountField + '% Off entire sale') }}>%</button>
                                </div>
                              </div>
                            </div>
                          }

                          {
                            rewardType === 'Free Item' &&
                            <div className={`focus-field rounded-3 mt-3 d-flex align-items-center ${isFocusedItemName ? "active" : ""}`} onFocus={() => setIsFocusedItemName(true)}
                              onBlur={() => setIsFocusedItemName(false)}>
                              <div className="d-flex justify-content-between align-items-center w-100 p-2">
                                <input type="text" className='' placeholder='Item Name' value={itemName}
                                  onChange={
                                    (e) => {
                                      setItemName(e.target.value);

                                    }}

                                />
                              </div>
                            </div>
                          }

                          <div className="container-fluid p-0">
                            {
                              location?.state?.from !== 'campaign' &&

                              <div className='row g-2 mt-3'>
                                <p className='font-14 fw-bold py-3 my-0'>Points needed to earn this reward <RiErrorWarningLine className='text-gray' /></p>
                                <div className="col-3  mt-2">
                                  <label role='button' className=" w-100 " htmlFor="five">
                                    <input className=" d-none "
                                      value="500"
                                      checked={selectedPoints === '500'}
                                      onChange={(e) => setSelectedPoints(e.target.value)}
                                      defaultChecked type="radio" name="point" id="five" required />
                                    <div className=" custom-radio w-100 p-14 d-flex justify-content-center align-items-center rounded-3 " style={{ height: "74px" }}>
                                      <p className='fw-bold mb-0 '>500</p>
                                    </div>
                                  </label>
                                </div>
                                <div className="col-3  mt-2">
                                  <label role='button' className=" w-100 " htmlFor="one">
                                    <input className=" d-none  "
                                      value="1000"
                                      checked={selectedPoints === '1000'}
                                      onChange={(e) => setSelectedPoints(e.target.value)}
                                      type="radio" name="point" id="one" required />
                                    <div className=" custom-radio w-100 p-14 d-flex justify-content-center align-items-center rounded-3 " style={{ height: "74px" }}>
                                      <p className='fw-bold mb-0'>1000</p>
                                    </div>
                                  </label>
                                </div>
                                <div className="col-3  mt-2">
                                  <label role='button' className=" w-100 " htmlFor="two">
                                    <input className=" d-none  "
                                      value="2000"
                                      checked={selectedPoints === '2000'}
                                      onChange={(e) => setSelectedPoints(e.target.value)}
                                      type="radio" name="point" id="two" required />
                                    <div className=" custom-radio w-100 p-14 d-flex justify-content-center align-items-center rounded-3 " style={{ height: "74px" }}>
                                      <p className='fw-bold mb-0'>2000</p>
                                    </div>
                                  </label>
                                </div>
                                <div className="col-3  mt-2">
                                  <label role='button' className=" w-100 " htmlFor="custom">
                                    <input className=" d-none  "
                                      value="Custom"
                                      checked={selectedPoints === 'Custom'}
                                      onChange={(e) => setSelectedPoints(e.target.value)}
                                      type="radio" name="point" id="custom" required />
                                    <div className=" custom-radio w-100 p-14 d-flex justify-content-center align-items-center  rounded-3 " style={{ height: "74px" }}>
                                      <p className='fw-bold mb-0'>Custom</p>
                                    </div>
                                  </label>
                                </div>
                              </div>
                            }
                          </div>
                          <br />
                          {
                            selectedPoints === 'Custom' &&

                            <label role="button" onFocus={() => setCustomPointsFocused(true)}
                              onBlur={() => setCustomPointsFocused(false)} className={`focus-field p-2 pb w-100 rounded-3  ${customPointsFocused ? 'active' : ''}`} >
                              <div className="w-100 m-0 rounded-3">
                                <p className="font-14 mb-0 ">Points Needed</p>
                              </div>
                              <input
                                className="w-100 border-0 font-15 rounded-3"
                                type="number" min={500}
                                value={customPoints} onChange={(e) => setCustomPoints(e.target.value)}
                                required
                                placeholder='e.g 3000'
                                onBlur={(e) => { if (e.target.value < 500) { setCustomPoints(500) } }}

                              />
                            </label>
                          }
                          <br />
                          <p>Reward display</p>
                          <p className='font-14'>Your reward name will be displayed to your customers in places like their loyalty & offers hub. </p>
                          <label role="button" onFocus={() => setIsFocused(true)}
                            onBlur={() => setIsFocused(false)} className={`focus-field p-2 pb w-100 rounded-3  ${isFocused ? 'active' : ''}`} >
                            <div className="w-100 m-0 rounded-3">
                              <p className="font-14 mb-0 ">Reward display</p>
                            </div>
                            <input
                              className="w-100 border-0 font-15 rounded-3"
                              type="text" maxLength={50}
                              value={description ? description : defaultText} onChange={(e) => setDescription(e.target.value)}
                              required
                              placeholder='Off entire sale!'

                            />
                          </label>
                          <p className='text-dark-gray font-12 mt-2'>{!description ? '0' : description?.length}/50 characters</p>
                          <Button type='button' className='btn-poin-gray fw-bold text-blue mt-3' onClick={() => { handleNext(); }} >
                            Save
                          </Button>
                        </>
                      )}
                    </div>

                  </div>}

              </div>
              {createReward && <>

                {rewardType &&
                  <div className="col-lg-3 col-md-4 col-12 mt-3 d-flex flex-column justify-content-end ">
                    {/* <div className="focus-field p-2 rounded-3">
                      <p className='font-14 mb-1 fw-bold'>Point value</p>
                      <p className='font-14 mb-0'>{selectedPoints === 'Custom' ? customPoints : selectedPoints}</p>
                    </div>
                    <br />
                    <br />
                    <div className="focus-field p-2 rounded-3">
                      <p className=' mb-1 fw-bold'>Reward name</p>
                      <p className=' mb-0'>{description.length > 0 ? description : 'A$10.00 off entire sale'}</p>
                    </div>
                    <br /> */}
                  </div>}
              </>}
            </div>}
          {/* <div className="row justify-content-center ">
            <div className="col-lg-6 col-md-8 col-12">
              <Button type='button' className='btn-poin-gray fw-bold text-blue mt-3'
                disabled={createReward}
                style={{ textTransform: "math-auto" }} onClick={() => setCreateReward(true)} >
                <FaPlus />&nbsp;{!createReward ? nextStep ? "Create another reward" : "Create a reward" : "Create another reward"}
              </Button>
            </div>
          </div> */}
        </div>
      </main>
      <ToastContainer />
    </>
  )
}

export default Rewards;
