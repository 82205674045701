import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import Items from './Items';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';

export default function PaginatedItems({ itemsPerPage, items , ItemsComponent }) {
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    // Simulate fetching items from another resources.
    // (This could be items from props; or items loaded in a local state
    // from an API endpoint with useEffect and useState)
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = items.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(items.length / itemsPerPage);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % items.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };

    return (
        <>
            <ItemsComponent currentItems={currentItems} />
            <div className="d-flex w-100 flex-row-reverse">
                <ReactPaginate
                    nextLabel={<div className='flex gap-1 no-underline cursor-pointer text-sm font-medium items-center text-customBlue'>Next <FaAngleRight  size={18} /></div>}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel={<div className='flex gap-1  text-sm font-medium  items-center text-customBlue'> <FaAngleLeft  size={18} />Previous</div>}
                    pageClassName="page-item rounded"
                    pageLinkClassName="page-link rounded-lg bg-customBlue h-9 w-9 justify-center flex item-center"
                    previousClassName="page-item "
                    previousLinkClassName="page-link bg-white border-0"
                    nextClassName="page-item"
                    nextLinkClassName="page-link bg-white border-0"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                    containerClassName="pagination flex justify-center items-center space-x-2 mt-4"
      
                />
            </div>
        </>
    );
}
