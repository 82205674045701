import React from 'react'
import { Navigate, useLocation , Outlet} from "react-router-dom"

const ProtectedRoutes = ({ children }) => {
    const token = localStorage.getItem('userToken');
    let location = useLocation();

    if (token) {
        return <Navigate to="/" state={{ from: location }} replace />
    }
    return <Outlet />

};

export default ProtectedRoutes;