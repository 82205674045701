/* eslint-disable eqeqeq */
import React from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateLoyaltyStatus } from '../../redux/slices/userSlice';
import { FaAngleDown } from 'react-icons/fa6';
import { Menu, MenuItem } from '@mui/material';

export default function RewardItem({ currentItems }) {
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const [anchorElArray, setAnchorElArray] = React.useState([]);

    const handleClick = (index) => (event) => {
        const newAnchorElArray = [...anchorElArray];
        newAnchorElArray[index] = event.currentTarget;
        setAnchorElArray(newAnchorElArray);
    };

    const handleClose = (index) => {
        const newAnchorElArray = [...anchorElArray];
        newAnchorElArray[index] = null;
        setAnchorElArray(newAnchorElArray);
    };

    function handleUpdateLoyaltyStatus(id, currentStatus) {
        var data = {
            loyalty_id: id,
            status: currentStatus ? 'active' : 'inactive'
        }
        dispatch(updateLoyaltyStatus(data))
    }
    return (
        <>
            {
                currentItems.map((index) => {
                    return (
                        <div className="mt-3  pb-3" style={{ borderBottom: "1px solid #E4E5E7", minHeight: "90px" }}>
                            <div className="row">
                                <div className="col-lg-2 text-center">
                                    <div >

                                        <div className="ps-2">
                                            <p className="mb-0 font-14 fw-medium  inter colorgray">{index?.program_type === 'campaign'?'No Points Needed':'Points Needed'} </p>
                                            <h3 className={`mb-0 mt-1 ${index?.program_type === 'campaign'?'font-14 ':'font-16'}  fw-semibold inter colorblack`}>{index?.program_type === 'campaign'?'Campaign Reward':index?.points_needed}</h3>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-3 d-flex flex-column align-items-center justify-content-center ">
                                    <p className='fw-semibold font-18 text-dark-gray mb-0'>{index?.type?.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</p>
                                    <p className='fw-normal font-14 text-dark-gray mb-0'>{index?.type == 'free_item' ?  index?.item_name :  index?.discount_percentage ? index?.discount_percentage+' %' : index?.discount_amount + ' $'}</p>
                                </div>
                                <div className="col-lg-4 col-12 my-lg-0 my-2  ">
                                    <div className='d-flex flex-row text-center align-items-center justify-content-evenly  '>

                                        <div>
                                            <h3 className='font-30 fw-semibold mb-0'>{index.issued_count}</h3>
                                            <p className='font-20 fw-medium mb-0 text-gray'>Issued</p>
                                        </div>
                                        <div className='ms-1'>
                                            <h3 className='font-30 fw-semibold mb-0'>{index.redeemed_count}</h3>
                                            <p className='font-20 mb-0 fw-medium text-gray'>Redeemed</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-3 d-flex flex-row align-items-center justify-content-between  '>
                                    <div className='business_admin_main '>
                                        <div className="step-three">

                                            <div className="container-stepOne " style={{ transform: "scale(1.2)" }}>
                                                <input type="checkbox" className="checkbox" id={`checkbox${index.id}`}
                                                    onChange={(e) => { handleUpdateLoyaltyStatus(index.id, e.target.checked) }}
                                                    checked={index.status == 'active' ? true : false}
                                                />
                                                <label className="switch" htmlFor={`checkbox${index.id}`} >
                                                    <span className="slider" />
                                                </label>
                                            </div>
                                        </div>

                                    </div>


                                    <div >
                                        <span role='button' style={{ background: "#D9D9D9", }}
                                            id={`basic-button-${index.id}`}
                                            aria-controls={`basic-menu-${index.id}`}
                                            aria-haspopup="true"
                                            onClick={handleClick(index.id)}
                                            className='d-flex flex-row align-items-center pe-2 rounded-3 '
                                        >
                                            <span className='p-2 fw-bold font-12' style={{ borderRight: "1.5px solid #E4E5E7" }}>View</span>
                                            <span className='ps-2'>

                                                <FaAngleDown size={14} />
                                            </span>
                                        </span>
                                        <Menu
                                            id={`basic-menu-${index.id}`}
                                            anchorEl={anchorElArray[index.id]}
                                            open={Boolean(anchorElArray[index.id])}
                                            onClose={() => handleClose(index.id)}
                                            MenuListProps={{
                                                'aria-labelledby': `basic-button-${index.id}`,
                                            }}
                                        >
                                            <MenuItem className='font-14' onClick={() => { handleClose(index.id) }}>Edit</MenuItem>
                                            <MenuItem className='font-14' onClick={() => { handleClose(index.id); Navigate('/loyalty/detail/new', { state: { loyalty: index } }) }}>View Statistics</MenuItem>

                                        </Menu>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </>
    )
}
