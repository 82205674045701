import React, { useEffect, useState } from "react";
import SideBar from "../../../components/superAdmin/SideBar";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createBlog, seTgetErrorNull } from "../../../redux/slices/userSlice";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
const BlogAdd = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading, getError } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    if (getError && getError.length > 1) {
      toast.error(getError, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(seTgetErrorNull());

    }
  }, [getError]);
  function handleResponse(response) {
    if (response?.status) {
        navigate('/super/blog')
      }
  }
  const [profile, setProfile] = useState(null);
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [image, setImage] = useState(null);
  const [description, setDescription] = useState("");
  const [metatags, setMetaTags] = useState([{ name: "", description: "" }]);

  const handleAddMetaTag = () => {
    setMetaTags([...metatags, { name: "", description: "" }]);
  };

  const handleRemoveMetaTag = (index) => {
    const updatedMetaTags = metatags.filter((_, i) => i !== index);
    setMetaTags(updatedMetaTags);
  };

  const handleMetaChange = (index, field, value) => {
    const updatedMetaTags = metatags.map((meta, i) =>
      i === index ? { ...meta, [field]: value } : meta
    );
    setMetaTags(updatedMetaTags);
  };
  const createSlug = (title) => {
    return title
        .toLowerCase() // Convert to lowercase
        .replace(/[^\w\s-]/g, '') // Remove special characters
        .replace(/\s+/g, '-') // Replace spaces with hyphens
        .replace(/-+/g, '-'); // Handle multiple hyphens
};

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validation
    for (const meta of metatags) {
      if (!meta.name || !meta.description) {
        alert("All meta tags must have a name and description.");
        return;
      }
    }
    const slug = createSlug(title);

    // Create form data
    const formData = new FormData();
    formData.append("avatar_file", profile);
    formData.append("author_name", name);
    formData.append("title", title);
    formData.append("slug", slug);
    formData.append("file", image);
    formData.append("description", description);
    formData.append("meta", JSON.stringify(metatags));
    dispatch(createBlog(formData, handleResponse))
  };

  return (
    <SideBar>
      <div className="super">
        <div className="px-lg-4 px-md-3 px-2 poppins inline">
          <h1 className="relative font-medium">Add Blog</h1>
          <form onSubmit={handleSubmit} className="bg-white shadow-md mt-4 rounded-xl p-3">
            <div className="grid lg:grid-cols-2 gap-4 mt-4">
              {/* Avatar Image */}
              <div>
                <p className="text-black text-sm mb-1">Avatar Image</p>
                <label htmlFor="avatar" className="w-full h-12 border border-gray-200 text-sm rounded-lg flex justify-center items-center font-medium">
                  {profile ? profile.name : "Upload Avatar"}
                </label>
                <input
                  type="file"
                  required
                  id="avatar"
                  onChange={(e) => setProfile(e.target.files[0])}
                  className="hidden"
                />
              </div>

              {/* Avatar Name */}
              <div>
                <label className="text-black text-sm">Avatar Name</label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  className="w-full h-12 border border-gray-200 text-sm rounded-lg p-2"
                />
              </div>

              {/* Title */}
              <div>
                <label className="text-black text-sm">Title</label>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                  className="w-full h-12 border border-gray-200 text-sm rounded-lg p-2"
                />
              </div>

              {/* Image */}
              <div>
                <p className="text-black text-sm mb-1">Image</p>
                <label htmlFor="image" className="w-full h-12 border border-gray-200 text-sm rounded-lg flex justify-center items-center font-medium">
                  {image ? image.name : "Upload Image"}
                </label>
                <input
                  type="file"
                  required
                  id="image"
                  onChange={(e) => setImage(e.target.files[0])}
                  className="hidden"
                />
              </div>
            </div>

            {/* Description */}
            <div className="mt-4">
              <ReactQuill
                theme="snow"
                value={description}
                onChange={setDescription}
                className="quill-editor"
                modules={{
                  toolbar: [
                    ["bold", "italic", "underline", "strike"],
                    ["blockquote", "code-block"],
                    [{ header: 1 }, { header: 2 }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ script: "sub" }, { script: "super" }],
                    [{ indent: "-1" }, { indent: "+1" }],
                    [{ direction: "rtl" }],
                    [{ size: ["small", false, "large", "huge"] }],
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    [{ color: [] }, { background: [] }],
                    [{ font: [] }],
                    [{ align: [] }],
                    ["clean"],
                  ],
                }}
              />
            </div>

            {/* Meta Tags */}
            <div className="mt-4">
              {metatags.map((meta, index) => (
                <div key={index} className="flex flex-row items-center gap-4 mb-2">
                  <div>
                    <label className="text-black text-sm">Meta Name</label>
                    <input
                      type="text"
                      value={meta.name}
                      onChange={(e) => handleMetaChange(index, "name", e.target.value)}
                      required
                      className="w-full border border-gray-200 text-sm rounded-lg p-2"
                    />
                  </div>
                  <div>
                    <label className="text-black text-sm">Meta Description</label>
                    <input
                      type="text"
                      value={meta.description}
                      onChange={(e) => handleMetaChange(index, "description", e.target.value)}
                      required
                      className="w-full border border-gray-200 text-sm rounded-lg p-2"
                    />
                  </div>
                  {index > 0 && (
                    <button
                      type="button"
                      onClick={() => handleRemoveMetaTag(index)}
                      className=" text-red-500  mt-4"
                    >
                      <MdDelete size={30} />
                    </button>
                  )}
                </div>
              ))}
              <button
                type="button"
                onClick={handleAddMetaTag}
                className="px-3 p-2 bg-customBlue font-medium rounded-full mt-4 text-white"
              >
                Add More Meta Field
              </button>
            </div>

            <div className="flex justify-center">
              <button type="submit" className="p-3 w-1/2 bg-customBlue font-medium rounded-xl mt-4 text-white">
                {
                  isLoading ? <Spinner color="white" size="md" />:'Submit'
                }
                
              </button>
            </div>
          </form>
        </div>
      </div>
              <ToastContainer />

    </SideBar>
  );
};

export default BlogAdd;
