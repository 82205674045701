import React from 'react'
import DataTable from 'react-data-table-component'
import { FaArrowDown } from 'react-icons/fa';
import { RxCross1 } from "react-icons/rx";
import { ImSpinner } from "react-icons/im";
import { MdOutlineCheck } from 'react-icons/md';
import toggle from '../assets/imges/table-toggle.png'
import { BsThreeDotsVertical } from 'react-icons/bs';
const LoyaltyDetalTable = () => {

    const columns = [
        {
            name: 'New Customer or Repeat?',
            selector: row => <>
                <p className="font-15 mb-1  fw-medium roboto" style={{ color: '#323C47' }}>
                    {row.customer}
                </p>
            </>,
            sortable: true,
        },

        {
            name: 'Gender',
            selector: row => <p className="font-15 mb-1  fw-medium roboto" style={{ color: '#7D7D7D' }}>
                {row.gender}
            </p>,
            sortable: true,
        },

        {
            name: 'Age Group',
            selector: row => <p className="font-15 mb-1 fw-medium roboto" style={{ color: '#7D7D7D' }}>
                {row.age}
            </p>,
            sortable: true,
        },

        {
            name: 'Frequency',
            selector: row => <>
                <p className="font-13 mb-0 font-medium roboto" style={{ color: '#7D7D7D' }}>
                    {row.frequency}
                </p>
            </>
            ,
            sortable: true,


        },
        {
            name: 'Stamps added',
            selector: row => <>
                <p className="font-13 mb-0 font-medium roboto" style={{ color: '#7D7D7D' }} >
                    {row.stamps}
                </p>
            </>
            ,
            sortable: true,


        },
        {
            name: 'Redemptions',
            selector: row => <>
                <p className="font-13 mb-0 font-medium roboto" style={{ color: '#7D7D7D' }}>
                    {row.redemptions}
                </p>
            </>
            ,
            sortable: true,


        },
        {
            name: '',
            selector: row => (
                <div className='roboto table-dropdown' style={{ color: '#7D7D7D' }}>
                    <div className="dropdown position-static ">
                        <span className=" dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false" >

                            <BsThreeDotsVertical />
                        </span>
                        <ul className="dropdown-menu">
                            <li><a className="dropdown-item" href="#">View</a></li>
                            <li><a className="dropdown-item" href="#">Pause</a></li>
                            <li><a className="dropdown-item" href="#">Delete</a></li>
                            <li><a className="dropdown-item" href="#">Reactivate</a></li>
                        </ul>
                    </div>
                </div>

            ),
            sortable: true,


        },
    ];
    const data = [
        {
            id: 1,
            customer: 'Repeat',
            gender: 'Male',
            age: '18-24',
            frequency: '3 visit',
            stamps: '1',
            redemptions: '0',
        },
        {
            id: 2,
            customer: 'Repeat',
            gender: 'Male',
            age: '18-24',
            frequency: '3 visit',
            stamps: '1',
            redemptions: '0',
        },

    ]


    const customStyles = {
        rows: {
            style: {
                minHeight: '80px', // override the row height

            },
        },
        headCells: {
            style: {
                paddingLeft: '15px', // override the cell padding for head cells
                paddingRight: '15px',
                color: '#7D7D7D',
                fontWeight: '500',
                borderBottom: '1px solid #F0F0F5',
                fontSize: '14px !important'
            },
        },
        cells: {
            style: {
                paddingLeft: '15px', // override the cell padding for data cells
                paddingRight: '10px',
            },
        },
    };

    return (
        <>
            {data.length == 0 &&
                <div className="  ">
                    <div className='d-flex  py-3' style={{
                        borderTop: '1px solid #1D59F9',
                        borderBottom: '1px solid #1D59F9',
                        background: 'rgba(29, 89, 249, 0.15)',
                    }}>
                        <div className="col-5 px-3">

                            <p className="font-14 inter  mb-0">Survey name</p>
                        </div>
                        <div className="col-3">

                            <p className="font-14 inter  mb-0">Status</p>
                        </div>
                        <div className="col-4">

                            <p className="font-14 inter  mb-0">Last edited</p>
                        </div>



                    </div>
                    <div className="col-12 text-center my-5 py-5">
                        <p className="font-12 font-500 inter colorgray mb-0">No current surveys </p>
                        <p className="font-12 font-500 inter colorgray mb-0">
                            All surveys are only active for 28 Days.
                        </p>
                        <p className="font-12 font-500 inter colorgray mb-0">
                            A maximum of three questions can be active at once, with back-up questions enabled
                            for questions with retargeting turned off.</p>
                        <p />
                    </div>
                </div>
            }
            <DataTable
                columns={columns}
                data={data}
                selectableRows
                customStyles={customStyles}
                // expandableRowsComponent={ExpandedComponent}
                // sortIcon={sortIcon}
                highlightOnHover
                pointerOnHover

            />


        </>
    )
}

export default LoyaltyDetalTable


