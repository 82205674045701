import React from "react";
import SideBar from "../../components/superAdmin/SideBar";
import { Button } from "@mui/material";
import { HiOutlineViewGridAdd } from "react-icons/hi";
import BusinessManagmentTable from "../../components/superAdmin/BusinessManagment/BusinessManagmentTable";
import Header from "../../components/superAdmin/Header";
import DeviceCard from "../../components/superAdmin/Device/DeviceCard";
import { FaArrowUp } from "react-icons/fa";
import { AiFillShop } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { getBusinessesList } from "../../redux/slices/userSlice";
import { Spinner } from "reactstrap";
const BusinessManagment = () => {
  const dispatch = useDispatch();
  const { isLoading, getError, businessesList } = useSelector(
    (state) => state.user
  );
  React.useEffect(() => {
    dispatch(getBusinessesList());
  }, []);
  const cardSvg = () => {
    return (
      <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M28.3333 9.9165H25.5C24.7485 9.9165 24.0279 10.215 23.4965 10.7464C22.9652 11.2777 22.6666 11.9984 22.6666 12.7498V28.3332H21.25V16.9998C21.25 16.2484 20.9515 15.5277 20.4201 14.9964C19.8888 14.465 19.1681 14.1665 18.4166 14.1665H15.5833C14.8319 14.1665 14.1112 14.465 13.5798 14.9964C13.0485 15.5277 12.75 16.2484 12.75 16.9998V28.3332H11.3333V22.6665C11.3333 21.9151 11.0348 21.1944 10.5034 20.663C9.9721 20.1317 9.25143 19.8332 8.49998 19.8332H5.66665C4.9152 19.8332 4.19453 20.1317 3.66318 20.663C3.13182 21.1944 2.83331 21.9151 2.83331 22.6665V29.7498C2.83331 30.1256 2.98257 30.4859 3.24824 30.7516C3.51392 31.0172 3.87426 31.1665 4.24998 31.1665H29.75C30.1257 31.1665 30.486 31.0172 30.7517 30.7516C31.0174 30.4859 31.1666 30.1256 31.1666 29.7498V12.7498C31.1666 11.9984 30.8681 11.2777 30.3368 10.7464C29.8054 10.215 29.0848 9.9165 28.3333 9.9165ZM5.66665 28.3332V22.6665H8.49998V28.3332H5.66665ZM15.5833 28.3332V16.9998H18.4166V28.3332H15.5833ZM25.5 28.3332V12.7498H28.3333V28.3332H25.5Z"
          fill="#232323"
        />
        <path
          d="M5.39752 17.8218L11.8717 8.75513L19.38 11.3335C19.5706 11.3982 19.7728 11.4215 19.9731 11.402C20.1734 11.3824 20.3672 11.3205 20.5417 11.2201L30.4583 5.55346C30.7852 5.3656 31.0241 5.05558 31.1224 4.69161C31.2207 4.32763 31.1704 3.93951 30.9825 3.61263C30.7947 3.28575 30.4846 3.04689 30.1207 2.94859C29.7567 2.85029 29.3686 2.9006 29.0417 3.08846L19.6775 8.37263L11.7867 5.73763C11.4989 5.64218 11.1881 5.64159 10.8999 5.73594C10.6117 5.8303 10.3615 6.01461 10.1859 6.2618L3.10252 16.1785C2.88599 16.4833 2.79873 16.8613 2.85977 17.2302C2.92081 17.599 3.12518 17.9288 3.42835 18.1476C3.6675 18.3208 3.95477 18.4149 4.25002 18.4168C4.47477 18.4158 4.69606 18.3613 4.89558 18.2578C5.09511 18.1543 5.26716 18.0049 5.39752 17.8218Z"
          fill="#232323"
        />
      </svg>
    );
  };
  return (
    <SideBar title={"Business Management"}>
      <div className="w-full super px-0">
        <div className="main_row ">
            <div className="w-full">
             
              <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-y-2 p-2 bg-white rounded-lg border">
                <div className="flex items-center">
                  <DeviceCard
                    svg={cardSvg()}
                    bgIcon="bg-[#E2F2FE]"
                    ratingColor="rgba(35, 197, 129, 1)"
                    rating="12"
                    icon={<FaArrowUp />}
                    head="Total"
                    number={businessesList?.users?.length}
                  />
                </div>
                <div className="lg:border-l md:border-l sm:border-l border-l-0 flex items-center">
                  <DeviceCard
                    svg={cardSvg()}
                    bgIcon="bg-[#E8FEE2]"
                    ratingColor="rgba(231, 69, 69, 1)"
                    rating="12"
                    icon={<FaArrowUp />}
                    head="Active"
                    number={businessesList?.users?.length}
                  />
                </div>
                <div className="lg:border-l flex items-center">
                  <DeviceCard
                    svg={cardSvg()}
                    bgIcon="bg-[#FEE2F3]"
                    ratingColor="rgba(35, 197, 129, 1)"
                    rating="12"
                    icon={<FaArrowUp />}
                    head="Orders"
                    number={businessesList?.orders}
                  />
                </div>
                <div className="lg:border-l md:border-l sm:border-l border-l-0 flex items-center">
                  <DeviceCard
                    svg={cardSvg()}
                    bgIcon="bg-[#FDFEE2]"
                    ratingColor="rgba(35, 197, 129, 1)"
                    rating="12"
                    icon={<FaArrowUp />}
                    head="Monthly Businesses"
                    number={businessesList?.averageMonthlySignups}
                  />
                </div>
              </div>
              <div className="bg-white p-6 shadow-md mt-4 rounded-lg">
                {isLoading ? (
                  <div className="w-100 d-flex justify-content-center align-items-center py-5">
                    <Spinner
                      size={"md"}
                      color="black"
                      className="mx-auto mt-5"
                    />
                  </div>
                ) : (
                  businessesList?.users?.length > 0 && (
                    <BusinessManagmentTable
                      businesses={businessesList?.users}
                    />
                  )
                )}
              </div>
            </div>
        </div>
      </div>
      <style>
        {`
                    .modal {
                        --bs-modal-width: 800px !important;
                    }
                    `}
      </style>
    </SideBar>
  );
};

export default BusinessManagment;
