import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setSumitCamgpaingState } from '../../redux/slices/userSlice';
const StepTwo = ({ childFunc }) => {

    const dispatch = useDispatch();
    const { submitCampaign } = useSelector((state) => state.user)
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [selectedAgeGroup, setSelectedAgeGroup] = useState([]);
    const [selectedGender, setSelectedGender] = useState([]);
    const [selectedEducation, setSelectedEducation] = useState([]);
    const [selectedInterestBehaviors, setSelectedInterestBehaviors] = useState([]);
    useEffect(() => {
        childFunc.current = handleSubmit
    }, [childFunc.current]);
    useEffect(() => {
        console.log('submit campaign state in redux')
        for (var pair of submitCampaign.entries()) {
            console.log(pair[0] + ' - ' + pair[1]);
        }
    }, [submitCampaign])
    function handleSubmit() {
        selectedCategories.length >0 && dispatch(setSumitCamgpaingState({ key: 'campaign_category', value: selectedCategories }))
        selectedLocations.length >0 && dispatch(setSumitCamgpaingState({ key: 'campaign_location', value: selectedLocations }))
        selectedAgeGroup.length >0 && dispatch(setSumitCamgpaingState({ key: 'campaign_age_group', value: selectedAgeGroup }))
        selectedGender.length >0 && dispatch(setSumitCamgpaingState({ key: 'campaign_gender', value: selectedGender }))
        selectedEducation.length >0 && dispatch(setSumitCamgpaingState({ key: 'campaign_education', value: selectedEducation }))
        selectedInterestBehaviors.length >0 && dispatch(setSumitCamgpaingState({ key: 'interests_behaviours', value: selectedInterestBehaviors }))
    }
    // useEffect(() => {
    //     console.log('submit campaign state in redux', submitCampaign);
    //     setSelectedCategories(submitCampaign.getAll('campaign_category'))
    //     setSelectedLocations(submitCampaign.getAll('campaign_location'))
    //     setSelectedAgeGroup(submitCampaign.getAll('campaign_age_group'))
    //     setSelectedGender(submitCampaign.getAll('campaign_gender'))
    //     setSelectedEducation(submitCampaign.getAll('campaign_education'))
    //     setSelectedInterestBehaviors(submitCampaign.getAll('interests_behaviours'))
    // }, [])
    function handleSelectCategory(e) {
        if (e.target.checked) {
            if (selectedCategories.length < 2) {
                setSelectedCategories((sc) => {
                    return [...sc, e.target.value]
                })
            } else {
                e.target.checked = false
            }
        } else {
            if (selectedCategories.includes(e.target.value)) {
                const index = selectedCategories.indexOf(e.target.value);
                if (index > -1) { // only splice array when item is found
                    selectedCategories.splice(index, 1); // 2nd parameter means remove one item only
                }
            }
        }
    }
    function handleSelectLocation(e) {
        if (e.target.checked) {
            setSelectedLocations((sl) => {
                return [...sl, e.target.value]
            })
        } else {
            if (selectedLocations.includes(e.target.value)) {
                const index = selectedLocations.indexOf(e.target.value);
                if (index > -1) { // only splice array when item is found
                    selectedLocations.splice(index, 1); // 2nd parameter means remove one item only
                }
            }
        }
    }
    function handleSelectAgeGroup(e) {
        if (e.target.checked) {
            setSelectedAgeGroup((ag) => {
                return [...ag, e.target.value]
            })
        } else {
            if (selectedAgeGroup.includes(e.target.value)) {
                const index = selectedAgeGroup.indexOf(e.target.value);
                if (index > -1) { // only splice array when item is found
                    selectedAgeGroup.splice(index, 1); // 2nd parameter means remove one item only
                }
            }
        }
    }
    function handleSelectGender(e) {
        if (e.target.checked) {
            setSelectedGender((ag) => {
                return [...ag, e.target.value]
            })
        } else {
            if (selectedGender.includes(e.target.value)) {
                const index = selectedGender.indexOf(e.target.value);
                if (index > -1) { // only splice array when item is found
                    selectedGender.splice(index, 1); // 2nd parameter means remove one item only
                }
            }
        }
    }
    function handleSelectEducation(e) {
        if (e.target.checked) {
            setSelectedEducation((ag) => {
                return [...ag, e.target.value]
            })
        } else {
            if (selectedEducation.includes(e.target.value)) {
                const index = selectedEducation.indexOf(e.target.value);
                if (index > -1) { // only splice array when item is found
                    selectedEducation.splice(index, 1); // 2nd parameter means remove one item only
                }
            }
        }
    }
    function handleSelectInterestBehaviors(e) {
        if (e.target.checked) {
            setSelectedInterestBehaviors((ag) => {
                return [...ag, e.target.value]
            })
        } else {
            if (selectedInterestBehaviors.includes(e.target.value)) {
                const index = selectedInterestBehaviors.indexOf(e.target.value);
                if (index > -1) { // only splice array when item is found
                    selectedInterestBehaviors.splice(index, 1); // 2nd parameter means remove one item only
                }
            }
        }
    }
    return (
        <>
            <div className="row justify-content-center">

                {/* -------Campaign Category--- */}
                <div className="col-11 mt-5" style={{ borderTop: '2px solid #F6F6F6' }}>
                    <div className="row mt-3">
                        <div className="col-lg-4 col-md-6 col-12">
                            <h6 className="font-16 Inter text-blue">Campaign Category</h6>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="form-check">
                                <input className="form-check-input" value={'brandawareness'} onChange={handleSelectCategory} type="checkbox" defaultValue id="Awareness" />
                                <label className="form-check-label font-14 Inter text-blue" htmlFor="Awareness">
                                    Brand Awareness
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" value={'customerretention'} onChange={handleSelectCategory} type="checkbox" defaultValue id="Retention" />
                                <label className="form-check-label font-14 Inter text-blue" htmlFor="Retention">
                                    Customer Retention
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" value={'leadgeneration'} onChange={handleSelectCategory} type="checkbox" defaultValue id="Generation" />
                                <label className="form-check-label font-14 Inter text-blue" htmlFor="Generation">
                                    Lead Generation
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" value={'seasonalsales'} onChange={handleSelectCategory} type="checkbox" defaultValue id="Sales" />
                                <label className="form-check-label font-14 Inter text-blue" htmlFor="Sales">
                                    Seasonal Sales
                                </label>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="form-check">
                                <input className="form-check-input" value={'fundraising'} onChange={handleSelectCategory} type="checkbox" defaultValue id="Donations" />
                                <label className="form-check-label font-14 Inter text-blue" htmlFor="Donations">
                                    Fundraising &amp; Donations
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" value={'partnership'} onChange={handleSelectCategory} type="checkbox" defaultValue id="Collaboration" />
                                <label className="form-check-label font-14 Inter text-blue" htmlFor="Collaboration">
                                    Partnership &amp; Collaboration
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" value={'specialoffers'} onChange={handleSelectCategory} type="checkbox" defaultValue id="Discounts" />
                                <label className="form-check-label font-14 Inter text-blue" htmlFor="Discounts">
                                    Special Offers &amp; Discounts
                                </label>
                            </div>
                        </div>
                        {/* ----Location--- */}
                        <div className="col-lg-4 col-md-6 col-12 mt-lg-4 mt-md-3 mt-3">
                            <h6 className="font-16 Inter text-blue">Location</h6>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 mt-lg-4 mt-md-3 mt-3">
                            <div className="form-check">
                                <input className="form-check-input" value={'local'} onChange={handleSelectLocation} type="checkbox" defaultValue id="Local" />
                                <label className="form-check-label font-14 Inter text-blue" htmlFor="Local">
                                    Local
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" value={'regional'} onChange={handleSelectLocation} type="checkbox" defaultValue id="Regional" />
                                <label className="form-check-label font-14 Inter text-blue" htmlFor="Regional">
                                    Regional
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" value={'national'} onChange={handleSelectLocation} type="checkbox" defaultValue id="National" />
                                <label className="form-check-label font-14 Inter text-blue" htmlFor="National">
                                    National
                                </label>
                            </div>
                        </div>
                        <div className="col-lg-4" />
                    </div>
                </div>
                {/* ----Demographics--- */}
                <div className="col-11 mt-3" style={{ borderTop: '2px solid #F6F6F6' }}>
                    <div className="row mt-3">
                        <h6 className="font-16 Inter text-blue">Demographics</h6>
                        <div className="col-lg-4 col-md-6 col-12 ">
                            <h6 className="font-16 Inter text-blue text-end">Age Group</h6>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 ">
                            <div className="form-check">
                                <input className="form-check-input" value={'18-24'} onChange={handleSelectAgeGroup} type="checkbox" defaultValue id="Number1" />
                                <label className="form-check-label font-14 Inter text-blue" htmlFor="Number1">
                                    18-24
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" value={'25-34'} onChange={handleSelectAgeGroup} type="checkbox" defaultValue id="Number2" />
                                <label className="form-check-label font-14 Inter text-blue" htmlFor="Number2">
                                    25-34
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" value={'35-44'} onChange={handleSelectAgeGroup} type="checkbox" defaultValue id="Number3" />
                                <label className="form-check-label font-14 Inter text-blue" htmlFor="Number3">
                                    35-44
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" value={'45-54'} onChange={handleSelectAgeGroup} type="checkbox" defaultValue id="Number4" />
                                <label className="form-check-label font-14 Inter text-blue" htmlFor="Number4">
                                    45-54
                                </label>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="form-check">
                                <input className="form-check-input" value={'55-65'} onChange={handleSelectAgeGroup} type="checkbox" defaultValue id="Number5" />
                                <label className="form-check-label font-14 Inter text-blue" htmlFor="Number5">
                                    55-64
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" value={'65+'} onChange={handleSelectAgeGroup} type="checkbox" defaultValue id="Number6" />
                                <label className="form-check-label font-14 Inter text-blue" htmlFor="Number6">
                                    65+
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" value={'all'} onChange={handleSelectAgeGroup} type="checkbox" defaultValue id="Number7" />
                                <label className="form-check-label font-14 Inter text-blue" htmlFor="Number7">
                                    All
                                </label>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 mt-3 text-end">
                            <h6 className="font-16 Inter text-blue text-end">Gender</h6>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 mt-3">
                            <div className="form-check">
                                <input className="form-check-input" value={'male'} onChange={handleSelectGender} type="checkbox" defaultValue id="Number8" />
                                <label className="form-check-label font-14 Inter text-blue" htmlFor="Number8">
                                    Male
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" value={'female'} onChange={handleSelectGender} type="checkbox" defaultValue id="Number9" />
                                <label className="form-check-label font-14 Inter text-blue" htmlFor="Number9">
                                    Female
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" value={'nonbinary'} onChange={handleSelectGender} type="checkbox" defaultValue id="Number10" />
                                <label className="form-check-label font-14 Inter text-blue" htmlFor="Number10">
                                    Non-binary
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" value={'all'} onChange={handleSelectGender} type="checkbox" defaultValue id="Number11" />
                                <label className="form-check-label font-14 Inter text-blue" htmlFor="Number11">
                                    All
                                </label>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 mt-3">
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 mt-3 text-end">
                            <h6 className="font-16 Inter text-blue text-end">Education</h6>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 mt-3">
                            <div className="form-check">
                                <input className="form-check-input" value={'highschool'} onChange={handleSelectEducation} type="checkbox" defaultValue id="Number12" />
                                <label className="form-check-label font-14 Inter text-blue" htmlFor="Number12">
                                    High School
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" value={'university'} onChange={handleSelectEducation} type="checkbox" defaultValue id="Number13" />
                                <label className="form-check-label font-14 Inter text-blue" htmlFor="Number13">
                                    University
                                </label>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 mt-3">
                        </div>
                    </div>
                </div>
                {/* ----Interests & Behaviours--- */}
                <div className="col-lg-11 col-12 mt-3" style={{ borderTop: '2px solid #F6F6F6' }}>
                    <div className="row mt-3">
                        <div className="col-lg-4 col-md-6 col-12 ">
                            <h6 className="font-16 Inter text-blue">Interests &amp; Behaviours</h6>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 mt-lg-0 mt-md-3 mt-3">
                            <div className="form-check">
                                <input className="form-check-input" value={'techology'} onChange={handleSelectInterestBehaviors} type="checkbox" defaultValue id="Behaviours1" />
                                <label className="form-check-label font-14 Inter text-blue" htmlFor="Behaviours1">
                                    Technology
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" value={'sports'} onChange={handleSelectInterestBehaviors} type="checkbox" defaultValue id="Behaviours2" />
                                <label className="form-check-label font-14 Inter text-blue" htmlFor="Behaviours2">
                                    Sports
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" value={'foodandbeverages'} onChange={handleSelectInterestBehaviors} type="checkbox" defaultValue id="Behaviours3" />
                                <label className="form-check-label font-14 Inter text-blue" htmlFor="Behaviours3">
                                    Food &amp; Beverages
                                </label>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 mt-lg-0 mt-md-3 mt-3">
                            <div className="form-check">
                                <input className="form-check-input" value={'fashion'} onChange={handleSelectInterestBehaviors} type="checkbox" defaultValue id="Behaviours5" />
                                <label className="form-check-label font-14 Inter text-blue" htmlFor="Behaviours5">
                                    Fashion
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" value={'recurringbills'} onChange={handleSelectInterestBehaviors} type="checkbox" defaultValue id="Behaviours6" />
                                <label className="form-check-label font-14 Inter text-blue" htmlFor="Behaviours6">
                                    Recurring Bills
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" value={'healthandwellness'} onChange={handleSelectInterestBehaviors} type="checkbox" defaultValue id="Behaviours7" />
                                <label className="form-check-label font-14 Inter text-blue" htmlFor="Behaviours7">
                                    Health &amp; Wellness
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" value={'all'} onChange={handleSelectInterestBehaviors} type="checkbox" defaultValue id="Behaviours8" />
                                <label className="form-check-label font-14 Inter text-blue" htmlFor="Behaviours8">
                                    All
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}

export default StepTwo
