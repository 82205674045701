import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import SideBar from '../components/SideBar';
import Header from '../components/Header';
import img from "../assets/imges/quickbooks.png"
import { FiUploadCloud } from 'react-icons/fi';
import { Modal } from 'react-bootstrap';
import { FaAngleRight } from 'react-icons/fa6';
import { useDispatch, useSelector } from 'react-redux';
import { getSquareRedirectUrl, getUser, revokeAccess, seTgetErrorNull } from '../redux/slices/userSlice';
import { Spinner } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IoSettingsOutline } from 'react-icons/io5';
const IntegrationsDetail = () => {
    const dispatch = useDispatch();
  const { isLoading, getError, userDetail } = useSelector((state) => state.user)
    const param = useParams();
    console.log(param.id)
    const navigate = useNavigate();
    const [detailTab, setDetailTab] = useState("overview");
    const [modal, setModal] = useState(false);
    const [disconnectModal, setDisconnectModal] = useState(false);

    function handleGetRedirectUrl() {
        dispatch(getSquareRedirectUrl(handleResponse))
    }
    function handleResponse(url) {
        window.location.replace(url)
    }

    useEffect(() => {
        if (getError && getError.length > 1) {
          toast.error(getError, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          dispatch(seTgetErrorNull());
        }
      }, [getError])
      function handleDisconnectIntegration() {
        dispatch(revokeAccess(handleDisconnectResponse));
      }
      function handleDisconnectResponse(status) {
        if (status) {
          toast.success("Access Revoked Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.error("Something went wrong please try again later!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        setDisconnectModal(false);
        dispatch(getUser());
      }
    return (
        <>
            <section className='business_admin_main'>
                <div className="container-fluid setting-page">
                    <div className="row order">
                        {/* <!-- ===========SIDE-BAR======= --> */}
                        <SideBar />
                        {/* <!-- ========= content body ======= --> */}
                        <div className="w-lg-80 px-0 inter h-screen overflow-y-scroll">
                            <Header PageTitle="Integrations" />
                            <div className="px-lg-4 px-md-3 px-2">

                            <div className='w-4/5 mx-auto'>

                                <div className='mt-5 flex justify-between items-end '>
                                    <div>

                                        <p role='button' onClick={() => navigate(-1)} className='text-base text-gray-900 mb-2'>← App Integrations </p>
                                        <div className='flex gap-1 items-center'>
                                            <div className='inline-flex w-[52px] h-[52px]  justify-center items-center border-[1.5px] bg-white  rounded-full'>

                                                <img src={img} className='w-8  h-8 object-cover' alt="" />
                                            </div>
                                            <h3 className='text-3xl	mb-0 fw-bold'>Square</h3>
                                        </div>
                                    </div>
                                    <button className={`px-3 py-1.5 rounded-full shadow-[0_9px_28px_8px_#0000000D]
                 text-xs flex gap-1 items-center border-[1.3px] ${userDetail?.square_access_token ? 'bg-[#1B59F8] text-white  border-black' : 'bg-white  border-gray-300 text-blue-700'}`}
                    onClick={() => {
                       
                            if (userDetail?.square_access_token) {
                                setDisconnectModal(true);
                            } else {
                                setModal(true)
                            }
                        
                    }}
                >
                    {userDetail?.square_access_token ? 'Connected' : 'Connect'} <span> {!userDetail?.square_access_token ? <FiUploadCloud size={15} /> : <IoSettingsOutline size={15} />}</span>
                </button>
                                </div>
                                <div className='bg-white border-[1.5px] rounded-2xl p-8 mt-8 shadow-[0_2.02px_2.21px_0_#2B2E3204]'>

                                    <div className='border-b flex gap-4'>
                                        <p role='button' onClick={() => setDetailTab("overview")} className={`border-b-[4px] mb-0 text-sm pb-2 ${detailTab === "overview" ? "border-[#1B59F8] " : "border-white"}`}>Integration Overview</p>
                                        <p role='button' onClick={() => setDetailTab("configuration")} className={`border-b-[4px] mb-0 text-sm pb-2 ${detailTab === "configuration" ? "border-[#1B59F8] " : "border-white"}`}>Configuration</p>
                                        <p role='button' onClick={() => setDetailTab("square")} className={`border-b-[4px] mb-0 text-sm pb-2 ${detailTab === "square" ? "border-[#1B59F8] " : "border-white"}`}>About Square</p>
                                    </div>
                                    <div>
                                        {detailTab === "overview" && <div>
                                            <div className='mt-8'>
                                                <h5 className='fw-nomal'>Create and track square orders seamlessly </h5>
                                                <p className='text-sm text-gray-500 '>Effortlessly create Square orders with our integration. Allow your customers to track their orders in real-time, enhancing their experience and ensuring timely fulfilment. By integrating with Square, you can streamline your order management process and provide a seamless tracking experience for your customers.</p>

                                            </div>
                                            <div className='mt-8'>
                                                <h5 className='fw-nomal'>Comprehensive Insights and Analytics </h5>
                                                <p className='text-sm text-gray-500'>Gain valuable insights by integrating TimeLink with Square. Access detailed analytics and reporting tools that provide insights into your sales performance, customer behavior, and inventory levels. Use this data to make informed business decisions, optimize your operations, and drive growth.</p>

                                            </div>
                                            <div className='mt-8'>
                                                <h5 className='fw-nomal'>Simplified Payment Processing </h5>
                                                <p className='text-sm text-gray-500'>Integrate TimeLink with Square to simplify payment processing across multiple channels, including in-store, online, and mobile. This integration ensures a smooth and consistent payment experience for your customers, while also making it easier for you to manage and reconcile transactions directly within the TimeLink platform.</p>

                                            </div>
                                        </div>}
                                        {detailTab === "configuration" && <div>
                                            <div className='mt-4'>
                                                <h5 className='fw-nomal'>Shared Information through TimeLink and Square Integration </h5>
                                                <p className='text-sm text-gray-500 '>Through the integration of TimeLink and Square, businesses can share critical information seamlessly, including detailed order information, secure payment data, customer details, real-time inventory levels, and order status updates. Additionally, sales and analytics data is synchronised to provide valuable insights for informed decision-making and operational optimisation. This integration ensures accurate and efficient management of sales, payments, and customer interactions, enhancing overall business performance and customer satisfaction.</p>

                                            </div>
                                        </div>}
                                        {detailTab === "square" && <div>
                                            <div className='mt-4'>

                                                <p className='text-sm text-gray-500 '>Square is a leading financial services and mobile payment company that revolutionises the way businesses manage their sales and transactions. Founded in 2009, Square provides an integrated ecosystem of software, hardware, and financial services that empower businesses of all sizes to start, run, and grow. From point-of-sale solutions and payment processing to e-commerce and inventory management, Square offers a comprehensive suite of tools designed to simplify business operations and enhance customer experiences. Trusted by millions of businesses worldwide, Square continues to innovate and drive the future of commerce.</p>

                                            </div>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Modal
                show={modal}
                onHide={() => setModal(false)}
                // backdrop="static"
                keyboard={false}
                centered

            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className='flex gap-1 items-center'>
                            <div className='inline-flex w-[35px] h-[35px]  justify-center items-center border-[1.5px] bg-white  rounded-full'>

                                <img src={img} className='w-5 h-5 object-cover' alt="" />
                            </div>
                            <h6 className='	mb-0 fw-bold'>Square</h6>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h6 className='text-gray-400 flex gap-1'>
                        <span className='text-black'>Overview</span><FaAngleRight />Authenticate <FaAngleRight />Configure</h6>
                    <div>
                        <h6 className='text-sm mb-1'>Introduction</h6>
                        <p className='text-xs'>Enable your customers to track their orders in real-time through the TimeLink integration with Square. </p>
                        <p className='text-xs'>This feature keeps your customers informed and engaged, enhancing their overall experience by providing timely updates on their order status. </p>
                        <p className='text-xs'>By connecting Square to TimeLink you consent to the sharing of information. </p>
                        <p className='text-xs'>Learn more on <b>Helpdesk Documentation.</b></p>
                    </div>
                    <div>
                        <h6 className='text-sm mb-1'>Shared Information through TimeLink and Square Integration</h6>
                        <p className='text-xs'>Through the integration of TimeLink and Square, businesses can share critical information seamlessly, including detailed order information, secure payment data, customer details, real-time inventory levels, and order status updates. </p>
                        <p className='text-xs'>Additionally, sales and analytics data is synchronized to provide valuable insights for informed decision-making and operational optimization. This integration ensures accurate and efficient management of sales, payments, and customer interactions, enhancing overall business performance and customer satisfaction.</p>
                        <button onClick={() => handleGetRedirectUrl()} type='button' className='btn-blue p-2 w-100 rounded-2'>{isLoading ? <Spinner color="light" /> : 'Continue'}</button>
                    </div>
                </Modal.Body>

            </Modal>
            <Modal
        show={disconnectModal}
        onHide={() => setDisconnectModal(false)}
        // backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="flex gap-1 items-center">
              <div className="inline-flex w-[35px] h-[35px]  justify-center items-center border-[1.5px] bg-white  rounded-full">
                <img src={img} className="w-5 h-5 object-cover" alt="" />
              </div>
              <h6 className="	mb-0 fw-bold">Square</h6>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6 className="text-gray-400 flex gap-1">
            <span className="text-black">Overview</span>
          </h6>
          <div>
            <h6 className="text-sm mb-1">Introduction</h6>
            <p className="text-xs">
              Enable your customers to track their orders in real-time through
              the TimeLink integration with Square.{" "}
            </p>
            <p className="text-xs">
              This feature keeps your customers informed and engaged, enhancing
              their overall experience by providing timely updates on their
              order status.{" "}
            </p>
            <p className="text-xs">
              By connecting Square to TimeLink you consent to the sharing of
              information.{" "}
            </p>
            <p className="text-xs">
              Learn more on <b>Helpdesk Documentation.</b>
            </p>
          </div>
          <div>
            <h6 className="text-sm mb-1">
              Shared Information through TimeLink and Square Integration
            </h6>
            <p className="text-xs">
              Through the integration of TimeLink and Square, businesses can
              share critical information seamlessly, including detailed order
              information, secure payment data, customer details, real-time
              inventory levels, and order status updates.{" "}
            </p>
            <p className="text-xs">
              Additionally, sales and analytics data is synchronized to provide
              valuable insights for informed decision-making and operational
              optimization. This integration ensures accurate and efficient
              management of sales, payments, and customer interactions,
              enhancing overall business performance and customer satisfaction.
            </p>
            <button
              onClick={() => handleDisconnectIntegration()}
              type="button"
              className="btn-blue p-2 w-100 rounded-2"
            >
              {isLoading ? <Spinner color="light" /> : "Disconnect"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
            <ToastContainer />
        </>
    )
}

export default IntegrationsDetail
