import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Spinner } from 'reactstrap';
import LoginHeader from '../../components/LoginHeader';
// import verfy from '../../assets/OtpVerification.png'
// import OtpInput from 'react-otp-input';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FaRegEnvelope } from 'react-icons/fa';
import { MdOutlineDriveFileRenameOutline, MdWeb } from 'react-icons/md';
import { BiCategory } from 'react-icons/bi';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { HiOutlineIdentification } from 'react-icons/hi';
import { GoDotFill } from "react-icons/go";
import { useDispatch, useSelector } from 'react-redux';
import { setBusinessProfile } from '../../redux/slices/userSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaAngleLeft } from 'react-icons/fa6';

const SignUpEssentials = () => {
    const dispatch = useDispatch();
    const { isLoading, getError, userDetail } = useSelector((state) => state.user)
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [abn, setAbn] = useState('');
    const [url, setUrl] = useState('');
    const [isBtnDisable, setIsBtnDisable] = useState(true);  // Initially set to true

    useEffect(() => {
        // Check if all required fields are filled
        if (email && number && abn && url) {
            setIsBtnDisable(false);
        } else {
            setIsBtnDisable(true);
        }
    }, [email, number, abn, url]);  // Include clientRadio instead of selectedOption
    if (email.length > 0) {
        var style = {
            border: '1.5px solid rgba(37, 99, 235, 1)',
        }
    }
    else {
        var style = {
            border: '1.5px solid rgba(226, 232, 240, 1)',
        }
    }
    if (number.length > 0) {
        var styleNumber = {
            border: '1.5px solid rgba(37, 99, 235, 1)',
        }
    }
    else {
        var styleNumber = {
            border: '1.5px solid rgba(226, 232, 240, 1)',
        }
    }
    if (abn.length > 0) {
        var styleAbn = {
            border: '1.5px solid rgba(37, 99, 235, 1)',
        }
    }
    else {
        var styleAbn = {
            border: '1.5px solid rgba(226, 232, 240, 1)',
        }
    }
    if (url.length > 0) {
        var styleUrl = {
            border: '1.5px solid rgba(37, 99, 235, 1)',
        }
    }
    else {
        var styleUrl = {
            border: '1.5px solid rgba(226, 232, 240, 1)',
        }
    }
    useEffect(() => {
        if (getError && getError.length > 1) {
            toast.error(getError, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }, [getError])

    const handleSubmit = (e) => {
        if (number.length > 0 && abn.length > 0) {
            e.preventDefault();
            var data = {
                "phone": number,
                "abn": abn,
                "website_url": url
            }
            dispatch(setBusinessProfile(2, data, handleResponse))
        }
    }


    function handleResponse(data) {
        navigate('/auth/signup/Address')
    }

    return (
        <>
            <Container fluid className='signup-steps-pages'>
                <LoginHeader />
                <Row className='justify-content-center my-4 SignUp-progress-bar lato'>

                    <Col lg={6} md={6} sm={12} xs={12} >
                        <div role='button' onClick={()=> navigate(-1)} className='w-8 h-8 bg-blue-600 mb-3 rounded-full text-white flex justify-center items-center'>
                            <FaAngleLeft size={20} />
                        </div>

                        <Row>
                            <Col lg={3} md={3} sm={3} xs={3} className='px-1'>
                                <div className='text-center lh-base'>
                                    <p className="font-10 mb-0 fw-semibold " style={{ color: '#6F6F6F' }}>Sign Up</p>
                                    <GoDotFill size={8} className='my-0 mb-2 blue' />
                                </div>
                                <div className="progress rounded-5" role="progressbar" aria-label="Basic example" aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}>
                                    <div className="progress-bar rounded-5" style={{ width: '100%' }} />
                                </div>

                            </Col>
                            <Col lg={6} md={6} sm={6} xs={6} className='px-1'>
                                <div className='text-center lh-base'>
                                    <p className="font-10 mb-0 fw-semibold " style={{ color: '#6F6F6F' }}>Business Profile</p>
                                    <GoDotFill size={8} className='my-0 mb-2 blue' />
                                </div>
                                <div className="progress  rounded-5" role="progressbar" aria-label="Basic example" aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}>
                                    <div className="progress-bar  rounded-5" style={{ width: '50%' }} />
                                </div>
                            </Col>
                            <Col lg={3} md={3} sm={3} xs={3} className='px-1'>
                                <div className='text-center lh-base'>
                                    <p className="font-10 mb-0 fw-semibold " style={{ color: '#6F6F6F' }}>Primary Contact</p>
                                    <GoDotFill size={8} color='#D0E2FF' className='my-0 mb-2 ' />
                                </div>
                                <div className="progress  rounded-5" role="progressbar" aria-label="Basic example" aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}>
                                    <div className="progress-bar  rounded-5" style={{ width: '0%' }} />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row className='align-items-center justify-content-center d-flex login_height  my-5 inter' >
                    <Col lg={5} md={12} sm={12} xs={12} className=' ' >

                        <form className='business-login-form rounded-4 d-flex justify-content-between flex-column p-3' onSubmit={handleSubmit}>
                            <div>

                                <div className='inter text-center mt-2'>
                                    <h4 className="font-24 fw-bold  text-dak-blue">Business Essentials</h4>
                                    <p className="font-14 text-dark-gray">Now how about some deets?</p>
                                </div>
                                <div className='login-form' >

                                    <div className="input-group my-3 rounded-4 bg-white" style={style}>
                                        <span className="input-group-text border-0 ps-3" id="basic-addon1"><FaRegEnvelope
                                            size={20} color='rgba(100, 116, 139, 1)' /></span>
                                        <input type="email" disabled className="form-control border-0 p-3 ps-0 rounded-4 bg-white" placeholder='Business Email' value={userDetail?.email} onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                    <div className="input-group my-3 rounded-4 bg-white" style={styleNumber}>
                                        <span className="input-group-text border-0 ps-3" id="basic-addon1"><BsFillTelephoneFill
                                            size={20} color='rgba(100, 116, 139, 1)' /></span>
                                        <input type="number" required className="form-control border-0 p-3 ps-0 rounded-4 " placeholder='Phone Number' value={number} onChange={(e) => setNumber(e.target.value)} />
                                    </div>
                                    <div className="input-group my-3 rounded-4 bg-white" style={styleAbn}>
                                        <span className="input-group-text border-0 ps-3" id="basic-addon1"><HiOutlineIdentification
                                            size={20} color='rgba(100, 116, 139, 1)' /></span>
                                        <input type="text" required className="form-control border-0 p-3 ps-0 rounded-4 " placeholder='ABN' value={abn} onChange={(e) => setAbn(e.target.value)} />
                                    </div>
                                    <div className="input-group my-3 rounded-4 bg-white" style={styleUrl}>
                                        <span className="input-group-text border-0 ps-3" id="basic-addon1"><MdWeb
                                            size={20} color='rgba(100, 116, 139, 1)' /></span>
                                        <input type="text" className="form-control border-0 p-3 ps-0 rounded-4 " placeholder='Website URL' value={url} onChange={(e) => setUrl(e.target.value)} />
                                    </div>



                                </div>
                            </div>
                            <Button disabled={isBtnDisable} type='submit' className={`${isBtnDisable ?"!bg-gray-300":"btn-blueTwo "} rounded-4 inter px-3 mt-2 p-3 w-100 fw-bold font-16 text-capitalize`} sx={{ fontFamily: 'Inter, sans-serif !important' }}>{isLoading ? <Spinner color="light" /> : 'Continue'}</Button>

                        </form>


                    </Col>
                </Row>
                <ToastContainer />
            </Container>
        </>
    );
}

export default SignUpEssentials;
