import React from "react";
import DeviceCard from "../../components/superAdmin/Device/DeviceCard";
import { Button } from "@mui/material";
import { FaArrowUp } from "react-icons/fa";
import SideBar from "../../components/superAdmin/SideBar";
import { SiCoinmarketcap } from "react-icons/si";
import MarketingTable from "../../components/superAdmin/Marketing/MarketingTable";
import Header from "../../components/superAdmin/Header";
import { useDispatch, useSelector } from "react-redux";
import { getBusinessCampaings } from "../../redux/slices/userSlice";

const Marketing = () => {
  const dispatch = useDispatch();
  const { isLoading, getError, businessCampaigns } = useSelector(
    (state) => state.user
  );
  React.useEffect(() => {
    dispatch(getBusinessCampaings());
  }, []);
  return (
    <SideBar title={"Marketing"}>
      <div className="w-full super px-0">
        <div className="main_row flex-col ">
          <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-y-2 p-2 bg-white rounded-lg border">
            <div className=" flex items-center">
              <DeviceCard
                ratingColor="rgba(35, 197, 129, 1)"
                rating="12"
                icon={<FaArrowUp />}
                head="Total"
                number={businessCampaigns?.all?.length}
              />
            </div>
            <div className="lg:border-l md:border-l sm:border-l border-l-0flex items-center">
              <DeviceCard
                ratingColor="rgba(231, 69, 69, 1)"
                rating="12"
                icon={<FaArrowUp />}
                head="Pending "
                number={businessCampaigns?.pending?.length}
              />
            </div>
            <div className="lg:border-l flex items-center">
              <DeviceCard
                ratingColor="rgba(35, 197, 129, 1)"
                rating="12"
                icon={<FaArrowUp />}
                head="Accepted"
                number={businessCampaigns?.approved?.length}
              />
            </div>
            <div className="lg:border-l md:border-l sm:border-l border-l-0 flex items-center">
              <DeviceCard
                ratingColor="rgba(35, 197, 129, 1)"
                rating="12"
                icon={<FaArrowUp />}
                head="Rejected"
                number={businessCampaigns?.rejected?.length}
              />
            </div>
          </div>
          <div className="container-fluid flex justify-end  p-3">
            <Button className="btn-blue rounded-5 px-3 p-2 fw-medium font-20 text-capitalize">
              <SiCoinmarketcap size={24} className="me-2" />
              New Campaign
            </Button>
          </div>

          <div className="mt-4 h-100">
            {/* <h3 className="font-18 mt-4 inter fw-medium">Marketing Program</h3> */}
            <MarketingTable />
          </div>
        </div>
      </div>

      <style>
        {`
            .modal {
                --bs-modal-width: 800px !important;
            }
            `}
      </style>
    </SideBar>
  );
};

export default Marketing;
