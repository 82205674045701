import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Spinner } from 'reactstrap';
import { FaFacebook, FaRegEnvelope, FaRegEye, FaRegEyeSlash, FaRegUser } from 'react-icons/fa';
import { Button } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FcGoogle } from "react-icons/fc";
import { MdLock } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { signUpWithGoogle, userRegister } from '../../redux/slices/userSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GoogleLogin } from 'react-google-login';
import logoTwo from '../../assets/imges/Logo.png';

const SignUp = () => {
    const dispatch = useDispatch();
    const { isLoading, createError } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [name, setname] = useState('');
    const [password, setPassword] = useState('');
    const [type, setType] = useState('password');
    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const emailParam = searchParams.get('email');
        if (emailParam) {
            setEmail(emailParam);
        }
    }, [location.search]);

    useEffect(() => {
        if (createError) {
            toast.error(createError, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }, [createError]);

    const toggleType = () => {
        setType(prevType => prevType === 'password' ? 'text' : 'password');
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            email: email,
            name: name,
            password: password
        };
        dispatch(userRegister(data, handleResponse));
    };

    const handleResponse = (data) => {
        navigate('/auth/signup/otp');
    };

    const responseGoogle = (response) => {
        const { profileObj, tokenId } = response;
        const data = {
            email: profileObj.email,
            name: profileObj.name,
            tokenId: tokenId
        };
        dispatch(signUpWithGoogle(data, () => {
            navigate('/auth/signup/Profile');
        }));
    };

    return (
        <Container fluid className='login_height inter'>
            <Row className='login_height justify-content-center' style={{ backgroundColor: '#ffffff' }}>
                <div>
                    <img src={logoTwo} alt="Logo" className='mt-3 ' width="150" />
                </div>
                <Col lg={6} md={12} sm={12} xs={12} className='align-items-center justify-content-center d-flex mt-lg-0  pb-5 mt-4 pt-lg-5 pt-md-5 pt-sm-5 pt-2'>
                    <Container fluid>
                        <Row className='justify-content-center'>
                            <Col lg={9} md={9} sm={12} xs={12}>
                                <form className='login-form' onSubmit={handleSubmit}>
                                    <div className='inter'>
                                        <h4 className="font-24 fw-bold  text-dak-blue">Create your free account</h4>
                                        <div className="input-group my-3 rounded-4 bg-white">
                                            <span className="input-group-text border-0 ps-3">
                                                <FaRegUser size={20} color='rgba(100, 116, 139, 1)' />
                                            </span>
                                            <input type="text" required className="form-control border-0 p-3 ps-0 rounded-4 " placeholder='Full Name' value={name} onChange={(e) => setname(e.target.value)} name='username' />
                                        </div>
                                    </div>
                                    <div className="input-group my-4 rounded-4 bg-white">
                                        <span className="input-group-text border-0 ps-3">
                                            <FaRegEnvelope size={20} color='rgba(100, 116, 139, 1)' />
                                        </span>
                                        <input type="email" required className="form-control border-0 p-3 ps-0 rounded-4 " placeholder="Business email" value={email} onChange={(e) => setEmail(e.target.value)} name='email' />
                                    </div>
                                    <div className="input-group my-3 rounded-4 bg-white">
                                        <span className="input-group-text border-0 ps-3">
                                            <MdLock size={20} color='rgba(100, 116, 139, 1)' />
                                        </span>
                                        <input type={type} required className="form-control border-0 p-3 ps-0 rounded-4 " maxLength={10} minLength={8} placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)} name='password' />
                                        <span className="input-group-text border-0 ps-3" onClick={toggleType}>
                                            {type === 'password' ? <FaRegEye size={20} color='rgba(100, 116, 139, 1)' /> : <FaRegEyeSlash size={20} color='rgba(100, 116, 139, 1)' />}
                                        </span>
                                    </div>
                                    <p className="font-14 text-dark-gray">Your password must have at least 8 characters</p>
                                    <div className='d-flex align-items-start'>
                                        <input type="checkbox" id='terms' className="login-terms mt-1" required />
                                        <label className="font-14 text-dark-gray ms-2" htmlFor='terms'>By continuing you agree to the <b className='text-dark'>Terms & Conditions</b> <br className='d-lg-block d-none' /> and our <b className='text-dark'>Privacy Policy</b></label>
                                    </div>
                                    <Button type='submit' className='btn-blueTwo rounded-4 inter mt-3 px-3 p-3 w-100 fw-bold font-16 text-capitalize ' sx={{ fontFamily: 'Inter, sans-serif !important' }}>
                                        {isLoading ? <Spinner color="light" /> : 'Sign up'}
                                    </Button>
                                    <p className="font-14 inter text-dark-gray Or-lines d-flex justify-content-center align-items-center my-4">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Or sign up with&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                    <GoogleLogin
                                        clientId="212290965015-r0v8ahr9q13u3upesu87bisjvtoi82n7.apps.googleusercontent.com"
                                        buttonText="Google"
                                        onSuccess={responseGoogle}
                                        uxMode='redirect'
                                        onFailure={(error) => console.error("Google sign-in error", error)}
                                        cookiePolicy={'single_host_origin'}
                                        className='just-border bg-transparent p-3 w-100 text-center rounded-4 flex justify-center items-center gap-1'
                                        style={{ marginTop: '1rem' }}
                                        redirectUri='http://localhost:3000/auth/trial/signup'
                                    >
                                        <FcGoogle size={18} />
                                        <span className='fw-bold ms-2'>Google</span>
                                    </GoogleLogin>
                                    <Row className='inter mt-3'>
                                        <Col lg={6} md={6} sm={6} xs={12}>
                                            <button className='just-border bg-transparent p-3 w-100 text-center rounded-4 flex justify-center items-center gap-1'>
                                                <FaFacebook color='#2563EB' size={18} />
                                                <span className='fw-bold ms-2'>Facebook</span>
                                            </button>
                                        </Col>
                                    </Row>
                                    <p className="text-center mt-4">
                                        Already have an account?
                                        <span className="font-16 fw-bold cursor-pointer colorPrimary inter" onClick={() => navigate('/auth/login')}> Log In</span>
                                    </p>
                                </form>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
            <ToastContainer />
        </Container>
    );
};

export default SignUp;
