import * as React from "react";
import cofe from "../../assets/imges/covercafe.png";

import { FiDownload, FiSearch } from "react-icons/fi";
import SideBar from "../../components/superAdmin/SideBar";

export default function UserManagment({ users }) {
  const [userManagTab, setUserManagTab] = React.useState("orders");
  const isActive = (item) => {
    return userManagTab === item
      ? "before:content-[''] before:absolute before:left-0 before:bottom-0 before:w-full before:h-1 before:bg-customBlue before:rounded-t-md text-customBlue font-medium"
      : "text-[#718EBF] font-medium";
  };
  const svgImg = () => {
    return (
      <svg
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_227_1766)">
          <path
            d="M17.4168 17.4149C17.6195 17.4149 17.8221 17.3374 17.9741 17.1837L24.3073 10.8505C24.616 10.5418 24.616 10.0398 24.3073 9.73107C23.9986 9.42233 23.4966 9.42233 23.1879 9.73107L17.4151 15.5038L14.809 12.8976C14.5002 12.5889 13.9983 12.5889 13.6896 12.8976C13.3808 13.2064 13.3808 13.7083 13.6896 14.0171L16.8562 17.1837C17.0115 17.3374 17.2142 17.4149 17.4168 17.4149Z"
            fill="#1B4FF8"
          />
          <path
            d="M27.7307 23.68C30.6187 21.21 32.4584 17.5478 32.4584 13.4581C32.4584 6.03717 26.4213 0 19.0003 0C11.5794 0 5.54211 6.03717 5.54211 13.4581C5.54211 17.5478 7.38034 21.2085 10.2683 23.68L5.5865 36.945C5.48044 37.2474 5.5675 37.5878 5.80974 37.8015C5.95855 37.9298 6.14543 37.9978 6.33379 37.9978C6.4541 37.9978 6.57604 37.9693 6.68848 37.9139L12.5689 34.9737L16.1314 37.8252C16.3467 37.9962 16.6317 38.0422 16.8898 37.9535C17.1478 37.8632 17.3395 37.6463 17.3996 37.3787L18.4936 32.4578L20.6453 37.5165C20.7419 37.7445 20.9398 37.9139 21.1805 37.974C21.4196 38.039 21.6761 37.9788 21.8693 37.8252L25.4317 34.9737L31.3121 37.9139C31.4246 37.9693 31.5464 37.9978 31.6668 37.9978C31.8553 37.9978 32.0421 37.9298 32.1893 37.7999C32.4316 37.5862 32.5203 37.2457 32.4126 36.9434L27.7307 23.68ZM7.12547 13.4566C7.12547 6.90796 12.4517 1.58173 19.0003 1.58173C25.5489 1.58173 30.8752 6.90796 30.8752 13.4566C30.8752 20.0052 25.5489 25.3314 19.0003 25.3314C12.4517 25.3314 7.12547 20.0052 7.12547 13.4566ZM16.1282 35.7955L13.161 33.4221C12.9188 33.2289 12.5878 33.1957 12.3123 33.3319L7.73183 35.6213L11.5935 24.6807C12.9125 25.5547 14.396 26.1959 15.984 26.5616L17.4265 29.9515L16.1282 35.7955ZM25.6866 33.3319C25.4111 33.1957 25.0802 33.2289 24.8379 33.4221L21.695 35.938L17.8286 26.8546C18.2149 26.8878 18.6044 26.9131 19.0002 26.9131C21.7362 26.9131 24.2806 26.0882 26.4069 24.6807L30.2671 35.6198L25.6866 33.3319Z"
            fill="#1B4FF8"
          />
          <path
            d="M19.0002 22.1649C23.8024 22.1649 27.7084 18.2573 27.7084 13.4567C27.7084 8.65617 23.8024 4.74854 19.0002 4.74854C14.198 4.74854 10.292 8.65617 10.292 13.4567C10.292 18.2573 14.198 22.1649 19.0002 22.1649ZM19.0002 6.33182C22.9284 6.33182 26.1251 9.52853 26.1251 13.4567C26.1251 17.3849 22.9284 20.5816 19.0002 20.5816C15.072 20.5816 11.8753 17.3849 11.8753 13.4567C11.8753 9.52853 15.072 6.33182 19.0002 6.33182Z"
            fill="#1B4FF8"
          />
        </g>
        <defs>
          <clipPath id="clip0_227_1766">
            <rect width="38" height="38" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  };
  return (
    <SideBar title="Users Management">
      <div className="px-4 flex items-center gap-2">
        <img
          src="https://via.placeholder.com/150"
          className="w-28 h-28 rounded-full"
          alt="Placeholder Image"
        />
        <h3 className="text-3xl font-semibold ms-2 ">Username</h3>
      </div>
      <div className="bg-white p-3 rounded-xl pb-8 mt-4">
        <div className="container-fluid ">
          <div className="w-full mb-4">
            <div className=" d-flex align-items-end inter flex-wrap ps-0 border-b w-fit">
              <button
                className={`relative  ${isActive("orders")} py-2 px-6`}
                onClick={() => setUserManagTab("orders")}
                type="button"
              >
                Orders
              </button>

              <button
                className={`relative ${isActive("enrolled")} py-2 px-6`}
                onClick={() => setUserManagTab("enrolled")}
                type="button"
              >
                Enrolled in Campaigns{" "}
              </button>

              <button
                className={`relative ${isActive("rewards")} py-2 px-6`}
                onClick={() => setUserManagTab("rewards")}
                type="button"
              >
                Rewards
              </button>
            </div>
          </div>
        </div>
        <div>
          {/* ----DEVICES---------- */}

          {userManagTab === "enrolled" && (
            <div>
              <p className="font-medium text-base">Campaign list</p>
              <div className="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-2">
                <div className="border rounded-2xl p-3">
                  <img
                    src={cofe}
                    alt="w8"
                    className="h-24 w-full rounded-3xl object-cover"
                  />
                  <p className="text-xs mb-2 mt-2 font-semibold">
                    Thank you for using Time Link:
                  </p>
                  <p className="text-xs font-medium text-gray-500">
                    Congratulations! Enjoy a free coffee at Donald's Cafe. Claim
                    your discount now. <u>Terms & Conditions apply.</u>{" "}
                  </p>
                  <p className="text-xs font-semibold mb-0">
                    Date Created:{" "}
                    <span className="text-gray-500 font-medium">
                      16 February 2024
                    </span>
                  </p>
                </div>
                <div className="border rounded-2xl p-3">
                  <img
                    src={cofe}
                    alt="w8"
                    className="h-24 w-full rounded-3xl"
                  />
                  <p className="text-xs mb-2 mt-2 font-semibold">
                    Thank you for using Time Link:
                  </p>
                  <p className="text-xs font-medium text-gray-500">
                    Congratulations! Enjoy a free coffee at Donald's Cafe. Claim
                    your discount now. <u>Terms & Conditions apply.</u>{" "}
                  </p>
                  <p className="text-xs font-semibold mb-0">
                    Date Created:{" "}
                    <span className="text-gray-500 font-medium">
                      16 February 2024
                    </span>
                  </p>
                </div>
                <div className="border rounded-2xl p-3">
                  <img
                    src={cofe}
                    alt="w8"
                    className="h-24 w-full rounded-3xl"
                  />
                  <p className="text-xs mb-2 mt-2 font-semibold">
                    Thank you for using Time Link:
                  </p>
                  <p className="text-xs font-medium text-gray-500">
                    Congratulations! Enjoy a free coffee at Donald's Cafe. Claim
                    your discount now. <u>Terms & Conditions apply.</u>{" "}
                  </p>
                  <p className="text-xs font-semibold mb-0">
                    Date Created:{" "}
                    <span className="text-gray-500 font-medium">
                      16 February 2024
                    </span>
                  </p>
                </div>
              </div>
            </div>
          )}

          {/* --------TABLETS------------------ */}
          {userManagTab === "rewards" && (
            <div className="grid g:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3">
              <div className="border rounded-3xl p-3">
                <div className="w-[70px] h-[70px] flex items-center justify-center bg-[#E1E7FF] rounded-xl">
                  {svgImg()}
                </div>
                <p className="text-lg font-medium mb-1 mt-2">
                  Jamieson’s Pizzeria
                </p>
                <p className="text-base mb-2">25% discount voucher</p>
                <div className="flex-1 bg-gray-300 rounded-full h-[8px] ">
                  <div
                    className="h-[8px] rounded-full"
                    style={{ width: `60%`, backgroundColor: "#E1E7FF" }}
                  ></div>
                </div>
                <p className="text-xs mb-2 mt-[1px] text-gray-400">
                  100 points
                </p>

                <p className="text-xs font-medium text-gray-500">
                  Congratulations! Enjoy a free coffee at Donald's Cafe. Claim
                  your discount now. <u>Terms & Conditions apply.</u>{" "}
                </p>
                <p className="text-xs font-semibold mb-0">For all Members</p>
              </div>
              <div className="border rounded-3xl p-3">
                <div className="w-[70px] h-[70px] flex items-center justify-center bg-[#E1E7FF] rounded-xl">
                  {svgImg()}
                </div>
                <p className="text-lg font-medium mb-1 mt-2">
                  Jamieson’s Pizzeria
                </p>
                <p className="text-base mb-2">25% discount voucher</p>
                <div className="flex-1 bg-gray-300 rounded-full h-[8px] ">
                  <div
                    className="h-[8px] rounded-full"
                    style={{ width: `60%`, backgroundColor: "#E1E7FF" }}
                  ></div>
                </div>
                <p className="text-xs mb-2 mt-[1px] text-gray-400">
                  100 points
                </p>

                <p className="text-xs font-medium text-gray-500">
                  Congratulations! Enjoy a free coffee at Donald's Cafe. Claim
                  your discount now. <u>Terms & Conditions apply.</u>{" "}
                </p>
                <p className="text-xs font-semibold mb-0">For all Members</p>
              </div>
              <div className="border rounded-3xl p-3">
                <div className="w-[70px] h-[70px] flex items-center justify-center bg-[#E1E7FF] rounded-xl">
                  {svgImg()}
                </div>
                <p className="text-lg font-medium mb-1 mt-2">
                  Jamieson’s Pizzeria
                </p>
                <p className="text-base mb-2">25% discount voucher</p>
                <div className="flex-1 bg-gray-300 rounded-full h-[8px] ">
                  <div
                    className="h-[8px] rounded-full"
                    style={{ width: `60%`, backgroundColor: "#E1E7FF" }}
                  ></div>
                </div>
                <p className="text-xs mb-2 mt-[1px] text-gray-400">
                  100 points
                </p>

                <p className="text-xs font-medium text-gray-500">
                  Congratulations! Enjoy a free coffee at Donald's Cafe. Claim
                  your discount now. <u>Terms & Conditions apply.</u>{" "}
                </p>
                <p className="text-xs font-semibold mb-0">For all Members</p>
              </div>
            </div>
          )}
          {userManagTab === "orders" && <p>Not found</p>}
        </div>
      </div>
    </SideBar>
  );
}
