import React, { useState } from 'react'
import user from '../../assets/imges/blogUser.png'
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';
import { BsThreeDotsVertical } from 'react-icons/bs';

function createData(Business, Category, Subscription, Devices, Priority, Status, Drop) {
    return { Business, Category, Subscription, Devices, Priority, Status, Drop };
}
const rows = [
    createData
        (
            <>
                <div className='poppins d-flex align-items-center '>
                    <img src={user} width={50} height={50} alt="w8" style={{ clipPath: 'circle(50%)' }} />
                    <div className='ms-2'>
                        <p className="font-16 fw-semibold text-dark mb-0" >Business name</p>
                        <p className="font-13 text-gray fw-medium  mb-0" >Email</p>
                    </div>
                </div>
            </>,
            <p className="font-16 fw-semibold text-dark mb-0 poppins">Cafe</p>,
            <div className='text-start'>
                <p className="font-16 fw-semibold text-dark mb-0 poppins">Elite Plan</p>
                <p className="font-10 text-gray fw-medium  mb-0 poppins" >(Monthly)</p>

            </div>,
            <div className='text-start'>
                <p className="font-16 fw-semibold text-dark mb-0 poppins">x2</p>
                <p className="font-10 text-gray fw-medium  mb-0 poppins" >Time Link Connect, Lenovo M7</p>

            </div>,
            <Button className='btn-red poppins px-3 rounded-5 font-12 fw-medium text-capitalize' style={{ whiteSpace: 'nowrap' }}>5 Days</Button>,

            <Button className='btn-blue poppins px-3 rounded-5 font-12 fw-medium text-capitalize' style={{ whiteSpace: 'nowrap' }}>Confirm</Button>,
            <div className="dropdown business-table-drop-down">
                <button className="border-0 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <BsThreeDotsVertical size={18} />
                </button>
                <ul className="dropdown-menu">
                    <li><a className="dropdown-item" href="#">Action</a></li>
                    <li><a className="dropdown-item" href="#">Another action</a></li>
                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                </ul>
            </div>




        ),
    createData
        (
            <>
                <div className='poppins d-flex align-items-center '>
                    <img src={user} width={50} height={50} alt="w8" style={{ clipPath: 'circle(50%)' }} />
                    <div className='ms-2'>
                        <p className="font-16 fw-semibold text-dark mb-0" >Business name</p>
                        <p className="font-13 text-gray fw-medium  mb-0" >Email</p>
                    </div>
                </div>
            </>,
            <p className="font-16 fw-semibold text-dark mb-0 poppins">Cafe</p>,
            <div className='text-start'>
                <p className="font-16 fw-semibold text-dark mb-0 poppins">Elite Plan</p>
                <p className="font-10 text-gray fw-medium  mb-0 poppins" >(Monthly)</p>

            </div>,
            <div className='text-start'>
                <p className="font-16 fw-semibold text-dark mb-0 poppins">x2</p>
                <p className="font-10 text-gray fw-medium  mb-0 poppins" >Time Link Connect, Lenovo M7</p>

            </div>,
            <Button className='btn-orange poppins px-3 rounded-5 font-12 fw-medium text-capitalize' style={{ whiteSpace: 'nowrap' }}>5 Days</Button>,

            <Button className='btn-blue poppins px-3 rounded-5 font-12 fw-medium text-capitalize' style={{ whiteSpace: 'nowrap' }}>Confirm</Button>,
            <div className="dropdown business-table-drop-down">
                <button className="border-0 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <BsThreeDotsVertical size={18} />
                </button>
                <ul className="dropdown-menu">
                    <li><a className="dropdown-item" href="#">Action</a></li>
                    <li><a className="dropdown-item" href="#">Another action</a></li>
                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                </ul>
            </div>




        ),
    createData
        (
            <>
                <div className='poppins d-flex align-items-center '>
                    <img src={user} width={50} height={50} alt="w8" style={{ clipPath: 'circle(50%)' }} />
                    <div className='ms-2'>
                        <p className="font-16 fw-semibold text-dark mb-0" >Business name</p>
                        <p className="font-13 text-gray fw-medium  mb-0" >Email</p>
                    </div>
                </div>
            </>,
            <p className="font-16 fw-semibold text-dark mb-0 poppins">Cafe</p>,
            <div className='text-start'>
                <p className="font-16 fw-semibold text-dark mb-0 poppins">Elite Plan</p>
                <p className="font-10 text-gray fw-medium  mb-0 poppins" >(Monthly)</p>

            </div>,
            <div className='text-start'>
                <p className="font-16 fw-semibold text-dark mb-0 poppins">x2</p>
                <p className="font-10 text-gray fw-medium  mb-0 poppins" >Time Link Connect, Lenovo M7</p>

            </div>,
            <Button className='btn-green poppins px-3 rounded-5 font-12 fw-medium text-capitalize' style={{ whiteSpace: 'nowrap' }}>5 Days</Button>,

            <Button className='btn-blue poppins px-3 rounded-5 font-12 fw-medium text-capitalize' style={{ whiteSpace: 'nowrap' }}>Confirm</Button>,
            <div className="dropdown business-table-drop-down">
                <button className="border-0 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <BsThreeDotsVertical size={18} />
                </button>
                <ul className="dropdown-menu">
                    <li><a className="dropdown-item" href="#">Action</a></li>
                    <li><a className="dropdown-item" href="#">Another action</a></li>
                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                </ul>
            </div>




        ),
    createData
        (
            <>
                <div className='poppins d-flex align-items-center '>
                    <img src={user} width={50} height={50} alt="w8" style={{ clipPath: 'circle(50%)' }} />
                    <div className='ms-2'>
                        <p className="font-16 fw-semibold text-dark mb-0" >Business name</p>
                        <p className="font-13 text-gray fw-medium  mb-0" >Email</p>
                    </div>
                </div>
            </>,
            <p className="font-16 fw-semibold text-dark mb-0 poppins">Cafe</p>,
            <div className='text-start'>
                <p className="font-16 fw-semibold text-dark mb-0 poppins">Elite Plan</p>
                <p className="font-10 text-gray fw-medium  mb-0 poppins" >(Monthly)</p>

            </div>,
            <div className='text-start'>
                <p className="font-16 fw-semibold text-dark mb-0 poppins">x2</p>
                <p className="font-10 text-gray fw-medium  mb-0 poppins" >Time Link Connect, Lenovo M7</p>

            </div>,
            <Button className='btn-green poppins px-3 rounded-5 font-12 fw-medium text-capitalize' style={{ whiteSpace: 'nowrap' }}>5 Days</Button>,

            <Button className='btn-blue poppins px-3 rounded-5 font-12 fw-medium text-capitalize' style={{ whiteSpace: 'nowrap' }}>Confirm</Button>,
            <div className="dropdown business-table-drop-down">
                <button className="border-0 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <BsThreeDotsVertical size={18} />
                </button>
                <ul className="dropdown-menu">
                    <li><a className="dropdown-item" href="#">Action</a></li>
                    <li><a className="dropdown-item" href="#">Another action</a></li>
                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                </ul>
            </div>




        ),


].sort((a, b) => (a.Category < b.Category ? -1 : 1));


const NewBusinessTabel = () => {
    return (
        <>


            <div className="container-fluid ProfileVisit order-table my-42">
                <div className="p-3">

                    <div className='p-4 rounded-3' style={{ backgroundColor: 'rgba(29, 89, 249, 1)' }}>
                        <p className="font-15 fw-bold inter  mb-0  text-white">Attention: Please Review new businesses. </p>
                    </div>
                </div>
                <div className="row justify-content-center bg-white rounded-3 p-3">
                    <div className="col-12  px-0" >

                        <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
                            <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                                <TableHead >
                                    <TableRow>
                                        <TableCell className='font-16 poppins fw-medium '>Business information</TableCell>
                                        <TableCell className='font-16 poppins fw-medium' align="center">Category</TableCell>
                                        <TableCell className='font-16 poppins fw-medium' align="center">Subscription</TableCell>
                                        <TableCell className='font-16 poppins fw-medium' align="center">Devices Assigned</TableCell>
                                        <TableCell className='font-16 poppins fw-medium' align="center">Priority Level</TableCell>
                                        <TableCell className='font-16 poppins fw-medium' align="center">Status</TableCell>
                                        <TableCell className='font-16 poppins fw-medium' align="center"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow key={row.Business}>
                                            <TableCell className='' sx={{width:'27%'}}  >
                                                {row.Business}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {row.Category}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {row.Subscription}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {row.Devices}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {row.Priority}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {row.Status}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {row.Drop}
                                            </TableCell>

                                        </TableRow>
                                    ))}

                                </TableBody>

                            </Table>
                        </TableContainer>

                    </div>
                </div>
            </div>
            <div>

            </div>



        </>
    )
}

export default NewBusinessTabel 
