import React, { useState } from 'react';
import SideBar from '../../components/superAdmin/SideBar';
import { Button } from '@mui/material';
import { BiCalendarEvent } from 'react-icons/bi';
import { FaAngleDown, FaArrowUp, FaTags } from 'react-icons/fa';
import DeviceCard from '../../components/superAdmin/Device/DeviceCard';
import LoyaltyProgramTable from '../../components/superAdmin/Loyalty/LoyaltyProgramTable';
import CloseIcon from "@mui/icons-material/Close";
import uploadicon from "../../assets/imges/upload.png";
import Dropzone from "react-dropzone";
import Header from '../../components/superAdmin/Header';

const LoyaltyProgram = () => {
    const [logoUpload, setLogoUpload] = useState([]);
    const handleLogoDrop = (acceptedFiles) => {
        // Limit the number of images to 3
        if (logoUpload.length + acceptedFiles.length > 1) {
            alert("You can upload a maximum of 1 images.");
            return;
        }

        // Process and store the uploaded images
        const updatedLogo = [...logoUpload, ...acceptedFiles];
        setLogoUpload(updatedLogo);
    };

    const removeLogo = (index) => {
        const updatedLogo = logoUpload.filter((img, i) => i !== index);
        setLogoUpload(updatedLogo);
    };
    // ------------MODAL---LOGICS-------
    const [businessData, setBusinessData] = useState(null);
    const handleShowBusinessData = () => {
        setBusinessData(!businessData);
    };
    return (
        <SideBar>
         
            <div className="w-full super px-0">
            <div className="main_row ">
                        <div className="container-fluid  h-100 p-3">
                            <div className="container ">
                                <div className="row inter mb-2">
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <h4 className="font-25">Loyalty Program</h4>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-3 text-end ">
                                        <Button className="btn-blue rounded-5 px-3 p-2 fw-medium font-20 text-capitalize" data-bs-toggle="modal"
                                            data-bs-target="#loyalty"
                                        >
                                            <FaTags size={24} className="me-2" />
                                            New Program
                                        </Button>
                                        {/* Modal */}
                                        <div
                                            className="modal businessModal fade pe-0 me-0"
                                            id="loyalty"
                                            data-bs-backdrop="static"
                                            data-bs-keyboard="false"
                                            tabIndex={-1}
                                            aria-labelledby="loyaltyLabel"
                                            aria-hidden="true"
                                        >
                                            <div className="modal-dialog modal-dialog-scrollable ">
                                                <div className="modal-content px-2 pb-3">
                                                    <div className="modal-header text-center ">
                                                        <h4 className="font-25 inter mt-3 w-100">
                                                            -Reject- <br />
                                                            <span className='text-uppercase'>

                                                                BUY 6 COFFEES, GET ONE FREE
                                                            </span>
                                                        </h4>
                                                     
                                                    </div>
                                                    <div className="modal-body">
                                                        <div className="container-fluid">
                                                            <div className="row ">
                                                                <div className="col-7">
                                                                    <div className="text-start ">
                                                                        <p className="form-label text-gray text-start font-17 fw-medium mb-2">
                                                                        Feedback
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-5">
                                                                    <div className="text-start ">
                                                                        <p className="form-label text-gray mb-2 text-start font-17 fw-medium ">
                                                                        Attachments
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row ">
                                                                <div className="col-7">
                                                                    <textarea
                                                                        className="form-control h-100 rounded-4 input-field "
                                                                        id="textarea"
                                                                        rows={6}
                                                                    />
                                                                </div>
                                                                <div className="col-5">
                                                                    {logoUpload.length < 1 && (
                                                                        <Dropzone
                                                                            onDrop={handleLogoDrop}
                                                                            accept=".jpeg, .png, .gif, .mp4, .pdf, .psd, .ai, .doc, .docx, .ppt, .pptx"
                                                                        >
                                                                            {({ getRootProps, getInputProps }) => (
                                                                                <div
                                                                                    className="   dropzone   rounded-4"
                                                                                    {...getRootProps()}
                                                                                >
                                                                                    <div className="p-2 py-4 business-uploader rounded-4 d-flex text-center align-items-center  w-100">
                                                                                        <div className="w-100 mulish">
                                                                                            <input {...getInputProps()} />
                                                                                            <img
                                                                                                src={uploadicon}
                                                                                                height={60}
                                                                                                width={70}
                                                                                                alt="w8"
                                                                                            />
                                                                                            <p className="mb-0 font-16 fw-bold mt-4">
                                                                                                Drag & drop files or{" "}
                                                                                                <u className="blue-text">
                                                                                                    Browse
                                                                                                </u>
                                                                                            </p>
                                                                                            <p
                                                                                                className="mb-0 font-12 "
                                                                                                style={{
                                                                                                    color:
                                                                                                        "rgba(103, 103, 103, 1)",
                                                                                                }}
                                                                                            >
                                                                                                Supported formats:
                                                                                                <br />
                                                                                                JPEG, PNG, GIF, MP4, PDF, PSD,
                                                                                                AI, Word, PPT
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </Dropzone>
                                                                    )}
                                                                    {logoUpload.map((file, index) => (
                                                                        <div
                                                                            key={index}
                                                                            className="h-100 p-3 pe-4 mulish rounded-4 text-start position-relative "
                                                                            style={{ backgroundColor: "#e9efff" }}
                                                                        >
                                                                            {/* <img src={URL.createObjectURL(file)} className='w-100 rounded-3 h-100' alt={`Uploaded ${index + 1}`} /> */}
                                                                            <p className="mb-0">{file.name}</p>
                                                                            <button
                                                                                type="button "
                                                                                className="border-0 position-absolute end-0 top-0 mt-1 me-1"
                                                                                onClick={() => removeLogo(index)}
                                                                                style={{
                                                                                    backgroundColor: "transparent",
                                                                                }}
                                                                            >
                                                                                <CloseIcon />
                                                                            </button>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-6 text-center">
                                                            <Button
                                                                className="btn-white rounded-5 w-75 px-3 p-2 fw-medium font-20 text-capitalize"
                                                                data-bs-dismiss="modal"
                                                            >
                                                                Cancel
                                                            </Button>
                                                        </div>
                                                        <div className="col-6 text-center">
                                                            <Button
                                                                onClick={handleShowBusinessData}
                                                                className="btn-blue rounded-5 w-75 px-3 p-2 fw-medium font-20 text-capitalize"
                                                                data-bs-dismiss="modal"
                                                            >
                                                                Create
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-lg-3 col-md-6 col-12 mt-lg-0 mt-3">
                                        <DeviceCard ratingColor='rgba(35, 197, 129, 1)' rating='12' icon={<FaArrowUp />} head="Total" number="1543" />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-12 mt-lg-0 mt-3">
                                        <DeviceCard ratingColor='rgba(231, 69, 69, 1)' rating='12' icon={<FaArrowUp />} head="Active" number="13" />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-12 mt-lg-0 mt-3">
                                        <DeviceCard ratingColor='rgba(35, 197, 129, 1)' rating='12' icon={<FaArrowUp />} head="Orders" number="1" />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-12 mt-lg-0 mt-3">
                                        <DeviceCard ratingColor='rgba(35, 197, 129, 1)' rating='12' icon={<FaArrowUp />} head="Monthly users" number="1" />
                                    </div>
                                </div>
                                <div className='mt-4'>
                                    {/* <h3 className="font-18 mt-4 inter fw-medium">Loyalty Program</h3> */}
                                    <LoyaltyProgramTable />
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            <style>
                {`
            .modal {
                --bs-modal-width: 800px !important;
            }
            `}
            </style>
        </SideBar>
    );
}

export default LoyaltyProgram;
