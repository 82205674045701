import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Spinner } from "reactstrap";
import LoginHeader from '../../components/LoginHeader';
import { Autocomplete, Button, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FaAngleDown, FaRegAddressCard, FaRegEnvelope } from 'react-icons/fa';
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { MdLocationOn } from 'react-icons/md';
import { GoDotFill, GoTriangleDown } from 'react-icons/go';
import { HiIdentification } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { setBusinessProfile } from '../../redux/slices/userSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaAngleLeft } from 'react-icons/fa6';

const SignUpContact = () => {
    const dispatch = useDispatch();
    const { isLoading, getError } = useSelector((state) => state.user)
    const navigate = useNavigate();
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState();
    const [position, setPostion] = useState("");
    const [selectedOption, setSelectedOption] = useState(null);
    const [clientRadio, setClientRadio] = React.useState();
    const [client, setClient] = React.useState(false);
    const [isBtnDisable, setIsBtnDisable] = React.useState(false);

    useEffect(() => {
        // Check if all required fields are filled
        if (fname && lname && email && number && clientRadio) {
            setIsBtnDisable(false);
        } else {
            setIsBtnDisable(true);
        }
    }, [fname, lname, email, number, clientRadio]); 
    useEffect(() => {
        if (getError && getError.length > 1) {
            toast.error(getError, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }, [getError])
    const handleOptionChange = (event, newValue) => {
        setSelectedOption(newValue);
        setPostion(newValue ? newValue.label : ""); // Set the address based on the selected option
    };
    var styleLocation = {
        border: selectedOption ? "1.5px solid rgba(37, 99, 235, 1)" : "1.5px solid rgba(226, 232, 240, 1)",
    };
    if (fname.length > 0) {
        var style = {
            border: '1.5px solid rgba(37, 99, 235, 1)',
        }
    }
    else {
        var style = {
            border: '1.5px solid rgba(226, 232, 240, 1)',
        }
    }
    if (lname.length > 0) {
        var stylelname = {
            border: '1.5px solid rgba(37, 99, 235, 1)',
        }
    }
    else {
        var stylelname = {
            border: '1.5px solid rgba(226, 232, 240, 1)',
        }
    }
    if (email.length > 0) {
        var styleEmail = {
            border: '1.5px solid rgba(37, 99, 235, 1)',
        }
    }
    else {
        var styleEmail = {
            border: '1.5px solid rgba(226, 232, 240, 1)',
        }
    }



    const handleSubmit = (e) => {
        e.preventDefault();
        var data = {
            "primary_contact_position": clientRadio && clientRadio.length > 0 ? clientRadio : null,
            "primary_contact_phone": number.length > 0 ? number : null,
            "primary_contact_email": email.length > 0 ? email : null,
            "primary_contact_l_name": lname.length > 0 ? lname : null,
            "primary_contact_f_name": fname.length > 0 ? fname : null
        }
        dispatch(setBusinessProfile(4, data, handleResponse))

    };
    function handleResponse(data) {
        navigate("/");
    }

    return (
        <>
            <Container fluid className='signup-steps-pages inter'>
                <LoginHeader />
                <Row className='justify-content-center my-4 SignUp-progress-bar lato'>

                    <Col lg={6} md={6} sm={12} xs={12} >
                        <div role='button' onClick={() => navigate(-1)} className='w-8 h-8 bg-blue-600 mb-3 rounded-full text-white flex justify-center items-center'>
                            <FaAngleLeft size={20} />
                        </div>
                        <Row>
                            <Col lg={3} md={3} sm={3} xs={3} className='px-1'>
                                <div className='text-center lh-base'>
                                    <p className="font-10 mb-0 fw-semibold " style={{ color: '#6F6F6F' }}>Sign Up</p>
                                    <GoDotFill size={8} className='my-0 mb-2 blue' />
                                </div>
                                <div className="progress rounded-5" role="progressbar" aria-label="Basic example" aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}>
                                    <div className="progress-bar rounded-5" style={{ width: '100%' }} />
                                </div>

                            </Col>
                            <Col lg={6} md={6} sm={6} xs={6} className='px-1'>
                                <div className='text-center lh-base'>
                                    <p className="font-10 mb-0 fw-semibold " style={{ color: '#6F6F6F' }}>Business Profile</p>
                                    <GoDotFill size={8} className='my-0 mb-2 blue' />
                                </div>
                                <div className="progress  rounded-5" role="progressbar" aria-label="Basic example" aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}>
                                    <div className="progress-bar  rounded-5" style={{ width: '100%' }} />
                                </div>
                            </Col>
                            <Col lg={3} md={3} sm={3} xs={3} className='px-1'>
                                <div className='text-center lh-base'>
                                    <p className="font-10 mb-0 fw-semibold " style={{ color: '#6F6F6F' }}>Primary Contact</p>
                                    <GoDotFill size={8} color='#1D59F8' className='my-0 mb-2 ' />
                                </div>
                                <div className="progress  rounded-5" role="progressbar" aria-label="Basic example" aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}>
                                    <div className="progress-bar  rounded-5" style={{ width: '6%' }} />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className='align-items-center justify-content-center d-flex login_height  my-5' >
                    <Col lg={5} md={12} sm={12} xs={12} className=' ' >

                        <form className='business-login-form rounded-4 d-flex justify-content-between flex-column p-3' onSubmit={handleSubmit}>
                            <div>

                                <div className='inter text-center mt-2'>
                                    <h4 className="font-24 fw-bold  text-dak-blue">Primary Contact</h4>
                                    <p className="font-14 text-dark-gray">Lucky last, Promise </p>
                                </div>
                                <div className='login-form' >
                                    <Row>
                                        <Col lg={6} md={6} sm={12} xs={12}>
                                            <div className="input-group my-2 rounded-4" style={style}>
                                                {/* <span className="input-group-text border-0 ps-3" id="basic-addon1"><FaRegAddressCard
                                                    size={20} color='rgba(100, 116, 139, 1)' /></span> */}
                                                <input type="text" required className="form-control border-0 p-3 rounded-4" placeholder='First Name' value={fname} onChange={(e) => setFname(e.target.value)} />
                                            </div>
                                        </Col>
                                        <Col lg={6} md={6} sm={12} xs={12}>
                                            <div className="input-group my-2 rounded-4" style={stylelname}>
                                                {/* <span className="input-group-text border-0 ps-3" id="basic-addon1"><FaRegAddressCard
                                                    size={20} color='rgba(100, 116, 139, 1)' /></span> */}
                                                <input type="text" required className="form-control border-0 p-3  rounded-4 " placeholder='Last Name' value={lname} onChange={(e) => setLname(e.target.value)} />
                                            </div>
                                        </Col>
                                        <Col lg={12} md={12} sm={12} xs={12}>
                                            <div className="input-group my-2 bg-white rounded-4" style={styleEmail}>
                                                <span className="input-group-text border-0 ps-3" id="basic-addon1"><FaRegEnvelope
                                                    size={20} color='rgba(100, 116, 139, 1)' /></span>
                                                <input type="email" required className="form-control border-0 p-3 ps-0 rounded-4 " placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} />
                                            </div>
                                        </Col>
                                        <Col lg={12} md={12} sm={12} xs={12}>
                                            <div className={`forget-number-field my-2 ${number ? 'forget-number-fieldGrater' : 'forget-number-fieldLower'}`}>

                                                <PhoneInput
                                                    className=""
                                                    international
                                                    defaultCountry="AU"
                                                    value={number}
                                                    placeholder="95765 744 7656"
                                                    onChange={setNumber}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={12} md={12} sm={12} xs={12}>
                                            <div className="position-relative">

                                                <div className="input-group my-3 rounded-4 d-flex flex-row flex-nowrap py-3 px-3" style={{ ...style, backgroundColor: '#ffffff' }}
                                                    onClick={() => { setClient(!client); }}>
                                                    <HiIdentification
                                                        size={20}
                                                        color="rgba(100, 116, 139, 1)"
                                                    />
                                                    <div className='d-flex align-items-center justify-content-between w-100 ps-3'>

                                                        {clientRadio ? clientRadio : 'Select Postion'}
                                                        <GoTriangleDown />
                                                    </div>
                                                </div>
                                                {
                                                    client &&
                                                    <div className='position-absolute bg-white p-2 px-3 shadow w-100 top-100 start-0 rounded-4 z-1'>
                                                        <FormControl>

                                                            <RadioGroup

                                                                // defaultValue="All-time"
                                                                onChange={(e) => {
                                                                    setClientRadio(e.target.value);
                                                                    setClient(false)
                                                                }
                                                                }
                                                                value={clientRadio}
                                                                name="radio-buttons-client"
                                                            >
                                                                <FormControlLabel value="Director" control={<Radio
                                                                    sx={{
                                                                        color: '#1D59F9',
                                                                        '&.Mui-checked': {
                                                                            color: '#1D59F9   ',
                                                                        },
                                                                    }}
                                                                />} label="Director" />
                                                                <FormControlLabel value="Sales Manager" control={<Radio
                                                                    sx={{
                                                                        color: '#1D59F9',
                                                                        '&.Mui-checked': {
                                                                            color: '#1D59F9   ',
                                                                        },
                                                                    }}
                                                                />} label="Sales Manager" />
                                                                <FormControlLabel value="Manager" control={<Radio
                                                                    sx={{
                                                                        color: '#1D59F9',
                                                                        '&.Mui-checked': {
                                                                            color: '#1D59F9   ',
                                                                        },
                                                                    }}
                                                                />} label="Manager" />
                                                                <FormControlLabel value="Staff member" control={<Radio
                                                                    sx={{
                                                                        color: '#1D59F9',
                                                                        '&.Mui-checked': {
                                                                            color: '#1D59F9   ',
                                                                        },
                                                                    }}
                                                                />} label="Staff member" />

                                                                <FormControlLabel value="Other" control={<Radio
                                                                    sx={{
                                                                        color: '#1D59F9',
                                                                        '&.Mui-checked': {
                                                                            color: '#1D59F9   ',
                                                                        },
                                                                    }}
                                                                />} label="Other" />






                                                            </RadioGroup>
                                                        </FormControl>

                                                    </div>
                                                }
                                            </div>
                                            {/* <div
                                                className="select-Address my-2 px-3 d-flex align-items-center rounded-4 fw-semibold"
                                                style={styleLocation}
                                            >
                                                <HiIdentification
                                                    size={20}
                                                    color="rgba(100, 116, 139, 1)"
                                                />
                                                <Autocomplete
                                                    disablePortal
                                                    options={top100Films}
                                                    getOptionLabel={(option) => option.label}
                                                    onChange={handleOptionChange}
                                                    value={selectedOption}
                                                    className="w-100"
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            value={position}
                                                            required
                                                            className="fw-normal"
                                                            onChange={(e) => setPostion(e.target.value)}
                                                            placeholder="Position"
                                                        />
                                                    )}
                                                />
                                                <FaAngleDown
                                                    size={20}
                                                    color="rgba(100, 116, 139, 1)"
                                                />
                                            </div> */}
                                        </Col>
                                    </Row>





                                </div>
                            </div>
                            <Row>
                                <Col lg={6} md={6} sm={12} xs={12}>

                                    <Button type='button' onClick={() => { navigate('/') }} className='btn-blueTwo rounded-4 inter px-3 mt-2 p-3 w-100 fw-bold font-16 text-capitalize' sx={{ fontFamily: 'Inter, sans-serif !important' }}>Skip</Button>
                                </Col>
                                <Col lg={6} md={6} sm={12} xs={12}>

                                    <Button disabled={isBtnDisable} type='submit' className={` rounded-4 inter px-3 mt-2 p-3 w-100 fw-bold font-16 text-capitalize ${isBtnDisable ? "!bg-gray-300":"btn-blueTwo"}`} x={{ fontFamily: 'Inter, sans-serif !important' }}>{isLoading ? <Spinner color="light" /> : 'Continue'}</Button>
                                </Col>

                            </Row>

                        </form>


                    </Col>
                </Row>
                <ToastContainer />
            </Container>
        </>
    );
}

export default SignUpContact;

