import React from "react";
import { FaArrowUp } from "react-icons/fa";

const DeviceCard = ({svg , bgIcon, head, number, ratingColor,icon , rating}) => {
  return (
    <>
      <div className="px-3 rounded-3 p-2 flex items-center w-full gap-2 overflow-hidden poppins">
        <div className={`w-14 h-14 rounded-full flex justify-center items-center  ${bgIcon}`}>
         {svg}
        </div>
        <div>
          <p className="text-sm mb-1 text-gray-400  ">{head}</p>
          <p className="text-3xl mb-1 fw-medium poppins  text-gray-800 break-all	">
            {number}
          </p>
          <p className="text-xs mb-0 grey flex text-start items-center justify-start gap-1">
            <span className="fw-bold flex" style={{ color: ratingColor }}>
              <span>{icon} </span>&nbsp;+ {rating}
            </span>{" "}
            this week
          </p>
        </div>
      </div>
    </>
  );
};
export default DeviceCard;
