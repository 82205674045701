import React, { useEffect } from "react";
import BlogCard from "./common/BlogCard";
import blog from "../../../assets/imges/cafepic.png";
import { useNavigate } from "react-router-dom";
import SideBar from "../../../components/superAdmin/SideBar";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAllBlogs, seTgetErrorNull } from "../../../redux/slices/userSlice";
import { Spinner } from "react-bootstrap";
import * as moment from 'moment'
import FullScreenLoader from "../../../components/loaders/FullScreenLoader";
const Blogs = () => {
    const dispatch = useDispatch();
    const { isLoading, getError, allBlogs , userDetail } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    if (getError && getError.length > 1) {
      toast.error(getError, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(seTgetErrorNull());

    }
  }, [getError]);
  const Navigate = useNavigate();
    useEffect(() => {
    dispatch(getAllBlogs());
  }, []);
  return (
    <SideBar>
      {/* <!-- ========= content body ======= --> */}

      <div className="w-full">
        <div className="px-lg-4 px-md-3 px-2">
          <div className="flex justify-end">
            <button
              type="button"
              onClick={() =>{ userDetail?.role == 'marketing_staff'?Navigate("/marketing/blog/add") :Navigate("/super/blog/add")}}
              className="px-3 p-2 bg-customBlue  font-medium rounded-full text-white"
            >
              Add Blog
            </button>
          </div>
          <div className="w-full h-full grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-4 my-4">
            {
              isLoading ?
                <FullScreenLoader isLoading={isLoading}/>
                :
                <>
                  {
                    allBlogs?.length > 0 ?
                      allBlogs.map((blog, index)=>{
                        return (
                          <div key={index}>
                    <BlogCard
                      img={blog.image}
                      btn="product"
                              head={blog.title}
                              phara={blog.description}
                      user={blog.avatar}
                      userName={blog.author_name}
                      date={moment(blog.created_at).format('MMMM D, YYYY')}
                    />
                      </div>
                        )
                      })
                  
                      :
                      <div className="w-full h-full flex justify-center items-center">
                        <h5> No Blogs Found</h5>
                </div>
                  }
                </>
            }
            
          </div>
        </div>
      </div>
      <ToastContainer />
    </SideBar>
  );
};

export default Blogs;
