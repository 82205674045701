/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import Header from '../../components/Header'
import SideBar from '../../components/SideBar'
import { Button, FormControlLabel, Pagination, Radio, RadioGroup, Slider, Stack } from '@mui/material'
import StepOne from '../../components/campaigns/StepOne'
import StepTwo from '../../components/campaigns/StepTwo'
import StepThree from '../../components/campaigns/StepThree'
import cafe from '../../assets/imges/cafepic.png'
import notification from '../../assets/imges/noti.png'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { Modal } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { FaAngleLeft } from 'react-icons/fa6'
import { useDispatch, useSelector } from 'react-redux';
import { getBusinessCampaings, createBusinessCampaign, setSumitCamgpaingState, emptySumitCamgpaingState } from '../../redux/slices/userSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Spinner } from 'reactstrap';
import MarketingSlider from '../../components/MarketingSlider'
import ReactPaginate from 'react-paginate';
import PaginatedItems from '../../components/pagination/PaginatedItems'
import Items from '../../components/pagination/Items'
import { Area, Line, LineChart, ResponsiveContainer, defs, linearGradient, stop } from 'recharts'
import NewCampaignsItems from '../../components/pagination/NewCampaignsItems'
import { FormControl, MenuItem, Select } from '@mui/material'
import { PieChart } from 'react-minimal-pie-chart'
import { GoTriangleDown } from 'react-icons/go'

const data = [
    {
        name: "Page A",
        uv: 40,
        pv: 3,
        amt: 24
    },
    {
        name: "Page B",
        uv: 30,
        pv: 4,
        amt: 22
    },
    {
        name: "Page C",
        uv: 2,
        pv: 2,
        amt: 2290
    },
    {
        name: "Page D",
        uv: 3,
        pv: 4,
        amt: 20
    },
    {
        name: "Page E",
        uv: 18,
        pv: 5,
        amt: 21
    },
    {
        name: "Page F",
        uv: 23,
        pv: 6,
        amt: 25
    },
    {
        name: "Page G",
        uv: 34,
        pv: 4,
        amt: 20
    }
];
// ============== charts ========
const piaChart = [
    { title: '1,350', reach: 'Sign-ups', value: 50, color: '#2B65F8' },
    { title: '4,500', reach: 'Impressions', value: 50, color: '#DDE6FE' },
];
const NewCampaigns = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoading, getError, businessCampaigns, submitCampaign, userDetail } = useSelector((state) => state.user)
    const [activeStep, setActiveStep] = useState(0);
    const [createCampaigns, setCreateCampaigns] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [submitStepOne, setSubmitStepOne] = useState(false);
    const childFunc = React.useRef(null)
    const [timeframe, setTimeframe] = React.useState(false);
    const [client, setClient] = React.useState(false);
    const [status, setStatus] = React.useState(false);
    const [timeframeRadio, setTimeframeRadio] = React.useState('All-time');
    const [clientRadio, setClientRadio] = React.useState('All');
    const [statusRadio, setStatusRadio] = React.useState('All');
    const [conversionChart, setConversionChart] = React.useState(null);
    const [filteredData, setFilteredData] = React.useState(null);

    function handleCurrentStep() {
        setActiveStep((ps) => {
            return ps - 1;
        });
    }

    const handleNext = () => {
        var check = childFunc?.current()
        setActiveStep((prevActiveStep) => {
            if (prevActiveStep < 2) {
                return prevActiveStep + 1;
            } else {
                console.log("Not increasing step");
                return prevActiveStep;
            }
        });
        if (activeStep == 2) {
            if (!check) {
            } else {
                setShowModal(true);
            }

        }
    }
    function _renderContent(step) {
        switch (step) {
            case 0:
                return (
                    <StepOne childFunc={childFunc} stepChecker={handleCurrentStep} />
                )
            case 1:
                return (
                    <StepTwo childFunc={childFunc} />
                )
            case 2:
                return (
                    <StepThree childFunc={childFunc} />
                )
            default:
                break;
        }

    }
    const Navigate = useNavigate();

    console.log(activeStep)
    const hanleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);

    }
    useEffect(() => {
        if (getError && getError.length > 1) {
            toast.error(getError, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }, [getError])
    useEffect(() => {
        dispatch(getBusinessCampaings());
    }, []);

    function handleCampaignSubmit() {
        setShowModal(false);
        setCreateCampaigns(false);
        setActiveStep(0);
        dispatch(createBusinessCampaign(submitCampaign, handleResponse))
    }
    function handleResponse(data) {
        console.log('reponse data of campaign create ', data)
        dispatch(getBusinessCampaings());
        dispatch(emptySumitCamgpaingState())
    }
    useEffect(() => {
        if (businessCampaigns.hasOwnProperty('members') && businessCampaigns.hasOwnProperty('impressions')) {
            setConversionChart([
                { title: businessCampaigns.members, reach: 'Sign-ups', value: businessCampaigns.members, color: '#2B65F8' },
                { title: businessCampaigns.impressions, reach: 'Impressions', value: businessCampaigns.impressions, color: '#DDE6FE' },

            ])
        }
    }, [businessCampaigns])

    useEffect(() => {
        if (!clientRadio || clientRadio === 'All') {
            setFilteredData(null);
            return;
        }
        if (clientRadio === 'With Reward') {
            setFilteredData(businessCampaigns?.all.filter(_campaign => _campaign?.reward_id))
            return;
        }
        if (clientRadio === 'Without Reward') {
            setFilteredData(businessCampaigns?.all.filter(_campaign => !_campaign?.reward_id))
            return;
        }
    }, [clientRadio])
    useEffect(() => {
        if (!statusRadio || statusRadio === 'All') {
            setFilteredData(null);
            return;
        }
        if (statusRadio === 'Active') {
            setFilteredData(businessCampaigns?.all.filter(_campaign => _campaign?.status == 'active'))
            return;
        }
        if (statusRadio === 'Inactive') {
            setFilteredData(businessCampaigns?.all.filter(_campaign => _campaign?.status == 'inactive'))
            return;
        }
    }, [statusRadio])
    return (
        <section className='business_admin_main'>

            <div className="container-fluid ">
                <div className="row order ">

                    {/* <!-- ===========SID-BAR======= --> */}
                    <SideBar />
                    {/* <!-- ========= content body ======= --> */}

                    <div className="w-lg-80 px-0 pb-2 marketing_tab h-screen overflow-y-scroll">
                        {/* <!-- ===========HEADER========== --> */}
                        <Header PageTitle="Marketing Campaign" />
                        <div className="px-lg-4 px-md-3 px-2">

                        {createCampaigns == false ?
                            <div className="container-fluid p-0 mt-4">

                                <div className='row justify-content-between new-select-option g-0'>
                                    <div className='col-8'>
                                        <div className="row g-2">
                                            <div className="col-4 position-relative d-none">

                                                <div className=' stats_select rounded-5  d-flex align-items-center white-border '
                                                    onClick={() => { setTimeframe(!timeframe); setStatus(false); setClient(false) }}>
                                                    <p className='mb-0 me-1 text-dark-gray fw-medium '>Timeframe:</p>
                                                    <div className='d-flex align-items-center justify-content-between w-100'>

                                                        {timeframeRadio}
                                                        <GoTriangleDown />
                                                    </div>
                                                </div>
                                                {
                                                    timeframe &&
                                                    <div className='position-absolute bg-white p-2 px-3 shadow w-100 top-100 start-0 rounded-4 z-1'>
                                                        <FormControl>

                                                            <RadioGroup

                                                                // defaultValue="All-time"
                                                                onChange={(e) => {
                                                                    setTimeframeRadio(e.target.value);
                                                                    setTimeframe(false)
                                                                }
                                                                }
                                                                value={timeframeRadio}
                                                                name="radio-buttons-time"
                                                            >
                                                                <FormControlLabel value="All-time" control={<Radio
                                                                    sx={{
                                                                        color: '#1D59F9',
                                                                        '&.Mui-checked': {
                                                                            color: '#1D59F9   ',
                                                                        },
                                                                    }}
                                                                />} label="All-time" />
                                                                <FormControlLabel value="Once" control={<Radio
                                                                    sx={{
                                                                        color: '#1D59F9',
                                                                        '&.Mui-checked': {
                                                                            color: '#1D59F9   ',
                                                                        },
                                                                    }}
                                                                />} label="One" />
                                                                <FormControlLabel value="Monthly" control={<Radio
                                                                    sx={{
                                                                        color: '#1D59F9',
                                                                        '&.Mui-checked': {
                                                                            color: '#1D59F9   ',
                                                                        },
                                                                    }}
                                                                />} label="Monthly" />
                                                            </RadioGroup>
                                                        </FormControl>

                                                    </div>
                                                }
                                            </div>

                                            <div className="col-4 position-relative">

                                                <div className=' stats_select rounded-5  d-flex align-items-center white-border '
                                                    onClick={() => { setStatus(!status); setClient(false); setTimeframe(false); }}>
                                                    <p className='mb-0  text-dark-gray fw-medium '>Status:</p>
                                                    <div className='ms-2 d-flex align-items-center justify-content-between w-100'>

                                                        {statusRadio}
                                                        <GoTriangleDown />
                                                    </div>
                                                </div>
                                                {
                                                    status &&
                                                    <div className='position-absolute bg-white p-2 px-3 shadow w-100 top-100 start-0 rounded-4 z-1'>
                                                        <FormControl>

                                                            <RadioGroup

                                                                // defaultValue="All-time"
                                                                onChange={(e) => {
                                                                    setStatusRadio(e.target.value);
                                                                    setStatus(false)
                                                                }
                                                                }
                                                                value={statusRadio}
                                                                name="radio-buttons-status"
                                                            >
                                                                <FormControlLabel value="All" control={<Radio
                                                                    sx={{
                                                                        color: '#1D59F9',
                                                                        '&.Mui-checked': {
                                                                            color: '#1D59F9   ',
                                                                        },
                                                                    }}
                                                                />} label="All" />
                                                                <FormControlLabel value="Active" control={<Radio
                                                                    sx={{
                                                                        color: '#1D59F9',
                                                                        '&.Mui-checked': {
                                                                            color: '#1D59F9   ',
                                                                        },
                                                                    }}
                                                                />} label="Active" />
                                                                <FormControlLabel value="Inactive" control={<Radio
                                                                    sx={{
                                                                        color: '#1D59F9',
                                                                        '&.Mui-checked': {
                                                                            color: '#1D59F9   ',
                                                                        },
                                                                    }}
                                                                />} label="Inactive" />
                                                            </RadioGroup>
                                                        </FormControl>

                                                    </div>
                                                }
                                            </div>
                                            <div className="col-5 position-relative">

                                                <div className=' stats_select rounded-5  d-flex align-items-center white-border '
                                                    onClick={() => { setClient(!client); setStatus(false); setTimeframe(false); }}>
                                                    <p className='mb-0 ms-2 text-dark-gray fw-medium '>Reward Type:</p>
                                                    <div className='ms-2 d-flex align-items-center justify-content-between w-50'>

                                                        {clientRadio}
                                                        <GoTriangleDown />
                                                    </div>
                                                </div>
                                                {
                                                    client &&
                                                    <div className='position-absolute bg-white p-2 px-3 shadow w-100 top-100 start-0 rounded-4 z-1'>
                                                        <FormControl>

                                                            <RadioGroup

                                                                // defaultValue="All-time"
                                                                onChange={(e) => {
                                                                    setClientRadio(e.target.value);
                                                                    setClient(false)
                                                                }
                                                                }
                                                                value={clientRadio}
                                                                name="radio-buttons-client"
                                                            >
                                                                <FormControlLabel value="All" control={<Radio
                                                                    sx={{
                                                                        color: '#1D59F9',
                                                                        '&.Mui-checked': {
                                                                            color: '#1D59F9   ',
                                                                        },
                                                                    }}
                                                                />} label="All" />
                                                                <FormControlLabel value="With Reward" control={<Radio
                                                                    sx={{
                                                                        color: '#1D59F9',
                                                                        '&.Mui-checked': {
                                                                            color: '#1D59F9   ',
                                                                        },
                                                                    }}
                                                                />} label="With Reward" />
                                                                <FormControlLabel value="Without Reward" control={<Radio
                                                                    sx={{
                                                                        color: '#1D59F9',
                                                                        '&.Mui-checked': {
                                                                            color: '#1D59F9   ',
                                                                        },
                                                                    }}
                                                                />} label="Without Reward" />
                                                            </RadioGroup>
                                                        </FormControl>

                                                    </div>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-3 text-end">


                                        <button onClick={() => {
                                            if (userDetail?.credits <= 0) {
                                                toast.error('OOPS! You have ran out of credits. Buy more credits please.', {
                                                    position: "top-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                    progress: undefined,
                                                    theme: "light",
                                                });
                                                return false;
                                            }
                                            navigate('/custom/marketing/campaign')
                                        }} type="button" className="btn-blue rounded-5 inter px-3 p-2  fw-bold font-14 text-capitalize">New Campaign</button>
                                    </div>
                                </div>


                                <div className="mt-3 white-border p-3 rounded-4 ">
                                    <div style={{ borderBottom: "1px solid #E4E5E7" }}>

                                        <h6 className="font-14" >Campaign list</h6>
                                        <h6 className="font-14" >Status</h6>
                                    </div>
                                    <div className="webkit-scroll">
                                        {
                                            isLoading ?
                                                <div className="row justify-content-center not-active-Campaigns rounded-3 text-center mt-3">
                                                    <div className="col-12">
                                                        <div className="Campaigns p-4 rounded-3">
                                                            <Spinner color='dark' />                                                            </div>
                                                    </div>
                                                </div>
                                                :
                                                !businessCampaigns.all?.length > 0 ?
                                                    <div className="row justify-content-center not-active-Campaigns align-items-center rounded-3 text-center mt-3" style={{ height: "402px" }}>
                                                        <div className="col-12">
                                                            <div className="Campaigns p-4 rounded-3">
                                                                <h5 className='fw-bold text-dark'>No Active Campaigns</h5>
                                                            </div>
                                                        </div>
                                                    </div> :
                                                    <div className="container-fluid Campaigns overflow-y-scroll" style={{ height: "402px" }}>
                                                        <div className="row p-2">
                                                            <NewCampaignsItems currentItems={filteredData ? filteredData : businessCampaigns?.all} />
                                                            {/* <PaginatedItems items={businessCampaigns?.all} itemsPerPage={2} ItemsComponent={NewCampaignsItems} /> */}
                                                            {/* <div className='d-flex justify-content-end hireexpert mt-3 mb-3'>
                                                                    <Stack spacing={4}>
                                                                        <Pagination count={parseInt(businessCampaigns.length / 9 +1) } variant="outlined" shape="rounded" />
                                                                    </Stack>
                                                                </div> */}
                                                        </div>
                                                    </div>
                                        }
                                    </div>
                                    <div className="text-end pt-3">
                                        <h6 className="font-14">{businessCampaigns?.active?.length}/3 Active Campaigns</h6>
                                    </div>


                                </div>
                                <div className="container my-4">

                                    <div className='row '>
                                        <div className="col-8 ps-lg-0">
                                            <div className=' white-border rounded-4'>

                                                <div className="container-fluid pb-4">
                                                    <div className='py-3' style={{ borderBottom: "1px solid #E4E5E7" }}>

                                                        <h6 className="font-14 mb-0" >Marketing Campaign Activity</h6>
                                                    </div>

                                                    <div className="row justify-content-between pt-3">
                                                        <div className="col-4 align-items-center d-flex">
                                                            <p className='font-14 fw-normal mb-0 text-gray'>Members</p>
                                                        </div>
                                                        <div className="col-5 d-flex align-items-center" style={{ height: "40px" }}>
                                                            <p className='fw-bold font-14 mb-0 me-3'>{businessCampaigns?.members}</p>

                                                            <div className='line-chart w-100 ' >

                                                                <LineChart width={300} height={100} data={data}>
                                                                    <defs>
                                                                        <linearGradient id="linear-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                                                                            <stop offset="10%" stopColor="#FF00FF" />
                                                                            <stop offset="51.7%" stopColor="#9116F9" />
                                                                            <stop offset="100%" stopColor="#3199FF" />
                                                                        </linearGradient>
                                                                    </defs>
                                                                    <Line type="monotone" dataKey="pv" stroke="url(#linear-gradient)" strokeWidth={2} dot={false} />
                                                                    {/* <Area type="monotone" dataKey="uv" stroke="#8884d8" fill="#8884d8" /> */}


                                                                </LineChart>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-between">
                                                        <div className="col-4 align-items-center d-flex">
                                                            <p className='font-14 fw-normal mb-0 text-gray'>Redemptions</p>
                                                        </div>
                                                        <div className="col-5 d-flex align-items-center" style={{ height: "40px" }}>
                                                            <p className='fw-bold font-14 mb-0 me-3'>{businessCampaigns?.redeems}</p>

                                                            <div className='line-chart w-100 ' >

                                                                <LineChart width={300} height={100} data={data}>
                                                                    <defs>
                                                                        <linearGradient id="linear-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                                                                            <stop offset="10%" stopColor="#FF00FF" />
                                                                            <stop offset="51.7%" stopColor="#9116F9" />
                                                                            <stop offset="100%" stopColor="#3199FF" />
                                                                        </linearGradient>
                                                                    </defs>
                                                                    <Line type="monotone" dataKey="pv" stroke="url(#linear-gradient)" strokeWidth={2} dot={false} />
                                                                    {/* <Area type="monotone" dataKey="uv" stroke="#8884d8" fill="#8884d8" /> */}


                                                                </LineChart>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-between ">
                                                        <div className="col-4 align-items-center d-flex">
                                                            <p className='font-14 fw-normal mb-0 text-gray'>Average Reach</p>
                                                        </div>
                                                        <div className="col-5 d-flex align-items-center" style={{ height: "40px" }}>
                                                            <p className='fw-bold font-14 mb-0 me-3'>{businessCampaigns?.reach}</p>

                                                            <div className='line-chart w-100 ' >

                                                                <LineChart width={300} height={100} data={data}>
                                                                    <defs>
                                                                        <linearGradient id="linear-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                                                                            <stop offset="10%" stopColor="#FF00FF" />
                                                                            <stop offset="51.7%" stopColor="#9116F9" />
                                                                            <stop offset="100%" stopColor="#3199FF" />
                                                                        </linearGradient>
                                                                    </defs>
                                                                    <Line type="monotone" dataKey="pv" stroke="url(#linear-gradient)" strokeWidth={2} dot={false} />
                                                                    {/* <Area type="monotone" dataKey="uv" stroke="#8884d8" fill="#8884d8" /> */}


                                                                </LineChart>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4 pe-lg-0 ">
                                            <div className='white-border rounded-4 p-2'>
                                                <div className="container-fluid">
                                                    {
                                                        conversionChart &&

                                                        <div className="row">

                                                            <div className="col-6">
                                                                <div >
                                                                    <p className='font-14 fw-medium'>Conversion Rate</p>
                                                                    <h4 className='fw-bold'>{businessCampaigns?.impressions == 0 ? 0 : (businessCampaigns?.members / businessCampaigns?.impressions) * 100}%</h4>
                                                                    <br />
                                                                    {conversionChart.slice(0, 2).map((entry, index) => (
                                                                        <div className='flex-shrink-0' key={index} style={{
                                                                            display: 'flex', alignItems: 'baseline', whiteSpace: 'nowrap', marginTop: "10px"
                                                                        }}>
                                                                            <div className='rounded-1 flex-shrink-0' style={{ width: '18px', height: '10px', backgroundColor: entry.color, marginRight: '5px' }}>
                                                                            </div>
                                                                            <div>

                                                                                <p className="mb-0 font-13 fw-bold"> {entry.title} <span className='fw-normal'>{entry.reach}</span></p>

                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            <div className="col-6 h-auto">
                                                                <PieChart
                                                                    data={conversionChart}
                                                                    lineWidth={40}
                                                                    paddingAngle={5}
                                                                // rounded={1}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div> :
                            <div className="container bg-color rounded-4 mt-4">
                                <div className="row justify-content-center my-3">
                                    <div className="col-1 d-flex align-items-center ">
                                        {activeStep > 0 && <button title='Back step' className='btn-blue rounded-5' style={{ width: "30px", height: "30px" }} onClick={hanleBack}>
                                            <FaAngleLeft />
                                        </button>}
                                    </div>
                                    <div className="col-lg-4 col-12">
                                        <div className="row justify-content-center text-center">
                                            <div className="col-lg-3 col-md-6 col-12 pe-lg-0 pe-md-0 mt-lg-0 mt-2 "
                                                onClick={hanleBack}>
                                                <h6 className="font-13 inter colorblack mb-0">Details</h6>
                                                <i className="fa-solid fa-circle blue font-12" />
                                                <div className="progress rounded-5" role="progressbar" aria-label="Basic example" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                                                    <div className="progress-bar rounded-5 h-auto " style={{ width: `${activeStep == 0 ? "50" : "100"}%` }} />
                                                </div>


                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12 pe-lg-0 pe-md-0 mt-lg-0 mt-2">
                                                <h6 className="font-13 inter colorblack mb-0">Target Audience</h6>
                                                <i className={`fa-solid fa-circle ${activeStep === 0 ? "light-blue" : activeStep === 1 ? "blue" : "blue"} font-12`} />                                                <div className="progress" role="progressbar" aria-label="Basic example" aria-valuenow={50} aria-valuemin={0} aria-valuemax={100}>
                                                    <div className="progress-bar h-auto rounded-5" style={{ width: activeStep === 0 ? "0%" : activeStep === 1 ? "50%" : "100%" }} />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-12 pe-lg-0 pe-md-0 mt-lg-0 mt-2" >
                                                <h6 className="font-13 inter colorblack mb-0">Confirm</h6>
                                                <i className={`fa-solid fa-circle ${activeStep === 2 ? "blue" : "light-blue"} font-12`} />                                                <div className="progress" role="progressbar" aria-label="Basic example" aria-valuenow={75} aria-valuemin={0} aria-valuemax={100}>
                                                    <div className="progress-bar h-auto rounded-5" style={{ width: activeStep === 0 ? "0%" : activeStep === 1 ? "0%" : "50%" }} />

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-1"></div>
                                </div>
                                {_renderContent(activeStep)}

                                <div className="row">
                                    <div className="col-12 mt-4 mb-3 text-center">
                                        <button type='button' onClick={() => {
                                            setCreateCampaigns(false);
                                            setActiveStep(0);
                                        }} className="btn-Rejected rounded-5 px-4 py-2 fw-medium font-14 text-capitalize">
                                            Save &amp; Exit</button>
                                        <button type='button' onClick={handleNext} className="btn-blue rounded-5 px-5 py-2 ms-3 fw-medium font-14 text-capitalize">
                                            {activeStep == 2 ? "Activate" : "Next"}</button>

                                    </div>
                                </div>
                            </div>}
                        </div>


                    </div>

                </div>
            </div>
            {/* ============ MODAL ============= */}

            <Modal show={showModal} className='rounded-modal text-center modals_scroll business_admin_main' centered  >

                <Modal.Body style={{ maxHeight: '85vh', overflowY: 'auto' }}>
                    <div className="py-3 inter">
                        <div>
                            <img src={notification} width="50px" alt />
                            <h6 className="font-24 fw-bold colorblack my-3">Great Choice!</h6>
                            <p className="mt-2 fw-semibold  ">You’re about to use a credit to launch a new <br /> marketing campaign. Are you ready to boost <br /> your business?</p>
                        </div>
                        <div className="col-12 mt-3 mb-3 text-center d-flex align-items-center ">
                            <div className="w-50 px-2">
                                <button onClick={() => {
                                    // setShowModal(false);
                                    // setCreateCampaigns(false);
                                    // setActiveStep(0);
                                    dispatch(setSumitCamgpaingState({ key: 'is_draft', value: 1 }))
                                    handleCampaignSubmit();
                                }} className="btn-gray rounded-4 p-2 px-4  py-3 fw-medium w-100 font-14 text-capitalize" data-bs-dismiss="modal">
                                    Save &amp; Exit</button>
                            </div>
                            <div className="w-50 px-2">
                                <button onClick={handleCampaignSubmit} className="btn-blue rounded-4 p-2 px-5 py-3 fw-medium w-100 font-14 text-capitalize" >
                                    Boost!</button>
                            </div>


                        </div>
                    </div>

                </Modal.Body>

            </Modal>
            <ToastContainer />
        </section >
    )
}

export default NewCampaigns
