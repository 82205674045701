import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import SideBar from "../components/SideBar";
import img from "../assets/imges/quickbooks.png";
import IntegrationCard from "../components/inegration/IntegrationCard";
import { TbGridDots } from "react-icons/tb";
import { CgMenu } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import {
  getSquareRedirectUrl,
  getUser,
  revokeAccess,
  seTgetErrorNull,
} from "../redux/slices/userSlice";
import { Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { FaAngleRight } from "react-icons/fa6";

const Integrations = () => {
  const dispatch = useDispatch();
  const { isLoading, getError, userDetail } = useSelector(
    (state) => state.user
  );
  const [integrationTab, setIntegrationTab] = useState("all-integration");
  const [isToggled, setIsToggled] = useState(false);
  // const [detailTab, setDetailTab] = useState("overview");
  const [modal, setModal] = useState(false);
  const [disconnectModal, setDisconnectModal] = useState(false);

  function handleGetRedirectUrl() {
    dispatch(getSquareRedirectUrl(handleResponse));
  }
  function handleResponse(url) {
    window.location.replace(url);
  }

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };
  const integrationData = [
    {
      title: "Square",
      description:
        "Seamlessly sync your sales, payments, and inventory with Square. Simplify transactions and manage your business with ease.",
      icon: img,
      isConnected: userDetail?.square_access_token ? true : false,
    },
    // { title: "BigCommerce", description: "BigCommerce is a leading e-commerce software platform that provides startup...", icon: img, isConnected: false },
    // { title: "NetSuite", description: "Make smarter, faster decisions using the world's most deployed cloud ERP...", icon: img, isConnected: false },
    // { title: "Mailchimp", description: "Mailchimp makes it easy to sell stuff online, even if you don't have an e-commerce...", icon: img, isConnected: false },
    // { title: "Microsoft Excel", description: "Excel learns your patterns, organizing your data to save you time.", icon: img, isConnected: false },
    // { title: "Salesforce", description: "Salesforce offers a wide variety of CRM categories and systems to meet your...", icon: img, isConnected: false },
    // Add more integration data as needed
  ];
  useEffect(() => {
    if (getError && getError.length > 1) {
      toast.error(getError, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(seTgetErrorNull());

    }
  }, [getError]);
  function handleDisconnectIntegration() {
    dispatch(revokeAccess(handleDisconnectResponse));
  }
  function handleDisconnectResponse(status) {
    if (status) {
      toast.success("Access Revoked Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error("Something went wrong please try again later!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setDisconnectModal(false);
    dispatch(getUser());
  }
  return (
    <section className="business_admin_main">
      <div className="container-fluid setting-page">
        <div className="row order">
          {/* <!-- ===========SIDE-BAR======= --> */}
          <SideBar />
          {/* <!-- ========= content body ======= --> */}
          <div className="w-lg-80 px-0 inter h-screen overflow-y-scroll">
            <Header PageTitle="Integrations" />
            <div className="px-lg-4 px-md-3 px-2">
              <div className="mt-5">
                <p className="text-lg text-gray-400">
                  Select and connect tools you use to integrate with your
                  workflow
                </p>
                <div className="flex justify-between items-center my-4">
                  <div className="flex gap-4">
                    <div
                      role="button"
                      onClick={() => setIntegrationTab("all-integration")}
                      className={`rounded-full px-2.5 text-sm py-1.5 transform duration-300 ease-in-out ${integrationTab === "all-integration"
                          ? "bg-[#1B59F8] text-white"
                          : "bg-transparent text-black"
                        }`}
                    >
                      All Integrations{" "}
                      <span
                        className={` rounded-full px-2  ms-1  ${integrationTab === "all-integration"
                            ? "bg-white text-black"
                            : "bg-black text-white"
                          }`}
                      >
                        1
                      </span>
                    </div>
                    <div
                      role="button"
                      onClick={() => setIntegrationTab("pos")}
                      className={`rounded-full px-2.5 text-sm py-1.5 transform duration-300 ease-in-out ${integrationTab === "pos"
                          ? "bg-[#1B59F8] text-white"
                          : "bg-transparent text-black"
                        }`}
                    >
                      POS{" "}
                      <span
                        className={` rounded-full px-2  ms-1  ${integrationTab === "all-integration"
                            ? "bg-white text-black"
                            : "bg-black text-white"
                          }`}
                      >
                        1
                      </span>
                    </div>
                    <div
                      role="button"
                      onClick={() => setIntegrationTab("communications")}
                      className={`rounded-full px-2.5 text-sm py-1.5 transform duration-300 ease-in-out ${integrationTab === "communications"
                          ? "bg-[#1B59F8] text-white"
                          : "bg-transparent text-black"
                        }`}
                    >
                      Communications
                    </div>
                    <div
                      role="button"
                      onClick={() => setIntegrationTab("storage")}
                      className={`rounded-full px-2.5 text-sm py-1.5 transform duration-300 ease-in-out ${integrationTab === "storage"
                          ? "bg-[#1B59F8] text-white"
                          : "bg-transparent text-black"
                        }`}
                    >
                      Storage
                    </div>
                  </div>
                  <div className="flex p-[3px] bg-white rounded-full border-[1.5px] items-center">
                    <div
                      role="button"
                      onClick={() => setIsToggled(true)}
                      className={`w-6 h-6 rounded-full  text-xs transform duration-300 ease-in-out flex items-center justify-center ${isToggled
                          ? "bg-blue-600 text-white"
                          : "bg-white text-black"
                        }`}
                    >
                      <TbGridDots />
                    </div>
                    <div
                      role="button"
                      onClick={handleToggle}
                      className={`w-6 h-6  rounded-full  text-xs transform duration-300 ease-in-out flex items-center justify-center ${!isToggled
                          ? "bg-blue-600 text-white"
                          : "bg-white text-black"
                        }`}
                    >
                      <CgMenu />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="mt-7">
                    {integrationTab === "all-integration" && (
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 rounded-2xl overflow-hidden  shadow-[0_0_1.3px_0_rgb(200, 195, 201)]">
                        {integrationData.map((integration, index) => (
                          <IntegrationCard
                            key={index}
                            title={integration.title}
                            description={integration.description.slice(0, 60)}
                            icon={integration.icon}
                            isConnected={integration.isConnected}
                            showConnectModal={() => setModal(true)}
                            showDisonnectModal={() => setDisconnectModal(true)}
                          />
                        ))}
                      </div>
                    )}
                    {integrationTab === "pos" && (
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 rounded-2xl overflow-hidden  shadow-[0_0_1.3px_0_rgb(200, 195, 201)]">
                        {integrationData.map((integration, index) => (
                          <IntegrationCard
                            key={index}
                            title={integration.title}
                            description={integration.description.slice(0, 60)}
                            icon={integration.icon}
                            isConnected={integration.isConnected}
                            showConnectModal={() => setModal(true)}
                            showDisonnectModal={() => setDisconnectModal(true)}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={modal}
        onHide={() => setModal(false)}
        // backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="flex gap-1 items-center">
              <div className="inline-flex w-[35px] h-[35px]  justify-center items-center border-[1.5px] bg-white  rounded-full">
                <img src={img} className="w-5 h-5 object-cover" alt="" />
              </div>
              <h6 className="	mb-0 fw-bold">Square</h6>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6 className="text-gray-400 flex gap-1">
            <span className="text-black">Overview</span>
            <FaAngleRight />
            Authenticate <FaAngleRight />
            Configure
          </h6>
          <div>
            <h6 className="text-sm mb-1">Introduction</h6>
            <p className="text-xs">
              Enable your customers to track their orders in real-time through
              the TimeLink integration with Square.{" "}
            </p>
            <p className="text-xs">
              This feature keeps your customers informed and engaged, enhancing
              their overall experience by providing timely updates on their
              order status.{" "}
            </p>
            <p className="text-xs">
              By connecting Square to TimeLink you consent to the sharing of
              information.{" "}
            </p>
            <p className="text-xs">
              Learn more on <b>Helpdesk Documentation.</b>
            </p>
          </div>
          <div>
            <h6 className="text-sm mb-1">
              Shared Information through TimeLink and Square Integration
            </h6>
            <p className="text-xs">
              Through the integration of TimeLink and Square, businesses can
              share critical information seamlessly, including detailed order
              information, secure payment data, customer details, real-time
              inventory levels, and order status updates.{" "}
            </p>
            <p className="text-xs">
              Additionally, sales and analytics data is synchronized to provide
              valuable insights for informed decision-making and operational
              optimization. This integration ensures accurate and efficient
              management of sales, payments, and customer interactions,
              enhancing overall business performance and customer satisfaction.
            </p>
            <button
              onClick={() => handleGetRedirectUrl()}
              type="button"
              className="btn-blue p-2 w-100 rounded-2"
            >
              {isLoading ? <Spinner color="light" /> : "Continue"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={disconnectModal}
        onHide={() => setDisconnectModal(false)}
        // backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="flex gap-1 items-center">
              <div className="inline-flex w-[35px] h-[35px]  justify-center items-center border-[1.5px] bg-white  rounded-full">
                <img src={img} className="w-5 h-5 object-cover" alt="" />
              </div>
              <h6 className="	mb-0 fw-bold">Square</h6>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6 className="text-gray-400 flex gap-1">
            <span className="text-black">Overview</span>
          </h6>
          <div>
            <h6 className="text-sm mb-1">Introduction</h6>
            <p className="text-xs">
              Enable your customers to track their orders in real-time through
              the TimeLink integration with Square.{" "}
            </p>
            <p className="text-xs">
              This feature keeps your customers informed and engaged, enhancing
              their overall experience by providing timely updates on their
              order status.{" "}
            </p>
            <p className="text-xs">
              By connecting Square to TimeLink you consent to the sharing of
              information.{" "}
            </p>
            <p className="text-xs">
              Learn more on <b>Helpdesk Documentation.</b>
            </p>
          </div>
          <div>
            <h6 className="text-sm mb-1">
              Shared Information through TimeLink and Square Integration
            </h6>
            <p className="text-xs">
              Through the integration of TimeLink and Square, businesses can
              share critical information seamlessly, including detailed order
              information, secure payment data, customer details, real-time
              inventory levels, and order status updates.{" "}
            </p>
            <p className="text-xs">
              Additionally, sales and analytics data is synchronized to provide
              valuable insights for informed decision-making and operational
              optimization. This integration ensures accurate and efficient
              management of sales, payments, and customer interactions,
              enhancing overall business performance and customer satisfaction.
            </p>
            <button
              onClick={() => handleDisconnectIntegration()}
              type="button"
              className="btn-blue p-2 w-100 rounded-2"
            >
              {isLoading ? <Spinner color="light" /> : "Disconnect"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </section>
  );
};

export default Integrations;
