import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    Autocomplete,
    Button,
    FormControl,
    Menu,
    MenuItem,
    Paper,
    Select,
    TextField,
    ThemeProvider,
    createTheme,
} from "@mui/material";
import TableDownload from "../../../assets/imges/downloadDeviceTable.png";
import { HiSearch } from "react-icons/hi";
import { BiChevronDown } from "react-icons/bi";
import karlzzz from "../../../assets/imges/karlzzzz.png";
import Setting from "../../../assets/imges/DeviceTableSetting.png";
import link from "../../../assets/imges/DeviceTableLink.png";
import Toggle from "../../../assets/imges/DeviceTableToggle.png";
import DropDots from "../../../assets/imges/DeviceTableDropdown.png";
import DropDownload from "../../../assets/imges/DeviceTableDownloadIMG2.png";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import CloseIcon from "@mui/icons-material/Close";
import { Input } from "reactstrap";
import tableBook from '../../../assets/imges/tableBook.png'
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Spinner } from 'reactstrap';
import { getBusinessSurveys } from "../../../redux/slices/userSlice";
import PaginatedItems from "../../pagination/PaginatedItems";
import SurveyListItem from "./SurveyListItem";




// const theme = createTheme({
//     components: {
//         MuiCheckbox: {
//             styleOverrides: {
//                 root: {
//                     color: 'rgba(200, 195, 201, 1)',
//                 },
//             },
//         },
//     },
// });
export default function SurveyTable() {
    const dispatch = useDispatch();
    const { isLoading, getError, businessSurveys } = useSelector((state) => state.user)
    const [select5, setSelect5] = React.useState();
    const [select1, setSelect1] = React.useState();
    const [select2, setSelect2] = React.useState();
    const [select4, setSelect4] = React.useState();
    const [select3, setSelect3] = React.useState();
    React.useEffect(() => {
        if (getError && getError.length > 1) {
            toast.error(getError, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }, [getError])
    React.useEffect(() => {
        dispatch(getBusinessSurveys());
    }, []);

    return (
        <>
            {/* <ThemeProvider theme={theme}> */}
            <div className="container-fluid">

                <div className="row table-tabs justify-content-between">
                    <div className="col-lg-6 col-12 d-flex align-items-end inter ps-0">
                        <ul className="nav nav-pills ps-0 " id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link grey active" id="pills-All-tab" data-bs-toggle="pill" data-bs-target="#pills-All" type="button" role="tab" aria-controls="pills-All" aria-selected="true">All</button>
                            </li>
                            {/* <li className="nav-item" role="presentation">
                                    <button className="nav-link grey ms-3" id="pills-New-tab" data-bs-toggle="pill" data-bs-target="#pills-New" type="button" role="tab" aria-controls="pills-New" aria-selected="false">New</button>
                                </li> */}
                            <li className="nav-item" role="presentation">
                                <button className="nav-link grey ms-3" id="pills-Pending-tab" data-bs-toggle="pill" data-bs-target="#pills-Pending" type="button" role="tab" aria-controls="pills-Pending" aria-selected="false">Pending</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link grey ms-3" id="pills-Approved-tab" data-bs-toggle="pill" data-bs-target="#pills-Approved" type="button" role="tab" aria-controls="pills-Approved" aria-selected="false">Approved</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link grey ms-3" id="pills-Rejected-tab" data-bs-toggle="pill" data-bs-target="#pills-Rejected" type="button" role="tab" aria-controls="pills-Rejected" aria-selected="false">Rejected</button>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-5 col-12 mt-lg-0 mt-3">
                        <div className="row">
                            <div className="col-8 pe-2">

                                <FormControl className='mui-select-fieldTwo mt-2 w-100' >
                                    <Select
                                        value={select5}
                                        className='rounded-5 w-100 text-start font-14  ps-2'
                                        onChange={(e) => setSelect5(e.target.value)}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem value="">
                                            Sort by: Most Recent
                                        </MenuItem>
                                        <MenuItem value={10}>Ten</MenuItem>
                                        <MenuItem value={20}>Twenty</MenuItem>
                                        <MenuItem value={30}>Thirty</MenuItem>
                                    </Select>

                                </FormControl>
                            </div>
                            <div className="col-4 d-flex align-items-center">


                                <div className='d-flex text-gray align-items-center justify-content-between px-1 w-100'>
                                    <p className=' font-14 mb-0 fw-medium'>1 of 1</p>
                                    <div className='flex gap-1'>

                                        <FaAngleLeft size={18} /> <FaAngleRight size={18} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <hr className='border-0 mt-2 mb-1' style={{ height: '2px', opacity: '1', backgroundColor: 'rgba(200, 195, 201, 1)' }} />
                </div>
            </div>
            <div className="tab-content table-scroll" id="pills-tabContent">
                {/* ------------ALl---------- */}
                <div className="tab-pane fade show active" id="pills-All" role="tabpanel" aria-labelledby="pills-All-tab" tabIndex={0}>

                    <div>
                        {
                            isLoading ?
                                <div className="row justify-content-center not-active-Campaigns rounded-3 text-center mt-3">
                                    <div className="col-12">
                                        <div className="Campaigns p-4 rounded-3">
                                            <Spinner color='dark' />                                                            </div>
                                    </div>
                                </div>
                                :
                                !businessSurveys.all || businessSurveys?.all?.length < 1 ?
                                    <div className="row justify-content-center not-active-Campaigns rounded-3 text-center mt-3">
                                        <div className="col-12">
                                            <div className="Campaigns p-4 rounded-3">
                                                <h5 className='fw-bold'>No Marketing Campaigns</h5>
                                            </div>
                                        </div>
                                    </div> :

                                    <div className="container-fluid Campaigns">
                                        <div className="row">
                                            <PaginatedItems items={businessSurveys?.all} itemsPerPage={6} ItemsComponent={SurveyListItem} />
                                            {/* <div className='d-flex justify-content-end hireexpert mt-3 mb-3'>
                                                                    <Stack spacing={4}>
                                                                        <Pagination count={parseInt(businessSurveys .length / 9 +1) } variant="outlined" shape="rounded" />
                                                                    </Stack>
                                                                </div> */}
                                        </div>
                                    </div>
                        }


                    </div>

                </div>

                {/* --------Pending------------------ */}
                <div className="tab-pane fade" id="pills-Pending" role="tabpanel" aria-labelledby="pills-Pending-tab" tabIndex={0}>
                    <div>
                        {
                            isLoading ?
                                <div className="row justify-content-center not-active-Campaigns rounded-3 text-center mt-3">
                                    <div className="col-12">
                                        <div className="Campaigns p-4 rounded-3">
                                            <Spinner color='dark' />                                                            </div>
                                    </div>
                                </div>
                                :
                                !businessSurveys.pending || businessSurveys?.pending?.length < 1 ?
                                    <div className="row justify-content-center not-active-Campaigns rounded-3 text-center mt-3">
                                        <div className="col-12">
                                            <div className="Campaigns p-4 rounded-3">
                                                <h5 className='fw-bold'>No Pending Marketing Campaigns</h5>
                                            </div>
                                        </div>
                                    </div> :

                                    <div className="container-fluid Campaigns">
                                        <div className="row">
                                            <PaginatedItems items={businessSurveys?.pending} itemsPerPage={6} ItemsComponent={SurveyListItem} />
                                        </div>
                                    </div>
                        }

                    </div>


                </div>
                {/* ------------Approved---------- */}
                <div className="tab-pane fade" id="pills-Approved" role="tabpanel" aria-labelledby="pills-Approved-tab" tabIndex={0}>
                    <div>

                        {
                            isLoading ?
                                <div className="row justify-content-center not-active-Campaigns rounded-3 text-center mt-3">
                                    <div className="col-12">
                                        <div className="Campaigns p-4 rounded-3">
                                            <Spinner color='dark' />                                                            </div>
                                    </div>
                                </div>
                                :
                                !businessSurveys.approved || businessSurveys?.approved?.length < 1 ?
                                    <div className="row justify-content-center not-active-Campaigns rounded-3 text-center mt-3">
                                        <div className="col-12">
                                            <div className="Campaigns p-4 rounded-3">
                                                <h5 className='fw-bold'>No Approved Marketing Campaigns</h5>
                                            </div>
                                        </div>
                                    </div> :

                                    <div className="container-fluid Campaigns">
                                        <div className="row">
                                            <PaginatedItems items={businessSurveys?.approved} itemsPerPage={6} ItemsComponent={SurveyListItem} />
                                            {/* <div className='d-flex justify-content-end hireexpert mt-3 mb-3'>
                                                                    <Stack spacing={4}>
                                                                        <Pagination count={parseInt(businessSurveys .length / 9 +1) } variant="outlined" shape="rounded" />
                                                                    </Stack>
                                                                </div> */}
                                        </div>
                                    </div>
                        }

                    </div>

                </div>
                {/* ------------Rejected---------- */}
                <div className="tab-pane fade" id="pills-Rejected" role="tabpanel" aria-labelledby="pills-Rejected-tab" tabIndex={0}>
                    <div>
                        {
                            isLoading ?
                                <div className="row justify-content-center not-active-Campaigns rounded-3 text-center mt-3">
                                    <div className="col-12">
                                        <div className="Campaigns p-4 rounded-3">
                                            <Spinner color='dark' />                                                            </div>
                                    </div>
                                </div>
                                :
                                !businessSurveys.rejected || businessSurveys?.rejected?.length < 1 ?
                                    <div className="row justify-content-center not-active-Campaigns rounded-3 text-center mt-3">
                                        <div className="col-12">
                                            <div className="Campaigns p-4 rounded-3">
                                                <h5 className='fw-bold'>No Rejected Marketing Campaigns</h5>
                                            </div>
                                        </div>
                                    </div> :

                                    <div className="container-fluid Campaigns">
                                        <div className="row">
                                            <PaginatedItems items={businessSurveys?.rejected} itemsPerPage={6} ItemsComponent={SurveyListItem} />
                                            {/* <div className='d-flex justify-content-end hireexpert mt-3 mb-3'>
                                                                    <Stack spacing={4}>
                                                                        <Pagination count={parseInt(businessSurveys .length / 9 +1) } variant="outlined" shape="rounded" />
                                                                    </Stack>
                                                                </div> */}
                                        </div>
                                    </div>
                        }

                    </div>

                </div>
            </div>
            {/* </ThemeProvider> */}

        </>
    );
}