import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/imges/Logo.png";
import SidebarLink from "./SidebarLink";
import Header from "./Header";
import { IoMenu } from "react-icons/io5";
import { Drawer } from "@mui/material";
const SideBar = ({ children, title }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  return (
    <main className="lg:flex w-full bg-[#F5F7FA]">
      <div className="lg:flex hidden w-[275px] h-screen overflow-y-auto p-3 bg-white ">
        <div className="w-full">
          <Link to="/">
            <img src={logo} width="160px" alt="logo" />
          </Link>
          <SidebarLink />
        </div>
      </div>
      {/* responsive sidebar === start  */}
      <div className="h-16 ">
        <div className=" justify-between items-center w-full lg:hidden   fixed w-100 z-10 top-0 left-0 flex h-16 px-3 bg-white border-b">
          <Link to="/">
            <img src={logo} width="160px" alt="logo" />
          </Link>

          <IoMenu
            onClick={() => setDrawerOpen(true)}
            className="text-black cursor-pointer"
            size={25}
          />
        </div>
        <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)}>
          <div className=" p-4 ">
            <div>
              <Link to="/">
                <img src={logo} width="160px" alt="logo" />
              </Link>
              <SidebarLink />
            </div>
          </div>
        </Drawer>
      </div>
      <div className="flex-1 lg:h-screen lg:overflow-y-auto">
        <Header title={title} />
        <div className=" p-4">{children}</div>
      </div>
    </main>
  );
};

export default SideBar;
