import React from "react";
import SideBar from "../../components/superAdmin/SideBar";
import CompanyBg from "../../assets/imges/companyImage.png";
import { useLocation } from "react-router-dom";
import entry from "../../assets/imges/entry.png";
import cafe from "../../assets/imges/cafepic.png";

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
} from "@mui/material";
import { IoSearch } from "react-icons/io5";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md";
import { FiDownload } from "react-icons/fi";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/zoom.css";
import link from "../../assets/imges/DeviceTableLink.png";
import Setting from "../../assets/imges/DeviceTableSetting.png";
import Toggle from "../../assets/imges/DeviceTableToggle.png";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Button } from "react-bootstrap";

const ViewBusinessManagment = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const email = queryParams.get("email");
  const [user, setUser] = React.useState(false);
  const [userRadio, setUserRadio] = React.useState("New Customer");
  const [businessManagTab, setBusinessManagTab] = React.useState("orders");
  const isActive = (item) => {
    return businessManagTab === item
    ? "before:content-[''] before:absolute before:left-0 before:bottom-0 before:w-full before:h-1 before:bg-customBlue before:rounded-t-md text-customBlue font-medium"
    : "text-[#718EBF] font-medium";
  };
  return (
    <SideBar title={email || "NAN"}>
      <div className="w-full">
        <div>
          <div className="h-28">
            <img
              src={CompanyBg}
              alt="not found"
              className="h-48 w-full object-cover rounded-lg"
            />
          </div>
          <div className="px-4 flex items-end">
            <img
              src="https://via.placeholder.com/150"
              className="w-44 h-44 rounded-full"
              alt="Placeholder Image"
            />
            <h3 className="lg:text-3xl md:text-3xl text-2xl font-semibold ms-2 pb-3">Company Name</h3>
          </div>
        </div>
        <div className="w-full bg-white p-4 rounded-xl mt-12">
          <div className="mb-4">
            <div className=" d-flex align-items-end inter ps-0 border-b w-fit ">
              <button
                className={`relative ${isActive("orders")} py-2 px-6`}
                onClick={() => setBusinessManagTab("orders")}
                type="button"
              >
                Orders
              </button>

              <button
                className={`relative ${isActive("marketing")} py-2 px-6`}
                onClick={() => setBusinessManagTab("marketing")}
                type="button"
              >
                Marketing Campaigns
              </button>

              <button
                className={`relative ${isActive("devices")} py-2 px-6`}
                onClick={() => setBusinessManagTab("devices")}
                type="button"
              >
                Devices
              </button>
            </div>
          </div>
          {businessManagTab === "orders" && (
            <div className="  white-border rounded-4 p-3">
              <div className="  inter overflow-x-auto">
                {/* =========table header ======== */}
                <div className="d-flex mt-3 justify-content-between  w-100 ">
                  <div
                    className="  d-flex flex-shrink-0 align-items-center justify-content-center "
                    style={{ width: 200 }}
                  >
                    <div className="d-flex align-items-center">
                      <IoSearch className="text-dark-gray" size={22} />
                      <input
                        type="search"
                        className="form-control ps-2 border-0 font-14 fw-medium py-1"
                        placeholder="Search"
                      />
                    </div>
                  </div>
                  <div className="table-cell  d-flex align-items-center justify-content-center position-relative ">
                    <span
                      role="button"
                      className="py-2 flex-1 whitespace-nowrap d-flex justify-content-between align-items-center rounded-3 font-12 fw-semibold   text-dark-gray  table-header-badges"
                      onClick={() => setUser(!user)}
                      style={{ paddingInline: "12px" }}
                    >
                      {userRadio}
                      {user ? (
                        <FaAngleDown className="ms-2" />
                      ) : (
                        <FaAngleUp className="ms-2" />
                      )}
                    </span>{" "}
                    <span className="ms-2 ">
                      <img
                        src={entry}
                        className="whitespace-nowrap"
                        width="5"
                        height="10"
                        alt="w8"
                      />
                    </span>
                    {user && (
                      <div
                        className="position-absolute bg-white p-2 px-2 shadow  top-100 end-0 rounded-4 z-1"
                        style={{ width: "110%" }}
                      >
                        <FormControl>
                          <RadioGroup
                            // defaultValue="All-time"
                            onChange={(e) => {
                              setUserRadio(e.target.value);
                              setUser(false);
                            }}
                            value={userRadio}
                            name="radio-buttons-user"
                          >
                            <FormControlLabel
                              value="All"
                              control={
                                <Radio
                                  sx={{
                                    color: "#1D59F9",
                                    "&.Mui-checked": {
                                      color: "#1D59F9   ",
                                    },
                                  }}
                                />
                              }
                              label="All"
                            />
                            <FormControlLabel
                              value="New Customer"
                              control={
                                <Radio
                                  sx={{
                                    color: "#1D59F9",
                                    "&.Mui-checked": {
                                      color: "#1D59F9   ",
                                    },
                                  }}
                                />
                              }
                              label="New Customer"
                            />
                            <FormControlLabel
                              value="Repeat Customer"
                              control={
                                <Radio
                                  sx={{
                                    color: "#1D59F9",
                                    "&.Mui-checked": {
                                      color: "#1D59F9   ",
                                    },
                                  }}
                                />
                              }
                              label="Repeat Customer"
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    )}
                  </div>
                  <div className="table-cell  d-flex align-items-center justify-content-center">
                    <span
                      className="p-2 rounded-3 font-12 fw-semibold  d-flex justify-content-between align-items-center text-dark-gray px-4 table-header-badges"
                      style={{ width: "70%" }}
                    >
                      Start <FaAngleDown />
                    </span>{" "}
                    <span className="ms-2">
                      <img src={entry} width="5" height="10" alt="w8" />
                    </span>
                  </div>
                  <div className="table-cell  d-flex align-items-center justify-content-center">
                    <span
                      className="p-2  text-center rounded-3 font-12 fw-semibold   text-dark-gray px-2 table-header-badges"
                      style={{ width: "70%" }}
                    >
                      End
                    </span>{" "}
                    <span className="ms-2">
                      <img src={entry} width="5" height="10" alt="w8" />
                    </span>
                  </div>
                  <div className="table-cell  d-flex align-items-center justify-content-center">
                    <span
                      className="p-2 text-center whitespace-nowrap rounded-3 font-12 fw-semibold   text-dark-gray px-4 table-header-badges"
                      style={{ width: "100%" }}
                    >
                      Total Time
                    </span>{" "}
                    <span className="ms-2">
                      <img src={entry} width="5" height="10" alt="w8" />
                    </span>
                  </div>
                  <div className="table-cell  d-flex align-items-center justify-content-center">
                    <span
                      className="p-2 text-center rounded-3 font-12 fw-semibold   text-dark-gray px-2 table-header-badges"
                      style={{ width: "70%" }}
                    >
                      Status
                    </span>{" "}
                    <span className="ms-2">
                      <img src={entry} width="5" height="10" alt="w8" />
                    </span>
                  </div>
                </div>
                {/* ============ table body ======== */}

                <div className="d-flex mt-3 justify-content-between  w-100 ">
                  <div className="table-cell-1 flex-shrink-0 d-flex align-items-center ">
                    <Checkbox
                      icon={
                        <MdRadioButtonUnchecked
                          color="rgba(0, 0, 0, 0.3)
"
                          size={20}
                        />
                      }
                      checkedIcon={<MdRadioButtonChecked size={20} />}
                    />
                    <div>
                      <p className="mb-0 font-14 fw-semibold ">Tesla</p>
                      <p className="mb-0 font-14 text-black-50 ">
                        Repeat Customer
                      </p>
                    </div>
                  </div>
                  <div className="table-cell  text-center  d-flex align-items-center justify-content-center">
                    Yes
                  </div>
                  <div className="table-cell   text-center  d-flex align-items-center justify-content-center">
                    <div>
                      <p className="mb-0 font-14 fw-medium  text-black-50 ">
                        January 20, 2023
                      </p>
                      <p className="mb-0 font-14 fw-medium  text-black-50 ">
                        12:15pm
                      </p>
                    </div>
                  </div>
                  <div className="table-cell  text-center  d-flex align-items-center justify-content-center">
                    <div>
                      <p className="mb-0 font-14 fw-medium  text-black-50 ">
                        January 20, 2023
                      </p>
                      <p className="mb-0 font-14 fw-medium  text-black-50 ">
                        12:15pm
                      </p>
                    </div>
                  </div>
                  <div className="table-cell  text-center  d-flex align-items-center justify-content-center ">
                    <p className="mb-0 font-14 fw-medium  text-black-50 ">
                      00:02.30
                    </p>
                  </div>
                  <div className="table-cell  text-center  d-flex align-items-center justify-content-center ">
                    <p className="mb-0 font-14 fw-medium  text-black-50 ">
                      Notified
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {businessManagTab === "marketing" && (
            <div
              className="rounded-xl border border-[#E4E5E7] p-3"
            >
          <p className="border-b pb-2 font-semibold">Campaign list</p>
              <div className="flex flex-wrap gap-5 justify-between items-center px-3">
                <div className=" w-80">
                  <img
                    src={cafe}
                    className=" object-fit-cover rounded-4 my-2 h-32 w-full"
                    alt="offer "
                  />
                  <h6 className="font-13 inter fw-semibold  colorblack mb-0">
                  Thank you for using Time Link:                  </h6>
               
                  <p className="mb-0 font-13 fw-medium inter text-dark-gray">
                  Congratulations! Enjoy a free coffee at Donald's Cafe. Claim your discount now. <u>Terms & Conditions apply. </u>
                  </p>
               
                </div>
                <div className=" d-flex align-align-items-center  text-center ">
                    <div>
                      <p className="font-30 lh-1  fw-bold mb-0">
                        0
                      </p>
                      <p className="font-20 mb-0  fw-bold text-gray">Reach</p>
                    </div>
                   
                </div>
                <div className=" d-flex align-align-items-center  text-center">
                   
                    <div className="">
                      <p className="font-30 lh-1 fw-bold mb-0">
                        0
                      </p>
                      <p className="font-20 mb-0 fw-bold text-gray">Sign-up</p>
                    </div>
                </div>
                <div className="business_admin_main ">
                      <div className="step-three">
                        <div
                          className="container-stepOne "
                          style={{ transform: "scale(1.2)" }}
                        >
                          <input
                            type="checkbox"
                            className="checkbox"
                            id={`checkbox-1`}
                           
                            // checked={index.status == "active" ? true : false}
                          />
                          <label
                            className="switch"
                            htmlFor={`checkbox-1`}
                          >
                            <span className="slider" />
                          </label>
                        </div>
                      </div>
                    </div>
                <div className="d-flex flex-column  text-center mt-2 relative">
                    

                   
                    <Menu
              menuButton={
                <MenuButton>
                  <span
                        role="button"
                                      
                        className="d-flex align-items-center pe-2 rounded-3 bg-[#D9D9D9]"
                      >
                        <span
                          className="p-2 fw-bold font-12 border-r-[1.5px] border-[#E4E5E7]"
                        
                        >
                          View
                        </span>
                        <span className="ps-2">
                          <FaAngleDown size={14} />
                        </span>
                      </span>
                </MenuButton>
              } direction="left"
              align="center" position="anchor" arrow={true}  gap={12}
              shift={12}
              transition
              menuClassName="!min-w-fit"
            >
              <MenuItem>Edit</MenuItem>
              <MenuItem>Duplicate</MenuItem>
              <MenuItem>Delete</MenuItem>
            </Menu>
                </div>
              </div>
            </div>
          )}
          {businessManagTab === "devices" && (
            <div className="d-flex gap-5   justify-content-between  relative p-3 pt-lg-4 pt-5 bg-white rounded-[40px] flex-wrap mb-2 border">
              <div className="inter d-flex gap-2 align-items-center whitespace-nowrap	">
                <div className="px-2">
                  <input
                    type="checkbox"
                    className="!accent-[#65558F] !border-[#C8C3C9] !border-[1.5px] scale-150	"
                  />
                </div>

                <div className="text-gray-600">
                  <h3 className="text-xl mb-0">Time Link Connect</h3>
                  <p className="text-lg mb-0">TLC38910000001</p>
                  <h3 className="font-10 ">Created: Aug 26, 2023</h3>
                </div>
              </div>
              <div className="flex items-center">
                <button className="bg-green-500  text-white  rounded-5 w-24 p-2  font-14">
                  Active
                </button>
              </div>
              <div className="d-flex align-items-center justify-content-between  ps-3 my-2 py-2 whitespace-nowrap	border-l-[1.5px] text-[#343C6A]">
                <div>
                  <div className=" form-switch black-switch d-flex text-start align-items-center ps-0">
                    {/* <MdToggleOn className='grey' size={28} /> */}
                    <img src={Toggle} width={22} height={22} alt="w8" />

                    <p className="mb-0 font-12 fw-medium  ms-2">Active</p>
                  </div>
                  <div className="d-flex align-items-end mt-2 text-start">
                    {/* <FaHandHoldingUsd size={28} className='grey' /> */}
                    <img src={link} width={22} height={22} alt="w8" />
                    <p className="mb-0 font-12 fw-medium  ms-2">Leased</p>
                  </div>
                  <div className="d-flex align-items-end mt-2 text-start">
                    {/* <AiFillSetting size={28} className='grey' /> */}
                    <img src={Setting} width={22} height={22} alt="w8" />

                    <p className="mb-0 font-12 fw-medium  ms-2">Version 1</p>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center ">
                <button className="bg-white border-2 border-customBlue text-customBlue rounded-5 min-w-24 p-2  font-14">
                  Report Problem{" "}
                </button>
              </div>
              <div className="deviceTableLastTD flex ">
                <button
                  className={`bg-red-600 fw-medium text-white !rounded-tr-[40px] !rounded-bl-[40px] p-2 px-5 text-capitalize  font-12 absolute top-0 right-0 w-fit`}
                >
                  Faulty
                </button>

                <div className="d-flex gap-2 align-items-center justify-content-center mt-2">
                  <div className=" h-7 w-7 flex items-center justify-center rounded-lg bg-customBlue">
                    <FiDownload size={16} color="white" />
                  </div>

                  <Menu
                    menuButton={
                      <MenuButton>
                        <div className=" h-7 w-7 flex items-center justify-center rounded-lg bg-[#718EBF] ">
                          <BsThreeDotsVertical size={16} color="white" />
                        </div>
                      </MenuButton>
                    }
                    direction="left"
                    align="center"
                    position="anchor"
                    arrow={true}
                    gap={12}
                    shift={12}
                    transition
                    menuClassName="!min-w-fit !w-fit"
                  >
                    <MenuItem>Edit</MenuItem>
                    <MenuItem>Duplicate</MenuItem>
                    <MenuItem>Delete</MenuItem>
                  </Menu>
                </div>
                <div></div>
              </div>
            </div>
          )}
        </div>
      </div>
    </SideBar>
  );
};

export default ViewBusinessManagment;
