

import React, { useState } from 'react'
import toggle from '../assets/imges/table-toggle.png'
import { RxCross1 } from "react-icons/rx";
import { ImSpinner } from "react-icons/im";
import { MdOutlineCheck } from 'react-icons/md';

import { useDispatch, useSelector } from 'react-redux';
import { updateSurveyStatus, updateSurveyRetarget, deleteSurvey } from '../redux/slices/userSlice';
import { Spinner } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'


const SurveyTable = ({ data, handleShowQuestionData, refresh }) => {

    const dispatch = useDispatch();
    const { isLoading, getError, activatedQTs, businessSurveys, userDetail, surveyLimit } = useSelector((state) => state.user)
    function handleChangeStatus(id, currentStatus) {
        if(currentStatus == 'inactive' ){
            if(surveyLimit >= 3){
                toast.error('You can not activate more surveys! Please inactivate one of the previous acitve surveys first.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                return false;
            }
        }
        var data = {
            'survey_id': id,
            'status': currentStatus == 'active' ? 'inactive' : 'active'
        }
        dispatch(updateSurveyStatus(data))
    }
    function handleChangeRetarget(id, isRetarget) {
        var data = {
            'survey_id': id,
            'retarget': isRetarget == 0 ? 1 : 0
        }
        dispatch(updateSurveyRetarget(data));

    }

    function handleDelete(id){
        Swal.fire({
            title: "Are you sure?",
            text: "The responses to this survey will also be gone",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
          }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteSurvey(id))
            }
          });
        
    }
    return (
        <>

            <div className="container-fluid  rounded-0 surveys border-top-0 survey-table-responsive">

                <div className="row sms-automation p-3 survey-row-table" style={{
                    borderTop: '1px solid #1D59F9',
                    borderBottom: '1px solid #1D59F9',
                    background: 'rgba(29, 89, 249, 0.15)',
                    fontSize: '13px !important'
                }}>
                    <div className="col-5 ">
                        <p className="font-13 inter colorblack mb-0">Question name</p>
                    </div>
                    <div className="col-7 ">
                        <div className="row">
                            {/* <div className="col-4">
                                <p className="font-13 inter colorblack mb-0">Approval Status</p>
                            </div> */}
                            <div className="col-3 align-self-center">
                                <p className="font-13 inter colorblack mb-0">Retarget?</p>
                            </div>
                            <div className="col-3 align-self-center">
                                <p className="font-13 inter colorblack mb-0">Status</p>

                            </div>
                            <div className="col-2 align-self-center">

                            </div>
                        </div>
                    </div>


                </div>
                {/* ======== SMS AUTOMATION CONTANT START ======= */}
                <div className='overflow-y-scroll	overflow-x-hidden h-80 pb-2'>

                {data.length >0 && data?.map((value, index) => (

                    <div className="row survey-row-table p-3" key={index} style={{ borderBottom: "1px solid #EDEDED" }}>
                        <div onClick={() => {
                            handleShowQuestionData(value);
                        }}
                            className="col-5  align-self-center cursor-pointer">
                            <div>
                                <p className="font-15 mb-1 fw-medium inter">
                                    {value.question}
                                </p>
                                <p className="font-13 mb-0 font-medium inter">
                                    {value.options.length}  Choices
                                </p>
                            </div>
                        </div>
                        <div className="col-7 ">
                            <div className="row">
                                {/* <div className="col-4 align-self-center ">
                                    <p className="font-15 mb-1 fw-medium inter" style={{ color: value.approval_status == 'rejected' ? '#FF0000' : value.approval_status == 'pending' ? '#FF8A00' : '#04B700', textTransform: 'capitalize' }}>
                                        <span className='me-2 font-18'>{value.approval_status == 'rejected' ? <RxCross1 /> : value.approval_status == 'pending' ? <ImSpinner className='spinner' /> : <MdOutlineCheck />}</span>{value?.approval_status}
                                    </p>
                                </div> */}
                                <div className="col-3 align-self-center">
                                    <div className='survay-status '>
                                        <div className="form-check form-switch">
                                            {
                                                isLoading ? <Spinner color='white' size={1} /> :
                                                    <input className="form-check-input " type="checkbox" role="switch" checked={value.should_retarget} onChange={(e) => { handleChangeRetarget(value.id, value.should_retarget) }} />
                                            }
                                        </div>


                                    </div>
                                </div>
                                <div className="col-3 align-self-center">
                                    <div className='survay-status '>
                                        <div className="form-check form-switch">
                                            <input className="form-check-input " type="checkbox" role="switch" checked={value.status == 'active' ? true : false} onChange={(e) => { handleChangeStatus(value.id, value.status) }}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-5 align-self-center">
                                    <div className='inter align-self-center '>
                                        <div className="dropdown text-end">

                                            <img src={toggle} width={40} height={40} alt="w8"
                                                className=" dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false" />

                                            <ul className="dropdown-menu">
                                                <li><span className="dropdown-item cursor-pointer" onClick={()=>{handleDelete(value.id)}}>Delete</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                ))
                }
                </div>


            </div>
<ToastContainer />

        </>
    )
}

export default SurveyTable
