import React, { useState, useEffect } from "react";
import { Col, Container, Row, Spinner } from "reactstrap";
import LoginHeader from "../../components/LoginHeader";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GoDotFill } from "react-icons/go";
import AutocompleteGoggle from "react-google-autocomplete";
import { useDispatch, useSelector } from 'react-redux';
import { setBusinessProfile, seTgetErrorNull } from '../../redux/slices/userSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaAngleLeft } from "react-icons/fa6";

const SignUpAddress = () => {
  const dispatch = useDispatch();
  const { isLoading, getError } = useSelector((state) => state.user)
  const navigate = useNavigate();
  const [address, setAddress] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [postCode, setPostCode] = useState(null);
  const [isBtnDisable, setIsBtnDisable] = useState(true);  // Initially set to true

  useEffect(() => {
    // Check if all required fields are filled
    if (address && city && state && postCode) {
      setIsBtnDisable(false);
    } else {
      setIsBtnDisable(true);
    }
  }, [address, city, state, postCode]);  // Include clientRadio instead of selectedOption

  useEffect(() => {
    if (getError && getError.length > 1) {
      toast.error(getError, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(seTgetErrorNull());

    }
  }, [getError])

  if (city?.length > 0) {
    var styleCity = {
      border: '1.5px solid rgba(37, 99, 235, 1)',
    }
  }
  else {
    var styleCity = {
      border: '1.5px solid rgba(226, 232, 240, 1)',
    }
  }
  if (address) {
    console.log("if  under")
    var borderColor = '1.5px solid rgba(37, 99, 235, 1)';
  } else {
    console.log("else  under")

    var borderColor = '1.5px solid rgba(226, 232, 240, 1)';
  }

  if (state?.length > 0) {
    var styleState = {
      border: '1.5px solid rgba(37, 99, 235, 1)',
    }
  }
  else {
    var styleState = {
      border: '1.5px solid rgba(226, 232, 240, 1)',
    }
  }
  if (postCode?.length > 0) {
    var stylePostCode = {
      border: '1.5px solid rgba(37, 99, 235, 1)',
    }
  }
  else {
    var stylePostCode = {
      border: '1.5px solid rgba(226, 232, 240, 1)',
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    if (address && address.length > 3) {
      e.preventDefault();
      var data = {
        "business_address": address,
        "state": state,
        "postal_code": postCode,
        "city": city
      }
      dispatch(setBusinessProfile(3, data, handleResponse))
    } else {
      toast.error('Address Field is required!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }


  };
  function handleResponse(data) {
    navigate("/auth/signup/Contact");
  }
  return (
    <>
      <Container fluid className='signup-steps-pages'>
        <LoginHeader />
        <Row className='justify-content-center my-4 SignUp-progress-bar lato'>
          <Col lg={6} md={6} sm={12} xs={12}>
            <div role='button' onClick={() => navigate(-1)} className='w-8 h-8 bg-blue-600 mb-3 rounded-full text-white flex justify-center items-center'>
              <FaAngleLeft size={20} />
            </div>

            <Row>
              <Col lg={3} md={3} sm={3} xs={3} className='px-1'>
                <div className='text-center lh-base'>
                  <p className="font-10 mb-0 fw-semibold " style={{ color: '#6F6F6F' }}>Sign Up</p>
                  <GoDotFill size={8} className='my-0 mb-2 blue' />
                </div>
                <div className="progress rounded-5" role="progressbar" aria-label="Basic example" aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}>
                  <div className="progress-bar rounded-5" style={{ width: '100%' }} />
                </div>

              </Col>
              <Col lg={6} md={6} sm={6} xs={6} className='px-1'>
                <div className='text-center lh-base'>
                  <p className="font-10 mb-0 fw-semibold " style={{ color: '#6F6F6F' }}>Business Profile</p>
                  <GoDotFill size={8} className='my-0 mb-2 blue' />
                </div>
                <div className="progress  rounded-5" role="progressbar" aria-label="Basic example" aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}>
                  <div className="progress-bar  rounded-5" style={{ width: '100%' }} />
                </div>
              </Col>
              <Col lg={3} md={3} sm={3} xs={3} className='px-1'>
                <div className='text-center lh-base'>
                  <p className="font-10 mb-0 fw-semibold " style={{ color: '#6F6F6F' }}>Primary Contact</p>
                  <GoDotFill size={8} color='#D0E2FF' className='my-0 mb-2 ' />
                </div>
                <div className="progress  rounded-5" role="progressbar" aria-label="Basic example" aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}>
                  <div className="progress-bar  rounded-5" style={{ width: '0%' }} />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center d-flex login_height my-5">
          <Col lg={5} md={12} sm={12} xs={12} className=" ">
            <form
              className="business-login-form rounded-4 d-flex justify-content-between flex-column p-3"
              onSubmit={handleSubmit}
            >
              <div>
                <div className="inter text-center mt-2">
                  <h4 className="font-24 fw-bold  text-dak-blue">
                    Business Address
                  </h4>
                  <p className="font-14 text-dark-gray">Just a little more...</p>
                </div>
                <div className="login-form">

                  <div className="AutocompleteGoggle mt-3">
                    <AutocompleteGoggle
                      apiKey="AIzaSyDCtippmTE1DS7339j-zKhsIEMG0Y1m2uM"
                      style={{ width: '100%', border: borderColor }}
                      className="p-3 rounded-4"
                      onPlaceSelected={(place) => {
                        console.log('formated addres' + place.formatted_address);
                        setAddress(place.formatted_address)
                      }}
                      options={{
                        //types: ["(regions)"],
                        // componentRestrictions: { country: "ru" },
                      }}
                      onChange={e => setAddress(e.target.value)}
                      placeholder="Business Address *"
                    // defaultValue="Amsterdam"
                    />
                  </div>
                  <div className="input-group my-3 rounded-4" style={styleCity}>

                    <input type="text" required className="form-control border-0 p-3  rounded-4 " placeholder='City' value={city} onChange={(e) => setCity(e.target.value)} />
                  </div>

                  <div className="input-group my-3 rounded-4" style={styleState}>

                    <input type="text" required className="form-control border-0 p-3  rounded-4 " placeholder='State' value={state} onChange={(e) => setState(e.target.value)} />
                  </div>
                  <div className="input-group my-3 rounded-4" style={stylePostCode}>

                    <input type="text" required className="form-control border-0 p-3 rounded-4 " placeholder='Post Code' value={postCode} onChange={(e) => setPostCode(e.target.value)} />
                  </div>
                </div>
              </div>
              <Button
                type="submit" disabled={isBtnDisable}
                className={`${isBtnDisable ? "!bg-gray-300" : "btn-blueTwo"} rounded-4 inter px-3 mt-2 p-3 w-100 fw-bold font-16 text-capitalize`} sx={{ fontFamily: 'Inter, sans-serif !important' }}
              >
                {isLoading ? <Spinner color="light" /> : 'Continue'}
              </Button>
            </form>
          </Col>
        </Row>
        <ToastContainer />
      </Container>

    </>
  );
};

export default SignUpAddress;
