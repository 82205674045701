import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import SideBar from '../../components/SideBar'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Button } from '@mui/material';
import img from '../../assets/imges/cafeimg.png'
import { FaAngleLeft, FaUsers } from 'react-icons/fa6';
import { FaArrowLeft, FaEnvelope, FaPhoneAlt } from 'react-icons/fa';
import { PieChart } from 'react-minimal-pie-chart';
import { CircularProgressbar } from 'react-circular-progressbar';
import MarketingDetailTable from '../../components/MarketingDetailTable';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCampaignDetails } from '../../redux/slices/userSlice';

const data = [
    { name: 'Mon', reached: 300, },
    { name: 'Tue', reached: 270, },
    { name: 'Wed', reached: 350, },
    { name: 'Thu', reached: 200, },
    { name: 'Fri', reached: 340, },
    { name: 'Sat', reached: 260, },
    { name: 'Sun', reached: 300, },
];
const gradientColor = 'linear-gradient(180deg, rgba(134,85,251,1) 0%, rgba(255,255,255,1) 100%)';
const gradientOpacity = 0.1;
// =========== PIA CHART ==============
const piaChart = [
    { title: '18-24', value: 0, color: '#FF495F' },
    { title: '60+', value: 0, color: '#00E0FF' },
    { title: '45-54', value: 0, color: '#FFB038' },
    { title: '54-60', value: 0, color: '#4CC204' },
    { title: '25-34', value: 0, color: '#5E73E1' },
    { title: '35-44', value: 0, color: '#EE3CD2' },
    { title: 'Unknown', value: 0, color: '#1303fc' },

];

const colorsForPicChart = ['#FF495F', '#00E0FF', '#FFB038', '#4CC204', '#5E73E1', '#EE3CD2', '#EE3CD2']
const Impressions = 66;
const Clicks = 31;
const Redeems = 31;
const CTR = 30;

const MarketingDetail = () => {
    const dispatch = useDispatch();
    const { isLoading, getError, campaignDetails } = useSelector((state) => state.user)
    const Navigate = useNavigate();
    const location = useLocation();
    const campaignDetail = location.state?.campaignDetail || "Default Value";
    const [pieChartData, setPicChartData] = useState(null);
    const [daysGraph, setDaysGraph] = useState(null);
    useEffect(() => {
        dispatch(getCampaignDetails(campaignDetail.id))
    }, [])
    useEffect(() => {
        if (campaignDetails?.ageChartData) {

            campaignDetails?.ageChartData.forEach(item => {
                const index = piaChart.findIndex(ageGroup => ageGroup.title === item.age_group);
                if (index !== -1) {
                    piaChart[index].value = item.count;
                }
            });
            setPicChartData(piaChart)

        }
        if (campaignDetails?.dataForDays) {
            const transformedData = Object.keys(campaignDetails?.dataForDays).map(day => ({
                name: day.slice(0, 3), // Shorten the day name to three letters
                reached: campaignDetails?.dataForDays[day]
            }));
            console.log(transformedData);
            setDaysGraph(transformedData);
        }
    }, [campaignDetails])
    return (
        <>
            <div className="container-fluid business_admin_main">
                <div className="row order pt-3">

                    {/* <!-- ===========HEADER========== --> */}
                    <Header />
                    {/* <!-- ===========SID-BAR======= --> */}
                    <SideBar />
                    {/* <!-- ========= content body ======= --> */}

                    <div className="w-lg-80 px-lg-4 px-md-3 px-2">
                        <div className="container-fluid mt-4 mb-5">
                            <button title='Back ' className='btn-blue rounded-5' style={{ width: "30px", height: "30px" }} onClick={() => Navigate(-1)}>
                                <FaArrowLeft />
                            </button>
                            <h6 className="font-20 mt-3">{campaignDetail.offer_name}</h6>
                            <div className="row Surveys mt-4">
                                <div className="col-lg-6 col-md-6 col-12 d-flex justify-content-between flex-column ">
                                    <div className="surveys pe-4 pt-lg-5 pt-md-t pt-3 rounded-4" style={{ height: "270px" }}>
                                        <ResponsiveContainer>
                                            {
                                                daysGraph &&
                                                <AreaChart data={daysGraph}>
                                                    {/* <CartesianGrid vertical={false} strokeDasharray="3 3" /> */}
                                                    <XAxis
                                                        dataKey="name"
                                                        axisLine={false}
                                                        tickLine={false}
                                                        tick={{ fontFamily: 'Inter, sans-serif', fontSize: 12, fontWeight: "600", fill: "#959595" }}
                                                    />
                                                    <YAxis
                                                        axisLine={false}
                                                        tickLine={false}
                                                        tick={{ fontFamily: 'Inter, sans-serif', fontSize: 12, fontWeight: "600", fill: "#959595" }}
                                                    />

                                                    <Tooltip />
                                                    <Area
                                                        type="monotone"
                                                        dataKey="reached"
                                                        strokeWidth={2}
                                                        stroke="#1D59F9 "
                                                        fill={`url(${gradientColor})`}
                                                        fillOpacity={gradientOpacity}

                                                    />
                                                </AreaChart>
                                            }
                                        </ResponsiveContainer>
                                    </div>
                                    <div className="container-fluid surveys p-3  mt-lg-0 mt-md-0 mt-4">
                                        <p className="fw-medium mt-2">Campaign Metrics</p>
                                        <div className="row text-center mt-2">
                                            <div className="col-lg-3 col-md-3 col-sm-6 col-12 mt-lg-0 mt-md-0 mt-3 ">
                                                <div className="d-flex justify-content-center">

                                                    <div className=" order-progress-marketing-Impressions ">

                                                        <CircularProgressbar value={campaignDetails?.impressions} text={`${campaignDetails?.impressions}`} />
                                                    </div>
                                                </div>
                                                <p className="fw-medium mb-0 mt-2">Impressions</p>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-6 col-12 mt-lg-0 mt-md-0 mt-3 ">
                                                <div className="d-flex justify-content-center">

                                                    <div className="order-progress-marketing-Impressions order-progress-click">

                                                        <CircularProgressbar value={campaignDetails?.clicks} text={`${campaignDetails?.clicks}`} />
                                                    </div>
                                                </div>
                                                <p className="fw-medium mb-0 mt-2">Clicks </p>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-6 col-12 mt-lg-0 mt-md-0 mt-3 ">
                                                <div className="d-flex justify-content-center">

                                                    <div className="order-progress-marketing-Impressions order-progress-redeems">

                                                        <CircularProgressbar value={campaignDetails?.redeems} text={`${campaignDetails?.redeems}`} />
                                                    </div>
                                                </div>
                                                <p className="fw-medium mb-0 mt-2">Redeems </p>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-6 col-12 mt-lg-0 mt-md-0 mt-3">
                                                <div className="d-flex justify-content-center">

                                                    <div className="order-progress-marketing-Impressions order-progress-ctr">

                                                        <CircularProgressbar value={campaignDetails?.ctr} text={`${campaignDetails?.ctr}%`} />
                                                    </div>
                                                </div>
                                                <p className="fw-medium mb-0 mt-2">CTR <br />(Average) </p>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-0 mt-4">
                                    <div className="surveys p-3">
                                        <h6>Compaign Overview</h6>
                                        <div className="container-fluid mt-3">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-12 d-flex p-0">
                                                    <img src={campaignDetail.offer_images} height={70} width={70} className='rounded-4' alt="w8" />
                                                    <div className='ms-2'>
                                                        <p className="font-14">
                                                            {campaignDetail.offer_name}
                                                        </p>
                                                        <Button variant="contained" className={`${campaignDetail?.approval_status == 'pending'?'btn-compain-detail-pending': campaignDetail?.approval_status == 'rejected'?'btn-compain-detail-rejected':'btn-compain-detail-btn'} text-dark text-capitalize  rounded-5 py-0`}>{campaignDetail?.status == 'active' && campaignDetail?.approval_status == 'approved' ? 'Active': campaignDetail?.approval_status}</Button>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-0 mt-2 px-0 d-flex align-items-end">
                                                    <div className="container-fluid">
                                                        <div className="row">
                                                            <div className="col-6 p-1">

                                                                <div className="surveys rounded-3 py-1 px-2">
                                                                    <div className="font-10">
                                                                        Start Date
                                                                    </div>
                                                                    <div className="font-10 text-gray">
                                                                        {campaignDetails?.schedule_campaign && JSON.parse(campaignDetails?.schedule_campaign)?.start}</div>
                                                                </div>
                                                            </div>
                                                            <div className="col-6 p-1">

                                                                <div className="surveys rounded-3 py-1 px-2">
                                                                    <div className="font-10">
                                                                        End Date
                                                                    </div>
                                                                    <div className="font-10 text-gray">
                                                                        {campaignDetails?.schedule_campaign && JSON.parse(campaignDetails?.schedule_campaign)?.start}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-lg-flex d-md-flex  align-items-center mt-2'>
                                                    <div className='mt-lg-0 mt-md-0 mt-2'>
                                                        <FaUsers className='blue' />
                                                        <span className="mx-1 text-gark-gray font-12">Audience</span>
                                                    </div>
                                                    <div className='mt-lg-0 mt-md-0 mt-2 d-flex align-items-center'>
                                                        <span className="text-gray mx-1 d-lg-flex d-md-flex d-none ">|</span><FaEnvelope className='blue ms-1' /> <span className="mx-1 text-gark-gray font-12">{campaignDetails?.businessDetails?.email}</span>
                                                    </div>
                                                    <div className='mt-lg-0 mt-md-0 mt-2 d-flex align-items-center'>
                                                        <span className="text-gray mx-1 d-lg-flex d-md-flex d-none ">|</span><FaPhoneAlt className='blue ms-1' /> <span className="mx-1 text-gark-gray font-12">{campaignDetails?.businessDetails?.phone}</span>
                                                    </div>
                                                </div>
                                                <p className="text-gray font-12 mt-3">
                                                    In publishing and graphic design, Lorem ipsum is a placeholder text comm
                                                    only used to demonstrate the visual form of a doc ment or a typeface with
                                                    out relying on meaningful content. Lorem ipsum may be used as a placeho
                                                    lder before final copy is .
                                                </p>
                                                <div className="text-center mt-3 pb-2">
                                                    {
                                                        pieChartData &&

                                                        <div className='row'>
                                                            <div className="col-3 ps-3 pe-0">

                                                                <div >
                                                                    {pieChartData.slice(0, 4).map((entry, index) => (
                                                                        <div className='flex-shrink-0' key={index} style={{
                                                                            display: 'flex', alignItems: 'baseline', whiteSpace: 'nowrap', marginTop: "10px"
                                                                        }}>
                                                                            <div className='rounded-1 flex-shrink-0' style={{ width: '15px', height: '15px', backgroundColor: entry.color, marginRight: '5px' }}>
                                                                            </div>
                                                                            <div>

                                                                                <p className="mb-0 font-14"> {entry.title}</p>
                                                                                <p className="mb-0 font-20 fw-semibold "> {entry.value}%</p>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            <div className="col-5 px-0">
                                                                <div >

                                                                    <PieChart
                                                                        data={pieChartData}
                                                                        lineWidth={40}
                                                                        paddingAngle={5}
                                                                    />
                                                                </div>
                                                                <div className='d-flex align-items-center mt-3'>
                                                                    {pieChartData.slice(4, 7).map((entry, index) => (
                                                                        <div className='flex-shrink-0 ms-2' key={index} style={{
                                                                            display: 'flex', alignItems: 'baseline', whiteSpace: 'nowrap'
                                                                        }}>
                                                                            <div className='rounded-1 flex-shrink-0' style={{ width: '15px', height: '15px', backgroundColor: entry.color, marginRight: '5px' }}>
                                                                            </div>
                                                                            <div>

                                                                                <p className="mb-0 font-14"> {entry.title}</p>
                                                                                <p className="mb-0 font-20 fw-semibold "> {entry.value}%</p>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-12 mt-3">
                                    <div className="surveys datatable-show-dropdown p-2 rounded-3 ">
                                        <MarketingDetailTable />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default MarketingDetail
