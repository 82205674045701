import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaArrowRight, FaLock, FaPhone } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Container, Row } from "reactstrap";
import "../../style/businesLogin.scss";
import OTPInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { seTgetErrorNull, updateBusinessProfile, verifyOtp, verifyUserOTP } from "../../redux/slices/userSlice";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import FullScreenLoader from "../../components/loaders/FullScreenLoader";
const OnboardingNumberVerify = () => {
  const [phoneNumber, setPhoneNumber] = useState();
  const [otp, setOtp] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isLoading, getError, userDetail } = useSelector(
    (state) => state.user
  );
  useEffect(() => {
    if (getError && getError.length > 1) {
      toast.error(getError, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
            dispatch(seTgetErrorNull());

    }
  }, [getError]);
  useEffect(() => {
    if (Object.keys(userDetail).length > 0) {
      if (!userDetail?.phone) {
        navigate("/onboarding/number");
      }
    }
  }, [userDetail]);
  useEffect(() => {
    setPhoneNumber(userDetail?.phone);
  }, [userDetail]);
  function handleSubmit(e) {
    e.preventDefault();
    if (otp.length > 4) {
      var data = {
        otp: otp,
        phone: userDetail.phone
      };
      dispatch(verifyUserOTP(data , ()=>{
        navigate("/profile/business-name");
      }));
    } else {
      toast.error("oops Invalid otp!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  function handleResendOtp(){
    var data = {
      phone: userDetail?.phone
    }
    dispatch(updateBusinessProfile(data))
  }
  return (
    <>
     {
      isLoading &&
      <FullScreenLoader isLoading={isLoading} />
    }
      <Container fluid className=" inter">
        <Row
          className="min-h-screen flex flex-col "
          style={{ backgroundColor: "#ffffff" }}
        >
          <div className=" p-3 flex flex-row justify-between">
            <Link to="/onboarding/number" className="mt-3 no-underline">
              <button
                type="button"
                className="px-3 p-2 flex gap-2 items-center  border-0 rounded-lg text-black font-semibold"
              >
                <FaArrowLeft />
                Back
              </button>
            </Link>
            <button
              onClick={() => {
                localStorage.clear();
                navigate('/')
              }}
              type="button"
              className="px-3 p-2  border-0 rounded-lg text-black font-semibold"
            >
              Logout
            </button>
          </div>

          <Col
            xl={6}
            xxl={5}
            lg={7}
            md={12}
            sm={12}
            xs={12}
            className="align-items-center justify-content-center d-flex  my-2 mx-auto !grow"
          >
            <Container fluid>
              <Row className="justify-content-center">
                <Col lg={9} md={9} sm={12} xs={12}>
                  <div>
                    <div className="inter ">
                      <p className="font-14 font-semibold text-black  mb-1">
                        TimeLink | Start building your business profile
                      </p>
                      <h3 className=" fw-bold  text-black ">
                        What’s your Phone Number?
                      </h3>
                      <form onSubmit={handleSubmit} className=" mt-4">
                        <div className="flex items-center h-10 px-2	rounded-3 border-[1.2px] border-gray-300 focus:outline-none w-full onboarding-fields">
                          <PhoneInput
                            international
                            defaultCountry="AU"
                            className="w-full pointer-events-none"
                            placeholder="Phone Number"
                            value={phoneNumber}
                            name="phoneNumber"
                            onChange={()=>false}
                            class=" focus-visible:outline-none w-full text-sm pointer-events-none"
                          />
                          <span onClick={()=>{navigate('/onboarding/number')}} className="font-semibold text-blue cursor-pointer">Edit</span>
                        </div>

                        {/* <div className="flex items-center h-10 px-2	rounded-3 border-[1.2px] border-gray-300 focus:outline-none w-full ">
                          <FaPhone />
                          <input
                            type="number"
                            required
                            className=" !placeholder-gray-400 text-gray-500	text-sm p-2 rounded-3 border-0  border-gray-300 focus:outline-none w-full "
                            placeholder="Phone Number"
                            value={phoneNumber}
                            onChange={(e) => {
                              setPhoneNumber(e.target.value);
                            }}
                            name="phoneNumber"
                          />
                          <span className="font-semibold text-blue ">Edit</span>
                        </div> */}
                        <div className="react-otp-input flex items-center gap-5 p-3 border-2 h-[100px] border-gray-500 rounded-lg relative mt-4">
                          <span className="absolute top-[-11px] left-[20px] bg-white text-sm px-2">
                            Verification code
                          </span>
                          <FaLock />
                          <OTPInput
                            value={otp}
                            onChange={setOtp}
                            className="gt"
                            numInputs={5}
                            renderSeparator={<span>&nbsp;&nbsp;&nbsp;</span>}
                            inputStyle={{
                              width: "30px",
                              height: "30px",
                              margin: "0px",
                              fontSize: "16px",
                              borderRadius: "0px",
                              borderBottom: "2px solid #ddd",
                              textAlign: "center",
                            }}
                            renderInput={(props) => <input {...props} />}
                          />
                        </div>
                        <button
                          type="submit"
                          className="w-full h-10 border-0 flex justify-center items-center mt-3 rounded-md bg-blue-600 text-white p-2.5"
                        >
                          Continue
                        </button>
                        <p className="text-gray-600 mt-4 text-sm text-center">
                          Haven’t received a verification code?{" "}
                          <span className="font-semibold text-blue cursor-pointer " onClick={handleResendOtp}>
                            Resend
                          </span>
                        </p>
                      </form>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
};

export default OnboardingNumberVerify;
