import React, { useState } from 'react'
import Header from '../../components/Header'
import SideBar from '../../components/SideBar'
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { GrFormClose } from 'react-icons/gr';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import { getOtherOptionAnswers } from '../../redux/slices/userSlice';
import moment from 'moment';


const SurveyDetail = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const { isLoading, otherOptionAnswers } = useSelector((state) => state.user)

    const surveyData = location.state.isQtq ? location.state.surveyData.activate_quick_templates : location.state.surveyData;
    const countOptionNumber = (optionNumber) => {
        return surveyData.completes.filter(item => item.option_number === optionNumber).length || 0;
    };
    const Navigate = useNavigate();
    const [viewOtherOptionSubmissions, setViewOtherOptionSubmissions] = useState(false);
    return (
        <>
            <div className="container-fluid business_admin_main">
                <div className="row order ">

                  
                    {/* <!-- ===========SID-BAR======= --> */}
                    <SideBar />
                    {/* <!-- ========= content body ======= --> */}

                    <div className="w-lg-80 px-0 h-screen overflow-y-scroll">
                    <Header PageTitle="Surveys"/>
                    <div className="px-lg-4 px-md-3 px-2">

                        <div className="container-fluid Surveys mt-4">
                            <div className="row">
                                <div className="col-12 m-1">
                                    <span onClick={() => Navigate(-1)} className="cursor-pointer ">
                                        <div>
                                            <h6 className="font-12 mb-0 colorgray"><i className="fa-solid fa-arrow-left " /> Back</h6>
                                        </div>
                                    </span>
                                </div>
                                <div className="col-12">
                                    <div className="surveysone p-4">
                                        <div className="row ">
                                            <div className="col-lg-4 col-md-4 col-12 ">
                                                <div className>
                                                    <h6 className="font-20 inter colorblack">{surveyData?.q_name}</h6>
                                                </div>
                                                <div className="d-flex  justify-content-between align-items-center ">
                                                    <div>
                                                        <p className="font-12 inter font-600 colorgray mb-0">Date
                                                            Created</p>
                                                        <h6 className="font-14 inter font-600 colorblack mt-3">{surveyData?.created_at}</h6>
                                                    </div>
                                                    <div>
                                                        <p className="font-12 inter font-600 colorgray mb-0">Reach</p>
                                                        <h6 className="font-14 inter font-600 colorblack mt-3">{surveyData?.reaches?.length}</h6>
                                                    </div>
                                                    <div>
                                                        <p className="font-12 inter font-600 colorgray mb-0">Completed
                                                            by:</p>
                                                        <h6 className="font-14 inter font-600 colorblack mt-3">{surveyData?.completes?.length} </h6>
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button className="btn-Question px-5 py-2 rounded-5 colorblack" style={{ color: 'black' }}>{surveyData?.question} </button>
                                <div className="col-12 mt-4">
                                    <div className="surveysone p-4 mt-3">
                                        <div className="row ">
                                            <div className>
                                                <h6 className="font-18 inter font-600 colorblack">Responses</h6>
                                                <p className="font-12 inter font-600 colorgray mb-0">This is a list of latest Responses.</p>
                                            </div>
                                            <div className="col-12 Choices mt-2">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-12">
                                                        <div>
                                                            <p className="font-12 inter font-600 colorgray mt-3">Answer Choices</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-12 ">
                                                        <div>
                                                            <p className="font-12 inter font-600 colorgray mt-3">Responses</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {surveyData?.options.map((value, index) => {
                                                return (
                                                    <div className="col-12 Choices">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-6 col-12 ">
                                                                <div className="row">
                                                                    <div className="col-lg-6 col-md-6 col-12 ">
                                                                        <p className="font-14 inter font-600 colorblack mt-3">{index + 1}{' - ' + value.option_value} </p>
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-0 mt-3 align-self-center">
                                                                        {value.is_text_field ?
                                                                            <button onClick={() => { dispatch(getOtherOptionAnswers({ option_number: value.option_number, question_id: value.question_id })); setViewOtherOptionSubmissions(true) }} className="btn-skywhite px-2 py-1 rounded-2">View
                                                                                Submissions</button>
                                                                            : <></>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-12 ">
                                                                <div className="row">
                                                                    <div className="col-6 ">
                                                                        <p className="font-14 inter font-600 colorblack mt-3">{countOptionNumber(value.option_number) / surveyData.completes.length * 100}%</p>
                                                                    </div>
                                                                    <div className="col-6 ">
                                                                        <p className="font-14 inter font-600 colorblack mt-3">{countOptionNumber(value.option_number)}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-12 ">

                                                        <p className="font-14 inter font-600 colorblack mt-3">Total </p>

                                                    </div>



                                                    <div className="col-lg-6 col-md-6 col-12 ">
                                                        <p className="font-14 inter font-600 colorblack mt-3">{surveyData.completes.length}</p>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>

                </div>
                <Modal show={viewOtherOptionSubmissions} className='rounded-modal text-center modals_scroll business_admin_main' centered  >

                    <Modal.Body style={{ maxHeight: '85vh', overflowY: 'auto' }}>
                        <div className="w-100 h-100-d-flex">
                            <div className="d-flex flex-row justify-content-between ">
                                <h4 className="font-18 text-center ps-2 inter blue mt-3 w-100">
                                    Answers
                                </h4>
                                <span onClick={() => setViewOtherOptionSubmissions(false)} className='mt-3 cursor-pointer'><GrFormClose size={30} /></span>
                            </div>
                            <div className="w-100 d-flex flex-row">
                                <h4 className="font-17 text-center ps-2 inter colorBlack mt-3 w-100">
                                    Response
                                </h4>
                                <h4 className="font-17 text-center ps-2 inter colorBlack mt-3 w-100">
                                    Submitted at
                                </h4>
                            </div>
                            <hr className='w-100' />
                            {
                                isLoading ?
                                    <div className="row justify-content-center not-active-Campaigns rounded-3 text-center mt-3">
                                        <div className="col-12">
                                            <div className="Campaigns p-4 rounded-3">
                                                <Spinner color='dark' />                                                            </div>
                                        </div>
                                    </div>
                                    : !otherOptionAnswers || otherOptionAnswers < 1 ?
                                        <div className="row justify-content-center not-active-Campaigns rounded-3 text-center mt-3">
                                            <div className="col-12">
                                                <div className="Campaigns p-4 rounded-3">
                                                    <h5 className='fw-bold'>No Responses</h5>
                                                </div>
                                            </div>
                                        </div> :
                                        otherOptionAnswers.map((value) => {
                                            return (
                                                <>
                                                    <div className="w-100 d-flex flex-row">
                                                        <h4 className="font-13 text-center inter colorBlack w-100">
                                                            {value.option_value}
                                                        </h4>
                                                        <h4 className="font-13 text-center inter colorBlack w-100">
                                                            {moment(value?.created_at).format('DD MMM YYYY')}
                                                        </h4>
                                                    </div>
                                                    <hr className='w-100 m-0' />
                                                </>
                                            )
                                        })
                            }

                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}

export default SurveyDetail
