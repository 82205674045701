import React from 'react'

const Frame = () => {
  return (
    <div className="container-fluid p-0 m-0">
      <div className="row justify-content-center" style={{height:"100vh"}}>
        
          <iframe className="w-100 h-100 "  src="https://geo-devrel-javascript-samples.web.app/samples/places-placeid-finder/app/dist/" title="Newframe" frameBorder={0} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
      </div>
    </div>
  )
}

export default Frame
