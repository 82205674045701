/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { BsThreeDotsVertical } from 'react-icons/bs';
import { getTotalTimeForOrder } from '../helpers/helpers';
import { useDispatch } from 'react-redux';
import { deleteOrder, updateOrderStatus } from '../redux/slices/userSlice';
const OpenTableData = ({ data , status}) => {
   
    const dispatch = useDispatch();
    const [sorting, setSorting] = useState([]);
    const handleSort = (column, sortDirection) => {
        // Ensure sorting state is not undefined before updating
        if (sorting) {
            setSorting([{ column, sortDirection }]);
        } else {
            setSorting([]);
        }
    };

    const handleResetSorting = () => {
        // Reset sorting status
        setSorting([]);
    }
    const ActionCell = ({ row }) => {
        const handleDropdownClick = () => {
            alert(`Dropdown clicked for ${row.id}`);
        };

        function handleUpdateOrderStatus(status){
            var data ={
                is_from_admin:true,
                order_status:status,
                order_id:row.id
            }
            dispatch(updateOrderStatus(data));
        }

        function handleDeleteOrder(){
            dispatch(deleteOrder(row.id))
        }
    
        return (
            <div className='roboto table-dropdown' style={{ color: '#7D7D7D', position: 'relative' }}>
                <div className='dropdown'>
                    <span className='dropdown-toggle' role='button' data-bs-toggle='dropdown' aria-expanded='false'>
                        <BsThreeDotsVertical />
                    </span>
                    <ul className='dropdown-menu'>
                        <li><span className='dropdown-item' >View</span></li>
                        <li><span className='dropdown-item' onClick={()=>{handleUpdateOrderStatus('Notified')}} >Notify</span></li>
                        <li><span className='dropdown-item' onClick={()=>{handleUpdateOrderStatus('Notified and Complete')}} >Notify and Complete</span></li>
                        <li><span className='dropdown-item' onClick={()=>{handleUpdateOrderStatus('Complete')}} >Complete</span></li>
                        <li><span className='dropdown-item' onClick={handleDeleteOrder}>Delete</span></li>
                    </ul>
                </div>
            </div>
        );
    };
    
    const columns = [
        {
            name: 'Order',
            selector : row=>row.order_id,
            cell: row => <>
                <p className="font-15 mb-1  fw-medium roboto" style={{ color: '#323C47' }}>
                    {row.order_id}
                </p>
            </>,
            sortable: true,
        },
        {
            name: 'Start',
            selector : row=>row.start_time,
            cell: row => <p className="font-13 mb-1  roboto" style={{ color: '#7D7D7D' }}>
                {row.start_time}
            </p>,
            sortable: true,
        },

        {
            name: 'End',
            selector : row=>row.end_time,
            cell: row => <>
                <p className="font-13 mb-0  roboto" style={{ color: '#7D7D7D' }}>
                    {row.end_time}
                </p>
            </>
            ,
            sortable: true,
        },
        {
            name: 'Total Time',
            selector : row=>getTotalTimeForOrder(row.start_time, row.end_time) == 'N/A' ? '00:00:00' :getTotalTimeForOrder(row.start_time, row.end_time),
            cell: row => <>
                <p className="font-13 mb-0 roboto" style={{ color: '#7D7D7D' }} >
                    {getTotalTimeForOrder(row.start_time, row.end_time) == 'N/A' ? '00:00:00' :getTotalTimeForOrder(row.start_time, row.end_time)}
                </p>
            </>
            ,
            sortable: true,
        },
        {
            name: 'Status',
            selector : row=>row.status,
            cell: row => <>
                <p className="font-13 mb-0  roboto" style={{ color: '#7D7D7D' }}>
                    {status ? status : row.status}
                </p>
            </>
            ,
            sortable: true,
        },
        {
            cell: row => <ActionCell row={row} />,
            allowOverflow: true,
            button: true,
           
        },
    ];
    const customStyles = {
        rows: {
            style: {
                minHeight: '80px', // override the row height
                borderRadius: '0px',
                borderBottom: 'none',


            },
        },
        headCells: {
            style: {
                minHeight: '80px',
                paddingLeft: '15px', // override the cell padding for head cells
                paddingRight: '15px',
                color: '#7D7D7D',
                fontWeight: '500',
                borderBottom: '1px solid #F0F0F5',
                fontSize: '14px !important'
            },
        },
        cells: {
            style: {
                paddingLeft: '15px', // override the cell padding for data cells
                paddingRight: '10px',
            },
        },
    };

    return (
        <>

            <DataTable
                columns={columns}
                data={data}
                selectableRows
                customStyles={customStyles}
                responsive
                // noHeader
                pagination

                // expandableRowsComponent={ExpandedComponent}
                // sortIcon={sortIcon}
                highlightOnHover
                pointerOnHover
               

            />


        </>
    )
}

export default OpenTableData


