import React from 'react';
import logo from '../assets/imges/Logo.png';
import { Menu, MenuItem } from '@mui/material';
import { FaAngleDown } from 'react-icons/fa';
import { Link } from 'react-router-dom';
const LoginHeader = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <div className='d-flex justify-content-between align-items-center mt-lg-0 mt-md-0 mt-sm-0  mt-3 px-lg-3 px-md-3 login-header' >
                <Link to="/">

                    <img src={logo} alt="w8" width="150" />
                </Link>
                <div>
                    {/* <span className='cursor-pointer inter fw-semibold text-dark-gray'
                        onClick={handleClick}
                    >
                        English (UK)<FaAngleDown className='ms-2' />
                    </span>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        className='text-dark-gray mt-2'
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={handleClose}>English</MenuItem>
                        <MenuItem onClick={handleClose}>Arabic</MenuItem>
                        <MenuItem onClick={handleClose}>Persian</MenuItem>
                    </Menu> */}
                </div>

            </div>

        </>
    );
}

export default LoginHeader;
