import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Col, Container, Row } from 'reactstrap';
import logoTwo from '../../assets/imges/Logo.png';
import '../../style/businesLogin.scss';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa6';
import { useDispatch, useSelector } from 'react-redux';
import { seTgetErrorNull, updateBusinessProfile } from '../../redux/slices/userSlice';
import FullScreenLoader from '../../components/loaders/FullScreenLoader';
const WellcomeThree = () => {
    const dispatch = useDispatch();
    const { isLoading, getError, userDetail } = useSelector((state) => state.user)
    const navigate = useNavigate();
    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     navigate('/trial/wellcome/three')
    // }
    const [abn, setabn] = useState();
    useEffect(() => {
        if (getError && getError.length > 1) {
            toast.error(getError, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            dispatch(seTgetErrorNull());
        }
    }, [getError])
    useEffect(() => {
        if (Object.keys(userDetail).length > 0) {
            if (!userDetail?.phone) {
                navigate("/onboarding/number");
            } else if (!userDetail?.is_phone_verified) {
                navigate("/onboarding/number/verify");
            } else if (!userDetail?.business_name) {
                navigate("/profile/business-name");
            } else {

            }
        }
    }, [userDetail]);
    useEffect(() => {
        setabn(userDetail?.abn)
    }, [userDetail])
    function handleSubmit(e) {
        e.preventDefault();
        var data = {
            abn
        }
        dispatch(updateBusinessProfile(data, () => {
            navigate('/profile/logo')
        }))

    }
    return (
        <>
            {
                isLoading &&
                <FullScreenLoader isLoading={isLoading} />
            }
            <Container fluid className=' inter' >
                <Row className='min-h-screen flex flex-col ' style={{ backgroundColor: '#ffffff' }}>

                    <div className=' p-3 flex flex-row justify-between' >
                        <Link to="/profile/business-name" className='mt-3 no-underline'>
                            <button type='button' className='px-3 p-2 flex gap-2 items-center  border-0 rounded-lg text-black font-semibold'><FaArrowLeft />Back</button>
                        </Link>
                        <button
                            onClick={() => {
                                localStorage.clear();
                                navigate('/')
                            }}
                            type="button"
                            className="px-3 p-2  border-0 rounded-lg text-black font-semibold"
                        >
                            Logout
                        </button>
                    </div>

                    <Col lg={7} md={12} sm={12} xs={12} className='align-items-center justify-content-center d-flex  my-2 mx-auto !grow' >
                        <Container fluid >
                            <Row className='justify-content-center'>
                                <Col lg={9} md={9} sm={12} xs={12} >
                                    <div>
                                        <div className='inter '>
                                            <p className="font-14 font-semibold text-black  mb-1">TimeLink | Start building your business profile</p>
                                            <h3 className=" fw-bold  text-black ">What’s your ABN?  </h3>
                                            <form onSubmit={handleSubmit} className='flex gap-2 mt-4'>
                                                <input
                                                    type="text"
                                                    required
                                                    className="!placeholder-gray-400 h-10 text-sm p-2.5 rounded-3 border-[1.2px] border-gray-300 focus:outline-none w-full"
                                                    placeholder="Australian Business Number"
                                                    value={abn}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        // Only allow numeric input
                                                        if (/^\d*$/.test(value)) {
                                                            setabn(value);
                                                        }
                                                    }}
                                                    name="abn"
                                                    inputMode="numeric"
                                                    pattern="\d*"
                                                    maxLength={11}
                                                    minLength={11}
                                                />                                                <button type="submit" className='w-10 border-0 flex justify-center items-center h-10 rounded-md bg-blue-600 text-white p-2.5'>
                                                    <FaArrowRight />
                                                </button>

                                            </form>


                                        </div>
                                    </div>


                                </Col>
                            </Row>
                        </Container>
                    </Col>

                </Row>
                <ToastContainer />
            </Container>
        </>
    );
}

export default WellcomeThree;
