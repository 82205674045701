/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react'
import LoyaltyPointsHeader from '../../../components/LoyaltyPointsHeader'
import { Link, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { getBusinessRewards, setSumitCamgpaingState } from '../../../redux/slices/userSlice';

const CustomMarketingCompaign = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { submitCampaign, getError, businessRewards } = useSelector((state) => state.user)
    const [rewardBased, setRewardBased] = useState(false);
    const [rewardType, setRewardType] = useState();
    const [isFocusedVisit, setIsFocusedVisit] = useState(false);
    const [pointsEarn, setPointsEarn] = useState();
    const [selectedReward, setSelectedReward] = useState();
    const [selectedRewardName, setSelectedRewardName] = useState();

    useEffect(() => {
        console.log(submitCampaign.get('reward_type'), 'from redux ____________')
        if (submitCampaign.get('reward_type')) {
            setRewardBased(true)

        }
        setRewardType(submitCampaign.get('reward_type'))
        setPointsEarn(submitCampaign.get('points'))
        setSelectedReward(submitCampaign.get('reward_id'))
        // setFile(submitCampaign.get('image') ? submitCampaign.getAll('image') : undefined)
        // setFile(submitCampaign.get('image') ? URL.createObjectURL(submitCampaign.getAll('image')) : undefined)
    }, [])
    const handleNext = () => {
        if (rewardBased && rewardType === 'reward' && businessRewards?.length < 1) {
            navigate('/rewards/create', { state: { from: 'campaign' } })
            return;
        }
        if (rewardBased && !rewardType) {
            toast.error('Please select reward type!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return false;
        }
        if (rewardBased && rewardType === 'reward' && !selectedReward) {
            toast.error('Please select a reward!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return false;
        }
        if (rewardBased && rewardType === 'points' && !pointsEarn) {
            toast.error('Please select points!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return false;
        }
        if (rewardBased) {
            if (rewardType === 'reward' && selectedReward) {

                console.log('should set the state to reward now ! AND REWARD NAME WILL BE-----' , selectedRewardName);
                dispatch(setSumitCamgpaingState({ key: 'reward_type', value: rewardType }))
                dispatch(setSumitCamgpaingState({ key: 'reward_id', value: selectedReward }))
                dispatch(setSumitCamgpaingState({ key: 'reward_name', value: selectedRewardName }))

            }
            if (rewardType === 'points' && pointsEarn) {
                dispatch(setSumitCamgpaingState({ key: 'reward_type', value: rewardType }))
                dispatch(setSumitCamgpaingState({ key: 'points', value: pointsEarn }))

            }
        }
        navigate("/custom/marketing/detail")
    }
    const handleOptionChange = (event) => {
        setRewardBased(!rewardBased);
    };
    useEffect(() => {
        if (getError && getError.length > 1) {
            toast.error(getError, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }, [getError])
    // useEffect(() => {
    //     dispatch(getBusinessRewards());
    // }, []);

    return (
        <main className='bg-white p-3' style={{ minHeight: "100vh" }}>

            <LoyaltyPointsHeader handleNext={handleNext} />
            <div className="container inter customise-program mt-4">
                <div className="row justify-content-center ">
                    <div className="col-lg-6 col-md-8 col-12">

                        {/* <p className='text-uppercase font-12 text-black-50'>Step 1 of 4</p> */}
                        <h3 className='font-30 fw-bold '>Customise your marketing campaign</h3>
                        <p className='font-14 mt-3'>Specify if you want customers to earn a reward or purely for advertisement purposes.</p>

                        <div >
                            <div className="  mt-3">
                                <label role='button' className=" w-100 " htmlFor="no-reward">
                                    <input className=" d-none " defaultChecked type="radio" name="radioOptions" id="no-reward" required
                                        onChange={() => { setRewardBased(false) }}
                                    />
                                    <div className=" custom-radio w-100 p-14  rounded-3 ">

                                        <p className='font-14 mb-0 fw-medium '>No reward</p>
                                        <p className='font-12 mb-0'>Customers are shown an advertisement without the need for redeeming a reward</p>

                                    </div>
                                </label>
                            </div>
                            <div className="  mt-3">
                                <label role='button' className=" w-100 " htmlFor="offer">
                                    <input className=" d-none  " type="radio" name="radioOptions" id="offer" required
                                        checked={rewardBased}
                                        onChange={handleOptionChange}
                                    />
                                    <div className=" custom-radio w-100 p-14  rounded-3 ">

                                        <p className='font-14 mb-0 fw-medium '>With reward</p>
                                        <p className='font-12 mb-0'>Customers will get reward you select in this campaign or will get points</p>

                                    </div>
                                </label>
                            </div>
                            {
                                rewardBased &&
                                <div className="mt-3">
                                    <div className='h-auto rounded-2 d-flex flex-row gap-2 fw-bold font-14 justify-content-center' style={{ backgroundColor: '#F0F2F5', padding: 3, color: '#00000080', marginTop: -5 }}>
                                        <span onClick={() => { setRewardType('reward') }} className='py-2 px-4 cursor-pointer rounded-2' style={rewardType === 'reward' ? { backgroundColor: '#ffffff', color: '#1B59F8' } : {}}>
                                            Reward
                                        </span>
                                        <span onClick={() => { setRewardType('points') }} className='py-2 px-4 cursor-pointer rounded-2' style={rewardType === 'points' ? { backgroundColor: '#ffffff', color: '#1B59F8' } : {}}>
                                            Points
                                        </span>
                                    </div>
                                </div>
                            }

                            {
                                rewardBased &&
                                rewardType === 'points' &&
                                <div className="mt-3">
                                    <label role="button" onFocus={() => setIsFocusedVisit(true)}
                                        onBlur={() => setIsFocusedVisit(false)} className={`focus-field p-2 pb w-100 rounded-3  ${isFocusedVisit ? 'active' : ''}`} >
                                        <div className="w-100 m-0 rounded-3">
                                            <p className="font-14 mb-0 ">Customers earn</p>
                                        </div>
                                        <input
                                            className="w-100 border-0 font-15 rounded-3"
                                            type="text"
                                            min={100} max={1000} value={pointsEarn} onChange={(e) => { if (e.target.value > 1000) { setPointsEarn(1000) } else { setPointsEarn(e.target.value) } }}
                                            onBlur={(e) => { if (!e.target.value || e.target.value.length < 1) { setPointsEarn(null) } else if (e.target.value < 100) { setPointsEarn(100) } }}
                                            required

                                        />
                                    </label>
                                </div>
                            }
                            {
                                rewardBased &&
                                rewardType === 'reward' &&
                                <div className="mt-3 d-flex gap-3 flex-column">
                                    {
                                        businessRewards?.length > 0 ?
                                            <>
                                                {
                                                    businessRewards?.filter(_reward => _reward.program_type === 'campaign').map((reward) => {
                                                        return (
                                                            <div key={reward.id}
                                                                onClick={() => { setSelectedReward(reward.id);setSelectedRewardName(reward.description); }}
                                                                className={`cursor-pointer focus-field p-2 pb w-100 rounded-3 d-flex flex-row justify-content-between align-items-center  ${selectedReward == reward.id ? 'active' : ''}`}
                                                            >
                                                                {/* <p className='fw-semibold font-18 text-dark-gray mb-0'>{reward?.type?.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</p> */}
                                                                <p className='fw-semibold font-14 text-dark-gray mb-0 text-capitalize'>{reward?.description}</p>
                                                                <p className='fw-normal font-14 text-dark-gray mb-0'>{reward?.type === 'free_item' ? reward?.item_name : reward?.discount_percentage ? reward?.discount_percentage + ' %' : reward?.discount_amount + ' $'}</p>

                                                            </div>
                                                        )
                                                    })
                                                }
                                                <Link
                                                    style={{ marginLeft: 7 }}
                                                    to={'/rewards/create'}
                                                    state={{ from: 'campaign' }}
                                                >

                                                    Click here to create Reward
                                                </Link>
                                            </>
                                            :
                                            <>

                                            </>
                                        /* <div class="alert alert-warning" role="alert">
                                            <h5>
                                                Important Note!
                                            </h5>
                                            <p>
                                                You have no rewards created so you can not create a rewarding campaign
                                                {' '}
                                                <Link
                                                    style={{ marginLeft: 7 }}
                                                    to={'/rewards/create'}
                                                    state={{ from: 'campaign' }}
                                                >

                                                    click here to create one
                                                </Link>
                                            </p>
                                        </div> */
                                    }
                                </div>
                            }

                        </div>

                    </div>
                </div>

            </div>
            <ToastContainer />
        </main>
    )
}

export default CustomMarketingCompaign
