/* eslint-disable eqeqeq */
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store/store';
import axios from '../../utils/axios';


const initialState = {
  isLoading: false,
  createError: null,
  updateError: null,
  getError: null,
  deleteError: null,
  usersList: [],
  userDetail: {},
  isSignedIn: 'checking',
  businessOrders: [],
  businessDevices: [],
  businessLoyalties: [],
  businessRewards: [],
  businessCampaigns: [],
  businessSurveys: [],
  businessAutomations: [],
  activatedQTs: [],
  qTs: [],
  submitCampaign: new FormData(),
  surveyLimit: 0,
  businessesList: [],
  recentImagesList: [],
  campaignDetails: null,
  otherOptionAnswers: null,
  loyaltyDetails: null,
  feedbackReaches: null,
  feedbacks: null,
  priceDetails: null,
  allPrices: null,
  allBlogs: null,
  subscriptionDetails: null,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    //START LOADING
    startLoading(state) {
      state.isLoading = true
    },
    //STOP LOADING
    stopLoading(state) {
      state.isLoading = false
    },
    //HAS CREATE ERROR
    hasCreateError(state, action) {
      state.isLoading = false
      state.createError = action.payload
    },
    //UPDATE ERROR
    hasUpdateError(state, action) {
      state.isLoading = false
      state.updateError = action.payload
    },
    // HAS UPDATE ERROR
    hasGetError(state, action) {
      state.isLoading = false
      state.getError = action.payload
    },

    // HAS UPDATE ERROR
    hasDeleteError(state, action) {
      state.isLoading = false;
      state.deleteError = action.payload
    },
    // GET User Success
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.usersList = action.payload
    },

    // GET User DETAILS
    getUserDetailsSuccess(state, action) {
      state.isLoading = false;
      state.userDetail = action.payload
    },
    getBusinessOrdersSuccess(state, action) {
      state.isLoading = false;
      state.businessOrders = action.payload
    },
    getBusinessLoyaltiesSuccess(state, action) {
      state.isLoading = false;
      state.businessLoyalties = action.payload
    },
    getBusinessRewardsSuccess(state, action) {
      state.isLoading = false;
      state.businessRewards = action.payload
    },
    getBusinessCampaignsSuccess(state, action) {
      state.isLoading = false;
      state.businessCampaigns = action.payload
    },
    getBusinessDevicesSuccess(state, action) {
      state.isLoading = false;
      state.businessDevices = action.payload
    },
    getBusinessSurveySuccess(state, action) {
      state.isLoading = false;
      state.businessSurveys = action.payload
    },
    getcampaignDetailsSuccess(state, action) {
      state.isLoading = false;
      state.campaignDetails = action.payload
    },
    getLoyaltyDetailsSuccess(state, action) {
      state.isLoading = false;
      state.loyaltyDetails = action.payload
    },
    getSurveyLimitSuccess(state, action) {
      state.isLoading = false;
      state.surveyLimit = action.payload
    },
    getBusinessAutomationSuccess(state, action) {
      state.isLoading = false;
      state.businessAutomations = action.payload
    },
    getBusinessesSuccess(state, action) {
      state.isLoading = false;
      state.businessesList = action.payload
    },
    getOtherOptionAnswersSuccess(state, action) {
      state.isLoading = false;
      state.otherOptionAnswers = action.payload
    },
    getActivatedQTs(state, action) {
      state.isLoading = false;
      state.activatedQTs = action.payload
    },
    getQTs(state, action) {
      state.isLoading = false;
      state.qTs = action.payload
    },
    getFeedbackReachesSuccess(state, action) {
      state.isLoading = false;
      state.feedbackReaches = action.payload
    },
    getFeedbacksSuccess(state, action) {
      state.isLoading = false;
      state.feedbacks = action.payload
    },
    setIsSignedIn(state, action) {
      state.isSignedIn = action.payload
    },
    setSubmitCampaign(state, action) {
      state.submitCampaign.delete(action.payload.key);
      state.submitCampaign.append(action.payload.key, action.payload.value)
    },
    emptySubmitCampaign(state, action) {
      state.submitCampaign = new FormData();
    },
    getRecentImagesListSuccess(state, action) {
      state.isLoading = false;
      state.recentImagesList = action.payload
    },
    getPriceDetialsSuccess(state, action) {
      state.isLoading = false;
      state.priceDetails = action.payload
    },
    getSubscriptionDetialsSuccess(state, action) {
      state.isLoading = false;
      state.subscriptionDetails = action.payload
    },
    getAllPricesSuccess(state, action) {
      state.isLoading = false;
      state.allPrices = action.payload
    },
    getBlogsSuccess(state, action) {
      state.isLoading = false;
      state.allBlogs = action.payload
    },
  },
})
export const { getUserDetailsSuccess } = userSlice.actions;
export default userSlice.reducer

// User Functions

//User Login
export function userLogin(data, handleResponse) {
  dispatch(userSlice.actions.hasGetError(''));
  console.log('login function is called from redux and received this data ' + data)
  return async () => {
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('auth/login',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
      }
      //console.log(JSON.stringify(response.data))
      dispatch(userSlice.actions.getUserDetailsSuccess(response.data.data));
      localStorage.setItem('userToken', response.data.access_token)
      localStorage.setItem('userDetails', JSON.stringify(response.data.data))
      localStorage.setItem('authEmail', data.email)
      dispatch(userSlice.actions.setIsSignedIn(true));
      dispatch(userSlice.actions.stopLoading());
      handleResponse(response.data.data)

    } catch (error) {
      dispatch(userSlice.actions.stopLoading());
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

// user register

export function userRegister(data, handleResponse) {
  dispatch(userSlice.actions.hasGetError(''));
  console.log('register function is called from redux and received this data ' + data)
  return async () => {
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('auth/register',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
      }
      //console.log(JSON.stringify(response.data))
      dispatch(userSlice.actions.getUserDetailsSuccess(response.data.data));
      localStorage.setItem('tempToken', response.data.access_token)
      localStorage.setItem('authEmail', data.email)
      handleResponse(response.data.data);
      dispatch(userSlice.actions.setIsSignedIn(true));
      dispatch(userSlice.actions.stopLoading());

    } catch (error) {
      dispatch(userSlice.actions.stopLoading());
      dispatch(userSlice.actions.hasCreateError(error?.message));
    }
  };
}

export function signUpWithGoogle(data, handleResponse) {
  dispatch(userSlice.actions.hasGetError(''));
  console.log('signUpWithGoogle function is called from redux and received this data ' + data)
  return async () => {
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('auth/sign-up-with-google',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
      }
      //console.log(JSON.stringify(response.data))
      dispatch(userSlice.actions.getUserDetailsSuccess(response.data.data));
      localStorage.setItem('userToken', response.data.access_token)
      localStorage.setItem('userDetails', JSON.stringify(response.data.data))
      handleResponse(response.data.data);
      dispatch(userSlice.actions.setIsSignedIn(true));
      dispatch(userSlice.actions.stopLoading());

    } catch (error) {
      dispatch(userSlice.actions.stopLoading());
      dispatch(userSlice.actions.hasCreateError(error?.message));
    }
  };
}
export function signInWithGoogle(data, handleResponse) {
  dispatch(userSlice.actions.hasGetError(''));
  console.log('signInWithGoogle function is called from redux and received this data ' + data)
  return async () => {
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('auth/sign-in-with-google',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
      }
      //console.log(JSON.stringify(response.data))
      dispatch(userSlice.actions.getUserDetailsSuccess(response.data.data));
      localStorage.setItem('userToken', response.data.access_token)
      localStorage.setItem('userDetails', JSON.stringify(response.data.data))
      dispatch(userSlice.actions.setIsSignedIn(true));
      dispatch(userSlice.actions.stopLoading());
      handleResponse(response.data.data)

    } catch (error) {
      dispatch(userSlice.actions.stopLoading());
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function forgotPassword(data, handleResponse) {
  dispatch(userSlice.actions.hasGetError(''));
  console.log('forgot function is called from redux and received this data ' + data)
  return async () => {
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('forgot-password',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
      }
      //console.log(JSON.stringify(response.data))
      dispatch(userSlice.actions.stopLoading());
      handleResponse(response.data.data)

    } catch (error) {
      dispatch(userSlice.actions.stopLoading());
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function forgotPasswordReset(data, handleResponse) {
  dispatch(userSlice.actions.hasGetError(''));
  console.log('forgot password reset function is called from redux and received this data ' + JSON.stringify(data))
  return async () => {
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('forgot-password-reset',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      dispatch(userSlice.actions.stopLoading());
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
      } else {
        handleResponse(response.data)
      }
      //console.log(JSON.stringify(response.data))

    } catch (error) {
      dispatch(userSlice.actions.stopLoading());
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

// resend otp 
export function resendOtp(data, handleResponse) {
  if (!data) {
    var data = {
      email: localStorage.getItem('authEmail')
    }
  }
  console.log('resend otp should have this payload---->', data)
  dispatch(userSlice.actions.hasGetError(''));
  console.log('resend otp function is called from redux ')
  return async () => {
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('otp-resend',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      if (response.data.status) {
        handleResponse(response.data, true)
      }
      dispatch(userSlice.actions.stopLoading());
    } catch (error) {
      dispatch(userSlice.actions.stopLoading());
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}
// verify otp 
export function verifyOtp(data, handleResponse) {
  dispatch(userSlice.actions.hasGetError(''));
  console.log('verify otp function is called from redux and received this data ' + JSON.stringify(data))
  return async () => {
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('otp-verify',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
        return
      }
      //console.log(JSON.stringify(response.data))
      dispatch(userSlice.actions.getUserDetailsSuccess(response.data.data));
      localStorage.setItem('userToken', response.data.access_token)
      localStorage.setItem('userDetails', JSON.stringify(response.data.data))
      handleResponse(response.data.data);

    } catch (error) {
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}
//Get User Details
export function getUser() {
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.get('user', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });

      dispatch(userSlice.actions.getUserDetailsSuccess(response.data.data));
      localStorage.setItem('userDetails', JSON.stringify(response.data.data))
    } catch (error) {
      dispatch(userSlice.actions.stopLoading())
      localStorage.clear();
      // dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

//Send account under review email
export function sendAccountUnderReviewEmail() {
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.get('auth/send-account-under-review-email', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });
    } catch (error) {
      dispatch(userSlice.actions.stopLoading())
      localStorage.clear();
      // dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function updateBusiness(id, data) {
  return async () => {
    console.log('update business function called from redux and received this data', data)
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.patch('business/' + id,
        data,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
          }
        });
      dispatch(userSlice.actions.stopLoading());
      dispatch(getBusinessesList());
    } catch (error) {
      localStorage.removeItem('userToken')
      dispatch(userSlice.actions.hasGetError(error?.message));

    }
  };
}

//Get Square redirect url
export function getSquareRedirectUrl(handleResponse) {
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.get('square/redirect', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      handleResponse(response.data?.url)
      // dispatch(userSlice.actions.stopLoading());
    } catch (error) {
      localStorage.removeItem('userToken')
      dispatch(userSlice.actions.hasGetError(error?.message));

    }
  };
}
//revoke access
export function revokeAccess(handleResponse) {
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.get('square/disconnect', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      if (response.data.status) {
        handleResponse(response.data?.status)
      } else {
        dispatch(userSlice.actions.hasGetError(response.data?.message));
      }
      // dispatch(userSlice.actions.stopLoading());
    } catch (error) {
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}
export function getUserToken() {
  return async () => {


    try {
      const userToken = await localStorage.getItem("userToken");
      console.log('getting user token' + userToken)

      if (userToken) {
        dispatch(userSlice.actions.setIsSignedIn(true));
      } else {
        dispatch(userSlice.actions.setIsSignedIn(false));
      }
    } catch (error) {
      console.log(error + ' error getting token from encrypted storage!');
    }
  }
}

// profile complete steps
export function setBusinessProfile(profileStep, data, handleResponse) {
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    console.log(accessToken)
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('business/profile/' + profileStep,
        data,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + accessToken
          }
        });
      dispatch(userSlice.actions.stopLoading());
      if (response.data.status) {
        dispatch(userSlice.actions.getUserDetailsSuccess(response.data.data));
        localStorage.setItem('userDetails', JSON.stringify(response.data.data))
        handleResponse(response.data.data)
      }
    } catch (error) {
      dispatch(userSlice.actions.stopLoading());
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}
export function updateBusinessProfile(data, handleResponse) {
  console.log('update business profile is called from redux and received data', JSON.stringify(data))
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    console.log(accessToken)
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('auth/profile',
        data,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + accessToken
          }
        });
      dispatch(userSlice.actions.stopLoading());
      if (response.data.status) {
        dispatch(userSlice.actions.getUserDetailsSuccess(response.data.data));
        localStorage.setItem('userDetails', JSON.stringify(response.data.data))
      }
      if (handleResponse) {
        handleResponse(response.data.status)
      }
    } catch (error) {
      dispatch(userSlice.actions.stopLoading());
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}
export function verifyUserOTP(data, handleResponse) {
  return async () => {
    let accessToken = localStorage.getItem('userToken')

    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('admin/phone/verify',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      dispatch(userSlice.actions.stopLoading());
      if (response.data.status) {
        dispatch(userSlice.actions.getUserDetailsSuccess(response.data.data));
        localStorage.setItem('userDetails', JSON.stringify(response.data.data))
        handleResponse(response.data.data)
      }
    } catch (error) {
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}
export function getBusinessOrders(start, end) {
  if (start && end) {
    var route = 'auth/profile/' + start + '/' + end
  }
  dispatch(userSlice.actions.hasGetError(''));
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.get(route ? route : 'auth/profile',
        // data,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
          }
        });

      dispatch(userSlice.actions.getBusinessOrdersSuccess(response.data.data));
      console.log('orders data --------->', response.data.data)
    } catch (error) {

      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}
export function updateOrderStatus(data) {
  console.log('update order Status is called from redux and received data', JSON.stringify(data))
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    console.log(accessToken)
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('business/order/status',
        data,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
          }
        });
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
        return
      }
      //console.log(JSON.stringify(response.data))
      dispatch(userSlice.actions.stopLoading());
      dispatch(getBusinessOrders());
    } catch (error) {
      dispatch(userSlice.actions.stopLoading());
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}
export function deleteOrder(id) {
  console.log('update order Status is called from redux and received data', JSON.stringify(id))
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    console.log(accessToken)
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.delete('business/order/' + id,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
          }
        });
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
        return
      }
      //console.log(JSON.stringify(response.data))
      dispatch(userSlice.actions.stopLoading());
      dispatch(getBusinessOrders());
    } catch (error) {
      dispatch(userSlice.actions.stopLoading());
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}
export function getBusinessLoyalties() {
  dispatch(userSlice.actions.hasGetError(''));
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.get('business/loyalties', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      console.log('loyalties response-------', response.data.data)
      dispatch(userSlice.actions.getBusinessLoyaltiesSuccess(response.data.data));
    } catch (error) {

      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}
export function getRecentImagesList() {
  dispatch(userSlice.actions.hasGetError(''));
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.get('business/campaigns/recent/images', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      console.log('recent images list response-------', response.data.data)
      dispatch(userSlice.actions.getRecentImagesListSuccess(response.data.data));
    } catch (error) {

      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}
export function createBusinessLoayalty(data, handleResponse) {
  dispatch(userSlice.actions.hasGetError(''));
  console.log('create loyalty function is called from redux and received this data ' + JSON.stringify(data))
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('business/loyalty/create',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
        return
      }
      //console.log(JSON.stringify(response.data))
      handleResponse(response.data.data);

    } catch (error) {
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}
export function getBusinessRewards() {
  dispatch(userSlice.actions.hasGetError(''));
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.get('business/rewards', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      console.log('loyalties response-------', response.data.data)
      dispatch(userSlice.actions.getBusinessRewardsSuccess(response.data.data));
    } catch (error) {

      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function getBusinessRewardDetails(id , handleResponse) {
  dispatch(userSlice.actions.hasGetError(''));
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.get('business/rewards/'+id, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      console.log('loyalties response-------', response.data.data)
      handleResponse(response.data.data);
    } catch (error) {

      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function createBusinessReward(data, handleResponse) {
  dispatch(userSlice.actions.hasGetError(''));
  console.log('create reward function is called from redux and received this data ' + JSON.stringify(data))
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('business/rewards',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
        return
      }
      //console.log(JSON.stringify(response.data))
      handleResponse(response.data.data);

    } catch (error) {
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function updateBusinessReward(id , data, handleResponse) {
  dispatch(userSlice.actions.hasGetError(''));
  console.log('create reward function is called from redux and received this data ' + JSON.stringify(data))
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.patch('business/rewards/'+id,
        data,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
          }
        });
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
        return
      }
      //console.log(JSON.stringify(response.data))
      handleResponse(response.data.data);

    } catch (error) {
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}
export function getBusinessCampaings() {
  dispatch(userSlice.actions.hasGetError(''));
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.get('business/campaigns', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });

      dispatch(userSlice.actions.getBusinessCampaignsSuccess(response.data.data));
      console.log(response.data, ' business campaign response`')
    } catch (error) {

      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}
export function getCampaignDetails(id) {
  dispatch(userSlice.actions.hasGetError(''));
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.get('business/campaign/' + id,
        // data,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
          }
        });

      dispatch(userSlice.actions.getcampaignDetailsSuccess(response.data.data));
      console.log('campaign details data --------->', response.data.data)
    } catch (error) {

      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}
export function getLoyaltyDetails(id) {
  dispatch(userSlice.actions.hasGetError(''));
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.get('business/loyalty/' + id,
        // data,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
          }
        });

      dispatch(userSlice.actions.getLoyaltyDetailsSuccess(response.data.data));
      console.log('campaign details data --------->', response.data.data)
    } catch (error) {

      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}
export function createBusinessCampaign(data, handleResponse) {
  dispatch(userSlice.actions.hasGetError(''));
  console.log('create campaign function is called from redux and received this data ' + JSON.stringify(data))
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('business/campaign/create',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
        return
      }
      //console.log(JSON.stringify(response.data))
      handleResponse(response.data.data);
      dispatch(getUser())
    } catch (error) {
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function uploadCampaignGalleryImage(data, handleResponse) {
  dispatch(userSlice.actions.hasGetError(''));
  console.log('upload campaign gallery image function is called from redux and received this data ' + JSON.stringify(data))
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('business/campaigns/recent/images',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
        return
      }
      handleResponse(response.data.data)
      //console.log(JSON.stringify(response.data))
      dispatch(getRecentImagesList())

    } catch (error) {
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function setSumitCamgpaingState(data) {
  console.log(data, 'submit state setter is called from redux')
  return async () => {
    data && await dispatch(userSlice.actions.setSubmitCampaign(data));
  }
}

export function emptySumitCamgpaingState() {
  console.log('submit state setter is called from redux')
  return async () => {
    await dispatch(userSlice.actions.emptySubmitCampaign({}));
  }
}

export function updateCampaignApprovalStatus(data) {
  dispatch(userSlice.actions.hasGetError(''));
  console.log('update campaing approval status function is called from redux and received this data ' + JSON.stringify(data))
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('business/campaign/approval-status',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
        return
      }
      //console.log(JSON.stringify(response.data))
      dispatch(userSlice.actions.stopLoading());
      dispatch(getBusinessCampaings());
      dispatch(getUser());

    } catch (error) {
      dispatch(userSlice.actions.stopLoading());
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function updateCampaignStatus(data) {
  dispatch(userSlice.actions.hasGetError(''));
  console.log('update campaing status function is called from redux and received this data ' + JSON.stringify(data))
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('business/campaign/status',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
        return
      }
      //console.log(JSON.stringify(response.data))
      dispatch(userSlice.actions.stopLoading());
      dispatch(getBusinessCampaings());
      dispatch(getUser());

    } catch (error) {
      dispatch(userSlice.actions.stopLoading());
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function getBusinessDevices() {
  dispatch(userSlice.actions.hasGetError(''));
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.get('devices', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });

      dispatch(userSlice.actions.getBusinessDevicesSuccess(response.data.data));
      console.log(response.data, ' business devices response')
    } catch (error) {

      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function createBusinessDeviceIssue(data, handleResponse) {
  dispatch(userSlice.actions.hasGetError(''));
  console.log('create device issue function is called from redux and received this data ' + JSON.stringify(data))
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('device/problem/create',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
        return
      }
      //console.log(JSON.stringify(response.data))
      dispatch(userSlice.actions.stopLoading());
      handleResponse(response.data.data);

    } catch (error) {
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}
export function getBusinessSurveys() {
  dispatch(userSlice.actions.hasGetError(''));
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.get('business/surveys', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      const userDetail = JSON.parse(localStorage.getItem('userDetails'))
      if (userDetail?.role == 'admin') {
        dispatch(userSlice.actions.getBusinessSurveySuccess(response.data.data));
        return
      }
      dispatch(userSlice.actions.getBusinessSurveySuccess(response.data.data?.my_surveys));
      dispatch(userSlice.actions.getActivatedQTs(response.data.data?.activated_qt));
      dispatch(userSlice.actions.getQTs(response.data.data?.qts));
      dispatch(userSlice.actions.getSurveyLimitSuccess(response.data.data?.surveyLimit));
      dispatch(userSlice.actions.getFeedbackReachesSuccess(response.data.data?.feedbackReaches));
      dispatch(userSlice.actions.getFeedbacksSuccess(response.data.data?.feedbacks));
      console.log(response.data, ' business surveys response')
    } catch (error) {

      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function deleteSurvey(id) {
  console.log('delete survey is called from redux and received data', JSON.stringify(id))
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    console.log(accessToken)
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.delete('business/survey/' + id,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
          }
        });
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
        return
      }
      //console.log(JSON.stringify(response.data))
      dispatch(userSlice.actions.stopLoading());
      dispatch(getBusinessSurveys());
    } catch (error) {
      dispatch(userSlice.actions.stopLoading());
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}
export function getOtherOptionAnswers(data) {
  dispatch(userSlice.actions.hasGetError(''));
  console.log('get other option answers function is called from redux and received this data ' + JSON.stringify(data))
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('business/survey/other-option-answers',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
        return
      }
      //console.log(JSON.stringify(response.data))
      dispatch(userSlice.actions.stopLoading());
      dispatch(userSlice.actions.getOtherOptionAnswersSuccess(response.data.data));


    } catch (error) {
      dispatch(userSlice.actions.stopLoading());
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function createBusinessSurvey(data, handleResponse) {
  dispatch(userSlice.actions.hasGetError(''));
  console.log('create business survey function is called from redux and received this data ' + JSON.stringify(data))
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('business/survey/create',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
        return
      }
      //console.log(JSON.stringify(response.data))
      dispatch(userSlice.actions.stopLoading());
      handleResponse(response.data.data);

    } catch (error) {
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function updateSurveyApprovalStatus(data) {
  dispatch(userSlice.actions.hasGetError(''));
  console.log('update survey approval status function is called from redux and received this data ' + JSON.stringify(data))
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('business/survey/update-approval-status',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
        return
      }
      //console.log(JSON.stringify(response.data))
      dispatch(userSlice.actions.stopLoading());
      dispatch(getBusinessSurveys());
      dispatch(getUser());

    } catch (error) {
      dispatch(userSlice.actions.stopLoading());
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function getBusinessAutomations() {
  dispatch(userSlice.actions.hasGetError(''));
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.get('business/automations', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });

      dispatch(userSlice.actions.getBusinessAutomationSuccess(response.data.data));
      console.log(response.data, ' automaitons response surveys response')
    } catch (error) {

      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function createBusinessAutomation(data, handleResponse) {
  dispatch(userSlice.actions.hasGetError(''));
  console.log('create business automation function is called from redux and received this data ' + JSON.stringify(data))
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('business/automation/create',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
        return
      }
      //console.log(JSON.stringify(response.data))
      dispatch(userSlice.actions.stopLoading());
      handleResponse(response.data.data)
    } catch (error) {
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}
export function updateGetFeedback(data) {
  dispatch(userSlice.actions.hasGetError(''));
  console.log('update Get Feedback function is called from redux and received this data ' + JSON.stringify(data))
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('business/feedback',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
        return
      }
      //console.log(JSON.stringify(response.data))
      dispatch(userSlice.actions.stopLoading());
      dispatch(getBusinessSurveys());
      dispatch(getUser());

    } catch (error) {
      dispatch(userSlice.actions.stopLoading());
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function updateGetGoogleReview(data) {
  dispatch(userSlice.actions.hasGetError(''));
  console.log('update Get Google Review function is called from redux and received this data ' + JSON.stringify(data))
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('business/google/review',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
        return
      }
      //console.log(JSON.stringify(response.data))
      dispatch(userSlice.actions.stopLoading());
      dispatch(getBusinessSurveys());
      dispatch(getUser());

    } catch (error) {
      dispatch(userSlice.actions.stopLoading());
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function getBusinessesList() {
  dispatch(userSlice.actions.hasGetError(''));
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.get('businesses', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      console.log('business list api response', response.data)
      dispatch(userSlice.actions.getBusinessesSuccess(response.data.data));


    } catch (error) {

      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function getUsersList() {
  dispatch(userSlice.actions.hasGetError(''));
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.get('users', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });

      dispatch(userSlice.actions.getUsersSuccess(response.data.data));

    } catch (error) {

      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function createDevice(data, handleResponse) {
  dispatch(userSlice.actions.hasGetError(''));
  console.log('create device function is called from redux and received this data ' + JSON.stringify(data))
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('device/create',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
        return
      }
      //console.log(JSON.stringify(response.data))
      dispatch(userSlice.actions.stopLoading());
      handleResponse(response.data.data);

    } catch (error) {
      dispatch(userSlice.actions.stopLoading());
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function updateDevice(data, handleResponse) {
  dispatch(userSlice.actions.hasGetError(''));
  console.log('update device survey function is called from redux and received this data ' + JSON.stringify(data))
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('device/update',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
        return
      }
      //console.log(JSON.stringify(response.data))
      dispatch(userSlice.actions.stopLoading());
      handleResponse(response.data);

    } catch (error) {
      dispatch(userSlice.actions.stopLoading());
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function linkDevice(data) {
  dispatch(userSlice.actions.hasGetError(''));
  console.log('link device survey function is called from redux and received this data ' + JSON.stringify(data))
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('devices/link-square-locations',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
        return
      }
      //console.log(JSON.stringify(response.data))
      dispatch(userSlice.actions.stopLoading());
      dispatch(getBusinessDevices())
    } catch (error) {
      dispatch(userSlice.actions.stopLoading());
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}
export function deleteDevices(data) {
  dispatch(userSlice.actions.hasGetError(''));
  console.log('delete device function is called from redux and received this data ' + JSON.stringify(data))
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('devices/delete',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
        return
      }
      //console.log(JSON.stringify(response.data))
      dispatch(userSlice.actions.stopLoading());
      dispatch(getBusinessDevices());
      dispatch(getBusinessesList());

    } catch (error) {
      dispatch(userSlice.actions.stopLoading());
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}
export function updateSurveyStatus(data) {
  dispatch(userSlice.actions.hasGetError(''));
  console.log('update survey status function is called from redux and received this data ' + JSON.stringify(data))
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('business/survey/update-status',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
        return
      }
      //console.log(JSON.stringify(response.data))
      dispatch(userSlice.actions.stopLoading());
      dispatch(getBusinessSurveys());
      dispatch(getUser());

    } catch (error) {
      dispatch(userSlice.actions.stopLoading());
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function updateSurveyRetarget(data) {
  dispatch(userSlice.actions.hasGetError(''));
  console.log('update survey retarget function is called from redux and received this data ' + JSON.stringify(data))
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('business/survey/update-retarget',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
        return
      }
      //console.log(JSON.stringify(response.data))
      dispatch(userSlice.actions.stopLoading());
      dispatch(getBusinessSurveys());
      dispatch(getUser());

    } catch (error) {
      dispatch(userSlice.actions.stopLoading());
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function updateLoyaltyStatus(data) {
  dispatch(userSlice.actions.hasGetError(''));
  console.log('update loyalty status function is called from redux and received this data ' + JSON.stringify(data))
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('business/loyalty/update-status',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
        return
      }
      //console.log(JSON.stringify(response.data))
      dispatch(userSlice.actions.stopLoading());
      dispatch(getBusinessLoyalties());
      dispatch(getUser());

    } catch (error) {
      dispatch(userSlice.actions.stopLoading());
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function activateDeactivateQT(data) {
  dispatch(userSlice.actions.hasGetError(''));
  console.log('activateDeactivateQt function is called from redux and received this data ' + JSON.stringify(data))
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('business/activate-deactivate/qt',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
        return
      }
      //console.log(JSON.stringify(response.data))
      dispatch(userSlice.actions.stopLoading());
      dispatch(getBusinessSurveys());
      dispatch(getUser());

    } catch (error) {
      dispatch(userSlice.actions.stopLoading());
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function getPriceDetails(priceId) {
  dispatch(userSlice.actions.hasGetError(''));
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.get('stripe/price/' + priceId, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      console.log(response.data.prices);
      dispatch(userSlice.actions.getPriceDetialsSuccess(response.data.data));
      dispatch(userSlice.actions.getAllPricesSuccess(response.data.prices.data));

    } catch (error) {

      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function getSubscriptionDetails() {
  dispatch(userSlice.actions.hasGetError(''));
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.get('stripe/subscription/details', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      console.log(response.data.prices);
      dispatch(userSlice.actions.getSubscriptionDetialsSuccess(response.data.data));

    } catch (error) {

      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function createCheckout(data, handleResponse) {
  dispatch(userSlice.actions.hasGetError(''));
  console.log('create checkout function is called from redux and received this data ' + JSON.stringify(data))
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('stripe/create-checkout-session',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
        return
      }
      dispatch(userSlice.actions.stopLoading());
      handleResponse(response.data.data);
    } catch (error) {
      dispatch(userSlice.actions.stopLoading());
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function getCustomerSecret(handleResponse) {
  dispatch(userSlice.actions.hasGetError(''));
  console.log('get cusomter seceret function is called from redux ')
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.get('stripe/create-customer-session',
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + accessToken
          }
        });
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
        return
      }
      dispatch(userSlice.actions.stopLoading());
      handleResponse(response.data.data);
      dispatch(userSlice.actions.getUserDetailsSuccess(response.data.data));

    } catch (error) {
      dispatch(userSlice.actions.stopLoading());
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function seTgetErrorNull() {
  return async () => {
    dispatch(userSlice.actions.hasGetError(null));
  }
}

//Blogs section 

export function getAllBlogs() {
  dispatch(userSlice.actions.hasGetError(''));
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.get('blogs', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });

      dispatch(userSlice.actions.getBlogsSuccess(response.data.data));
      console.log(response.data, ' blogs response`')
    } catch (error) {

      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function createBlog(data, handleResponse) {
  dispatch(userSlice.actions.hasGetError(''));
  console.log('create blog function is called from redux and received this data ' + JSON.stringify(data))
  return async () => {
    let accessToken = localStorage.getItem("userToken");
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('blogs',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
        return
      }
      //console.log(JSON.stringify(response.data))
      dispatch(userSlice.actions.stopLoading());
      handleResponse(response.data);

    } catch (error) {
      dispatch(userSlice.actions.stopLoading());
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}
