import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Col, Container, Row } from 'reactstrap';
import logoTwo from '../../assets/imges/Logo.png';
import '../../style/businesLogin.scss';
import { useDispatch, useSelector } from 'react-redux';
import { seTgetErrorNull, updateBusinessProfile } from '../../redux/slices/userSlice';
const WellcomeOne = () => {
    const dispatch = useDispatch();
    const { isLoading, getError, userDetail } = useSelector((state) => state.user)
    const navigate = useNavigate();
    useEffect(() => {
        if (getError && getError.length > 1) {
            toast.error(getError, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            dispatch(seTgetErrorNull());
        }
    }, [getError])
    return (
        <>
            <Container fluid className=' inter' >
                <Row className='min-h-screen flex flex-col ' style={{ backgroundColor: '#ffffff' }}>

                    <div className=' p-3 flex flex-row justify-between ' >
                        <img src={logoTwo} alt="w8" className=' ' width="150" />
                        <button
                            onClick={() => {
                                localStorage.clear();
                                navigate('/')
                            }}
                            type="button"
                            className="px-3 p-2  border-0 rounded-lg text-black font-semibold"
                        >Logout
                        </button>
                    </div>

                    <Col lg={7} md={12} sm={12} xs={12} className='align-items-center justify-content-center d-flex  my-2 mx-auto !grow' >
                        <Container fluid >
                            <Row className='justify-content-center'>
                                <Col lg={9} md={9} sm={12} xs={12} >
                                    <div>
                                        <div className='inter '>
                                            <p className="font-14 font-semibold text-black  mb-1">TimeLink | Get started</p>
                                            <h3 className=" fw-bold  text-black ">Hi, <span className='text-blue-600'>{userDetail?.firstname ? userDetail?.firstname : '' + ' ' + userDetail?.lastname ? userDetail?.lastname : ''}</span> Let’s get your account set up. </h3>
                                            <Link to="/onboarding/number" className='mt-3'>
                                                <button type='button' className='px-3 p-2   border-[1.5px] rounded-lg text-black font-semibold'>Continue</button>
                                            </Link>

                                        </div>
                                    </div>


                                </Col>
                            </Row>
                        </Container>
                    </Col>

                </Row>
                <ToastContainer />
            </Container>
        </>
    );
}

export default WellcomeOne;