import React from 'react'
import {
    Button,
} from "@mui/material";
import { Input } from "reactstrap";
import Setting from "../../../assets/imges/DeviceTableSetting.png";
import link from "../../../assets/imges/DeviceTableLink.png";
import Toggle from "../../../assets/imges/DeviceTableToggle.png";
import DropDots from "../../../assets/imges/DeviceTableDropdown.png";
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { updateCampaignApprovalStatus } from '../../../redux/slices/userSlice';
export default function MarketingListItem({ currentItems }) {
    const dispatch = useDispatch();
    function handleUpdateApprovalStatus(id , status){
        var data = {
            campaign_id : id, 
            approval_status : status
        }
        dispatch(updateCampaignApprovalStatus(data));
    }
    return (

        <>
            {currentItems.map((index) => {
                return (
                    <div className="d-flex justify-content-between flex-wrap p-2 bg-white rounded-4 my-2" style={{ boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.10)', border: '1.5px solid rgba(255, 153, 0, 1)' }}>




                        <div className="inter d-flex align-items-center" style={{ whiteSpace: 'nowrap' }}>
                            <div className="px-3">
                                <Input type="checkbox"
                                    style={{ transform: 'scale(1.3)', borderColor: '#C8C3C9', borderWidth: '1.5' }} />
                            </div>
                            <div>
                                <h3 className="font-18 " style={{ color: 'rgba(74, 74, 74, 1)' }}>{index?.business?.business_name}</h3>
                                <h3 className="font-16" style={{ color: 'rgba(83, 83, 83, 1)' }}>{index?.business?.email}</h3>
                                <h3 className="font-10" style={{ color: 'rgba(83, 83, 83, 1)' }}>Created: {moment(index?.created_at).format('DD MMM YYYY')}</h3>
                            </div>
                        </div>

                        <div className="d-flex align-items-center">

                            <Button className={`${index.approval_status == 'approved'?'btn-green':index.approval_status == 'rejected'?'btn-red':'btn-orange'} poppins px-4 me-2  rounded-5 font-12 fw-semibold text-capitalize`} style={{ whiteSpace: 'nowrap' }}>{index.approval_status}</Button>
                        </div>

                        <div
                            className="d-flex align-items-center  ps-3 my-2 py-2"
                            style={{ borderLeft: "2px solid rgba(200, 195, 201, 1) ", whiteSpace: 'nowrap' }}
                        >
                            <div>
                                <div className=" form-switch black-switch d-flex text-start  ps-0">
                                    {/* <MdToggleOn className='grey' size={28} /> */}
                                    <img src={Toggle} width={22} height={22} alt="w8" />

                                    <p className="mb-0 font-12 fw-medium text-uppercase ms-2" style={{ color: 'rgba(170, 170, 170, 1)' }}>{index.offer_name}</p>
                                </div>
                                <div className="d-flex  mt-2 text-start">
                                    {/* <FaHandHoldingUsd size={28} className='grey' /> */}
                                    <img src={link} width={22} height={22} alt="w8" />
                                    <p className="mb-0 font-12 fw-medium  ms-2" style={{ color: 'rgba(170, 170, 170, 1)' }}>{index.offer_heading}</p>
                                </div>
                                <div className="d-flex  mt-2 text-start">
                                    {/* <AiFillSetting size={28} className='grey' /> */}
                                    <img src={Setting} width={22} height={22} alt="w8" />

                                    <p className="mb-0 font-12 fw-medium  ms-2" style={{ color: 'rgba(170, 170, 170, 1)' }}>Terms & Conditions apply.  </p>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center ms-2">

                            <Button className='btn-white-borderTwo text-capitalize fw-bold rounded-5 px-2 p-1  font-14' sx={{ minWidth: '100px' }}>Detail</Button>

                            <div className="ms-3 d-flex flex-column">
                                <Button className='btn-blue-border text-capitalize fw-bold rounded-5 px-2 p-1  font-15' sx={{ minWidth: '110px' }} onClick={()=>{handleUpdateApprovalStatus(index.id , 'rejected')}}>Reject</Button>
                                <Button className='btn-green-border mt-2 text-capitalize fw-bold rounded-5 px-2 p-1  font-15' sx={{ minWidth: '110px' }} onClick={()=>{handleUpdateApprovalStatus(index.id , 'approved')}}>Approve</Button>
                            </div>
                        </div>
                        <div className="d-flex align-items-center">


                            <div className="dropdown">
                                <img
                                    src={DropDots}
                                    className="mx-1 dropdown-toggle"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    width={35}
                                    height={35}
                                    alt=""
                                />

                                <ul className="dropdown-menu mt-4">
                                    <li>
                                        <span className="dropdown-item font-14 fw-medium" >
                                            Edit
                                        </span>
                                    </li>
                                    <li>
                                        <span className="dropdown-item font-14 fw-medium" >
                                            Duplicate
                                        </span>
                                    </li>
                                    <li>
                                        <span className="dropdown-item font-14 fw-medium" >
                                            Reassign
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                )
            })}

        </>

    )
}
