import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import SideBar from "../components/SideBar";
import "../style/index.scss";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Link, useNavigate } from "react-router-dom";
import { Pagination, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getBusinessOrders, seTgetErrorNull } from "../redux/slices/userSlice";
import AllTableData from "../components/AllTableData";
import OpenTableData from "../components/OpenTableData";
import PendingTableData from "../components/PendingTableData";
import CompleteTableData from "../components/CompleteTableData";
import { LuRefreshCcw } from "react-icons/lu";

const Order = () => {
  const dispatch = useDispatch();
  const { isLoading, userDetail, getError, businessOrders } = useSelector(
    (state) => state.user
  );
  const percentage = 30;
  const [searhId, setSearchId] = useState();
  const [filteredOrders, setFilteredOrders] = useState();
  const childFunc = React.useRef(null);
  useEffect(() => {
    if (getError && getError.length > 1) {
      toast.error(getError, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(seTgetErrorNull());

    }
  }, [getError]);
  useEffect(() => {
    dispatch(getBusinessOrders());
  }, []);
  useEffect(() => {
    if (searhId) {
      console.log(searhId, "----------search id");
      searchOrders(searhId);
    } else if (!searhId || searhId.length < 1) {
      setFilteredOrders(undefined);
    }
  }, [searhId]);

  function searchOrders(value) {
    setFilteredOrders(
      businessOrders?.user?.orders.filter((obj) =>
        obj.order_id.toString().includes(value)
      )
    );
  }

  useEffect(() => {
    if (filteredOrders) {
      console.log(filteredOrders);
    }
  }, [filteredOrders]);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    if (Object.keys(userDetail).length > 0) {
      if (!userDetail?.business_name) {
        navigate("/profile/business-name");
      } else if (!userDetail?.abn) {
        navigate("/profile/abn");
      } else if (!userDetail?.image) {
        navigate("/profile/logo");
      } else if (!userDetail?.business_category) {
        navigate("/profile/category");
      }
    }
  }, [userDetail]);
  return (
    <>
      <div className="container-fluid business_admin_main">
        <div className="row order  ">
          {/* <!-- ===========SID-BAR======= --> */}
          <SideBar />
          {/* <!-- ========= content body ======= --> */}

          <div className="w-lg-80 px-0 h-screen overflow-y-scroll">
            <div className="container-fluid">
              {/* <!-- ===========HEADER========== --> */}
              <Header PageTitle="Orders" />
              <div className="px-lg-4 px-md-3 px-2">
                <div className="d-flex  justify-content-between py-3 align-content-center ">
                  <h5 className="font-20  mb-0">Orders</h5>
                  <Link to="/order/status" className="text-decoration-none ">
                    <span className="font-16 colorgray me-1">
                      Go to Order Stats{" "}
                      <i className="fa-solid fa-arrow-right " />
                    </span>
                  </Link>
                </div>
                <div className="row justify-content-center  ">
                  <div className="col-lg-4 col-md-6 col-12  mt-lg-0 mt-md-3 mt-3 ">
                    <div className="trip-mycard p-3 h-100  d-flex align-items-end">
                      <div className="d-flex justify-content-between w-100">
                        <div className="align-self-center">
                          <h4 className=" font-20 fw-medium">
                            Repeat <br /> customer Rate
                          </h4>
                          <span className=" fw-normal  font-12 px-2 p-1 btn-blue rounded-5">
                            <i className="fa-solid fa-arrow-up me-1" />
                            +34% today{" "}
                          </span>
                        </div>
                        <div>
                          {/* <div className="circular-progress" data-inner-circle-color="white" data-percentage={30} data-progress-color="#1d59f9" data-bg-color="#bbcdfd">
                                                    <div className="inner-circle" />
                                                    <h6 className="percentage mb-0  font-20 fw-medium text-blue">0%</h6>
                                                </div> */}
                          <div className="order-progress-bar">
                            <CircularProgressbar
                              value={
                                businessOrders?.homeScreenData
                                  ?.repeatCustomerRate || 0
                              }
                              text={`${businessOrders?.homeScreenData
                                  ?.repeatCustomerRate || 0
                                }%`}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12 mt-lg-0 mt-md-3 mt-3 ">
                    <div className="trip-mycard p-3 h-100  d-flex align-items-end">
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <div className="align-self-center">
                          <h4 className=" font-20 fw-medium">
                            Average
                            <br /> wait-time
                          </h4>
                          <span className=" fw-normal  font-12 px-2 p-1 btn-blue rounded-5">
                            <i className="fa-solid fa-arrow-up me-1" />
                            +10.40 sec
                          </span>
                        </div>
                        <div className="align-items-center">
                          <h6 className="montserrat  fw-bold text-blue">
                            {businessOrders?.homeScreenData?.averageWaitTime}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12 mt-lg-0 mt-md-3 mt-3  ">
                    <div className="trip-mycard h-100 p-3  d-flex align-items-end">
                      <div className="d-flex justify-content-between align-items-start w-100 mt-4">
                        <div className="align-self-center">
                          <h4 className=" font-20 fw-medium">Total Orders</h4>
                          <span className=" fw-normal  font-12 px-2 p-1 btn-blue rounded-5">
                            <i className="fa-solid fa-arrow-up me-1" />
                            +34%
                          </span>
                        </div>
                        <div className>
                          <h6 className="montserrat  fw-bold text-blue">
                            {businessOrders?.user?.orders?.length}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-between  mt-3">
                  <div className=" col-xxl-4 col-lg-5  col-12 mt-lg-0 mt-md-3 mt-3  tab-nav-pills ">
                    <ul
                      className="nav nav-pills mt-3 justify-content-between   "
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active text-uppercase"
                          id="pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-home"
                          type="button"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                        >
                          All
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link text-uppercase"
                          id="pills-profile-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-profile"
                          type="button"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="false"
                        >
                          Open
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link text-uppercase"
                          id="pills-contact-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-contact"
                          type="button"
                          role="tab"
                          aria-controls="pills-contact"
                          aria-selected="false"
                        >
                          pending
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link text-uppercase"
                          id="pills-disabled-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-disabled"
                          type="button"
                          role="tab"
                          aria-controls="pills-disabled"
                          aria-selected="false"
                        >
                          completed
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="col-xxl-4 col-lg-6  col-12 mt-lg-0 mt-md-3 mt-3">
                    <div className="row ">
                      <div className="col-lg-3 col-12 mt-lg-0 mt-md-3 mt-3 pe-lg-0">
                        <div className="input-group  inputgroup rounded-3 p-1">
                          <span
                            className="input-group-text border-0"
                            style={{ backgroundColor: "transparent" }}
                            id="basic-addon1"
                          >
                            <i className="fa-solid fa-sliders" />
                          </span>
                          <input
                            type="text"
                            className="form-control border-0"
                            placeholder="Filter"
                            style={{ backgroundColor: "transparent" }}
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                          />
                        </div>
                      </div>
                      <div
                        className="col-lg-3 col-12 mt-lg-0 mt-md-3 mt-3 pe-lg-0 "
                        onClick={() => {
                          dispatch(getBusinessOrders());
                          setFilteredOrders(undefined);
                        }}
                      >
                        <div className="input-group  inputgroup rounded-3 py-1 px-1 align-items-center cursor-pointer">
                          <span
                            className="input-group-text border-0"
                            style={{ backgroundColor: "transparent" }}
                            id="basic-addon1"
                          >
                            <LuRefreshCcw size={25} color="black" />
                          </span>
                          <span>Reset</span>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 mt-lg-0 mt-md-3 mt-3 text-end">
                        <div className="input-group  inputgroup rounded-3 p-1">
                          <input
                            type="text"
                            className="form-control border-0"
                            placeholder="Search Orders"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                            onChange={(e) => {
                              setSearchId(e.target.value);
                            }}
                          />
                          <span
                            className="input-group-text border-0"
                            style={{ backgroundColor: "transparent" }}
                            id="basic-addon2"
                          >
                            {" "}
                            <i className="fa-solid fa-magnifying-glass" />{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-content order-table Surveys"
                  id="pills-tabContent"
                >
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                    tabIndex={0}
                  >
                    <div className="surveys order-rdt-table mt-4 py-2">
                      {/* {businessOrders.length > 0 && <AllTableData businessOrders={businessOrders} />} */}
                      <OpenTableData
                        childFunc={childFunc}
                        data={
                          filteredOrders
                            ? filteredOrders
                            : businessOrders?.user?.orders
                        }
                      />
                    </div>
                  </div>
                  <div
                    className="tab-pane fade "
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                    tabIndex={0}
                  >
                    <div className="surveys order-rdt-table mt-4 py-2">
                      <OpenTableData
                        childFunc={childFunc}
                        data={
                          filteredOrders
                            ? filteredOrders
                            : businessOrders?.openOrdersList
                        }
                        status={"Open"}
                      />
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="pills-contact"
                    role="tabpanel"
                    aria-labelledby="pills-contact-tab"
                    tabIndex={0}
                  >
                    <div className="surveys order-rdt-table mt-4 py-2">
                      {/* <PendingTableData /> */}
                      <OpenTableData
                        childFunc={childFunc}
                        data={
                          filteredOrders
                            ? filteredOrders
                            : businessOrders?.pendingOrdersList
                        }
                        status={"Pending"}
                      />
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-disabled"
                    role="tabpanel"
                    aria-labelledby="pills-disabled-tab"
                    tabIndex={0}
                  >
                    <div className="surveys order-rdt-table mt-4 py-2">
                      {/* <CompleteTableData /> */}
                      <OpenTableData
                        childFunc={childFunc}
                        data={
                          filteredOrders
                            ? filteredOrders
                            : businessOrders?.completedOrdersList
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default Order;
