/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import SideBar from "../../components/SideBar";
import { FaPlus, FaRegCircle } from "react-icons/fa";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import SurveyTable from "../../components/SurveyTable";
import "../../style/admin/survey.scss";
import SurveyTableTemplate from "../../components/SurveyTableTemplate";
import { Modal } from "react-bootstrap";
import SurveysCard from "../../components/SurveysCard";
import notification from "../../assets/imges/noti.png";
import { FaAngleDown } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import {
  createBusinessSurvey,
  getBusinessSurveys,
  seTgetErrorNull,
  updateGetFeedback,
  updateGetGoogleReview,
} from "../../redux/slices/userSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdDelete } from "react-icons/md";
import { Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import { GrFormClose } from "react-icons/gr";
import moment from "moment";
import { GoTriangleDown } from "react-icons/go";
const Surveys = () => {
  const dispatch = useDispatch();
  const {
    isLoading,
    getError,
    activatedQTs,
    qTs,
    businessSurveys,
    userDetail,
    surveyLimit,
    feedbackReaches,
    feedbacks,
  } = useSelector((state) => state.user);
  const [showModal, setShowModal] = useState(false);
  const [googleReview, setGoogleReview] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [showModalSecond, setShowModalSecond] = useState(false);
  const [showModalQuestion, setShowModalQuestion] = useState(false);
  const [questionName, setQuestionName] = useState();
  const [questionOne, setQuestionOne] = useState();

  const [fields, setFields] = useState([""]); // Initial input field
  const [question, setQuestion] = useState();
  const [minMaxError, setminMaxError] = useState();
  const [emptyError, setEmptyError] = useState();
  const [errorIndex, setErrorIndex] = useState([]);
  const [showQuestionData, setShowQuestionData] = useState();
  const [showOptions, setShowOptions] = useState();
  const [btnShow, setBtnShow] = useState(false);
  const [hideAddFieldButton, setHideAddFieldButton] = useState(false);
  const [hideAddTextFieldButton, setHideTextAddFieldButton] = useState(false);
  const [googleReviewModal, setGoogleReviewModal] = useState(false);
  const [placeId, setPlaceId] = useState();
  const [linkGenerated, setLinkGenerated] = useState();
  const [link, setLink] = useState();
  const [searchSurvey, setSearchSurvey] = useState();
  const [filteredSurveys, setFilteredSurveys] = useState();
  const [surveystab, setSurveystab] = useState("quick-start-template");

  console.log(btnShow);
  useEffect(() => {
    if (questionName || questionOne || fields[0]?.length > 0) {
      setBtnShow(true);
      console.log("if btn");
    } else {
      setBtnShow(false);
      console.log("error btn");
    }
  }, [questionName, questionOne, fields]);

  const addField = () => {
    setEmptyError(false);
    setErrorIndex([]);
    setminMaxError(false);
    if (fields.length < 5) {
      if (fields.length == 4) {
        setHideAddFieldButton(true);
      }
      setFields([...fields, ""]); // Add an empty input field
    }
  };
  const handleDeleteField = (indexToRemove) => {
    if (fields[indexToRemove].is_text_field) {
      setHideTextAddFieldButton(false);
    } else {
      setHideAddFieldButton(false);
    }
    const updatedFields = fields.filter((_, index) => index !== indexToRemove);
    setFields(updatedFields);
  };

  useEffect(() => {
    console.log("options array to be sent ", fields);
  }, [fields]);

  // Function to add 2 more fields to the existing ones
  const addMultipleFields = () => {
    if (fields.some((item) => typeof item === "object")) {
      toast.error("Only one text field is allowed!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return false;
    }
    setminMaxError(false);
    setEmptyError(false);
    setErrorIndex([]);
    setHideTextAddFieldButton(true);
    if (fields.length <= 5) {
      setFields([...fields, { is_text_field: true, value: "Other" }]); // Add an empty input field
    }
  };
  // Function to handle input field value change
  const handleFieldChange = (index, value, isTextField) => {
    setminMaxError(false);
    setEmptyError(false);
    setErrorIndex([]);
    if (isTextField) {
      const updatedFields = [...fields];
      updatedFields[index].value = value;
      setFields(updatedFields);
    } else {
      const updatedFields = [...fields];
      updatedFields[index] = value;
      setFields(updatedFields);
    }
  };
  useEffect(() => {
    if (getError && getError.length > 1) {
      toast.error(getError, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
            dispatch(seTgetErrorNull());

    }
  }, [getError]);
  useEffect(() => {
    refreshSurveyList();
  }, []);
  function refreshSurveyList() {
    dispatch(getBusinessSurveys());
  }
  useEffect(() => {
    setGoogleReview(
      userDetail?.settings?.get_google_review == 1 ? true : false
    );
    setFeedback(userDetail?.settings?.get_feedback == 1 ? true : false);
  }, [userDetail]);
  function handleSurveyCreate() {
    if (!questionName || !questionOne) {
      toast.error("Question Name and Question is requried", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return false;
    }
    var options = [];
    if (fields.length >= 2 && fields.length < 8) {
      fields.forEach((value, index) => {
        if (value.length < 1 || value?.value?.length < 1) {
          setErrorIndex([...errorIndex, index]);
          setEmptyError(true);
          setFields([...fields]);
          return false;
        } else {
          if (value.is_text_field) {
            options.push({
              option_number: index + 1,
              option_value: value.value,
              is_text_field: 1,
            });
          } else {
            options.push({
              option_number: index + 1,
              option_value: value,
            });
          }
        }
      });
      console.log("options data", options);
      if (questionName && questionOne && options.length == fields.length) {
        var data = {
          q_name: questionName,
          question: questionOne,
          options,
        };
        dispatch(createBusinessSurvey(data, handleResponse));
      }
    } else {
      setminMaxError(true);
    }
  }
  function handleResponse(data) {
    setShowModal(false);
    setQuestionName(null);
    setQuestionOne(null);
    setFields([""]);
    setSurveystab("created-you");
    dispatch(getBusinessSurveys());
    toast.success("Survey Created Successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
  function handleShowQuestion(data) {
  
    setShowOptions(data);
    setShowQuestionData(data);
    setShowModalQuestion(true);
  }
  function handleUpdateFeedback() {
    if (feedback == 0) {
      if (surveyLimit >= 3) {
        toast.error(
          "You can not activate more surveys! Please inactivate one of the previous acitve surveys first.",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        return false;
      }
    }
    var data = {
      get_feedback: feedback ? 0 : 1,
    };
    dispatch(updateGetFeedback(data));
  }
  function handleUpdateGoogleReview() {
    if (googleReview == 0) {
      if (surveyLimit >= 3) {
        toast.error(
          "You can not activate more surveys! Please inactivate one of the previous acitve surveys first.",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        return false;
      }
      setGoogleReviewModal(true);
    } else {
      var data = {
        get_google_review: googleReview ? 0 : 1,
      };
      dispatch(updateGetGoogleReview(data));
      setGoogleReviewModal(false);
    }
  }
  const handleGoogleReviewModal = () => {
    if (linkGenerated && link) {
      var data = {
        get_google_review: googleReview ? 0 : 1,
        google_review_link: link,
      };
      dispatch(updateGetGoogleReview(data));
    }
    setGoogleReviewModal(false);
  };

  function handelLinkGenerate() {
    if (placeId && placeId.length > 10) {
      setLinkGenerated(true);
      setLink("http://search.google.com/local/writereview?placeid=" + placeId);
    } else {
      toast.error("Place id is either invalid or missing!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return false;
    }
  }

  useEffect(() => {
    if (searchSurvey) {
      console.log(searchSurvey, "----------search id");
      searchSurveys(searchSurvey);
    } else if (!searchSurvey || searchSurvey.length < 1) {
      setFilteredSurveys(undefined);
    }
  }, [searchSurvey]);

  function searchSurveys(value) {
    setFilteredSurveys(
      businessSurveys?.filter((obj) => obj.question.toString().includes(value))
    );
  }
  useEffect(() => {
    if (filteredSurveys) {
      console.log(filteredSurveys);
    }
  }, [filteredSurveys]);

  const [status, setStatus] = React.useState(false);
  const [statusRadio, setStatusRadio] = React.useState("All");

  return (
    <div className="business_admin_main">
      <div className="container-fluid">
        <div className="row order">
          {/* <!-- ===========SID-BAR======= --> */}
          <SideBar />
          {/* <!-- ========= content body ======= --> */}

          <div className="w-lg-80 px-0 h-screen overflow-y-scroll">
            {/* <!-- ===========HEADER========== --> */}
            <Header PageTitle="Surveys" />
            <div className="px-lg-4 px-md-3 px-2">
              <div className="container-fluid mt-4 px-0">
                <div className="row justify-content-between  mb-3">
                  <div className="col-3 position-relative">
                    <div
                      className=" stats_select rounded-5  d-flex align-items-center white-border p-2"
                      onClick={() => setStatus(!status)}
                    >
                      <p className="mb-0 me-1 text-dark-gray fw-medium ">
                        Status:
                      </p>
                      <div className="d-flex align-items-center justify-content-between w-100">
                        {statusRadio}
                        <GoTriangleDown />
                      </div>
                    </div>
                    {status && (
                      <div className="position-absolute bg-white p-2 px-3 shadow w-100 top-100 start-0 rounded-4 ">
                        <FormControl>
                          <RadioGroup
                            // defaultValue="All-time"
                            onChange={(e) => {
                              setStatusRadio(e.target.value);
                              setStatus(false);
                            }}
                            value={statusRadio}
                            name="radio-buttons-status"
                          >
                            <FormControlLabel
                              value="All"
                              control={
                                <Radio
                                  sx={{
                                    color: "#1D59F9",
                                    "&.Mui-checked": {
                                      color: "#1D59F9   ",
                                    },
                                  }}
                                />
                              }
                              label="All"
                            />
                            <FormControlLabel
                              value="Active"
                              control={
                                <Radio
                                  sx={{
                                    color: "#1D59F9",
                                    "&.Mui-checked": {
                                      color: "#1D59F9   ",
                                    },
                                  }}
                                />
                              }
                              label="Active"
                            />
                            <FormControlLabel
                              value="Inactive"
                              control={
                                <Radio
                                  sx={{
                                    color: "#1D59F9",
                                    "&.Mui-checked": {
                                      color: "#1D59F9   ",
                                    },
                                  }}
                                />
                              }
                              label="Inactive"
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    )}
                  </div>
                  <div className="col-lg-7 col-12 mt-lg-0 mt-3">
                    <div className="d-flex flex-wrap justify-content-between  align-items-center">
                      <div className="d-flex align-items-center ">
                        <label
                          className="form-check-label pe-2 font-12 inter colorblack"
                          htmlFor="flexSwitchCheckChecked "
                        >
                          Request Feedback
                        </label>
                        <div className="form-check form-switch">
                          {isLoading ? (
                            <Spinner color="blue" size={"sm"} />
                          ) : (
                            <input
                              className="form-check-input "
                              checked={feedback}
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckChecked"
                              value={feedback}
                              onChange={handleUpdateFeedback}
                            />
                          )}
                        </div>
                      </div>
                      <div className="d-flex align-items-center ">
                        <label
                          className="form-check-label pe-2 font-12 inter colorblack"
                          htmlFor="flexSwitchCheckChecked"
                        >
                          Request Google Review
                        </label>
                        <div className="form-check form-switch">
                          {isLoading ? (
                            <Spinner color="blue" size={"sm"} />
                          ) : (
                            <input
                              className="form-check-input"
                              checked={googleReview}
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckChecked"
                              value={googleReview}
                              onChange={handleUpdateGoogleReview}
                            />
                          )}
                        </div>
                      </div>
                      <Modal
                        show={googleReviewModal} centered
                        onHide={() => setGoogleReviewModal(false)}
                        className="super"
                        size="lg"
                        keyboard={false}
                      >
                        <Modal.Body>
                          <div className="w-9/12 mx-auto py-8 ">
                            <div className="">
                              <label for="place_id" className=" text-base  ">
                                Enter your Place Id*
                              </label>
                              <input
                                type="text"
                                id="place_id"
                                required
                                className="w-full rounded-xl mt-1  p-2 border-[1.5px] text-start "
                                value={placeId}
                                onChange={(e) => setPlaceId(e.target.value)}
                              />
                              <div className="mt-2 text-center">
                                <p className="text-xs text-navyBlue mb-1">
                                  Dont’t know what is you place id? Click the
                                  following Link, Search your google business
                                  name copy your place id and come back we are
                                  right here.
                                </p>
                                <div>
                                  <Link
                                    target="_blank"
                                    to={"/get/placeid"}
                                    className="m-2 text-sm text-customBlue underline-offset-2" 
                                  >
                                    Find you place Id
                                  </Link>
                                </div>
                                {linkGenerated && link && (
                                  <>
                                    <div className="mt-4">
                                      <h3 className="text-danger ">
                                        Make sure to copy and check the link
                                        before submiting.
                                      </h3>
                                      <a target="_blank" rel="noreferrer" href={link}>{link}</a>
                                    </div>
                                    <Button
                                      onClick={async () => {
                                        try {
                                          if (navigator.clipboard) {
                                            await navigator.clipboard.writeText(
                                              link
                                            );
                                            toast.success(
                                              "Copied Successfully!",
                                              {
                                                position: "top-right",
                                                autoClose: 5000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                                theme: "light",
                                              }
                                            );
                                            console.log(
                                              "Text copied to clipboard"
                                            );
                                          } else {
                                            console.error(
                                              "Clipboard API not supported"
                                            );
                                          }
                                        } catch (err) {
                                          console.error(
                                            "Unable to copy text to clipboard",
                                            err
                                          );
                                        }
                                      }}
                                      type="button"
                                      className="btn-blue mt-3 rounded-5 m-2 px-3 p-1 fw-normal font-18 text-capitalize"
                                    >
                                      Copy Link
                                    </Button>
                                  </>
                                )}
                                {linkGenerated ? (
                                  <button
                                    onClick={handleGoogleReviewModal}
                                    type="button"
                                    className="bg-customBlue text-white mt-3 rounded-full w-9/12 px-3 p-3 font-semibold text-sm mx-auto"
                                  >
                                    Submit
                                  </button>
                                ) : (
                                  <button
                                    onClick={handelLinkGenerate}
                                    type="button"
                                    className="bg-customBlue text-white mt-3 rounded-full w-9/12 px-3 p-3 font-semibold text-sm mx-auto"
                                  >
                                    Generate Link
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                      <Button
                        type="button"
                        onClick={() => {
                          // alert(surveyLimit)
                          setShowModal(true);
                        }}
                        className="btn-blue rounded-5 m-2 px-3 p-1 fw-medium font-20 text-capitalize"
                      >
                        <FaPlus size={16} className="me-2" />
                        New Survey
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container-fluid  px-0 Surveys">
                <div className="surveys">
                  <div className=" flex justify-content-between items-center pe-2">
                    <div className="flex gap-2 items-center">
                      <button
                        onClick={() => setSurveystab("quick-start-template")}
                        className={`p-3 border-b-2 font-medium ${
                          surveystab === "quick-start-template"
                            ? " border-blue-600 text-blue"
                            : "border-white"
                        }`}
                        type="button"
                      >
                        Quick-Start Template
                      </button>

                      <button
                        onClick={() => setSurveystab("created-you")}
                        className={`p-3 border-b-2  font-medium ${
                          surveystab === "created-you"
                            ? " border-blue-600 text-blue"
                            : "border-white"
                        }`}
                        type="button"
                      >
                        Created by you
                      </button>
                    </div>
                    <div className="!w-52">
                      <div className="input-group inputgroupon rounded-5 p-1 w-full ">
                        <span
                          className="input-group-text border-0 blue py-0"
                          style={{ backgroundColor: "transparent" }}
                          id="basic-addon1"
                        >
                          <i className="fa-solid fa-magnifying-glass" />
                        </span>
                        <input
                          type="text"
                          className="form-control border-0 rounded-5 blue py-0"
                          placeholder="Search Survey..."
                          value={searchSurvey}
                          onChange={(e) => {
                            setSearchSurvey(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="w-full">
                 
                    {surveystab === "created-you" && (
                      <div>
                        {isLoading ? (
                          <div className="row justify-content-center not-active-Campaigns rounded-3 text-center  h-80">
                            <div className="col-12">
                              <div className="Campaigns p-4 rounded-3">
                                <Spinner color="dark" />
                              </div>
                            </div>
                          </div>
                        ) : businessSurveys?.length < 1 ? (
                          <>
                            <div
                              className="row sms-automation p-3 survey-row-table"
                              style={{
                                borderTop: "1px solid #1D59F9",
                                borderBottom: "1px solid #1D59F9",
                                background: "rgba(29, 89, 249, 0.15)",
                                fontSize: "13px !important",
                              }}
                            >
                              <div className="col-5 ">
                                <p className="font-13 inter colorblack mb-0">
                                  Question name
                                </p>
                              </div>
                              <div className="col-7 ">
                                <div className="row">
                                  {/* <div className="col-4">
                                <p className="font-13 inter colorblack mb-0">Approval Status</p>
                            </div> */}
                                  <div className="col-3 align-self-center">
                                    <p className="font-13 inter colorblack mb-0">
                                      Retarget?
                                    </p>
                                  </div>
                                  <div className="col-3 align-self-center">
                                    <p className="font-13 inter colorblack mb-0">
                                      Status
                                    </p>
                                  </div>
                                  <div className="col-2 align-self-center"></div>
                                </div>
                              </div>
                            </div>
                            <div className="row justify-content-center align-items-center not-active-Campaigns rounded-3 text-center mt-3 h-80">
                              <div className="col-12">
                                <div className="Campaigns p-4 rounded-3">
                                  <p
                                    className="fw-medium"
                                    style={{ color: "#C8C3C9" }}
                                  >
                                    No Current Survey
                                  </p>
                                  <div className="col-12">
                                    <p className="font-10 text-center font-500 inter colorgray mb-0">
                                      All surveys are only active for 28 Days.
                                      <br />A maximum of three questions can be
                                      retargeted at once. If all retargets are
                                      turned off, then your active surveys will
                                      rotate, depending on survey preference.
                                    </p>
                                    <p />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="survey-table">
                            <SurveyTable
                              data={
                                filteredSurveys
                                  ? filteredSurveys
                                  : businessSurveys
                              }
                              handleShowQuestionData={handleShowQuestion}
                              refresh={refreshSurveyList}
                            />
                          </div>
                        )}
                      </div>
                    )}
                    {surveystab === "quick-start-template" && (
                      <div>
                        {isLoading ? (
                          <div className="row justify-content-center not-active-Campaigns rounded-3 text-center mt-3 h-80">
                            <div className="col-12">
                              <div className="Campaigns p-4 rounded-3">
                                <Spinner color="dark" />
                              </div>
                            </div>
                          </div>
                        ) : qTs?.length < 1 ? (
                          <>
                            <div
                              className="row sms-automation p-3 survey-table "
                              style={{
                                borderTop: "1px solid #1D59F9",
                                borderBottom: "1px solid #1D59F9",
                                background: "rgba(29, 89, 249, 0.15)",
                                fontSize: "13px !important",
                              }}
                            >
                              <div className="col-5 ">
                                <p className="font-13 inter colorblack mb-0">
                                  Question name
                                </p>
                              </div>
                              <div className="col-7 ">
                                <div className="row">
                                  {/* <div className="col-4">
                                <p className="font-13 inter colorblack mb-0">Approval Status</p>
                            </div> */}
                                  <div className="col-3 align-self-center">
                                    <p className="font-13 inter colorblack mb-0">
                                      Retarget?
                                    </p>
                                  </div>
                                  <div className="col-3 align-self-center">
                                    <p className="font-13 inter colorblack mb-0">
                                      Status
                                    </p>
                                  </div>
                                  <div className="col-2 align-self-center"></div>
                                </div>
                              </div>
                            </div>
                            <div className="row justify-content-center align-items-center not-active-Campaigns rounded-3 text-center mt-3 h-80">
                              <div className="col-12">
                                <div className="Campaigns p-4 rounded-3">
                                  <p
                                    className="fw-medium"
                                    style={{ color: "#C8C3C9" }}
                                  >
                                    No Current Survey
                                  </p>
                                  <div className="col-12">
                                    <p className="font-10 text-center font-500 inter colorgray mb-0">
                                      All surveys are only active for 28 Days.
                                      <br />A maximum of three questions can be
                                      retargeted at once. If all retargets are
                                      turned off, then your active surveys will
                                      rotate, depending on survey preference.
                                    </p>
                                    <p />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="survey-table 	">
                            <SurveyTableTemplate
                              data={qTs}
                              handleShowQuestionData={handleShowQuestion}
                            />
                          </div>
                        )}
                      </div>
                    )}
                    {/* </div> */}
                  </div>
                </div>
              </div>
              <div className="container-fluid  px-0">
                <div className="row mt-1">
                  <div className="col-1 font-15 colorgray inter">
                    <p>{surveyLimit}/3</p>
                  </div>
                  {/* <div className="col-10">

                                    <p className="font-10 text-center font-500 inter colorgray mb-0">
                                        All surveys are only active for 28 Days.<br />
                                        A maximum of three questions can be retargeted at once. If all retargets are turned off, then your active surveys will rotate, depending on survey preference.</p>
                                    <p />
                                </div> */}
                </div>

                <div className="row mt-2 Surveys mb-2">
                  {userDetail?.settings?.first_time_turned_on == 1 && (
                    <div className="col-lg-6 col-md-6 col-12 mt-3">
                      <SurveysCard
                        feedbackData={feedbacks}
                        title={"Feedback"}
                        date={moment(userDetail?.settings?.created_at).format(
                          "DD MMM YYYY"
                        )}
                        reach={feedbackReaches}
                        complete={feedbacks?.length}
                        isFeedBack={true}
                      />
                    </div>
                  )}
                  {businessSurveys?.length > 0 &&
                    businessSurveys.map((value, index) => {
                      return (
                        <div className="col-lg-6 col-md-6 col-12 mt-3">
                          <SurveysCard
                            surveyData={value}
                            title={value?.question}
                            date={value.created_at}
                            reach={value?.reaches?.length}
                            complete={value.completes.length}
                            isQtq={false}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        size="lg"
        show={showModal}
        className="survey-page modals_scroll business_admin_main"
        centered
      >
        <Modal.Body style={{ maxHeight: "85vh", overflowY: "auto" }}>
          <form className=" px-2 pb-3 rounded-5">
            <div className="d-flex flex-row justify-content-between ">
              <h4 className="font-18 text-center ps-2 inter blue mt-3 w-100">
                New Question
              </h4>
              <span
                onClick={() => setShowModal(false)}
                className="mt-3 cursor-pointer"
              >
                <GrFormClose size={30} />
              </span>
            </div>

            <div className="mt-3">
              <div className="container-fluid">
                <div className="row mt-1 justify-content-center ">
                  <div className="col-12">
                   
                  </div>
                  <div className="col-12 mt-3">
                    <p className=" text-dark mb-1 text-start font-17  ">
                      Quesiton
                    </p>
                    <input
                      type="text"
                      value={questionOne}
                      onChange={(e) => {
                        setQuestionOne(e.target.value);
                        setQuestionName(e.target.value);
                      }}
                      className="form-control  input-field  font-17 p-3 rounded-4"
                      placeholder="Enter your question"
                    />
                    <p className=" text-gray mb-1 text-start font-10  ">
                      A maximum of 40 characters applies.
                    </p>
                  </div>
                </div>
                <div className="row justify-content-end">
                 
                  {fields.map((field, index) => (
                    <div
                      className="col-11 mt-3 d-flex align-items-center "
                      key={index}
                    >
                      <FaRegCircle size={18} className="me-2 colorPrimary" />
                      <div className="w-100 d-flex align-items-center ">
                        <input
                          className="form-control w-100  input-field  font-17 p-3 rounded-4"
                          placeholder={
                            field.is_text_field
                              ? "Enter the Name of Text field"
                              : "Enter a multiple choice"
                          }
                          readOnly={field.is_text_field ? true : false}
                          type="text"
                          value={field.is_text_field ? "Other" : field}
                          onChange={(e) =>
                            handleFieldChange(
                              index,
                              e.target.value,
                              field.is_text_field
                            )
                          }
                        />
                        {index > 0 && ( // Render delete button for all fields except the first one
                          <IconButton
                            className="ms-1"
                            aria-label="delete"
                            color="primary"
                            onClick={() => handleDeleteField(index)}
                          >
                            <MdDelete />
                          </IconButton>
                        )}
                        {minMaxError && (
                          <p
                            className=" fw-medium  mb-1 text-start font-10  "
                            style={{ color: "#FF0000" }}
                          >
                            You are required to have a minimum of two and a
                            maximum of seven.
                          </p>
                        )}
                        {emptyError && errorIndex.includes(index) && (
                          <p
                            className=" fw-medium  mb-1 text-start font-10  "
                            style={{ color: "#FF0000" }}
                          >
                            Can not be Empty please Write something.
                          </p>
                        )}
                      </div>
                    </div>
                  ))}
                  {!hideAddFieldButton && (
                    <div className="col-11 mt-3 d-flex align-items-center ">
                      <FaRegCircle size={18} className="me-2 colorPrimary" />

                      <button
                        type="button"
                        className="w-100  rounded-4 p-3 btn-blue-dashed"
                        onClick={addField}
                      >
                        + Multiple Choice
                      </button>
                    </div>
                  )}
                  {!hideAddTextFieldButton && (
                    <div className="col-11 mt-3 d-flex align-items-center ">
                      <FaRegCircle size={18} className="me-2 colorPrimary" />

                      <button
                        type="button"
                        onClick={addMultipleFields}
                        className="w-100  py-0 rounded-4 p-3 btn-blue-dashed"
                      >
                        + Multiple Choice
                        <br />
                        Other (text field)
                      </button>
                    </div>
                  )}
                  <div className="col-11 mt-3 d-flex align-items-center ">
                    <p className="font-12 mt-2 text-gray ps-3">
                      {" "}
                      A maximum of one ‘other’ limit applies. Also note where
                      selecting ‘Other’ a maximum of 5 multiple choice fields
                      apply instead of seven.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div
                className="d-flex mt-1 justify-content-center order"
                style={{ height: "auto" }}
              >
                {btnShow && (
                  <Button
                    className="btn-Rejected rounded-5  m-2 px-4 py-2 fw-bold font-20 text-lowercase"
                    onClick={() => {
                      setShowModal(false);
                    }}
                  >
                    Save and Exit
                  </Button>
                )}
                <Button
                  className={`btn-blue rounded-5 px-4 py-2 m-2 fw-bold font-20 text-capitalize ${
                    btnShow ? "" : "w-50"
                  }`}
                  onClick={handleSurveyCreate}
                >
                  {isLoading ? <Spinner /> : "Complete"}
                </Button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/* ============ MODAL ============= */}

      <Modal
        show={showModalSecond}
        className="rounded-modal text-center modals_scroll business_admin_main"
        centered
      >
        <Modal.Body style={{ maxHeight: "85vh", overflowY: "auto" }}>
          <div className="py-3 inter">
            <div>
              <img src={notification} width="50px" alt="notification" />
              <h6 className="font-24 fw-bold colorblack my-3">Great Choice!</h6>
              <p className="mt-2 fw-medium  " style={{ color: "#5E5E5E" }}>
                You’re about to use a credit to launch a new{" "}
                <br className="d-lg-block d-none" />
                Survey. Are you ready to boost your business?
              </p>
            </div>
            <div className="col-12 mt-3 mb-3 text-center d-flex align-items-center ">
              <div className="w-50 px-2">
                <button
                  onClick={() => {
                    setShowModalSecond(false);
                  }}
                  className="btn-gray rounded-4 p-2 px-4  py-3 fw-medium w-100 font-14 text-capitalize"
                  data-bs-dismiss="modal"
                >
                  Not now
                </button>
              </div>
              <div className="w-50 px-2">
                <button
                  onClick={() => {
                    setShowModalSecond(false);
                  }}
                  className="btn-blue rounded-4 p-2 px-5 py-3 fw-medium w-100 font-14 text-capitalize"
                >
                  boost!
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* ================ QUESTION NAME ========= */}
      <Modal
        show={showModalQuestion}
        centered
        className="survey-page modals_scroll business_admin_main"
      >
        <Modal.Body style={{ maxHeight: "85vh", overflowY: "auto" }}>
          <form className="row justify-content-center rounded-4 px-2 pb-3">
            <div className="d-flex flex-row justify-content-between align-items-center mt-3">
              <span></span>
              <h6 className="font-15 inter colorPrimary text-center">
                Question Name
              </h6>
              <span
                onClick={() => setShowModalQuestion(false)}
                className=" cursor-pointer"
              >
                <GrFormClose size={20} />
              </span>
            </div>
            <div className="col-lg-10 col-md-10 col-12">
              <div className="row justify-content-center">
                <div className="col-12">
                  <Accordion className="sms_automation_accordion rounded-5 mt-3">
                    <AccordionSummary
                      expandIcon={<FaAngleDown className="colorPrimary" />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div>
                        <p className="mb-0 font-12 fw-medium ">
                          {showQuestionData?.q_name}
                        </p>
                        <p className="mb-0 font-15">
                          {showQuestionData?.question}{" "}
                        </p>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ borderTop: "1.5px solid #D9D9D9" }}
                    >
                      <div>
                        <p className=" text-dark mb-1 text-start font-13 fw-medium ">
                          Multiple Choice
                        </p>
                        <FormControl className="radio-form-control">
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                          >
                            {showQuestionData?.options?.map((index) => {
                              return index.is_text_field ? (
                                <form className=" d-flex flex-column">
                                  <label htmlFor="otherTextField">
                                    {index.option_value}
                                  </label>
                                  <textarea
                                    id="otherTextField"
                                    required
                                    cols="25"
                                    rows="3"
                                    className="rounded-4 mt-1"
                                  ></textarea>
                                </form>
                              ) : (
                                <FormControlLabel
                                  value={index.option_number}
                                  control={
                                    <Radio style={{ color: "#3461FD" }} />
                                  }
                                  label={index.option_value}
                                />
                              );
                            })}
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
          
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default Surveys;
