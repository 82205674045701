import React, { memo, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { LuLogOut } from "react-icons/lu";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/zoom.css";
import { FaAngleDown } from "react-icons/fa6";
import { MdLogout } from "react-icons/md";
const Header = ({ title }) => {
  const { isLoading, userDetail } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.clear();
    navigate("/auth/login", { replace: true })
  };
  useEffect(() => {
    if (!localStorage.getItem("userToken")) {
      navigate("/auth/login", { replace: true });
    }
  }, []);
  return (
    <>
      <div
        className={`px-4 bg-white border-b flex justify-${
          title ? "between" : "end"
        } flex-wrap  p-3  `}
      >
        <h4 className="font-semibold text-2xl">{title && title}</h4>
        <div className="d-flex align-items-center me-lg-3">
          <button className="btn-white rounded-5 px-3 py-1 fw-medium font-14 text-capitalize">
            {" "}
            5 Credits
          </button>
          {isLoading ? (
            <Spinner size="sm" color="dark" />
          ) : (
            <Menu
              menuButton={
                <MenuButton>
                  <div className="  flex items-center justify-center bg-white">
                    <img
                      src={userDetail.image}
                      className="profile mx-2 w-8 h-8"
                      alt=""
                    />
                    <FaAngleDown />
                  </div>
                </MenuButton>
              }
              direction="bottom"
              align="center"
              position="anchor"
              arrow={true}
              // gap={12}
              shift={12}
              transition
              menuClassName="!min-w-fit "
            >
              <MenuItem>
                <div onClick={handleLogout} className="flex gap-2 items-center">
                  <MdLogout /> Logout
                </div>
              </MenuItem>
            </Menu>
          )}
        </div>
      </div>
    </>
  );
};

export default memo(Header);
