import React, { useEffect, useState } from "react";
import SideBar from "../../components/superAdmin/SideBar";
import {
  Button,
  FormControl,
  FormHelperText,
  LinearProgress,
  MenuItem,
  Select,
} from "@mui/material";
import { HiOutlineViewGridAdd } from "react-icons/hi";
import Dropzone from "react-dropzone";
import { FaRegImage, FaTimes } from "react-icons/fa";
import "../../style/superAdmin/newBusiness.scss";
import uploadicon from "../../assets/imges/upload.png";
import Crossicon from "../../assets/imges/CrossIcon.png";
import LoadingCross from "../../assets/imges/LoadingCross.png";
import delateImg from "../../assets/imges/Delete.png";
import CloseIcon from "@mui/icons-material/Close";
import { PiWarningCircleFill } from "react-icons/pi";
import NewBusinessTabel from "../../components/superAdmin/NewBusinessTabel";
import "../../style/superAdmin/index.scss";
import Header from "../../components/superAdmin/Header";
import { Modal } from "react-bootstrap";
import { FiPlusCircle, FiUploadCloud } from "react-icons/fi";
const NewBusiness = () => {
  // ----------BUSNIESS--MODAL---LOGICS-------
  const [businessData, setBusinessData] = useState(false);
  const [file, setFile] = useState(false);
  const handleXlFile = (e) => {
    const changeFile = e.target.files[0];
    setFile(changeFile);
  };
  const [modalFile, setModalFile] = useState(false);
  const handleModalFile = (e) => {
    const changeFile = e.target.files[0];
    setModalFile(changeFile);
  };
  return (
    <>
      <SideBar title="New Business">
        <div className="w-full super px-0">
          <div className=" ">
            <div className="container mb-4 bg-white p-4 rounded-xl">
              <div className="flex justify-between items-center inter ">
                <h4 className="text-lg font-semibold">Upload*</h4>

                {/* --------------ADD----BUSINESS------------MODAL--BUTTON- */}
                <button
                  onClick={() => setBusinessData(true)}
                  className="bg-customBlue rounded-full text-white flex items-center px-3 p-2 font-medium text-base"
                >
                  <FiPlusCircle size={24} className="me-2" />
                  Add Business
                </button>
              </div>

              <label
                htmlFor="xmlFile"
                className="border-[1.4px] h-52 flex items-center justify-center w-full poppins text-center border-dashed bg-[#FAFDFF] rounded-xl border-black mt-4 "
              >
                {file ? (
                  <p className="mb-0 font-16 font-medium mt-4">{file.name}</p>
                ) : (
                  <div className="  flex flex-col items-center">
                    <FiUploadCloud className="text-customBlue" size={55} />
                    <p className="mb-0 font-16 font-medium mt-4">
                      Drag & drop files or{" "}
                      <u className="blue-text font-bold">Browse</u>
                    </p>
                    <p className="mb-0 text-xs text-navyBlue ">
                      Supported formats: .xlsx
                    </p>
                  </div>
                )}
              </label>
              <input
                type="file"
                id="xmlFile"
                onChange={handleXlFile}
                className="hidden"
                accept=".xlsx"
              />
              <div className="flex justify-center mt-4">
                <button className="bg-customBlue rounded-full text-white  px-3 p-2 font-medium text-base">
                  Upload Files
                </button>
              </div>
              {/* <NewBusinessTabel/> */}
            </div>
          </div>
        </div>
      </SideBar>
      <Modal
        size="lg"
        show={businessData}
        onHide={() => setBusinessData(false)} className="ps-0"
      >
        <Modal.Body >
          <div className=" px-2 pb-3">
            <div className=" text-center">
              <h4 className="font-lg inter my-3">
              Business Registration
              </h4>
             
            </div>
            <div className="container-fluid">
              <div className="grid gap-4 lg:grid-cols-2  grid-cols-1">
                <div className="">
                  <label className="text-sm mb-2 fw-medium ">
                    Business Name*
                  </label>
                  <input
                    type="text"
                    className="focus:outline-none placeholder:text-navyBlue text-navyBlue !text-sm  border h-10 px-2 rounded-xl   w-full"
                  />
                </div>
                <div className="">
                  <label className="text-sm mb-2 fw-medium ">Email</label>
                  <input
                    type="email"
                    className="focus:outline-none placeholder:text-navyBlue text-navyBlue text-sm border h-10 px-2 rounded-xl   w-full"
                    placeholder="louis@gmail.com "
                  />
                </div>
                <div className="">
                  <label className="text-sm mb-2 fw-medium ">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    className="focus:outline-none placeholder:text-navyBlue text-navyBlue text-sm border h-10 px-2 rounded-xl   w-full"
                    placeholder="Enter Phone number"
                  />
                </div>
                <div className="">
                  <label className="text-sm mb-2 fw-medium ">
                    Business Web URL
                  </label>
                  <input
                    type="url"
                    className="focus:outline-none placeholder:text-navyBlue text-navyBlue text-sm border h-10 px-2 rounded-xl   w-full"
                    placeholder="Enter URL"
                  />
                </div>
                <div className="">
                  <label className="text-sm mb-2 fw-medium ">ABN*</label>
                  <input
                    type="text"
                    className="focus:outline-none placeholder:text-navyBlue text-navyBlue text-sm border h-10 px-2 rounded-xl   w-full"
                    placeholder="Enter ABN"
                  />
                </div>
                <div className="">
                  <label className="text-sm mb-2 fw-medium ">
                    Empty Title Dropdown*
                  </label>
                  <div className="focus:outline-none border h-10 px-2 rounded-xl   w-full">
                    <select className="w-full text-navyBlue text-sm border-0 focus:outline-none h-full">
                      <option selected>Select One</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="row mt-3 items-end">
                <div className="col-lg-9  col-12 ">
                  <div className="">
                    <label className="text-lg mb-2 fw-semibold ">
                      Business Web URL
                    </label>
                    <input
                      type="text"
                      className="focus:outline-none placeholder:text-navyBlue text-navyBlue text-sm border h-10 px-2 rounded-xl   w-full"
                      placeholder="Enter ABN"
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-12  text-center">
                  <button className="bg-customBlue mt-lg-0 mt-3 h-10 w-full rounded-full text-white  px-3 p-2 font-medium text-base">
                    Generate
                  </button>
                </div>
              </div>
              <div className="grid gap-4 lg:grid-cols-2  grid-cols-1 mt-3">
                <div className="focus:outline-none border h-10 px-2 rounded-xl   w-full">
                  <select className=" text-navyBlue text-sm w-full border-0 focus:outline-none h-full">
                    <option selected>Assign Devices*</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>

                <div className=" focus:outline-none border h-10 px-2 rounded-xl   w-full">
                  <select className="text-navyBlue text-sm w-full border-0 focus:outline-none h-full">
                    <option selected>Assign Devices*</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
              </div>
              <div className="row   mt-3">
                <div className=" col-lg-6 col-12 ">
                  <label className="text-sm mb-2 fw-medium ">
                    Assign Devices*
                  </label>
                  <div className=" focus:outline-none border h-10 px-2 rounded-xl   w-full">
                    <select className="w-full border-0 focus:outline-none h-full text-navyBlue text-sm">
                      <option selected>Select one</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </div>
                <div className=" col-lg-6 col-12 mt-lg-0 mt-3">
                  <div className=" row items-end">
                    <div className=" col-4 px-1 ">
                      <label className="text-sm mb-2 fw-medium ">
                        Purchase*
                      </label>
                      <input
                        type="text"
                        className="focus:outline-none text-navyBlue placeholder:text-navyBlue text-sm border h-10 px-2 rounded-full  text-center w-full"
                        placeholder="Lease"
                      />
                    </div>
                    <div className=" col-4 px-1 ">
                      <input
                        type="text"
                        className="focus:outline-none text-navyBlue placeholder:text-navyBlue text-sm border h-10 px-2 rounded-full  text-center  w-full"
                        placeholder="Outright"
                      />
                    </div>
                    <div className=" col-4 px-1 ">
                      <label className="text-sm mb-2 fw-medium ">Amount*</label>
                      <input
                        type="text"
                        className="focus:outline-none text-navyBlue placeholder:text-navyBlue text-sm border h-10 px-2 rounded-xl  text-center  w-full"
                        placeholder="Enter Amount"
                      />
                    </div>
                    <div className=" col-8 px-1 mt-2 ">
                      <input
                        type="text"
                        className="focus:outline-none placeholder:text-navyBlue text-navyBlue text-sm border h-10 px-2 rounded-xl   text-center w-full"
                        placeholder="N/A"
                      />
                    </div>
                    <div className=" col-4 px-1 mt-2">
                      <div className=" focus:outline-none border h-10 px-2 rounded-xl   w-full">
                        <select className="w-full border-0 focus:outline-none h-full text-navyBlue text-sm">
                          <option selected>Select one</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3 items-end">
                <div className="col-lg-9 col-12 ">
                  <div className="">
                    <label className="text-lg mb-2 fw-semibold ">
                      Unique Business Key
                    </label>
                    <input
                      type="text"
                      className="focus:outline-none placeholder:text-navyBlue text-navyBlue text-sm border h-10 px-2 rounded-xl   w-full"
                      placeholder="Enter Key"
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-12 lg:mt-0 mt-3  text-center">
                  <button className="bg-customBlue h-10 w-full rounded-full text-white  px-3 p-2 font-medium text-base">
                    Generate
                  </button>
                </div>
              </div>
              <div className="row mt-3 items-end">
                <div className="col-lg-9 col-12 ">
                  <div className="">
                    <label className="text-lg mb-2 fw-semibold ">
                      Unique Static URL
                    </label>
                    <input
                      type="url"
                      className="focus:outline-none placeholder:text-navyBlue text-navyBlue text-sm border h-10 px-2 rounded-xl   w-full"
                      placeholder="Enter URL"
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-12 lg:mt-0 mt-3  text-center">
                  <button className="bg-customBlue h-10 w-full rounded-full text-white  px-3 p-2 font-medium text-base">
                    Generate
                  </button>
                </div>
              </div>
              <div className="row mt-3">
                <label className="text-sm mb-2 fw-medium ">Notes*</label>
                <div className="col-lg-6 col-12 ">
                  <div className="">
                    <textarea
                      type="text"
                      className="focus:outline-none h-44 border p-3 resize-none placeholder:text-navyBlue text-navyBlue text-sm px-2 rounded-xl   w-full"
                      placeholder="Enter text here"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-12 mt-lg-0 mt-3">
                  <label
                    htmlFor="modalfile"
                    className="border-[1.4px] h-44 flex items-center justify-center w-full poppins text-center border-dashed bg-[#FAFDFF] rounded-xl border-navyBlue  "
                  >
                    {modalFile ? (
                      <p className="mb-0 font-16 font-medium mt-4">
                        {modalFile.name}
                      </p>
                    ) : (
                      <div className="  flex flex-col items-center">
                        <FiUploadCloud className="text-customBlue" size={40} />
                        <p className="mb-0 font-16 font-medium mt-3">
                          Drag & drop files or{" "}
                          <strong className="text-customBlue">Browse</strong>
                        </p>
                        <p className="mb-0 text-xs text-navyBlue ">
                          Supported formats: <br /> JPEG, PNG, GIF, MP4, PDF,
                          PSD, AI, Word, PPT
                        </p>
                      </div>
                    )}
                  </label>
                  <input
                    type="file"
                    id="modalfile"
                    onChange={handleModalFile}
                    className="hidden"
                    accept=".jpeg, .jpg, .png, .gif, .mp4, .pdf, .ai, .doc, .docx, .ppt, .pptx"
                  />
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-6 text-center">
                  <button
                    onClick={() => setBusinessData(false)}
                    className="bg-white rounded-full text-navyBlue p-2.5 w-full font-medium text-base border border-navyBlue"
                  >
                    Cancel
                  </button>
                </div>
                <div className="col-6  text-center">
                  <button
                    onClick={() => setBusinessData(false)}
                    className="bg-customBlue rounded-full text-white p-2.5 w-full font-medium text-base"
                  >
                    Create
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NewBusiness;
