import React, { useState, useEffect } from 'react';
import logoTwo from '../../assets/imges/Logo.png'
import '../../style/businesLogin.scss'
import { Col, Container, Row, Spinner } from 'reactstrap';
import { FaFacebook, FaRegEnvelope, FaRegEye, FaRegEyeSlash, FaRegUser } from 'react-icons/fa';
import { Button } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FcGoogle } from "react-icons/fc";
import { MdLock } from 'react-icons/md';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoginSocialGoogle } from 'reactjs-social-login';
const NewLogin = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [type, setType] = useState('password');



    const toggleType = () => {
        if (type == 'password') {
            setType('text');
        }
        else {
            setType('password');

        }
    }
    if (email.length > 0) {
        var style = {
            border: '1.5px solid rgba(37, 99, 235, 1)',
        }
    }
    else {
        var style = {
            border: '1.5px solid rgba(226, 232, 240, 1)',
        }
    }

    // =========
    if (password.length > 0) {
        var stylepassword = {
            border: '1.5px solid rgba(37, 99, 235, 1)',
        }
    }
    else {
        var stylepassword = {
            border: '1.5px solid rgba(226, 232, 240, 1)',
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault();


        navigate('/signUp/new')
    }



    return (
        <>
            <Container fluid className='login_height inter' >
                <Row className='login_height justify-content-center '>
                    <LoginSocialGoogle

                        client_id={process.env.REACT_APP_GG_APP_ID || ''}
                        onLoginStart={() => { alert('oauth 2.0') }}
                        onResolve={({ provider, data }) => {
                            console.log(provider, data)
                        }}
                        onReject={(err) => {
                            console.log(err)
                        }}
                    >

                        <Button>
                            Oauth 2.0 google
                        </Button>
                    </LoginSocialGoogle>


                    <div className=''>
                        <img src={logoTwo} alt="w8" className='mt-3 ' width="150" />
                    </div>
                    <Col lg={6} md={12} sm={12} xs={12} className='align-items-center justify-content-center d-flex py-4' >
                        <Container fluid >
                            <Row className='justify-content-center'>
                                <Col lg={9} md={9} sm={12} xs={12} >
                                    <form className='login-form' onSubmit={handleSubmit}>
                                        <div className='inter '>
                                            <h4 className="font-24 fw-bold  text-dak-blue">Sign In to your Account</h4>
                                            <p className="font-14 text-dark-gray ">Welcome back! please enter your detail</p>

                                        </div>
                                        <div className="input-group my-4 rounded-4 bg-white" style={style}>
                                            <span className="input-group-text border-0 ps-3" id="basic-addon1"><FaRegEnvelope
                                                size={20} color='rgba(100, 116, 139, 1)' /></span>
                                            <input type="email" required className="form-control border-0 p-3 ps-0 rounded-4 " placeholder="Business email" value={email} onChange={(e) => setEmail(e.target.value)} name='email' />
                                        </div>
                                        <div className="input-group my-3 rounded-4 bg-white" style={stylepassword}>
                                            <span className="input-group-text border-0 ps-3" id="basic-addon1"><MdLock
                                                size={20} color='rgba(100, 116, 139, 1)' /></span>
                                            <input type={type} required className="form-control border-0 p-3 ps-0 rounded-4 " placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)} name='password' /><span className="input-group-text border-0 ps-3" role="button"
                                                onClick={toggleType} id="basic-addon1">{
                                                    type != 'password' ? <FaRegEye
                                                        size={20} color='rgba(100, 116, 139, 1)' /> : <FaRegEyeSlash
                                                        size={20} color='rgba(100, 116, 139, 1)' />}</span>
                                        </div>

                                        <div className="d-flex  justify-content-between  mt-3">
                                            <div>

                                                <input type="checkbox" id="terms" className="login-terms" />
                                                <label className="font-14 text-dark-gray ms-2" htmlFor="terms"><b className="text-dark">Remember me</b> </label>
                                            </div>
                                            <Link to='/auth/reset' className='text-decoration-none'>
                                                <p className="font-16 fw-bold  colorPrimary inter"> Forgot Password?</p>
                                            </Link>
                                        </div>

                                        <Button type='submit' className='btn-blueTwo rounded-4 inter mt-3 px-3 p-3 w-100 fw-bold font-16 text-capitalize ' sx={{ fontFamily: 'Inter, sans-serif !important' }} >Sign in</Button>
                                        <p class="font-14 inter text-dark-gray Or-lines d-flex justify-content-center align-items-center my-4">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Or sign up with&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>

                                    </form>
                                    <Row className='inter mt-3'>
                                        <Col lg={6} md={6} sm={6} xs={12} >
                                            <button role='button' className='just-border bg-tranparent p-3 w-100  text-center rounded-4' >
                                                <FcGoogle size={18} /><span className='fw-bold ms-2'>Google</span>
                                            </button>
                                        </Col>
                                        <Col lg={6} md={6} sm={6} xs={12} className='mt-lg-0 mt-md-0 mt-sm-0 mt-3' >
                                            <button className='just-border bg-tranparent p-3 w-100 text-center rounded-4'>
                                                <FaFacebook color='#2563EB' size={18} /><span className='fw-bold ms-2'>Facebook</span>
                                            </button>
                                        </Col>

                                    </Row>
                                    <p className="text-center  mt-4">
                                        Don’t have an account?
                                        <span className="font-16 fw-bold cursor-pointer colorPrimary inter"
                                            onClick={() => navigate('/signUp/new')}> Sign Up</span>
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <ToastContainer />
            </Container>
        </>
    );
}

export default NewLogin;
