import React, { useEffect, useRef, useState } from 'react'
import Header from '../components/Header'
import SideBar from '../components/SideBar'
import { useNavigate } from 'react-router-dom'
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { FaArrowUp, FaUsers } from 'react-icons/fa';
import { LuAlarmClock } from "react-icons/lu";
import { BsFileEarmarkSpreadsheetFill } from "react-icons/bs";
import { PieChart } from 'react-minimal-pie-chart';
import { RxDotsVertical } from 'react-icons/rx';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'bootstrap-daterangepicker/daterangepicker.css'; // Import DateRangePicker CSS
import $ from 'jquery'; // Import jQuery
import 'bootstrap-daterangepicker/daterangepicker'; // Import DateRangePicker JavaScript
import moment from 'moment'
import { FaCalendarDays } from 'react-icons/fa6';
import { FormControl, MenuItem, Select } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getBusinessOrders, seTgetErrorNull } from '../redux/slices/userSlice';
import OpenTableData from '../components/OpenTableData';
const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        const uvValue = payload[0].value;
        // const arrowStyle = {
        //     position: 'absolute',
        //     left: '18%',     // Center the arrow
        //     transform: 'translateX(-50%)',  // Center the arrow
        //     top: '100%',  // Adjust this value as needed
        //     width: '15px',
        //     height: '8px',
        //     background: 'white',  // Arrow color
        //     clipPath: 'polygon(0% 0%, 50% 100%, 100% 0%)',  // Arrow shape
        // };   
        return (
            <div className="custom-tooltip  rounded-3 p-1 px-2 border-0 " style={{ backgroundColor: 'white', filter: 'drop-shadow(6px 12px 35px rgba(0, 0, 0, 0.08))' }}>
                {/* <div className="tooltip-arrow" style={arrowStyle} /> */}
                <p className="label mb-0  poppins font-12 fw-semibold"><span><FaUsers className='blue' /></span> Orders:
                    <span className='fw-normal'>{` ${payload[0].value}`}</span></p>
                <p className="label mb-0  poppins font-12 fw-semibold"><span><LuAlarmClock className='blue' /></span> Average Wait:
                    <span className='fw-normal'>1hr 15m</span></p>
                <p className="label mb-0  poppins font-12 fw-semibold"><span><BsFileEarmarkSpreadsheetFill className='blue' /></span> 8-9am: <span className='fw-normal'>Busiest period</span></p>
            </div>
        );
    }

    return null;
};
const CustomTick = (props) => {
    const { x, y, payload } = props;
    return (
        <text x={x} y={y + 10} fontSize="12" className='poppins fw-medium' textAnchor="middle" fill="#A3AED0">

            {payload.value}
        </text>
    );
};

const OrderStatus = () => {
    const dispatch = useDispatch();
    const { isLoading, getError, businessOrders } = useSelector((state) => state.user)
    let [barGraphData, setBarGraphData] = useState({})
    let [mainGraph, setMainGraph] = useState(null)

    useEffect(() => {
        if (getError && getError.length > 1) {
            toast.error(getError, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            dispatch(seTgetErrorNull());
        }
    }, [getError])
    useEffect(() => {
        dispatch(getBusinessOrders())
    }, [])
    const [select1, setSelect1] = useState("");

    const Navigate = useNavigate();

    const ALLdata = [
        {
            title: '1 am',
            value: 9,


        },
        {
            title: '2 am',
            value: 22,

        },
        {
            title: '3 am',
            value: 15,

        },
        {
            title: '4 am',
            value: 18,

        },
        {
            title: '5 am',
            value: 17,

        },
        {
            title: '6 am',
            value: 26,

        },
        {
            title: '7 am',
            value: 12,

        },
        {
            title: '8am',
            value: 24,

        },
        {
            title: '9pam',
            value: 4,

        },
        {
            title: '10 am',
            value: 18,

        },
        {
            title: '11 am',
            value: 9,

        },
        {
            title: '12 am',
            value: 14,

        },
    ]

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
    const [hoverIndex, setHoverIndex] = useState(-1);

    const handleCellHover = (index) => {
        setHoverIndex(index);
    };

    const handleCellHoverClear = () => {
        setHoverIndex(-1);
    };
    const [donutChart, setDonutChart] = useState();
    // var donutChart = [
    //     { title: 'Male', value: 15, color: '#FFAA00' },
    //     { title: 'Female', value: 12, color: '#FFCDEA  ' },
    //     { title: 'Non-binary', value: 30, color: '#8ED1FF' },
    // ];
    var colorsForDonutChart = ['#FFAA00', '#FFCDEA  ', '#8ED1FF', '#8ED1FF']
    useEffect(() => {
        if (businessOrders?.homeScreenData?.genderChartData) {
            setDonutChart(businessOrders?.homeScreenData?.genderChartData.map((index, key) => {
                return {
                    title: index.gender,
                    value: index.count,
                    color: colorsForDonutChart[key]
                }
            }))
        }
        if (businessOrders?.homeScreenData?.graphData) {
            let transformedData = [];

            // Iterate over the original data object
            for (const timeSlot in businessOrders?.homeScreenData?.graphData) {
                if (businessOrders?.homeScreenData?.graphData.hasOwnProperty(timeSlot)) {
                    // Creating an object with 'title' and 'value' keys
                    transformedData.push({
                        title: convertTimeRange(timeSlot),
                        orders: businessOrders?.homeScreenData?.graphData[timeSlot]
                    });
                }
            }
            setMainGraph(transformedData)
        }
    }, [businessOrders])

    // ========== DATEPICKER =============
    let $datepicker;
    let datepickerInstance; // Store the DateRangePicker instance

    const datepickerRef = useRef(null);
    const calendarIconRef = useRef(null);

    useEffect(() => {
        // Define predefined date ranges
        const predefinedRanges = {
            'Today': [moment(), moment()],
            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
            'Last 90 Days': [moment().subtract(89, 'days'), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        };

        // Initialize the DateRangePicker with predefined ranges
        $datepicker = $(datepickerRef?.current);

        datepickerInstance = $datepicker.daterangepicker({
            ranges: predefinedRanges,
            opens: 'center', // Adjust the position of the calendar
        }).data('daterangepicker');


        // Specify any additional configurations or event handlers here if needed
        // Event handler for when the user applies a new date range
        const handleDateRangeChange = (event, picker) => {
            // Retrieve the selected start and end dates
            const startDate = picker.startDate.format('YYYY-MM-DD');
            const endDate = picker.endDate.format('YYYY-MM-DD');

            // Do something with the selected dates, such as updating state or calling a function
            console.log('Selected start date:', startDate);
            console.log('Selected end date:', endDate);
            dispatch(getBusinessOrders(startDate, endDate))
        };

        // Attach the event handler to the 'apply.daterangepicker' event
        $datepicker.on('apply.daterangepicker', handleDateRangeChange);

        // Clean up the DateRangePicker instance when the component unmounts
        return () => {
            if (datepickerInstance) {
                datepickerInstance.remove();
                $datepicker.off('apply.daterangepicker', handleDateRangeChange);
            }
        };
    }, []);



    const handleIconClick = () => {
        if (datepickerInstance) {
            datepickerInstance.show(); // Open the date range picker
        }
    };

    function convertTimeRange(inputString) {
        // Split the input string by ' - ' to separate start and end times
        const times = inputString.split(' - ');

        // Extract the start and end times
        const startTime = times[0];
        const endTime = times[1];

        // Extract the hour part and the period indicator (AM or PM)
        const startHour = parseInt(startTime);
        const endHour = parseInt(endTime);

        // Format the start and end times
        const startFormatted = `${startHour % 12 || 12}-${endHour % 12 || 12}${endTime.slice(-2)[0].toLowerCase()}`;

        return startFormatted;
    }

    // Example usage
    const inputString = "1AM - 2AM";
    const outputString = convertTimeRange(inputString);
    console.log(outputString); // Output: 1-2a
    return (
        <>

            <div className="container-fluid  business_admin_main">
                <div className="row order pt-3">

                    {/* <!-- ===========HEADER========== --> */}
                    <Header />
                    {/* <!-- ===========SID-BAR======= --> */}
                    <SideBar />
                    {/* <!-- ========= content body ======= --> */}

                    <div className="w-lg-80 px-lg-4 px-md-3 px-2 ">
                        <div className="container">
                            <div className="d-flex align-items-center py-3 justify-content-between">
                                <span onClick={() => Navigate(-1)} className="cursor-pointer ">
                                    <div>
                                        <h6 className="font-12 mb-0 colorgray"><i className="fa-solid fa-arrow-left " /> Back</h6>
                                    </div>
                                </span>
                                <div className="">
                                    <div className='d-flex align-items-center rounded-3 border '>
                                        <label ref={calendarIconRef} onClick={handleIconClick}

                                            htmlfor='datePicker' className='ps-2 text-gray'><FaCalendarDays /></label>
                                        <input
                                            type="text"
                                            ref={datepickerRef}
                                            id='datePicker'
                                            className="form-control border-0 rounded-3 text-gray"
                                            placeholder="Select Date Range"
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-lg-4 col-md-6 col-12 mt-lg-0 mt-md-3 mt-3">
                                    <div className="trip-mycard p-4">
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <p className="inter font-20 fw-medium">Open</p>
                                                <h4 className="inter font-24 fw-bold mt-3 text-blue">{businessOrders?.homeScreenData?.timeBasedopenOrders}</h4>
                                            </div>
                                            <div className="align-self-end">
                                                <span className="inter font-12 btn-blue px-2 pe-3 py-1 rounded-5 mb-0"><i className="fa-solid fa-arrow-up me-1" />+20</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12 mt-lg-0 mt-md-3 mt-3">
                                    <div className="trip-mycard p-4">
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <p className="inter font-20 fw-medium">Pending</p>
                                                <h4 className="inter font-24 fw-bold mt-3 text-blue">{businessOrders?.homeScreenData?.timeBasedpendingdOrders}</h4>
                                            </div>
                                            <div className="align-self-end">
                                                <spam className="inter font-12 btn-blue px-2 pe-3 py-1 rounded-5 mb-0"><i className="fa-solid fa-arrow-up me-1" />+20</spam>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12 mt-lg-0 mt-md-3 mt-3">
                                    <div className="trip-mycard p-4">
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <p className="inter font-20 fw-medium">Completed</p>
                                                <h4 className="inter font-24 fw-bold mt-3 text-blue">{businessOrders?.homeScreenData?.timeBasedcompletedOrders}</h4>
                                            </div>
                                            <div className="align-self-end">
                                                <span className="inter font-12 btn-blue px-2 pe-3 py-1 rounded-5 mb-0"><i className="fa-solid fa-arrow-up me-1" />+34</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-9 col-12 mt-3 ">
                                    <div className="row bgcolor p-3">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div><h4 className='mb-0 fw-bold font-24'>Daily Orders</h4></div>
                                            <div>
                                                <FormControl className="mui-select-fieldTwo order-status  w-100">
                                                    <Select
                                                        value={select1}
                                                        className=" border-0   w-100 text-start font-14"
                                                        onChange={(e) => setSelect1(e.target.value)}
                                                        displayEmpty
                                                        inputProps={{ "aria-label": "Without label" }}
                                                    >
                                                        <MenuItem value="">
                                                            Filter
                                                        </MenuItem>
                                                        <MenuItem value={10}>Ten</MenuItem>
                                                        <MenuItem value={20}>Twenty</MenuItem>
                                                        <MenuItem value={30}>Thirty</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-6">
                                            <div className="mt-2">
                                                <p className="font-14 inter colorgray mb-0">Total Orders</p>
                                                <h3 className="font-32 fw-bold">{businessOrders?.homeScreenData?.graphOrderCount}</h3>
                                                <div>
                                                    <span className="inter font-12 btn-blue w-75 px-2 py-1 rounded-5 mb-0"><i className="fa-solid fa-arrow-up" /> +34%</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-10 col-12 mt-lg-0 mt-md-3 mt-3">
                                            <div style={{ width: '100%' }}>
                                                <ResponsiveContainer width="100%" height={300}>
                                                    {
                                                        mainGraph &&
                                                        <BarChart width={150} height={40} data={mainGraph}>
                                                            <Tooltip content={<CustomTooltip />} position={{ x: barGraphData.x, y: barGraphData.y - 40 }} cursor={{ fill: 'transparent' }} />
                                                            <XAxis dataKey="title" tick={<CustomTick />} interval={0} axisLine={false} />
                                                            <Bar dataKey="orders" >
                                                                {mainGraph.map((entry, index) => (
                                                                    <Cell axisLine='none'
                                                                        cursor="pointer"
                                                                        fill={hoverIndex === index ? '#4318FF' : '#E9EDF7'}
                                                                        onMouseEnter={() => handleCellHover(index)}
                                                                        onMouseLeave={handleCellHoverClear}
                                                                        radius={[10, 10, 10, 10]}
                                                                        key={`cell-${index}`}
                                                                        onMouseOver={(data) => {
                                                                            console.log('data', data)
                                                                            setBarGraphData(data)
                                                                        }}
                                                                    />
                                                                ))}
                                                            </Bar>
                                                        </BarChart>
                                                    }
                                                </ResponsiveContainer>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-12 mt-3">
                                    <div className="trip-mycard  p-3">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="align-self-center">
                                                <h6 className="inter font-14 fw-medium">Total Orders
                                                </h6>
                                                <span className="inter font-12 btn-blue w-75 px-2 py-1 rounded-5 mb-0"><i className="fa-solid fa-arrow-up" /> +34%</span>
                                            </div>
                                            <div className="align-items-center">
                                                <h6 className="inter font-16 fw-medium text-blue">{businessOrders?.homeScreenData?.timeBasedOrders?.length}</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="trip-mycard p-3 mt-3">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="align-self-center">
                                                <h6 className="inter font-14 fw-medium">Average<br /> wait-time
                                                </h6>

                                                <span className="inter font-12 btn-blue w-75 px-2 py-1 rounded-5 mb-0"><i className="fa-solid fa-arrow-up" /> 1 sec </span>
                                            </div>
                                            <div className>
                                                <h6 className="inter font-16 fw-medium text-blue">{businessOrders?.homeScreenData?.timeBasedaverageWaitTime}</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="trip-mycard p-3  mt-3">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="align-self-center">
                                                <h6 className="inter font-14 fw-medium">Repeat <br /> customer  Rate
                                                </h6>
                                                {/* <p class="inter font-12 p-1 px-3 green mb-0 btn-blue rounded-5  py-2"><i class="fa-solid fa-arrow-up me-1"></i>+34%  today </p> */}
                                            </div>
                                            <div className='order-progress-bar order-status-bar' >
                                                <CircularProgressbar value={businessOrders?.homeScreenData?.timeBasedRepeatCustomerRate || 0} text={`${businessOrders?.homeScreenData?.timeBasedRepeatCustomerRate || 0}%`} />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-9 mt-3">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-8 col-12">
                                            <div className="bgcolor p-3">

                                                <h5 className="font-20 fw-bold   inter">Age Groups</h5>
                                                <div className="" style={{ height: '370px' }}>

                                                    <ResponsiveContainer width="100%" height="100%" className="pt-3 pe-4 ps-0">
                                                        {businessOrders?.homeScreenData?.ageChartData &&
                                                            <BarChart data={businessOrders?.homeScreenData?.ageChartData}>
                                                                <CartesianGrid vertical={false} />
                                                                <XAxis dataKey="age_group" tick={{ fontFamily: 'Inter,sans', fontWeight: '700', fill: 'black', fontSize: 12 }} />
                                                                <YAxis axisLine={false} tick={{ fontFamily: 'Inter,sans', fontWeight: '700', fill: 'black', fontSize: 12 }} />
                                                                <Tooltip cursor={{ fill: 'transparent' }} />
                                                                {/* <Legend /> */}
                                                                <Bar dataKey="count" fill="#D99BFF"
                                                                //    activeBar={<Rectangle fill="gold" stroke="purple" />}
                                                                />

                                                            </BarChart>
                                                        }
                                                    </ResponsiveContainer>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-12 mt-lg-0 mt-md-3 mt-3">
                                            <div className="bgcolor p-4 ">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <h5 className="font-20 fw-bold mb-0  inter">Gender</h5>
                                                    </div>
                                                    <div>
                                                        <div className='inter'>
                                                            <div className="dropdown position-static ">

                                                                <RxDotsVertical className=" dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false" />

                                                                <ul className="dropdown-menu">
                                                                    <li><a className="dropdown-item" href="#">View</a></li>
                                                                    <li><a className="dropdown-item" href="#">Pause</a></li>
                                                                    <li><a className="dropdown-item" href="#">Delete</a></li>
                                                                    <li><a className="dropdown-item" href="#">Reactivate</a></li>
                                                                </ul>
                                                            </div>
                                                        </div></div>
                                                </div>

                                                <div className="text-center mt-3 pb-2 ">
                                                    {donutChart &&
                                                        <div>
                                                            <PieChart
                                                                data={donutChart}
                                                                lineWidth={24}
                                                                rounded

                                                            />
                                                            <div style={{ marginTop: '10px' }}>
                                                                {donutChart.map((entry, index) => (
                                                                    <div key={index} style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                                                                        <div className='rounded-5' style={{ width: '15px', height: '15px', backgroundColor: entry.color, marginRight: '5px' }}>

                                                                        </div>
                                                                        {entry.title}
                                                                    </div>
                                                                ))}
                                                            </div>

                                                        </div>
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="surveys order-rdt-table mt-4 py-2" >
                            {/* <PendingTableData /> */}
                            <OpenTableData data={businessOrders?.homeScreenData?.timeBasedOrders} />


                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
            <style>
                {
                    `
                    .recharts-cartesian-axis-tick-line{
                        display:none !important;
                    }
                    .paiChart .recharts-legend-wrapper ul li{
display:block !important;
                    }
                    `

                }
            </style>
        </>
    )
}

export default OrderStatus
