import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Col, Container, Row } from 'reactstrap';
import '../../style/businesLogin.scss';
import { FaArrowLeft } from 'react-icons/fa6';
import { useDispatch, useSelector } from 'react-redux';
import { seTgetErrorNull, updateBusinessProfile } from '../../redux/slices/userSlice';
import FullScreenLoader from '../../components/loaders/FullScreenLoader';
const WellcomeFive = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading, getError, userDetail } = useSelector((state) => state.user)
    const [selectedOption, setSelectedOption] = useState("General Restaurant");

    const options = [
        "Cafe",
        "Burger Shop",
        "Pizza Shop",
        "Pub/Bar",
        "Food Trucks",
        "Quick-Service Restaurant",
        "Take Away",
        "Juice Bar",
        "General Restaurant",
        "Other"
    ];
    const handleOptionClick = (option) => {
        setSelectedOption(option);
    };
    useEffect(() => {
        if (getError && getError.length > 1) {
            toast.error(getError, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            dispatch(seTgetErrorNull());
        }
    }, [getError])
    useEffect(() => {
        if (Object.keys(userDetail).length > 0) {
          if (!userDetail?.phone) {
            navigate("/onboarding/number");
          } else if (!userDetail?.is_phone_verified) {
            navigate("/onboarding/number/verify");
          } else if (!userDetail?.business_name) {
            navigate("/profile/set");
          } else if (!userDetail?.abn) {
            navigate("/profile/abn");
          } else if (!userDetail?.image) {
            navigate("/profile/logo");
          } else{

          }
        }
      }, [userDetail]);
    useEffect(() => {
        setSelectedOption(userDetail?.business_category)
    }, [userDetail])
    function handleSubmit() {
        var data = {
            'business_category': selectedOption
        }
        dispatch(updateBusinessProfile(data, handleResponse))

    }
    function handleResponse(status) {
        if (status) {
            navigate('/onboarding/reviewing')
        }
    }
    return (
        <>
         {
      isLoading &&
      <FullScreenLoader isLoading={isLoading} />
    }
            <Container fluid className=' inter' >
                <Row className='min-h-screen flex flex-col ' style={{ backgroundColor: '#ffffff' }}>

                    <div className=' p-3 flex flex-row justify-between' >
                        <Link to="/profile/logo" className='mt-3 no-underline'>
                            <button type='button' className='px-3 p-2 flex gap-2 items-center  border-0 rounded-lg text-black font-semibold'><FaArrowLeft />Back</button>
                        </Link>
                        <button
                            onClick={() => {
                                localStorage.clear();
                                navigate('/')
                            }}
                            type="button"
                            className="px-3 p-2  border-0 rounded-lg text-black font-semibold"
                        >
                            Logout
                        </button>
                    </div>

                    <Col lg={7} md={12} sm={12} xs={12} className='align-items-center justify-content-center d-flex  my-2 mx-auto !grow' >
                        <Container fluid >
                            <Row className='justify-content-center'>
                                <Col lg={9} md={9} sm={12} xs={12} >
                                    <div>
                                        <div className='inter '>
                                            <p className="font-14 font-semibold text-black  mb-1">TimeLink | Start building your business profile</p>
                                            <h3 className=" fw-bold  text-black ">What category best suites your business?  </h3>
                                            <div>
                                                <div className="flex flex-wrap gap-2">
                                                    {options.map((option, index) => (
                                                        <button
                                                            key={index}
                                                            onClick={() => handleOptionClick(option)}
                                                            className={`px-4 py-2 border rounded ${selectedOption === option ? 'bg-blue-600 text-white' : 'bg-white text-black border-gray-300'
                                                                }`}
                                                        >
                                                            {option}
                                                        </button>
                                                    ))}
                                                </div>
                                                <button type='button' onClick={handleSubmit} className='px-3 p-2 mt-5 border-[1.5px] rounded-lg text-black'>Continue</button>
                                            </div>


                                        </div>
                                    </div>


                                </Col>
                            </Row>
                        </Container>
                    </Col>

                </Row>
                <ToastContainer />
            </Container>
        </>
    );
}

export default WellcomeFive;
