import React from 'react'
import LoyaltyPointsHeader from '../../../components/LoyaltyPointsHeader'
import { IoCheckbox } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom';

const ReviewMarketing = () => {
    const navigate = useNavigate();
    function handleNext() {
        navigate("/marketing/campaigns/new");
    }
    return (
        <main className='bg-white p-3' style={{ minHeight: "100vh" }}>

            <LoyaltyPointsHeader handleNext={handleNext} />
            <div className="container inter customise-program mt-4">
                <div className="row justify-content-center ">
                    <div className="col-lg-6 col-md-8 col-12">
                        <p className='text-uppercase font-12 text-black-50'>Step 4 of 4</p>
                        <h3 className='font-30 fw-bold '>Review and submit</h3>

                        <p className='font-14 mt-1'>Confirm all marketing campaign details before submission, as edits are not allowed post-submission. This will use 1 marketing credit.</p>

                        <div className="p-3 rounded-3 d-flex mt-3 align-items-center justify-content-between" style={{ boxShadow: "0px 0px 4px 0px #0000001A" }}>
                            <div className='w-100 '>
                                <div className="d-flex align-items-center justify-content-between  ">

                                    <p className='mb-0 font-18 fw-bold '>Rewards</p>
                                    <span role='button' className='mb-0 font-18 fw-bold text-blue '>Edit</span>
                                </div>
                                <p className='mb-0 mt-3 fw-bold'>No reward</p>
                                {/* <p className='mb-0 font-14 text-black-50'>$10.00 discount on entire sale.</p> */}
                            </div>

                        </div>
                        <div className="p-3 rounded-3 d-flex mt-3 align-items-center justify-content-between" style={{ boxShadow: "0px 0px 4px 0px #0000001A" }}>
                            <div className=' w-100'>
                                <div className="d-flex align-items-center justify-content-between  ">

                                    <p className='mb-0 font-18 fw-bold '>Campaign details</p>
                                    <span role='button' className='mb-0 font-18 fw-bold text-blue '>Edit</span>
                                </div>
                                <p className='mb-0 mt-3 fw-bold'>Store grand opening!</p>
                                <p className='mb-0 font-14 text-black-50'>Visit our Cranbourne store grand opening on the...</p>
                            </div>

                        </div>
                        <div className="p-3 rounded-3 d-flex mt-3 align-items-center justify-content-between" style={{ boxShadow: "0px 0px 4px 0px #0000001A" }}>
                            <div className=' w-100'>
                                <div className="d-flex align-items-center justify-content-between  ">

                                    <p className='mb-0 font-18 fw-bold '>Target audience</p>
                                    <span role='button' className='mb-0 font-18 fw-bold text-blue '>Edit</span>
                                </div>
                                <p className='mb-0 mt-3 fw-bold'>Campaign category, Location, demographics, Interests and behaviours, number of visits, professions.</p>
                                <p className='mb-0 font-14 text-black-50'>Scheduled for 07/02/2024</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default ReviewMarketing
