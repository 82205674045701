import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Container, Row } from "reactstrap";
import "../../style/businesLogin.scss";
import { useDispatch, useSelector } from 'react-redux';
import { seTgetErrorNull, updateBusinessProfile } from '../../redux/slices/userSlice';
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import FullScreenLoader from "../../components/loaders/FullScreenLoader";
const OnboardingNumber = () => {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState();
  const dispatch = useDispatch();
  const { isLoading, getError, userDetail } = useSelector((state) => state.user)
  useEffect(() => {
    if (getError && getError.length > 1) {
      toast.error(getError, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
            dispatch(seTgetErrorNull());

    }
  }, [getError])
  useEffect(() => {
    setPhoneNumber(userDetail?.phone)
  }, [userDetail])
  async function handleSubmit(e) {
    e.preventDefault();
    if (userDetail?.is_phone_verified) {
      navigate('/profile/business-name')
      return false;
    }
    var data = {
      phone: phoneNumber
    }
    dispatch(updateBusinessProfile(data,(data)=>{
      navigate('/onboarding/number/verify')
    }))

  }
  useEffect(() => {
    if (phoneNumber) {
      console.log(isValidPhoneNumber(phoneNumber) + '  =======> is phone number valid ')
    }
  }, [phoneNumber])
  return (
    <>
     {
      isLoading &&
      <FullScreenLoader isLoading={isLoading} />
    }
      <Container fluid className=" inter">
        <Row
          className="min-h-screen flex flex-col "
          style={{ backgroundColor: "#ffffff" }}
        >
          <div className=" p-3 flex flex-row justify-between">
            <Link to="/profile/business-name" className="mt-3 no-underline">
              <button
                type="button"
                className="px-3 p-2 flex gap-2 items-center  border-0 rounded-lg text-black font-semibold"
              >
                <FaArrowLeft />
                Back
              </button>
            </Link>
            <button
              onClick={() => {
                localStorage.clear();
                navigate('/')
              }}
              type="button"
              className="px-3 p-2  border-0 rounded-lg text-black font-semibold"
            >
              Logout
            </button>
          </div>

          <Col xl={6} xxl={5}
            lg={7}
            md={12}
            sm={12}
            xs={12}
            className="align-items-center justify-content-center d-flex  my-2 mx-auto !grow"
          >
            <Container fluid>
              <Row className="justify-content-center">
                <Col lg={9} md={9} sm={12} xs={12}>
                  <div>
                    <div className="inter ">
                      <p className="font-14 font-semibold text-black  mb-1">
                        TimeLink | Start building your business profile
                      </p>
                      <h3 className=" fw-bold  text-black ">
                        What’s your Phone Number?
                      </h3>
                      <form onSubmit={handleSubmit} className=" mt-4">
                        <div className="flex items-center h-10 px-2	rounded-3 border-[1.2px] border-gray-300 focus:outline-none w-full onboarding-fields">
                          <PhoneInput
                            international
                            defaultCountry="AU"
                            className="w-full"
                            placeholder="Phone Number"
                            value={phoneNumber}
                            name="phoneNumber"
                            onChange={setPhoneNumber}
                            class=" focus-visible:outline-none w-full text-sm"
                            disabled={userDetail?.is_phone_verified}
                          />
                          {
                            userDetail?.is_phone_verified ?
                              <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_5272_57137)">
                                  <path d="M8.43071 0.704608C8.73957 0.391758 9.26043 0.391758 9.56929 0.704608L11.0699 2.2246C11.2528 2.40995 11.5221 2.4935 11.7842 2.44628L13.9336 2.05903C14.376 1.97933 14.7973 2.27175 14.8547 2.69825L15.1333 4.7704C15.1673 5.02308 15.3337 5.24183 15.5748 5.35078L17.552 6.24419C17.9589 6.42807 18.1199 6.90122 17.9038 7.27846L16.854 9.11127C16.726 9.33477 16.726 9.60516 16.854 9.82867L17.9038 11.6615C18.1199 12.0387 17.9589 12.5119 17.552 12.6958L15.5748 13.5892C15.3337 13.6981 15.1673 13.9169 15.1333 14.1696L14.8547 16.2417C14.7973 16.6682 14.376 16.9606 13.9336 16.8809L11.7842 16.4937C11.5221 16.4465 11.2528 16.53 11.0699 16.7154L9.56929 18.2354C9.26043 18.5482 8.73957 18.5482 8.43071 18.2354L6.93014 16.7154C6.74715 16.53 6.47792 16.4465 6.21582 16.4937L4.06644 16.8809C3.62405 16.9606 3.20266 16.6682 3.14532 16.2417L2.86671 14.1696C2.83274 13.9169 2.66634 13.6981 2.42524 13.5892L0.448048 12.6958C0.0410954 12.5119 -0.11986 12.0387 0.09621 11.6615L1.14599 9.82867C1.27401 9.60516 1.27401 9.33477 1.14599 9.11127L0.0962101 7.27846C-0.11986 6.90122 0.0410956 6.42807 0.448049 6.24419L2.42524 5.35078C2.66634 5.24183 2.83274 5.02308 2.86671 4.7704L3.14532 2.69825C3.20266 2.27175 3.62405 1.97933 4.06644 2.05903L6.21582 2.44628C6.47792 2.4935 6.74715 2.40995 6.93014 2.2246L8.43071 0.704608Z" fill="#3461FD" />
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7696 7.10035C12.991 7.31829 12.9938 7.67443 12.7759 7.89582L8.34618 12.3959C8.1294 12.6161 7.77557 12.6202 7.55373 12.4051L5.23342 10.155C5.0104 9.93877 5.00492 9.58266 5.22118 9.35964C5.43745 9.13662 5.79356 9.13114 6.01658 9.3474L7.9361 11.2088L11.9741 7.10662C12.1921 6.88522 12.5482 6.88242 12.7696 7.10035Z" fill="white" />
                                </g>
                                <defs>
                                  <clipPath id="clip0_5272_57137">
                                    <rect width="18" height="18" fill="white" transform="translate(0 0.469971)" />
                                  </clipPath>
                                </defs>
                              </svg>
                              :
                              <></>
                          }
                        </div>
                        {/* <input
                          type="number"
                          required
                          className=" !placeholder-gray-400 h-10	text-sm p-2.5 rounded-3 border-[1.2px] border-gray-300 focus:outline-none w-full "
                          placeholder="Phone Number"
                          value={phoneNumber}
                          onChange={(e) => {
                            setPhoneNumber(e.target.value);
                          }}
                          name="phoneNumber"
                        /> */}
                        <button
                          type="submit"
                          className={`w-full h-10 border-0 flex justify-center items-center mt-3 rounded-md ${isValidPhoneNumber(phoneNumber ? phoneNumber : '') ? 'bg-blue-600' : 'bg-slate-400 pointer-events-none'} text-white p-2.5`}
                        >
                          Continue
                        </button>
                      </form>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
};

export default OnboardingNumber;
