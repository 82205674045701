/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Header from "../../components/Header";
import SideBar from "../../components/SideBar";
import { useNavigate } from "react-router-dom";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getBusinessRewards, seTgetErrorNull } from "../../redux/slices/userSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "reactstrap";
import { GoTriangleDown } from "react-icons/go";
import RewardItem from "../../components/pagination/RewardsItem";

const RewardsList = () => {
  const dispatch = useDispatch();
  const { isLoading, getError, businessRewards } = useSelector(
    (state) => state.user
  );
  const Navigate = useNavigate();
  const [timeframe, setTimeframe] = React.useState(false);
  const [client, setClient] = React.useState(false);
  const [status, setStatus] = React.useState(false);
  const [timeframeRadio, setTimeframeRadio] = React.useState("All-time");
  const [clientRadio, setClientRadio] = React.useState("All");
  const [statusRadio, setStatusRadio] = React.useState("All");
  useEffect(() => {
    if (getError && getError.length > 1) {
      toast.error(getError, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(seTgetErrorNull());

    }
  }, [getError]);
  useEffect(() => {
    dispatch(getBusinessRewards());
  }, []);

  return (
    <section className="business_admin_main">
      <div className="container-fluid ">
        <div className="row order ">
          {/* <!-- ===========SID-BAR======= --> */}
          <SideBar />
          {/* <!-- ========= content body ======= --> */}

          <div className="w-lg-80 px-0 pb-3   h-screen overflow-y-scroll ">
            {/* <!-- ===========HEADER========== --> */}
            <Header PageTitle="Rewards" />
            <div className="px-lg-4 px-md-3 px-2">
              <div className="container-fluid mt-4">
                <div className="row justify-content-between new-select-option g-0">
                  <div className="col-8">
                    <div className="row g-2">
                      <div className="col-4 position-relative">
                        <div
                          className=" stats_select rounded-5  d-flex  align-items-center white-border "
                          onClick={() => {
                            setTimeframe(!timeframe);
                            setStatus(false);
                            setClient(false);
                          }}
                        >
                          <p className="mb-0 me-1 text-dark-gray fw-medium ">
                            Timeframe:
                          </p>
                          <div className="d-flex align-items-center justify-content-between w-100">
                            {timeframeRadio.slice(0, 8)}
                            <GoTriangleDown />
                          </div>
                        </div>
                        {timeframe && (
                          <div className="position-absolute bg-white p-2 px-3 shadow w-100 top-100 start-0 rounded-4 z-1">
                            <FormControl>
                              <RadioGroup
                                // defaultValue="All-time"
                                onChange={(e) => {
                                  setTimeframeRadio(e.target.value);
                                  setTimeframe(false);
                                }}
                                value={timeframeRadio}
                                name="radio-buttons-time"
                              >
                                <FormControlLabel
                                  value="All (enabled by default)"
                                  control={
                                    <Radio
                                      sx={{
                                        color: "#1D59F9",
                                        "&.Mui-checked": {
                                          color: "#1D59F9   ",
                                        },
                                      }}
                                    />
                                  }
                                  label="All (enabled by default)"
                                />
                                <FormControlLabel
                                  value="active"
                                  control={
                                    <Radio
                                      sx={{
                                        color: "#1D59F9",
                                        "&.Mui-checked": {
                                          color: "#1D59F9   ",
                                        },
                                      }}
                                    />
                                  }
                                  label="Active"
                                />
                                <FormControlLabel
                                  value="inactive"
                                  control={
                                    <Radio
                                      sx={{
                                        color: "#1D59F9",
                                        "&.Mui-checked": {
                                          color: "#1D59F9   ",
                                        },
                                      }}
                                    />
                                  }
                                  label="Inactive"
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                        )}
                      </div>
                      <div className="col-4 position-relative">
                        <div
                          className=" stats_select rounded-5  d-flex flex-row align-items-center white-border "
                          onClick={() => {
                            setClient(!client);
                            setStatus(false);
                            setTimeframe(false);
                          }}
                        >
                          <p className="mb-0 me-1 text-dark-gray fw-medium ">
                            Client:
                          </p>
                          <div className="d-flex align-items-center justify-content-between w-100">
                            {clientRadio}
                            <GoTriangleDown />
                          </div>
                        </div>
                        {client && (
                          <div className="position-absolute bg-white p-2 px-3 shadow w-100 top-100 start-0 rounded-4 z-1">
                            <FormControl>
                              <RadioGroup
                                // defaultValue="All-time"
                                onChange={(e) => {
                                  setClientRadio(e.target.value);
                                  setClient(false);
                                }}
                                value={clientRadio}
                                name="radio-buttons-client"
                              >
                                <FormControlLabel
                                  value="All"
                                  control={
                                    <Radio
                                      sx={{
                                        color: "#1D59F9",
                                        "&.Mui-checked": {
                                          color: "#1D59F9   ",
                                        },
                                      }}
                                    />
                                  }
                                  label="All"
                                />
                                <FormControlLabel
                                  value="Once"
                                  control={
                                    <Radio
                                      sx={{
                                        color: "#1D59F9",
                                        "&.Mui-checked": {
                                          color: "#1D59F9   ",
                                        },
                                      }}
                                    />
                                  }
                                  label="One"
                                />
                                <FormControlLabel
                                  value="Monthly"
                                  control={
                                    <Radio
                                      sx={{
                                        color: "#1D59F9",
                                        "&.Mui-checked": {
                                          color: "#1D59F9   ",
                                        },
                                      }}
                                    />
                                  }
                                  label="Monthly"
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                        )}
                      </div>
                      <div className="col-4 position-relative">
                        <div
                          className=" stats_select rounded-5  d-flex flex-row align-items-center white-border "
                          onClick={() => {
                            setStatus(!status);
                            setClient(false);
                            setTimeframe(false);
                          }}
                        >
                          <p className="mb-0 me-1 text-dark-gray fw-medium ">
                            Status:
                          </p>
                          <div className="d-flex align-items-center justify-content-between w-100">
                            {statusRadio}
                            <GoTriangleDown />
                          </div>
                        </div>
                        {status && (
                          <div className="position-absolute bg-white p-2 px-3 shadow w-100 top-100 start-0 rounded-4 z-1">
                            <FormControl>
                              <RadioGroup
                                // defaultValue="All-time"
                                onChange={(e) => {
                                  setStatusRadio(e.target.value);
                                  setStatus(false);
                                }}
                                value={statusRadio}
                                name="radio-buttons-status"
                              >
                                <FormControlLabel
                                  value="All"
                                  control={
                                    <Radio
                                      sx={{
                                        color: "#1D59F9",
                                        "&.Mui-checked": {
                                          color: "#1D59F9   ",
                                        },
                                      }}
                                    />
                                  }
                                  label="All"
                                />
                                <FormControlLabel
                                  value="Active"
                                  control={
                                    <Radio
                                      sx={{
                                        color: "#1D59F9",
                                        "&.Mui-checked": {
                                          color: "#1D59F9   ",
                                        },
                                      }}
                                    />
                                  }
                                  label="Active"
                                />
                                <FormControlLabel
                                  value="Inactive"
                                  control={
                                    <Radio
                                      sx={{
                                        color: "#1D59F9",
                                        "&.Mui-checked": {
                                          color: "#1D59F9   ",
                                        },
                                      }}
                                    />
                                  }
                                  label="Inactive"
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-3 text-end">
                    <button
                      type="button"
                      onClick={() => {
                        Navigate("/rewards/create");
                      }}
                      className="btn-blue rounded-5 inter px-3 mt-2 p-2  fw-bold font-14 text-capitalize"
                    >
                      New Reward
                    </button>
                  </div>
                </div>
                <div className="container-fluid">
                  <div className="row justify-content-between mt-3 white-border rounded-4 p-3">
                    <div style={{ borderBottom: "1px solid #E4E5E7" }}>
                      <h6 className="font-14">Rewards list</h6>
                    </div>

                    <div className="container-fluid">
                      {isLoading ? (
                        <div className="row justify-content-center not-active-Campaigns rounded-3 text-center mt-3">
                          <Spinner color="dark" />
                        </div>
                      ) : (
                        <div className="webkit-scroll">
                          {businessRewards?.length < 1 ? (
                            <div
                              className="row justify-content-center align-items-center  not-active-Campaigns rounded-3 text-center mt-3"
                              style={{ height: "420px" }}
                            >
                              <div className="col-12">
                                <div className="Campaigns p-4 rounded-3">
                                  <h5 className="fw-bold mb-0">No Rewards</h5>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="container-fluid overflow-y-scroll"
                              style={{ height: "425px" }}
                            >
                              <div className="row justify-content-center">
                                <RewardItem currentItems={businessRewards} />
                                {/* <PaginatedItems items={businessRewards} itemsPerPage={2} ItemsComponent={NewLoyaltyItems} /> */}
                              </div>
                            </div>
                          )}
                          <div className="text-end pt-3">
                            <h6 className="font-14">
                              {businessRewards?.length}/10 Rewards
                            </h6>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
};

export default RewardsList;
