import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa6';
import { MdLock } from 'react-icons/md';
import { Col, Container, Row , Spinner } from 'reactstrap';
import logoTwo from '../assets/imges/Logo.png'
import { useSelector , useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { forgotPasswordReset, seTgetErrorNull } from '../redux/slices/userSlice';
import { useNavigate, useParams } from 'react-router-dom';


const NewPassword = () => {
    const navigate = useNavigate();
    let { token } = useParams();
    const dispatch = useDispatch()
    const { isLoading, getError } = useSelector((state) => state.user)
    const [password, setPassword] = useState('');
    const [togglePassword, setTogglePassword] = useState('password');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [toggleConfirmPassword, setToggleConfirmPassword] = useState('password');
    useEffect(()=>{
        if(!token){
            navigate('/auth/login')
        }
    },[])
    useEffect(() => {
        if (getError && getError.length > 1) {
            toast.error(getError, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            dispatch(seTgetErrorNull());
        }
    }, [getError])
    const hanleToggleType = () => {
        setTogglePassword(prevType => (prevType === "password" ? "text" : "password"));
    }
    const hanleToggleConfirm = () => {
        setToggleConfirmPassword(prevType => (prevType === "password" ? "text" : "password"));
    }
    function handleResponse(data){
        toast.success('Password reset successfully!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        navigate('/auth/login')
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if (confirmPassword === password) {
           
            dispatch(forgotPasswordReset({token,password} , handleResponse))
            setConfirmPassword('');
            setPassword('')
        }else{
            toast.error('Passwords do not match!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
    return (
        <Container fluid className='inter' >
            <Row className=' justify-content-center text-center'  >

                <Col lg={6} md={6} sm={10} xs={12} className='d-flex align-items-center 'style={{minHeight:"100vh"}} >
                    <form className='login-form py-3 w-100' onSubmit={handleSubmit}>
                        <img src={logoTwo} alt="w8" width="150" />
                        <div className='inter  my-4'>
                            <h4 className="font-24 fw-bold  text-dak-blue">Update Password</h4>
                        </div>
                        <div className="input-group my-3 rounded-4" style={{ border: "1.5px solid rgb(226, 232, 240)" }}>
                            <span className="input-group-text border-0 ps-3" id="basic-addon1"><MdLock
                                size={20} color='rgba(100, 116, 139, 1)' /></span>
                            <input type={togglePassword} required className="form-control border-0 p-3 ps-0 rounded-4 " placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)} name='password' />
                            <span className="input-group-text border-0 ps-3" role="button"
                                onClick={hanleToggleType} id="basic-addon1">
                                {
                                    togglePassword != 'password' ? <FaRegEye
                                        size={20} color='rgba(100, 116, 139, 1)' /> : <FaRegEyeSlash
                                        size={20} color='rgba(100, 116, 139, 1)' />}</span>
                        </div>
                        <div className="input-group my-3 rounded-4" style={{ border: "1.5px solid rgb(226, 232, 240)" }}>
                            <span className="input-group-text border-0 ps-3" id="basic-addon2"><MdLock
                                size={20} color='rgba(100, 116, 139, 1)' /></span>
                            <input type={toggleConfirmPassword} required className="form-control border-0 p-3 ps-0 rounded-4 " placeholder='Confirm Password' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} name='password' />
                            <span className="input-group-text border-0 ps-3" role="button"
                                onClick={hanleToggleConfirm} id="basic-addon2">
                                {
                                    toggleConfirmPassword != 'password' ? <FaRegEye
                                        size={20} color='rgba(100, 116, 139, 1)' /> : <FaRegEyeSlash
                                        size={20} color='rgba(100, 116, 139, 1)' />}</span>
                        </div>
                        <Button type='submit' className='btn-blueTwo rounded-4 inter  px-3 p-3 w-100 fw-semibold font-18 text-capitalize ' sx={{ fontFamily: 'Inter, sans-serif !important' }} >{isLoading ?<Spinner color="light" />:'Submit'}</Button>
                    </form>
                </Col>
            </Row>
            <ToastContainer />
        </Container>
    )
}

export default NewPassword
