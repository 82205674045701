import React, { useState, useEffect } from 'react';
import logo from '../../assets/imges/LogoWhite.png';
import logoTwo from '../../assets/imges/Logo.png'
import Activity from '../../assets/imges/LoginActivity.png';
import '../../style/businesLogin.scss'
import { Col, Container, Row, Spinner } from 'reactstrap';
import { FaFacebook, FaRegEnvelope, FaRegEye, FaRegEyeSlash, FaRegUser } from 'react-icons/fa';
import { Button } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FcGoogle } from "react-icons/fc";
import { MdLock } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { resendOtp, signInWithGoogle, userLogin } from '../../redux/slices/userSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../../firebase/firebase.config';
import { LoginSocialGoogle } from 'reactjs-social-login';
const Login = () => {
    const dispatch = useDispatch();
    const { isLoading, getError } = useSelector((state) => state.user)
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [type, setType] = useState('password');
    useEffect(() => {
        if (getError && getError.length > 1) {
            toast.error(getError, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }, [getError])

    useEffect(() => {
        const token = localStorage.getItem('userToken');
        const userDetail = JSON.parse(localStorage.getItem('userDetails'))
        console.log(token, userDetail);
        if (token && userDetail?.role == 'business') {
            navigate('/')
        } else if (token && userDetail?.role == 'admin') {
            navigate('/super/device')
        }
    }, [])
    const toggleType = () => {
        if (type == 'password') {
            setType('text');
        }
        else {
            setType('password');

        }
    }
    if (email.length > 0) {
        var style = {
            border: '1.5px solid rgba(37, 99, 235, 1)',
        }
    }
    else {
        var style = {
            border: '1.5px solid rgba(226, 232, 240, 1)',
        }
    }

    // =========
    if (password.length > 0) {
        var stylepassword = {
            border: '1.5px solid rgba(37, 99, 235, 1)',
        }
    }
    else {
        var stylepassword = {
            border: '1.5px solid rgba(226, 232, 240, 1)',
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if (email.length > 0 && password.length >= 8) {
            var data = {
                "email": email,
                "password": password,
            }
            dispatch(userLogin(data, handleResponse))
        }

    }
    function handleNavigation(data) {
        if (data.business_name == 'null') {
            navigate('/auth/signup/profile')
        } else {
            navigate('/')
        }
    }

    function handleResponse(data) {
        if (data.is_verified) {
            localStorage.setItem('isSignedIn', true)
            data.role == 'admin' ? navigate('/super/newBusiness') : handleNavigation(data)
        } else {
            localStorage.setItem('tempToken', localStorage.getItem('userToken'))
            localStorage.removeItem('userToken')
            alert('Your Email has not been verified yet please verify now!');
            dispatch(resendOtp(handleResponse))
            navigate('/auth/signup/otp')
        }
    }
    const provider = new GoogleAuthProvider();
    function loginWithGoogle() {
        signInWithPopup(auth, provider)
            .then(async (result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                const user = result.user;
                var data = {
                    email: user.email,
                    name: user.displayName,
                }
                dispatch(signInWithGoogle(data, handleResponse))
                console.log(user)
            }).catch((error) => {
                console.log(error.message)
            });
    }
    return (
        <>
            <stripe-pricing-table
                pricing-table-id="prctbl_1PXc9REhsh9AaJCvzfwOwy0p"
                publishable-key="pk_test_51N3dH4Ehsh9AaJCv68cNxHFJAKqdiWcputPDcxfTBEB2EcxIo6v09gx4PSFrs08DmC4gsoEwBpd6dELKOG81vjDR008N7o6PLM"
                customer-email="mafzaal@timelink.au"
            >
            </stripe-pricing-table>
            {/* <Container fluid className='login_height inter' >
                <Row className='login_height justify-content-center' style={{ backgroundColor: '#ffffff' }}>
                    {/* <Col lg={6} md={12} sm={12} xs={12} className='d-lg-flex d-md-block d-sm-block d-none align-items-center ' style={{ backgroundColor: '#2563EB' }}>
                        <Link to="https://www.timelink.au/">

                            <img src={logo} alt="w8" className='mt-3 auth-logo' width="150" />
                        </Link>


                        <div className="text-center " style={{ height: 'fit-content' }}>


                            <img src={Activity} alt="w8" className='w-75 mt-5 ' />
                            <div className='text-white inter text-center mb-5'>
                                <h4 className="font-24 fw-bold  ">Admin Console Acess</h4>
                                <p className="font-14   ">Manage, monitor and maximise your TimeLink experience.</p>
                            </div>
                        </div>

                    </Col> 
                    <div className=''>
                        <img src={logoTwo} alt="w8" className='mt-3 ' width="150" />
                    </div>

                    <Col lg={6} md={12} sm={12} xs={12} className='align-items-center justify-content-center d-flex mt-lg-0  pb-5 mt-4 pt-lg-5 pt-md-5 pt-sm-5 pt-2' >
                        <Container fluid >
                            <Row className='justify-content-center'>
                                <Col lg={9} md={9} sm={12} xs={12} >
                                    <form className='login-form' onSubmit={handleSubmit}>
                                        <div className='inter '>
                                            <h4 className="font-24 fw-bold  text-dak-blue">Sign In to your Account</h4>
                                            <p className="font-14 text-dark-gray ">Welcome back! please enter your detail</p>

                                        </div>
                                        <div className="input-group my-4 rounded-4 bg-white" style={style}>
                                            <span className="input-group-text border-0 ps-3" id="basic-addon1"><FaRegEnvelope
                                                size={20} color='rgba(100, 116, 139, 1)' /></span>
                                            <input type="email" required className="form-control border-0 p-3 ps-0 rounded-4 " placeholder="Business email" value={email} onChange={(e) => setEmail(e.target.value)} name='email' />
                                        </div>
                                        <div className="input-group my-3 rounded-4 bg-white" style={stylepassword}>
                                            <span className="input-group-text border-0 ps-3" id="basic-addon1"><MdLock
                                                size={20} color='rgba(100, 116, 139, 1)' /></span>
                                            <input type={type} required className="form-control border-0 p-3 ps-0 rounded-4 " placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)} name='password' /><span className="input-group-text border-0 ps-3" role="button"
                                                onClick={toggleType} id="basic-addon1">{
                                                    type != 'password' ? <FaRegEye
                                                        size={20} color='rgba(100, 116, 139, 1)' /> : <FaRegEyeSlash
                                                        size={20} color='rgba(100, 116, 139, 1)' />}</span>
                                        </div>

                                        <div className="d-flex  justify-content-between  mt-3">
                                            <div>

                                                <input type="checkbox" id="terms" className="login-terms" />
                                                <label className="font-14 text-dark-gray ms-2" htmlFor="terms"><b className="text-dark">Remember me</b> </label>
                                            </div>
                                            <Link to='/auth/reset' className='text-decoration-none'>
                                                <p className="font-16 fw-bold  colorPrimary inter"> Forgot Password?</p>
                                            </Link>
                                        </div>

                                        <Button type='submit' className='btn-blueTwo rounded-4 inter mt-3 px-3 p-3 w-100 fw-bold font-16 text-capitalize ' sx={{ fontFamily: 'Inter, sans-serif !important' }} >{isLoading ? <Spinner color="light" /> : 'Sign in'}</Button>
                                        <p class="font-14 inter text-dark-gray Or-lines d-flex justify-content-center align-items-center my-4">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Or sign in with&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>

                                    </form>
                                    <Row className='inter mt-3'>
                                        <Col lg={6} md={6} sm={6} xs={12} >
                                            <LoginSocialGoogle

                                                client_id={'212290965015-r0v8ahr9q13u3upesu87bisjvtoi82n7.apps.googleusercontent.com' || ''}
                                                onLoginStart={() => { console.log('oauth 2.0 started') }}
                                                onResolve={({ provider, data }) => {
                                                    var d = {
                                                        email: data.email,
                                                        name: data.name,
                                                    }
                                                    dispatch(signInWithGoogle(d, handleResponse))
                                                    console.log('data object to be sent in signInWithGoogle', d)
                                                    //console.log(provider, data)
                                                }}
                                                onReject={(err) => {
                                                    console.log(err)
                                                }}
                                            >
                                                <button className='just-border bg-tranparent p-3 w-100  text-center rounded-4 flex justify-center items-center gap-1'>
                                                    <FcGoogle size={18} /><span className='fw-bold ms-2'>Google</span>
                                                </button>
                                            </LoginSocialGoogle>
                                        </Col>
                                        <Col lg={6} md={6} sm={6} xs={12} className='mt-lg-0 mt-md-0 mt-sm-0 mt-3' >
                                            <button className='just-border bg-tranparent p-3 w-100 text-center rounded-4 flex justify-center items-center gap-1'>
                                                <FaFacebook color='#2563EB' size={18} /><span className='fw-bold ms-2'>Facebook</span>
                                            </button>
                                        </Col>

                                    </Row>
                                    <p className="text-center  mt-4">
                                        Don’t have an account?
                                        <span className="font-16 fw-bold cursor-pointer colorPrimary inter"
                                            onClick={() => navigate('/auth/signup')}> Sign Up</span>
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <ToastContainer />
            </Container> */}
        </>
    );
}

export default Login;
