import React from 'react'
import { Navigate, useLocation , Outlet} from "react-router-dom"

const MarketingRoutes = ({ children }) => {
    const token = localStorage.getItem('userToken');
    const userDetail = JSON.parse(localStorage.getItem('userDetails'))
    let location = useLocation();
    console.log(token , userDetail);
    if (token && userDetail?.role == 'marketing_staff') {
        return <Outlet />
    }else if(token && userDetail?.role == 'business'){
        return <Navigate to="/" state={{ from: location }} replace />
    }else if (token && userDetail?.role == 'admin') {
        return <Navigate to="/super/newBusiness" state={{ from: location }} replace />
    }else{
        localStorage.clear();
        return <Navigate to="/auth/login" state={{ from: location }} replace />
    }

};

export default MarketingRoutes;