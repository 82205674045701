import React from "react";
import { AiFillShop } from "react-icons/ai";
import { FaBlog, FaTags } from "react-icons/fa";
import { FaRegFileLines } from "react-icons/fa6";
import { HiOutlineUsers, HiOutlineViewGridAdd } from "react-icons/hi";
import { MdDevices } from "react-icons/md";
import { PiUserFocusFill } from "react-icons/pi";
import { SiCoinmarketcap, SiGoogleanalytics } from "react-icons/si";
import { NavLink, useLocation } from "react-router-dom";
import { HiOutlineCodeBracketSquare } from "react-icons/hi2";
import { CiSettings } from "react-icons/ci";
import { useSelector } from "react-redux";
const SidebarLink = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const { isLoading, userDetail } = useSelector((state) => state.user)

  return (
    <>
      <div className="w-full">
        {/* <ul className='ps-0 sideNav scrolll side-scroll' style={{ height: '60vh', boxShadow: 'none' }}> */}
        <ul className="ps-0 mt-6">
          {
            userDetail?.role == 'marketing_staff' ?
              <li className="mt-4">
                <NavLink
                  className={({ isActive }) =>
                    ` flex items-center text-base font-medium p-2 ps-3 rounded-lg no-underline ${isActive ? " bg-[#E8EEFE]  text-customBlue" : "text-black"
                    }`
                  }
                  to="/marketing/blog"
                >
                  <FaBlog size={18} className="me-2 " /> Blog
                </NavLink>
              </li> :
              <>
                {/* --------------- New Business------------- */}
                <li className="">
                  <NavLink
                    className={({ isActive }) =>
                      ` flex items-center text-base font-medium p-2 ps-3 rounded-lg no-underline ${isActive ? " bg-[#E8EEFE]  text-customBlue" : "text-black"
                      }`
                    }
                    to="/super/newBusiness"
                  >
                    <FaRegFileLines size={18} className="me-2 " /> New Business
                  </NavLink>
                </li>
                {/* -------------------- Devices-------------- */}
                <li className="mt-4">
                  <NavLink
                    className={({ isActive }) =>
                      ` flex items-center text-base font-medium p-2 ps-3 rounded-lg no-underline ${isActive ? " bg-[#E8EEFE]  text-customBlue" : "text-black"
                      }`
                    }
                    to="/super/device"
                  >
                    <MdDevices size={18} className="me-2 " /> Devices
                  </NavLink>
                </li>

                {/* -------------- Business Management------------ */}
                <li className="mt-4">
                  <NavLink
                    className={({ isActive }) =>
                      ` flex items-center text-base font-medium p-2 ps-3 rounded-lg no-underline ${isActive ? " bg-[#E8EEFE]  text-customBlue" : "text-black"
                      }`
                    }
                    to="/super/businessManagment"
                  >
                    <SiGoogleanalytics size={18} className="me-2 " /> Business
                    Management
                  </NavLink>
                </li>
                {/* ------------ Users------------ */}
                <li className="mt-4">
                  <NavLink
                    className={({ isActive }) =>
                      ` flex items-center text-base font-medium p-2 ps-3 rounded-lg no-underline ${isActive ? " bg-[#E8EEFE]  text-customBlue" : pathname === "/super/userManagment" ? "bg-[#E8EEFE]  text-customBlue" : "text-black"
                      }`
                    }
                    to="/super/user"
                  >
                    <HiOutlineUsers size={18} className="me-2 " /> Users
                  </NavLink>
                </li>
                {/* ----------------- Marketing Program-------------- */}
                <li className="mt-4">
                  <NavLink
                    className={({ isActive }) =>
                      ` flex items-center text-base font-medium p-2 ps-3 rounded-lg no-underline ${isActive ? " bg-[#E8EEFE]  text-customBlue" : "text-black"
                      }`
                    }
                    to="/super/marketing"
                  >
                    <HiOutlineCodeBracketSquare size={18} className="me-2 " />{" "}
                    Marketing Program
                  </NavLink>
                </li>
                {/* ---------------Loyalty Program------- */}
                {/* <li className='mt-4'>

                            <NavLink className='forLink' to='/super/loyalty'><FaTags size={18} className='me-2 ' />Loyalty Program </NavLink>
                        </li> */}
                {/* ---------------Survey------- */}
                <li className="mt-4">
                  <NavLink
                    className={({ isActive }) =>
                      ` flex items-center text-base font-medium p-2 ps-3 rounded-lg no-underline ${isActive ? " bg-[#E8EEFE]  text-customBlue" : "text-black"
                      }`
                    }
                    to="/super/survey"
                  >
                    <CiSettings size={18} className="me-2 " /> Survey
                  </NavLink>
                </li>
                {/* --------------- Stats---------- */}
                {/* <li className='mt-4'>

                            <NavLink className='forLink' to='/Billing'> <ImStatsDots size={18} className='me-2 ' /> Stats</NavLink>
                        </li> */}
                {/* ------------ Chat------- */}
                {/* <li className='mt-4'>

                            <NavLink className='forLink' to='/Billing'><BsChatLeftTextFill size={18} className='me-2 ' /> Chat</NavLink>
                        </li> */}
                {/* ------------ Support tickets---------- */}
                {/* <li className='mt-4
                        '>

                            <NavLink className='forLink' to='/Billing'>   <RiTicketFill size={18} className='me-2 ' /> Support tickets</NavLink>
                        </li> */}
                <li className="mt-4">
                  <NavLink
                    className={({ isActive }) =>
                      ` flex items-center text-base font-medium p-2 ps-3 rounded-lg no-underline ${isActive ? " bg-[#E8EEFE]  text-customBlue" : "text-black"
                      }`
                    }
                    to="/super/blog"
                  >
                    <FaBlog size={18} className="me-2 " /> Blog
                  </NavLink>
                </li>
                <li className="mt-4">
                  <NavLink
                    className={({ isActive }) =>
                      ` flex items-center text-base font-medium p-2 ps-3 rounded-lg no-underline ${isActive ? " bg-[#E8EEFE]  text-customBlue" : "text-black"
                      }`
                    }
                    to="/https://www.timelink.au"
                  //   to="/super/newBusiness"
                  >
                    <HiOutlineCodeBracketSquare size={18} className="me-2 " /> Website
                  </NavLink>
                </li>
              </>
          }
        </ul>
      </div>
      {/* <div className="text-center mt-5 inter"> */}
      {/* <button className="upgrade-btn font-12 fw-medium rounded-5 px-3 p-2">
          Super Admin
        </button>
      </div> */}
    </>
  );
};

export default SidebarLink;
