import React from 'react';
import { FiUploadCloud } from 'react-icons/fi';
import { IoSettingsOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';

const IntegrationCard = ({ title, description, icon, isConnected, showConnectModal, showDisonnectModal }) => {
    return (
        <div className="bg-white border-1  p-8">
            <div className=" ">
                <img src={icon} alt={`${title} icon`} className="w-8 h-8 " />
                <div className='mt-2'>
                    <h6 className="text-lg font-nedium mb-0">{title}</h6>
                    <p className="text-gray-500 text-sm mb-2">{description}...</p>
                </div>
            </div>
            <div className="flex gap-3 items-center">
                <button className={`px-3 py-1.5 rounded-full shadow-[0_9px_28px_8px_#0000000D]
                 text-xs flex gap-1 items-center border-[1.3px] ${isConnected ? 'bg-[#1B59F8] text-white  border-black' : 'bg-white  border-gray-300 text-blue-700'}`}
                    onClick={() => {
                        if (title === 'Square') {
                            if (isConnected) {
                                showDisonnectModal()
                            } else {
                                showConnectModal();
                            }
                        }
                    }}
                >
                    {isConnected ? 'Connected' : 'Connect'} <span> {!isConnected ? <FiUploadCloud size={15} /> : <IoSettingsOutline size={15} />}</span>
                </button>
                <Link to={`/integrations/detail/1`} className="text-black text-xs no-underline	">Integration details →</Link>
            </div>
        </div>
    );
};

export default IntegrationCard;
