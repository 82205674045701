import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Spinner } from 'reactstrap';
import LoginHeader from '../../components/LoginHeader';
import verfy from '../../assets/imges/OtpVerification.png'
import OtpInput from 'react-otp-input';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resendOtp, verifyOtp } from '../../redux/slices/userSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Otp = () => {
    const dispatch = useDispatch();
    const { isLoading, getError } = useSelector((state) => state.user)
    const navigate = useNavigate();
    const [otp, setOtp] = useState('');
    useEffect(() => {
        if (getError && getError.length > 1) {
            toast.error(getError, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }, [getError])
    const renderInput = (inputProps, index) => {


        return (
            <input
                {...inputProps}
                maxLength={1}
            />
        );
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (otp.length > 4) {
            var data = {
                token: otp,
                email: localStorage.getItem('authEmail')
            };
            dispatch(verifyOtp(data, handleResponse));
        }
    }
    function handleResnedOtp() {
        var data = {
            email: localStorage.getItem('authEmail')
        };
        dispatch(resendOtp(data, handleResponse))
    }
    function handleResponse(data, isResend) {
        if (isResend) {
            toast.success(data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            navigate('/auth/signup/Profile')
        }
    }
    return (
        <>
            <Container fluid className='signup-main' style={{backgroundColor:'#ffffff'}}>
                <LoginHeader />
                <Row className='d-flex align-items-center justify-content-center login_height mt-lg-0 mt-md-0 mt-4'>
                    <Col lg={4} md={12} sm={12} xs={12} >

                        <form className='business-login-form rounded-4 p-3'>

                            <div className='flex justify-center '>
                                <img src={verfy} width={132} className='mt-2' alt=" Not Found" />
                            </div>
                            <div className='inter text-center mt-3'>
                                <h4 className="font-24 fw-bold  text-dak-blue">Verify your Email</h4>
                                <p className="font-14 text-dark-gray ">Thank you, check your email for your one time passcode. </p>
                            </div>
                            <div className='otp '>
                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={5}
                                    renderSeparator={<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
                                    renderInput={renderInput}
                                />
                            </div>
                            <Button className='btn-blueTwo rounded-4 inter px-3 mt-5 p-3 w-100 fw-bold font-16 text-capitalize mx-2' sx={{ fontFamily: 'Inter, sans-serif !important' }} onClick={handleSubmit}>{isLoading ? <Spinner color="light" /> : 'Verify'}</Button>
                            <p className="font-14 text-dark-gray inter text-center mt-3">Don’t receive an email? <span className='admin-text-blue fw-semibold cursor-pointer'
                                onClick={handleResnedOtp}>Resend</span></p>
                        </form>


                    </Col>
                </Row>
                <ToastContainer />
            </Container>
        </>
    );
}

export default Otp;
