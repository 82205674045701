export const getTotalTimeForOrder = (start_time, end_time) => {
    // if(!end_time){
    //     end_time = '00:00:00'
    // }
    // console.log(start_time , end_time)
    if (start_time && end_time) {
        // Extract the time portion from the date-time strings
        const startTimeParts = start_time.split(':')
        const endTimeParts = end_time.split(':')

        if (startTimeParts.length === 3 && endTimeParts.length === 3) {
            // Parse the time components into integers
            const startHour = parseInt(startTimeParts[0], 10)
            const startMinute = parseInt(startTimeParts[1], 10)
            const startSecond = parseInt(startTimeParts[2], 10)
            const endHour = parseInt(endTimeParts[0], 10)
            const endMinute = parseInt(endTimeParts[1], 10)
            const endSecond = parseInt(endTimeParts[2], 10)

            // Calculate the time duration in milliseconds
            const startTimeInMillis = (startHour * 3600 + startMinute * 60 + startSecond) * 1000
            const endTimeInMillis = (endHour * 3600 + endMinute * 60 + endSecond) * 1000
            const timeDiff = endTimeInMillis - startTimeInMillis

            return formatDuration(timeDiff)
        } else {
            return 'N/A'
        }
    } else {
        return 'N/A'
    }
}

const formatDuration = ms  => {
    if (ms < 0) ms = -ms;
    const time = {
      d: Math.floor(ms / 86400000),
      h: Math.floor(ms / 3600000) % 24,
      m: Math.floor(ms / 60000) % 60,
    };
    return Object.entries(time)
      .filter(val => val[1] !== 0)
      .map(val => val[1] + ' ' + (val[1] !== 1 ? val[0] + '' : val[0]))
      .join(', ');
  };