import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/zoom.css";
import moment from "moment";
import React from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaRegClock, FaRegUser } from "react-icons/fa6";
import { FiDownload } from "react-icons/fi";
import { IoGlobeOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
export default function UserItems({ currentItems }) {
  const Navigate = useNavigate();
  return (
    <>
      {currentItems.map((index) => {
        return (
          <div
            className="flex gap-4 justify-between  relative p-3 bg-white rounded-xl flex-wrap mb-2 border"
          >
            <div
              className="inter d-flex align-items-center w-60 "
            >
              <div className="px-3">
                <input
                  type="checkbox"
                  className="!accent-[#65558F] !border-[#C8C3C9] !border-[1.5px] scale-150	"
                />
              </div>

              <div>
                <h3
                  className="font-18 "
                  style={{ color: "rgba(74, 74, 74, 1)" }}
                >
                  {index?.phone}
                </h3>
                <h3
                  className="font-16"
                  style={{ color: "rgba(83, 83, 83, 1)" }}
                >
                  {index?.email || "N/A"}
                </h3>
                <h3
                  className="font-10"
                  style={{ color: "rgba(83, 83, 83, 1)" }}
                >
                  {index?.name || "N/A"}
                </h3>
                <h3
                  className="font-10"
                  style={{ color: "rgba(83, 83, 83, 1)" }}
                >
                  {" "}
                  Joined: {moment(index?.created_at).format("DD MMM YYYY")}
                </h3>
              </div>
            </div>
       
            <div className="d-flex align-items-center border-l border-r px-3 bg-white">
              <div className="text-center">
                <h1 className="font-medium text-gray-700 text-3xl inter mb-0 ">
                {index?.user_orders_count}
                </h1>
                <p className="font-medium text-sm text-gray-700 mb-1 inter ">
                  Orders
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <button
                className="bg-green-500 text-white p-2 font-medium text-sm poppins px-4  rounded-full"
              >
                Active
              </button>
            </div>

            <div
              className="d-flex align-items-center border-l ps-3">
              <div>
                <div className=" d-flex text-start  gap-2">
                  {/* <MdToggleOn className='grey' size={28} /> */}
                  <IoGlobeOutline size={20} />
                  <p
                    className="mb-0 text-sm fw-medium "
                  >
                    Australia
                  </p>
                </div>
                <div className="d-flex gap-2  mt-2 text-start">
                <FaRegUser size={20} />
                  <p
                    className="mb-0 text-sm fw-medium "
                  >
                    {index?.gender || "N/A"}
                  </p>
                </div>
                <div className="d-flex  mt-2 gap-2 text-start">
                <FaRegClock size={20} />

                  <p
                    className="mb-0 text-sm fw-medium  "
                  >
                    Last order on:{" "}
                    {moment(index?.last_order?.created_at).format(
                      "DD MMM YYYY"
                    )}
                    <br />@ {index?.last_order?.business?.business_name}
                  </p>
                </div>
              </div>
            </div>
          
            <div className="flex gap-2 items-center">
            <div className=" h-7 w-7 flex items-center justify-center rounded-lg bg-customBlue">
                <FiDownload size={18} color="white" />
              </div>
              <div className="relative">

              <Menu
                menuButton={
                  <MenuButton>
                    <div className=" h-7 w-7 flex items-center justify-center border-gray-600 rounded-lg bg-white border">
                      <BsThreeDotsVertical size={18} />
                    </div>
                  </MenuButton>
                }
                direction="left"
                align="center"
                position="anchor"
                arrow={true}
                shift={12}
                transition
                menuClassName="!min-w-fit"
              >
                <MenuItem>Edit</MenuItem>
                <MenuItem onClick={()=> Navigate('/super/userManagment')}>Detail</MenuItem>
                <MenuItem>Delete</MenuItem>
              </Menu>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}
