import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Container, Row } from "reactstrap";
import "../../style/businesLogin.scss";
import DataPending from "../../assets/imges/DataPending.png";
import logo from "../../assets/imges/Logo.png";
import { useDispatch, useSelector } from "react-redux";
import { sendAccountUnderReviewEmail } from "../../redux/slices/userSlice";
import FullScreenLoader from "../../components/loaders/FullScreenLoader";
const Reviewing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, userDetail } = useSelector(
    (state) => state.user
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    // navigate("/onboarding/number/verify");
  };
  useEffect(() => {
    if (Object.keys(userDetail).length > 0) {
      if (!userDetail?.phone) {
        navigate("/onboarding/number");
      } else if (!userDetail?.is_phone_verified) {
        navigate("/onboarding/number/verify");
      } else if (!userDetail?.business_name) {
        navigate("/profile/business-name");
      } else if (!userDetail?.abn) {
        navigate("/profile/abn");
      } else if (!userDetail?.image) {
        navigate("/profile/logo");
      } else if (!userDetail?.business_category) {
        navigate("/profile/category");
      } else if (!userDetail?.is_approved) {
        navigate("/onboarding/reviewing");
      } else 
      if (userDetail?.is_approved && !userDetail?.subscription_information?.stripe_price_id) {
        navigate("/subscription/payment");
      }
    }
  }, [userDetail]);
  return (
    <>
    {
      isLoading &&
      <FullScreenLoader isLoading={isLoading} />
    }
      <Container fluid className=" inter">
        <Row
          className="min-h-screen flex flex-col "
          style={{ backgroundColor: "#ffffff" }}
        >
          <div className=" p-3 flex justify-between">
            <button
              type="button"
              className="px-3 invisible	 font-semibold"
            >
              Logout
            </button>

            <img src={logo} className="w-32" alt="w8" />

            <button
              onClick={() => {
                localStorage.clear();
                navigate('/')
              }}
              type="button"
              className="px-3 p-2  border-0 rounded-lg text-black font-semibold"
            >
              Logout
            </button>

          </div>

          <Col
            xxl={4}
            xl={6}
            lg={6}
            md={10}
            sm={10}
            xs={12}
            className="flex justify-evenly flex-col items-center inter flex-1 py-2 mx-auto !grow"
          >
            <img src={DataPending} className="w-16 h-16" alt="not found" />
            <h3 className=" fw-semibold text-center text-black ">
              We’re reviewing your profile.
            </h3>
            <p className="font-16 font-medium   text-center text-black  mb-1">
              Thank you for signing up to TimeLink. You should hear back from us
              pretty quickly, but in some cases, it could take up to 2-3
              business days.
            </p>
            <div>

              <p className="font-16 font-medium   text-center text-black  mb-1">
                For any questions, please contact our support team.
              </p>
              <p className="font-16 font-medium text-center text-black mb-1">
                <a href="mailto:support@timelink.au" className="text-black text-decoration-none">support@timelink.au</a>
              </p>

            </div>

            <button
              type="submit"
              className="px-14 mx-auto h-10 border-0 flex justify-center items-center m rounded-md bg-blue-600 text-white p-2.5"
            >
              Watch a demo
            </button>

          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
};

export default Reviewing;
