import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaRegEnvelope } from 'react-icons/fa';
import logo from '../../assets/imges/Logo.png'
import resetDone from '../../assets/imges/resetDone.png'
import { RiShareBoxFill } from 'react-icons/ri';
const ResetHelpWay = () => {
    const location = useLocation();
    const {email} = location.state;

    const navigate = useNavigate();





    return (
        <>
        <Container fluid className='reset-pages'>
                <Row className=' justify-content-center d-flex login_height py-5 h-100 ' >
                    <Col lg={5} md={8} sm={10} xs={12} className='d-flex justify-content-between flex-column' >

                        <div className='rounded-4 rest-screens px-lg-3 px-md-3 px-sm-3 inter' >

                            <div className="d-flex justify-content-center">

                                <img src={logo} alt="w8" className='reset-logo' />
                            </div>
                            <div className="d-flex justify-content-center">

                                <img src={resetDone} alt="w8" className='reset-done-img' />
                            </div>
                            <div className='inter text-center  mt-2'>
                                <h4 className="font-24 fw-bold  text-dak-blue">Help is on the way</h4>
                                <p className="font-14 text-dark-gray ">We’re combing through our records to find the TimeLink account for <strong className='text-dark'>{email}. </strong></p>
                                <p className="font-14 text-dark-gray ">If we find a match, you’ll get an email with further instructions.
                                    If you don’t hear from us in the next 15 minutes, please double check that you entered the correct email address and check your spam folder. </p>
                            </div>


                            <p className="text-center inter mt-4">
                                Go Back to
                                <span className="font-16 fw-bold cursor-pointer colorPrimary inter"
                                    onClick={() => navigate('/auth/login')}> console.timelink.au/login</span>
                            </p>
                        </div>
                        <div>
                            <p className="text-center inter font-14 mt-4">

                                ©2024 Time Link Tech Pty Ltd, All Rights Reserved.
                                <span className="font-16 d-block fw-bold cursor-pointer colorPrimary inter"
                                > Privacy Policy&nbsp;&nbsp;<RiShareBoxFill size={18} className='text-gray' /></span>
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ResetHelpWay;
