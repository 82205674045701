/* eslint-disable eqeqeq */
import React from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateLoyaltyStatus } from '../../redux/slices/userSlice';

export default function LoyaltyItems({ currentItems }) {
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    function handleUpdateLoyaltyStatus(id, currentStatus) {
        var data = {
            loyalty_id: id,
            status: currentStatus == 'active' ? 'inactive' : 'active'
        }
        dispatch(updateLoyaltyStatus(data))
    }
    return (
        <>
            {
                currentItems.map((index) => {
                    return (
                        <div onClick={() => Navigate('/loyalty/detail', { state: { loyalty: index } })} className="col-12  mt-3 not-active-Campaigns p-2 rounded-4 cursor-pointer">
                            <div className="row">
                                <div className="col-lg-5 col-12 align-self-center">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <img src={index?.backdrop_image} className='object-fit-cover rounded-4   ' width={70} height={70} alt="w8" />
                                        </div>
                                        <div className="ps-2">
                                            <p className="mb-0 font-14 fw-bold inter colorblack">{index.card_name}</p>
                                            <p className="mb-0 font-15 inter colorgray">{index.description}
                                                <br />
                                                {index.offer_details}. </p>
                                        </div>
                                    </div>
                                </div>
                                
                                    <div className="col-lg-2 col-12 my-lg-0 my-2  align-self-center">
                                        <button className={`${index.status == 'active'?'btn-green':'btn-Pending'} rounded-5 px-3 py-1 fw-medium font-14 text-capitalize`}>
                                            {index.status}</button>
                                    </div>
                                
                                <div className="col-lg-5 col-12 align-self-center" onClick={(e) => e.stopPropagation()}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <p className="font-15 font-500 inter colorgray mb-0"> Reach</p>
                                            <h6 className="mt-2">{index?.reaches?.length}</h6>
                                        </div>
                                        <div>
                                            <p className="font-15 font-500 inter colorgray mb-0"> Opened </p>
                                            <h6 className="mt-2">0%</h6>
                                        </div>
                                        <div>
                                            <p className="font-15 font-500 inter colorgray mb-0"> Clicked</p>
                                            <h6 className="mt-2">0%</h6>
                                        </div>
                                        <div className='dropdown dropdown-angle-none'>
                                            <span className="dropdown-toggle colorgray" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className="fa-solid fa-ellipsis-vertical me-lg-3 fs-3" />
                                            </span>
                                            <ul className="dropdown-menu">
                                                <li><span className="dropdown-item" onClick={()=>{handleUpdateLoyaltyStatus(index.id , index.status)}}>{index.status == 'active' ? 'Inactivate' : 'Activate'}</span></li>
                                                <li><a className="dropdown-item">Another action</a></li>
                                                <li><a className="dropdown-item">Something else here</a></li>
                                            </ul>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </>
    )
}
