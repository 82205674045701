import { Button } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Container, Row } from "reactstrap";
import logoTwo from "../../assets/imges/Logo.png";
import "../../style/businesLogin.scss";
import { RiErrorWarningLine } from "react-icons/ri";
const ForgotPasswordWay = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { email } = location.state || "";

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <Container fluid className=" inter">
        <Row
          className="min-h-screen flex flex-col justify-between p-3"
          style={{ backgroundColor: "#ffffff" }}
        >
          <div className="flex justify-between items-center gap-2 p-2">
            <img src={logoTwo} alt="w8" className=" " width="150" />
            <Link to="/auth/login">
              <button
                type="button"
                className="px-3 p-2  border-[1.5px] rounded-lg text-black"
              >
                Login
              </button>
            </Link>
          </div>

          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className="align-items-center justify-content-center d-flex  my-2 mx-auto"
          >
            <Container fluid>
              <Row className="justify-content-center">
                <Col lg={9} md={9} sm={12} xs={12}>
                  <div>
                    <div className="inter text-center">
                      <h4 className="font-24 fw-bold  text-black text-center">
                        Help is on the way!
                      </h4>
                      <p className="font-14 text-gray-700 text-center mb-1">
                        If you're using email and password for login, we sent
                        the password reset email to
                      </p>
                      <p className="fw-bold mb-1">{email}</p>
                      <p className="font-14 text-gray-700 text-center flex  justify-center items-center gap-1">
                        <RiErrorWarningLine /> Didn't get the email?
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
          <p className="text-center  mt-4">
            @2024 Time Tech Pty Ltd,All Rights Reserved
            <br />
            <span
              className="font-16 font-bold cursor-pointer colorPrimary inter"
              onClick={() => navigate("/auth/signup")}
            >
              {" "}
              Privacy Policy
            </span>
          </p>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
};

export default ForgotPasswordWay;
