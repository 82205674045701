import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setSumitCamgpaingState } from '../../redux/slices/userSlice';
import { ToastContainer, toast } from 'react-toastify';

const StepThree = ({ childFunc }) => {
    const dispatch = useDispatch();
    const { submitCampaign } = useSelector((state) => state.user)
    const [selectedCutomers, setSelectedCutomers] = useState([]);
    const [selectedProfession, setSelectedProfession] = useState([]);
    const [startDate, setStartDates] = useState();
    const [endDate, setEndDates] = useState();
    const [retargeting, setRetargeting] = useState(false);
    const [trackingConversion, setTrackingConversion] = useState(false);
    const [isQrcode, setIsQrcode] = useState([]);
    const [timelinkSmartTargeting, setTimelinkSmartTargeting] = useState();

    useEffect(() => {
        childFunc.current = handleSubmit
    }, [childFunc.current])

    function handleSubmit() {
        if (!startDate || !endDate) {
            toast.error('It is required to select both start and end date.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return false;
        }else{
            selectedCutomers.length > 0 && dispatch(setSumitCamgpaingState({ key: 'customers_visit', value: selectedCutomers }))
            selectedProfession.length > 0 && dispatch(setSumitCamgpaingState({ key: 'professions', value: selectedProfession }))
            startDate && endDate && dispatch(setSumitCamgpaingState({ key: 'schedule_campaign', value: JSON.stringify({ start: startDate, end: endDate }) }))
            retargeting && dispatch(setSumitCamgpaingState({ key: 'retargetting', value: retargeting }))
            trackingConversion && dispatch(setSumitCamgpaingState({ key: 'track_conversation', value: trackingConversion }))
            isQrcode && dispatch(setSumitCamgpaingState({ key: 'qr_code', value: isQrcode }))
            timelinkSmartTargeting && dispatch(setSumitCamgpaingState({ key: 'smart_targetting', value: timelinkSmartTargeting }))
            return true ;
        }
    }
    function handleSelectCustomers(e) {
        if (e.target.checked) {
            setSelectedCutomers((ag) => {
                return [...ag, e.target.value]
            })
        } else {
            if (selectedCutomers.includes(e.target.value)) {
                const index = selectedCutomers.indexOf(e.target.value);
                if (index > -1) { // only splice array when item is found
                    selectedCutomers.splice(index, 1); // 2nd parameter means remove one item only
                }
            }
        }
    }
    function handleSelectProfession(e) {
        if (e.target.checked) {
            setSelectedProfession((ag) => {
                return [...ag, e.target.value]
            })
        } else {
            if (selectedProfession.includes(e.target.value)) {
                const index = selectedProfession.indexOf(e.target.value);
                if (index > -1) { // only splice array when item is found
                    selectedProfession.splice(index, 1); // 2nd parameter means remove one item only
                }
            }
        }
    }
    return (
        <>
            <div>
                <div className="row justify-content-center step-three">

                    {/* -------Customers--- */}
                    <div className="col-11 mt-4" style={{ borderTop: '2px solid #F6F6F6' }}>
                        <div className="row mt-3">
                            <div className="col-lg-4 col-md-4 col-12 ">
                                <h5 className="font-16 Inter text-blue mb-0">Customers
                                </h5>
                                <p className="font-12 Inter text-blue">Who have visited:</p>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mt-lg-0 mt-md-0 mt-3">
                                <div className="form-check">
                                    <input className="form-check-input" value={'never'} onChange={handleSelectCustomers} type="checkbox" defaultValue id="Customers1" />
                                    <label className="form-check-label font-14 Inter text-blue" htmlFor="Customers1">
                                        Never
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" value={'1-3'} onChange={handleSelectCustomers} type="checkbox" defaultValue id="Customers2" />
                                    <label className="form-check-label font-14 Inter text-blue" htmlFor="Customers2">
                                        1-3
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" value={'>3but<5'} onChange={handleSelectCustomers} type="checkbox" defaultValue id="Customers3" />
                                    <label className="form-check-label font-14 Inter text-blue" htmlFor="Customers3">
                                        &gt;3 but &lt; 5 </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" value={'>5but<8'} onChange={handleSelectCustomers} type="checkbox" defaultValue id="Customers4" />
                                    <label className="form-check-label font-14 Inter text-blue" htmlFor="Customers4">
                                        &gt;5 but &lt; 8 </label>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mt-lg-0 mt-md-0 mt-3">
                                <div className="form-check">
                                    <input className="form-check-input" value={'>10'} onChange={handleSelectCustomers} type="checkbox" defaultValue id="Customers5" />
                                    <label className="form-check-label font-14 Inter text-blue" htmlFor="Customers5">
                                        &gt;10
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" value={'<10'} onChange={handleSelectCustomers} type="checkbox" defaultValue id="Customers6" />
                                    <label className="form-check-label font-14 Inter text-blue" htmlFor="Customers6">
                                        &lt; 10 </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" value={'all'} onChange={handleSelectCustomers} type="checkbox" defaultValue id="Customers7" />
                                    <label className="form-check-label font-14 Inter text-blue" htmlFor="Customers7">
                                        All
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* -------Professions--- */}
                    <div className="col-11 mt-3" style={{ borderTop: '2px solid #F6F6F6' }}>
                        <div className="row mt-3">
                            <div className="col-lg-4 col-md-4 col-12 ">
                                <h5 className="font-16 Inter text-blue mb-0">Professions
                                </h5>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mt-lg-0 mt-md-0 mt-3">
                                <div className="form-check">
                                    <input className="form-check-input" value={'employed'} onChange={handleSelectProfession} type="checkbox" defaultValue id="Professions1" />
                                    <label className="form-check-label font-14 Inter text-blue" htmlFor="Professions1">
                                        Employed
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" value={'unemployed'} onChange={handleSelectProfession} type="checkbox" defaultValue id="Professions2" />
                                    <label className="form-check-label font-14 Inter text-blue" htmlFor="Professions2">
                                        Unemployed
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" value={'student'} onChange={handleSelectProfession} type="checkbox" defaultValue id="Professions3" />
                                    <label className="form-check-label font-14 Inter text-blue" htmlFor="Professions3">
                                        Student
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" value={'retired'} onChange={handleSelectProfession} type="checkbox" defaultValue id="Professions4" />
                                    <label className="form-check-label font-14 Inter text-blue" htmlFor="Professions4">
                                        Retired
                                    </label>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mt-lg-0 mt-md-0 mt-3">
                                <div className="form-check">
                                    <input className="form-check-input" value={'all'} onChange={handleSelectProfession} type="checkbox" defaultValue id="Professions5" />
                                    <label className="form-check-label font-14 Inter text-blue" htmlFor="Professions5">
                                        All
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* -------Schedule Campaign--- */}
                    <div className="col-11 mt-3" style={{ borderTop: '2px solid #F6F6F6' }}>
                        <div className="row mt-3">
                            <div className="col-lg-4 col-md-4 col-12 ">
                                <h5 className="font-16 Inter text-blue mb-0">Schedule Campaign
                                </h5>
                                <p className="font-12 Inter colorblack mt-3 mb-0">Note**  Campaigns are only active for 21 days</p>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mt-lg-0 mt-mf-0 mt-3 px-1">
                                <div className="trip-card p-3 step-three-card rounded-3">
                                    <h6 className="font-16 Inter text-blue ">Start Date</h6>
                                    <p className="font-12 Inter colorblack mb-0">Setting a specific date to
                                        automatically publish your campaign.</p>


                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mt-lg-0 mt-mf-0 mt-3 px-1">
                                <div className="trip-card step-three-card rounded-3 p-3 h-100 d-flex align-items-center justify-content-center ">
                                    <div className="backgroundgray p-2 d-flex align-items-center justify-content-between">
                                        <div>
                                            <h6 className="font-16 Inter text-blue ">Start Date</h6>
                                            <input type='date' className="font-12 Inter colorblack mb-0"

                                                onChange={(e) => {
                                                    setStartDates(e.target.value)
                                                    const newEndDate = new Date(e.target.value);
                                                    newEndDate.setDate(newEndDate.getDate() + 17);

                                                    // Format the end date as YYYY-MM-DD (matching the input type='date' format)
                                                    const formattedEndDate = newEndDate.toISOString().split('T')[0];
                                                    setEndDates(formattedEndDate);
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <p className="font-14 Inter colorblack mb-0">to</p>
                                        </div>
                                        <div>
                                            <h6 className="font-16 Inter text-blue ">End Date</h6>
                                            <input type='date' className="font-12 Inter colorblack mb-0 "
                                                value={endDate} readOnly
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* -------Additional Setting--- */}
                    <div className="col-11 mt-3" style={{ borderTop: '2px solid #F6F6F6' }}>
                        <div className="row mt-3">
                            <div className="col-lg-4  col-12 px-0">
                                <h5 className="font-16 Inter text-blue mb-0">Additional Setting
                                </h5>
                            </div>
                            <div className="col-lg-4  col-12 mt-lg-0 mt-3 px-1">
                                <div className="trip-card p-3 step-three-card rounded-3">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <h6 className="font-14 Inter text-blue mb-0">Retargeting</h6>
                                        </div>
                                        <div className="px-2">

                                            <div className="container-stepOne ">
                                                <input type="checkbox" className="checkbox" id="checkbox1"
                                                    value={retargeting} onChange={(e) => {
                                                        setRetargeting(!e.target.value)
                                                    }}
                                                />
                                                <label className="switch" htmlFor="checkbox1">
                                                    <span className="slider" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className=" border rounded-5">
                                            <select className="form-select border-0 font-12 py-0 rounded-5" >
                                                <option selected>select</option>
                                                <option value={1}>...</option>
                                                <option value={2}>...</option>
                                                <option value={3}>...</option>
                                            </select>
                                        </div>
                                    </div>
                                    <p className="font-10 Inter colorblack mb-0 mt-2">Displaying campaigns to users who have
                                        previously enrolled/or viewed your campaign to encourage greater
                                        impressions.</p>
                                </div>
                            </div>
                            <div className="col-lg-4  col-12 mt-lg-0 mt-3  px-1">
                                <div className="trip-card p-3 h-100 step-three-card rounded-3">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <h6 className="font-16 Inter text-blue ">Tracking Conversions</h6>
                                        </div>
                                        <div>
                                            <div className="container-stepOne">
                                                <input type="checkbox" className="checkbox" id="checkbox2"
                                                    value={trackingConversion} onChange={(e) => {
                                                        setTrackingConversion(!e.target.value)
                                                    }}
                                                />
                                                <label className="switch" htmlFor="checkbox2">
                                                    <span className="slider" />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="font-10 Inter colorblack mb-0">Monitoring actions users take after
                                        clicking an ad to assess the ad's effectiveness.</p>
                                </div>
                            </div>

                            <div className="col-lg-8 col-12 offset-lg-4 px-1">
                                <div className="trip-card p-3 mt-3 step-three-card rounded-3">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <h6 className="font-14 Inter text-blue ">Does this offer require a unique QR
                                                code for single-use verification?</h6>
                                        </div>
                                        <div>
                                            <div className="container-stepOne">
                                                <input type="checkbox" className="checkbox" id="checkbox3"
                                                    value={isQrcode} onChange={(e) => {
                                                        setIsQrcode(!e.target.value)
                                                    }}
                                                />
                                                <label className="switch" htmlFor="checkbox3">
                                                    <span className="slider" />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="font-10 Inter colorblack mb-0">Select this option if your offer is a
                                        discount, or if it requires a single-use unique QR code for verification by
                                        your business.</p>
                                </div>
                            </div>

                            <div className="col-lg-8 col-12 offset-lg-4 px-1">
                                <div className="trip-card p-3 mt-3 step-three-card rounded-3">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <h6 className="font-14 Inter text-blue ">Time Links’ Smart Targeting</h6>
                                        </div>
                                        <div>
                                            <div className="container-stepOne">
                                                <input type="checkbox" value={timelinkSmartTargeting} onChange={(e) => {
                                                    setTimelinkSmartTargeting(!e.target.value)
                                                }} className="checkbox" id="checkbox4" />
                                                <label className="switch" htmlFor="checkbox4">
                                                    <span className="slider" />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="font-10 Inter colorblack mb-0">Opt for TimeLink's Smart Targeting to
                                        let our algorithm select the most effective audience for your campaign. Be
                                        aware that the algorithm may broaden or refine your selected audience for
                                        optimised results, potentially overriding manual selections. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">

                    {/* Modal */}
                    <div className="modal fade p-4" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body text-center p-3">
                                    <div>
                                        <img src="/vendor/assets/imges/noti.png" width="50px" alt />
                                        <h6 className="font-20 Inter colorblack mt-3">Great Choice!</h6>
                                        <p className="mt-2 mb-0">You’re about to use a credit to launch a new <br /> marketing campaign. Are you ready to boost <br /> your business?</p>
                                    </div>
                                    <div className="col-12 mt-3 mb-3 text-center">
                                        <button className="btn-Rejected rounded-2 p-2 px-4 py-3 fw-medium font-14 text-capitalize" data-bs-dismiss="modal">
                                            Save &amp; Exit</button>
                                        <button className="btn-blue rounded-2 p-2 px-5 py-3 fw-medium font-14 text-capitalize" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                            Boost!</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default StepThree
