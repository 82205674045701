import React, { useState } from "react";
import Header from "../../components/Header";
import SideBar from "../../components/SideBar";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { GrFormClose } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import { getOtherOptionAnswers } from "../../redux/slices/userSlice";
import moment from "moment";

const Feedback = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isLoading, feedbacks, feedbackReaches, userDetail } = useSelector(
    (state) => state.user
  );

  const Navigate = useNavigate();
  const [viewOtherOptionSubmissions, setViewOtherOptionSubmissions] =
    useState(false);
  return (
    <>
      <div className="container-fluid business_admin_main">
        <div className="row order">
          {/* <!-- ===========SID-BAR======= --> */}
          <SideBar />
          {/* <!-- ========= content body ======= --> */}

          <div className="w-lg-80 px-0 h-screen overflow-y-scroll">
            <Header PageTitle="Surveys" />
            <div className="px-lg-4 px-md-3 px-2">
              <div className="container-fluid Surveys mt-4">
                <div className="row">
                  <div className="col-12 m-1">
                    <span
                      onClick={() => Navigate(-1)}
                      className="cursor-pointer "
                    >
                      <div>
                        <h6 className="font-12 mb-0 colorgray">
                          <i className="fa-solid fa-arrow-left " /> Back
                        </h6>
                      </div>
                    </span>
                  </div>
                  <div className="col-12">
                    <div className="surveysone p-4">
                      <div className="row ">
                        <div className="col-lg-4 col-md-4 col-12 ">
                          <div className>
                            <h6 className="font-20 inter colorblack">
                              Feedback
                            </h6>
                          </div>
                          <div className="d-flex  justify-content-between align-items-center ">
                            <div>
                              <p className="font-12 inter font-600 colorgray mb-0">
                                Date Created
                              </p>
                              <h6 className="font-14 inter font-600 colorblack mt-3">
                                {moment(
                                  userDetail?.settings?.created_at
                                ).format("DD MMM YYYY")}
                              </h6>
                            </div>
                            <div>
                              <p className="font-12 inter font-600 colorgray mb-0">
                                Reach
                              </p>
                              <h6 className="font-14 inter font-600 colorblack mt-3">
                                {feedbackReaches}
                              </h6>
                            </div>
                            <div>
                              <p className="font-12 inter font-600 colorgray mb-0">
                                Completed by:
                              </p>
                              <h6 className="font-14 inter font-600 colorblack mt-3">
                                {feedbacks?.length}{" "}
                              </h6>
                            </div>
                          </div>
                          <hr />
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    className="btn-Question px-5 py-2 rounded-5 mt-3 colorblack"
                    style={{ color: "black" }}
                  >
                    {"Feedback"}{" "}
                  </button>
                  <div className="col-12 mt-4">
                    <div className="surveysone p-4 mt-3">
                      <div className="row ">
                        <div className>
                          <h6 className="font-18 inter font-600 colorblack">
                            Feedbacks
                          </h6>
                          <p className="font-12 inter font-600 colorgray mb-0">
                            This is a list of latest Feedbacks.
                          </p>
                        </div>
                        <div className="col-12 Choices mt-2">
                          <div className="row">
                            <div className="col-lg-3 col-md-3 col-12">
                              <div>
                                <p className="font-12 inter font-600 colorgray mt-3">
                                  User
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-12 ">
                              <div>
                                <p className="font-12 inter font-600 colorgray mt-3">
                                  Reaction
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-12 ">
                              <div>
                                <p className="font-12 inter font-600 colorgray mt-3">
                                  Message
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-12 ">
                              <div>
                                <p className="font-12 inter font-600 colorgray mt-3">
                                  Date submitted
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {feedbacks &&
                          feedbacks.map((value, index) => {
                            return (
                              <div className="col-12 Choices">
                                <div className="row">
                                  <div className="col-lg-3 col-md-3 col-12 ">
                                    <div className="row">
                                      <div className="col-12 ">
                                        <p className="font-14 inter font-600 colorblack mt-3">
                                          {value?.is_anonymous
                                            ? "Anonymous"
                                            : value?.user?.phone}{" "}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-9 col-md-8 col-12 ">
                                    <div className="row">
                                      <div className="col-4 ">
                                        <p className="font-14 inter font-600 colorblack mt-3">
                                          {value?.reaction}
                                        </p>
                                      </div>
                                      <div className="col-4 ">
                                        <p className="font-14 inter font-600 colorblack mt-3">
                                          {value?.message}
                                        </p>
                                      </div>
                                      <div className="col-4 ">
                                        <p className="font-14 inter font-600 colorblack mt-3">
                                          {moment(value?.created_at).format(
                                            "DD MMM YYYY"
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}

                        <div className="col-12">
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-12 ">
                              <p className="font-14 inter font-600 colorblack mt-3">
                                Total{" "}
                              </p>
                            </div>

                            <div className="col-lg-6 col-md-6 col-12 ">
                              <p className="font-14 inter font-600 colorblack mt-3">
                                {feedbacks?.length}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Feedback;
