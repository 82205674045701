// ToastContext.js
import React, { createContext, useState, useContext, useCallback } from 'react';
import { AiOutlineCheckCircle, AiOutlineClose } from 'react-icons/ai';

const ToastContext = createContext();

export const useToast = () => useContext(ToastContext);

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const addToast = useCallback((message, type) => {
    const id = Date.now();
    setToasts((prevToasts) => [...prevToasts, { id, message, type }]);
    setTimeout(() => {
      setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    }, 5000); // Toast display duration
  }, []);

  const removeToast = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  return (
    <ToastContext.Provider value={addToast}>
      {children}
      <div className="myToast-container">
        {toasts.map((toast) => (
          <div key={toast.id} className={`myToast ${toast.type}`}>
            <AiOutlineCheckCircle className="icon" />
            {toast.message}
            <AiOutlineClose
              className="close-icon"
              onClick={() => removeToast(toast.id)}
            />
          </div>
        ))}
      </div>
    </ToastContext.Provider>
  );
};
