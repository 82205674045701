import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoginSocialGoogle } from "reactjs-social-login";
import { Col, Container, Row } from "reactstrap";
import logoTwo from "../../assets/imges/Logo.png";
import { useToast } from "../../components/toast/ToastContext";
import { signUpWithGoogle, userRegister } from "../../redux/slices/userSlice";
import "../../style/businesLogin.scss";
const TrialSignup = () => {
  const dispatch = useDispatch();
  const { isLoading, createError } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState("password");
  const location = useLocation();
  const showToast = useToast();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    console.log(searchParams);
    const emailParam = searchParams.get("email");
    if (emailParam) {
      setEmail(emailParam);
    }
  }, []);
  const toggleType = () => {
    if (type == "password") {
      setType("text");
    } else {
      setType("password");
    }
  };
  useEffect(() => {
    toast.error(createError, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }, [createError]);

  const handleSubmit = (e) => {
    e.preventDefault();
    var data = {
      email: email,
      firstname: firstName,
      lastname: lastName,
      password: password,
    };
    dispatch(userRegister(data, handleResponse));
  };
  function handleResponse(data) {
    showToast("Confirmation email sent!", "info");

    setTimeout(() => {
      navigate("/auth/otpverify");
    }, 3000);
  }

  return (
    <>
      <Container fluid className="login_height inter">
        <Row
          className="min-h-screen justify-content-center p-3"
          style={{ backgroundColor: "#ffffff" }}
        >
          <div className="flex justify-between items-center gap-2 p-2">
            <img src={logoTwo} alt="w8" className=" " width="150" />
            <Link to="/auth/login">
              <button
                type="button"
                className="px-3 p-2 border-[1.5px]  font-medium rounded-lg text-black"
              >
                Log In
              </button>
            </Link>
          </div>

          <Col
            xxl={5}
            xl={5}
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className="align-items-center justify-content-center d-flex  my-5"
          >
            <Container fluid>
              <Row className="justify-content-center">
                <Col lg={9} md={9} sm={12} xs={12}>
                  <form className="login-form" onSubmit={handleSubmit}>
                    <div className="inter ">
                      <h4 className="font-24 fw-bold  text-black text-center">
                        Start 7-day free trial
                      </h4>
                      <p className="font-14 text-gray-400 text-center">
                        No credit card required
                      </p>
                    </div>
                    <div className=" grid grid-cols-2 gap-3">
                      <div>
                        <label className="text-sm mb-2">First name</label>
                        <input
                          type="text"
                          required
                          className=" !placeholder-gray-400	text-sm p-2.5 rounded-3 border-[1.2px] border-gray-300 focus:outline-none w-full"
                          placeholder="John"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          name="firstName"
                        />
                      </div>
                      <div>
                        <label className="text-sm mb-2">Last name</label>
                        <input
                          type="text"
                          required
                          className=" !placeholder-gray-400	text-sm p-2.5 rounded-3 border-[1.2px] border-gray-300 focus:outline-none w-full"
                          placeholder="Doh"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          name="lastName"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="my-2">
                        <label className="text-sm mb-2">Business email</label>
                        <input
                          type="email"
                          required
                          className=" !placeholder-gray-400	text-sm p-2.5 rounded-3 border-[1.2px] border-gray-300 focus:outline-none w-full mb-2"
                          placeholder="name@work-email.com"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          name="email"
                        />
                      </div>
                    </div>
                    <div className="mt-2">
                      <div className="flex justify-between items-center">
                        <p className="text-sm mb-2">Password</p>
                        <p
                          className="text-sm mb-2 text-blue-600 cursor-pointer"
                          onClick={() => navigate("/trial/forgot")}
                        >
                          Forgot password?
                        </p>
                      </div>
                      <div className="  rounded-3 bg-white border-[1.2px] border-gray-300 flex items-center">
                        <input
                          type={type}
                          required
                          className=" !placeholder-gray-400	text-sm p-2.5 rounded-3 border-0 focus:outline-none w-full"
                          placeholder="Enter your password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          name="password"
                        />
                        <span
                          className=" border-0 pe-2.5"
                          role="button"
                          onClick={toggleType}
                          id="basic-addon1"
                        >
                          {type != "password" ? (
                            <FaRegEye size={16} color="black" />
                          ) : (
                            <FaRegEyeSlash size={16} color="black" />
                          )}
                        </span>
                      </div>
                    </div>

                    <Button
                      type="submit"
                      className="btn-blueTwo rounded-3 inter mt-3 !p-2.5 w-100 fw-semibold text-sm !normal-case"
                      sx={{ fontFamily: "Inter, sans-serif !important" }}
                    >
                      {isLoading ? <Spinner color="light" /> : "Sign up"}
                    </Button>
                    <p class="font-14 inter text-dark-gray Or-lines d-flex justify-content-center align-items-center mt-3">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Or&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </p>
                  </form>
                  <Row className="inter mt-3 justify-center">
                    <Col
                      lg={6}
                      md={6}
                      sm={6}
                      xs={12}
                      className="flex justify-center items-center"
                    >
                      <LoginSocialGoogle
                        client_id={
                          "212290965015-r0v8ahr9q13u3upesu87bisjvtoi82n7.apps.googleusercontent.com" ||
                          ""
                        }
                        onLoginStart={() => {
                          console.log("oauth 2.0 started");
                        }}
                        onResolve={({ provider, data }) => {
                          var d = {
                            email: data.email,
                            firstname: data.name,
                          };
                          dispatch(
                            signUpWithGoogle(d, () => {
                              navigate("/auth/signup/Profile");
                            })
                          );
                          console.log(
                            "data object to be sent in signInWithGoogle",
                            d
                          );
                          //console.log(provider, data)
                        }}
                        onReject={(err) => {
                          console.log(err);
                        }}
                      >
                        <button className="just-border bg-tranparent p-2 pe-3 whitespace-nowrap  rounded-3 flex justify-center items-center gap-1">
                          <FcGoogle size={18} />
                          <span className="fw-bold ms-1">
                            Sign up with Google
                          </span>
                        </button>
                      </LoginSocialGoogle>
                    </Col>
                  </Row>
                  <p className="text-center font-14 mt-3">
                    You agree to our
                    <span
                      className="cursor-pointer colorPrimary inter"
                      onClick={() => navigate("/auth/signup")}
                    >
                      {" "}
                      Terms of Use{" "}
                    </span>
                    and
                    <span
                      className="cursor-pointer colorPrimary inter"
                      onClick={() => navigate("/auth/signup")}
                    >
                      {" "}
                      Privacy Policy
                    </span>
                  </p>
                </Col>
              </Row>
            </Container>
          </Col>
          <p className="text-center  font-14  mt-4">
            @2024 Time Tech Pty Ltd,All Rights Reserved
            <br />
            <span
              className="font-bold cursor-pointer colorPrimary inter"
              onClick={() => navigate("/auth/signup")}
            >
              {" "}
              Privacy Policy
            </span>
          </p>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
};

export default TrialSignup;
