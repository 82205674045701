import * as React from "react";
import { FiDownload, FiSearch } from "react-icons/fi";
import BusinessItem from "../../pagination/Businessitem";
import PaginatedItems from "../../pagination/PaginatedItems";

const options = [
  { label: "Option 1", value: "option1" },
  { label: "Option 2", value: "option2" },
  { label: "Option 3", value: "option3" },

  // ... other options ...
];

export default function BusinessManagmentTable({ businesses }) {
  const [businessManagTab, setBusinessManagTab] = React.useState("all");
  const isActive = (item) => {
    return businessManagTab === item
    ? "before:content-[''] before:absolute before:left-0 before:bottom-0 before:w-full before:h-1 before:bg-customBlue before:rounded-t-md text-customBlue font-medium"
        : "text-[#718EBF] font-medium";
  };

  return (
    <>
      <div className="container-fluid">
        <div className="w-full flex justify-between flex-wrap gap-2 mb-4 justify-content-between">
          <div className=" d-flex align-items-end inter ps-0 border-b">
            <button
              className={`relative ${isActive("all")} py-2 px-6`}
              onClick={() => setBusinessManagTab("all")}
              type="button"
            >
              All
            </button>

            <button
              className={`relative ${isActive("pending")} py-2 px-6`}
              onClick={() => setBusinessManagTab("pending")}
              type="button"
            >
              Pending
            </button>

            <button
              className={`relative ${isActive("approved")} py-2 px-6`}
              onClick={() => setBusinessManagTab("approved")}
              type="button"
            >
              Approved
            </button>

            <button
              className={`relative ${isActive("reject")} py-2 px-6`}
              onClick={() => setBusinessManagTab("reject")}
              type="button"
            >
              Reject
            </button>
          </div>
          <div className=" ">
            <div className="border h-10 flex items-center whitespace-nowrap gap-1 w-48 px-2 rounded-full bg-white text-sm">
              <p className="m-0">Sort by:</p>
              <select className="flex-1 border-0 focus:outline-none h-full rounded-full text-customBlue font-medium ">
                <option selected>Most Recent</option>
                <option value="1">One</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className=" overflow-y-hidden" >
        {/* ------------ALl---------- */}
        <div className="d-flex devices-table px-1 p-2 my-2  ">
          <div className="d-flex align-items-center">
            <div className="px-3">
            <input
              type="checkbox"
              className="!accent-[#65558F] !border-[#C8C3C9] !border-[1.5px] scale-150	"
            />
            </div>
          </div>
          <div className="w-full flex flex-wrap gap-2 items-center">
            <div className="border flex items-center justify-center h-10  w-32 px-2 rounded-xl bg-white">
              <FiSearch />
              <input
                type="search"
                className="focus:outline-none w-100 border-0 ps-1 text-sm placeholder:text-navyBlue"
                placeholder="Search.."
              />
            </div>
            <div className="border h-10 w-32 px-2 rounded-xl bg-white">
              <select className="w-full border-0 focus:outline-none h-full text-navyBlue text-sm">
                <option selected>Brand</option>
                <option value="1">One</option>
              </select>
            </div>
            <div className="border h-10 w-32 px-2 rounded-xl bg-white">
              <select className="w-full border-0 focus:outline-none h-full text-navyBlue text-sm">
                <option selected>Status</option>
                <option value="1">One</option>
              </select>
            </div>
            <div className="border h-10 w-32 px-2 rounded-xl bg-white">
              <select className="w-full border-0 focus:outline-none h-full text-navyBlue text-sm">
                <option selected>Purchase</option>
                <option value="1">One</option>
              </select>
            </div>
            <div className="border h-10 w-32 px-2 rounded-xl bg-white">
              <select className="w-full border-0 focus:outline-none h-full text-navyBlue text-sm">
                <option selected>Version</option>
                <option value="1">One</option>
              </select>
            </div>
            <div className=" h-10 w-10 flex items-center justify-center rounded-xl bg-customBlue">
              <FiDownload size={20} color="white" />
            </div>
          </div>
        </div>
         {businessManagTab === "all" && <div>
            <PaginatedItems
              items={businesses}
              itemsPerPage={6}
              ItemsComponent={BusinessItem}
            />
          </div>}
        {/* ----DEVICES---------- */}
       
          {businessManagTab === "pending" &&  
            <div>
                
                </div>}
         

        {/* --------TABLETS------------------ */}
     
           {businessManagTab === "approved" &&  <div>
                
                </div>}
         
        {/* ------------FAVOURIT---------- */}
       {businessManagTab === "reject" && 
           <div>
                
           </div>}
          </div>
     

    </>
  );
}
