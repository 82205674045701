import React from "react";
import SideBar from "../../components/superAdmin/SideBar";
import { HiOutlineViewGridAdd } from "react-icons/hi";
import { Button } from "@mui/material";
import UserTable from "../../components/superAdmin/Users/UserTable";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "../../style/admin/users.scss";
import { BiCalendarEvent } from "react-icons/bi";
import { FaAngleDown, FaArrowUp } from "react-icons/fa";
import DeviceCard from "../../components/superAdmin/Device/DeviceCard";
import Header from "../../components/superAdmin/Header";
import { useDispatch, useSelector } from "react-redux";
import { getUsersList } from "../../redux/slices/userSlice";
const Users = () => {
  const [value, setValue] = React.useState(dayjs("2022-04-17"));
  const [calendarShow, setCalendarShow] = React.useState(false);
  const dispatch = useDispatch();
  const { isLoading, getError, usersList } = useSelector((state) => state.user);
  React.useEffect(() => {
    dispatch(getUsersList());
  }, []);
  const cardSvg = () => {
    return (
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_211_2365)">
        <path d="M21.5833 13.9444C18.2082 13.9444 15.4722 11.2084 15.4722 7.83328C15.4722 4.45821 18.2082 1.72217 21.5833 1.72217C24.9584 1.72217 27.6944 4.45821 27.6944 7.83328C27.6944 11.2084 24.9584 13.9444 21.5833 13.9444Z" stroke="black" stroke-width="3"/>
        <path d="M12.4166 12.4167C9.88525 12.4167 7.83329 10.3646 7.83329 7.83333C7.83329 5.30203 9.88525 3.25 12.4166 3.25" stroke="black" stroke-width="3" stroke-linecap="round"/>
        <path d="M21.5833 30.7501C15.6769 30.7501 10.8889 28.014 10.8889 24.6389C10.8889 21.2639 15.6769 18.5278 21.5833 18.5278C27.4897 18.5278 32.2778 21.2639 32.2778 24.6389C32.2778 28.014 27.4897 30.7501 21.5833 30.7501Z" stroke="black" stroke-width="3"/>
        <path d="M7.83331 20.0552C5.15329 20.6429 3.24998 22.1313 3.24998 23.8746C3.24998 25.4472 4.79869 26.8124 7.06942 27.4961" stroke="black" stroke-width="3" stroke-linecap="round"/>
        </g>
        <defs>
        <clipPath id="clip0_211_2365">
        <rect width="34" height="34" fill="white"/>
        </clipPath>
        </defs>
        </svg>
        
    );
  };
  return (
    <SideBar>
      <div className="w-full super px-0">
        <div className="main_row ">
          <div className="w-full  p-3">
          <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-y-2 p-2 bg-white rounded-lg border">
          <div className=" flex items-center">
                <DeviceCard
                svg={cardSvg()}
                bgIcon="bg-[#E2F2FE]"

                  ratingColor="rgba(35, 197, 129, 1)"
                  rating="12"
                  icon={<FaArrowUp />}
                  head="Total"
                  number={usersList?.users?.length}
                />
              </div>
              <div className="lg:border-l md:border-l sm:border-l border-l-0 flex items-center">
                <DeviceCard
                  svg={cardSvg()}
                  bgIcon="bg-[#E8FEE2]"
                  ratingColor="rgba(231, 69, 69, 1)"
                  rating="12"
                  icon={<FaArrowUp />}
                  head="Active"
                  number={usersList?.users?.length}
                />
              </div>
              <div className="lg:border-l flex items-center">
                <DeviceCard
                  svg={cardSvg()}
                  bgIcon="bg-[#FEE2F3]"
                  ratingColor="rgba(35, 197, 129, 1)"
                  rating="12"
                  icon={<FaArrowUp />}
                  head="Orders"
                  number={usersList?.orders}
                />
              </div>
              <div className="lg:border-l md:border-l sm:border-l border-l-0 flex items-center">
                <DeviceCard
                  svg={cardSvg()}
                  bgIcon="bg-[#FDFEE2]"
                  ratingColor="rgba(35, 197, 129, 1)"
                  rating="12"
                  icon={<FaArrowUp />}
                  head="Monthly users"
                  number={usersList?.averageMonthlySignups}
                />
              </div>
            </div>
            <div className="flex justify-end flex-wrap inter mt-4">
              <div className=" text-end position-relative">
                <button
                  onClick={() => setCalendarShow(!calendarShow)}
                  className="bg-customBlue flex gap-2 items-center text-white rounded-full py-2 p-3"
                >
                  <BiCalendarEvent size={16} className="me-2" />
                  Bulk Add
                </button>
                {calendarShow && (
                  <div className="user-calendar rounded-4">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DateCalendar", "DateCalendar"]}
                      >
                        {/* <DemoItem label="Uncontrolled calendar">
                                                    <DateCalendar defaultValue={dayjs('2022-04-17')} />
                                                </DemoItem> */}
                        <DemoItem>
                          <DateCalendar
                            value={value}
                            onChange={(newValue) => setValue(newValue)}
                          />
                        </DemoItem>
                        <div className="px-2 pb-2 mt-0">
                          <hr
                            className="border-0 mt-0 mb-1"
                            style={{
                              height: "1px",
                              opacity: "1",
                              backgroundColor: "rgba(172, 171, 171, 0.8)",
                            }}
                          />
                          <div>
                            <Button
                              onClick={() => setCalendarShow(false)}
                              className="inter text-capitalize font-14"
                              variant="text"
                              sx={{ color: "rgba(57, 50, 48, 1)" }}
                            >
                              Cancel
                            </Button>
                            <Button
                              onClick={() => setCalendarShow(false)}
                              className="inter text-capitalize font-14"
                              variant="text"
                              sx={{ color: "rgba(54, 97, 236, 1)" }}
                            >
                              Apply
                            </Button>
                          </div>
                        </div>
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                )}
              </div>
            </div>

            <div className="bg-white p-6 shadow-md mt-4 rounded-lg">
            {usersList?.users?.length > 0 && (
                <UserTable users={usersList?.users} />
              )}
            </div>
          </div>
        </div>
      </div>
    </SideBar>
  );
};

export default Users;
