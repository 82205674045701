import { Button } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import {
  FaFacebook,
  FaRegEnvelope,
  FaRegEye,
  FaRegEyeSlash,
} from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { MdLock } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Col, Container, Row } from "reactstrap";
import logoTwo from "../../assets/imges/Logo.png";
import "../../style/businesLogin.scss";
import { LoginSocialGoogle } from "reactjs-social-login";
import {
  resendOtp,
  seTgetErrorNull,
  signInWithGoogle,
  userLogin,
} from "../../redux/slices/userSlice";
import { ToastContainer, toast } from "react-toastify";
import { Tour } from "@frigade/react";
import { Modal, Spinner } from "react-bootstrap";
import { useToast } from "../../components/toast/ToastContext";

const TrialLogin = () => {
  const dispatch = useDispatch();
  const { isLoading, getError } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState("password");
  const frigadeTriggerRef = useRef(null);

  useEffect(() => {
    if (getError && getError.length > 1) {
      toast.error(getError, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(seTgetErrorNull());
    }
  }, [getError]);

  useEffect(() => {
    const token = localStorage.getItem("userToken");
    const userDetail = JSON.parse(localStorage.getItem("userDetails"));
    console.log(token, userDetail);
    if (token && userDetail?.role == "business") {
      navigate("/");
    } else if (token && userDetail?.role == "admin") {
      navigate("/super/device");
    }
  }, [navigate]);

  useEffect(() => {
    if (frigadeTriggerRef.current) {
      frigadeTriggerRef.current.click();
    }
  }, []);

  const toggleType = () => {
    setType(type === "password" ? "text" : "password");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email.length > 0 && password.length >= 8) {
      const data = {
        email: email,
        password: password,
      };
      dispatch(userLogin(data, handleResponse));
    }
  };

  function handleNavigation(data) {
    if (data.business_name === "null") {
      navigate("/auth/signup/profile");
    } else {
      navigate("/");
    }
  }

  function handleResponse(data) {
    if (data.is_verified) {
      localStorage.setItem("isSignedIn", true);
      data.role === "admin"
        ? navigate("/super/newBusiness")
        : handleNavigation(data);
    } else {
      dispatch(
        resendOtp({ email: localStorage.getItem("authEmail") }, () => {})
      );
      localStorage.setItem("tempToken", localStorage.getItem("userToken"));
      localStorage.removeItem("userToken");
      alert("Your Email has not been verified yet please verify now!");
      navigate("/auth/otpverify");
    }
  }

  return (
    <>
      <Container fluid className="login_height inter ">
        {/* Placeholder div for Frigade tour trigger */}
        <div
          id="tooltip-select-0"
          ref={frigadeTriggerRef}
          style={{ position: "absolute", top: "40%", left: "40%" }}
        ></div>
        <Tour flowId="flow_fOsp34Wy" tooltipPosition="auto" zIndex={9999} />
        <div
          id="tooltip-select-1"
          ref={frigadeTriggerRef}
          style={{ position: "absolute", top: "40%", left: "40%" }}
        ></div>

        <Row
          className="min-h-screen justify-content-center p-3"
          style={{ backgroundColor: "#ffffff" }}
        >
          <div className="flex justify-between items-center gap-2 p-2">
            <img src={logoTwo} alt="w8" className=" " width="150" />
            <Link to="/auth/signup">
              <button
                type="button"
                className="px-3 p-2  font-medium border-[1.5px] rounded-lg text-black"
              >
                Sign up free
              </button>
            </Link>
          </div>

          <Col
            xxl={5}
            xl={5}
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className="align-items-center justify-content-center d-flex  my-5"
          >
            <Container fluid>
              <Row className="justify-content-center">
                <Col lg={9} md={9} sm={12} xs={12}>
                  <form className="login-form" onSubmit={handleSubmit}>
                    <div className="inter ">
                      <h4 className="font-24 fw-bold  text-black text-center">
                        Log in
                      </h4>
                    </div>

                    <div>
                      <div className="my-2">
                        <label className="text-sm mb-2">Business email</label>
                        <input
                          type="email"
                          required
                          className=" !placeholder-gray-400	text-sm p-2.5 rounded-3 border-[1.2px] border-gray-300 focus:outline-none w-full mb-2"
                          placeholder="name@work-email.com"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          name="email"
                        />
                      </div>
                    </div>
                    <div className="mt-2">
                      <div className="flex justify-between items-center">
                        <p className="text-sm mb-2">Password</p>
                        <p
                          className="text-sm mb-2 text-blue-600 cursor-pointer"
                          onClick={() => navigate("/auth/trial/forgot")}
                        >
                          Forgot password?
                        </p>
                      </div>
                      <div className="  rounded-3 bg-white border-[1.2px] border-gray-300 flex items-center">
                        <input
                          type={type}
                          required
                          className=" !placeholder-gray-400	text-sm p-2.5 rounded-3 border-0 focus:outline-none w-full"
                          placeholder="Enter your password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          name="password"
                        />
                        <span
                          className=" border-0 pe-2.5"
                          role="button"
                          onClick={toggleType}
                          id="basic-addon1"
                        >
                          {type !== "password" ? (
                            <FaRegEye size={16} color="black" />
                          ) : (
                            <FaRegEyeSlash size={16} color="black" />
                          )}
                        </span>
                      </div>
                    </div>

                    <Button
                      type="submit"
                      className="btn-blueTwo rounded-3 inter mt-4 !p-2.5 w-100 fw-semibold text-sm !normal-case"
                      sx={{ fontFamily: "Inter, sans-serif !important" }}
                    >
                      {isLoading ? <Spinner color="light" /> : "Log in"}
                    </Button>
                    <p className="font-14 inter text-dark-gray Or-lines d-flex justify-content-center align-items-center my-4">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Or&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </p>
                  </form>
                  <Row className="inter mt-3  flex justify-center items-center">
                    <Col
                      lg={6}
                      md={6}
                      sm={6}
                      xs={12}
                      className="flex justify-center items-center"
                    >
                      <LoginSocialGoogle
                        client_id={
                          "212290965015-r0v8ahr9q13u3upesu87bisjvtoi82n7.apps.googleusercontent.com" ||
                          ""
                        }
                        onLoginStart={() => {
                          console.log("oauth 2.0 started");
                        }}
                        onResolve={({ provider, data }) => {
                          const d = {
                            email: data.email,
                            name: data.name,
                          };
                          dispatch(signInWithGoogle(d, handleResponse));
                          console.log(
                            "data object to be sent in signInWithGoogle",
                            d
                          );
                        }}
                        onReject={(err) => {
                          console.log(err);
                        }}
                      >
                        <button className="just-border  bg-tranparent flex justify-center items-center pe-4 p-2 whitespace-nowrap  rounded-3  gap-1">
                          <FcGoogle size={18} />
                          <span className="fw-bold ms-1">
                            Log in with Google
                          </span>
                        </button>
                      </LoginSocialGoogle>
                    </Col>
                  </Row>
                  <p
                    className="text-center  mt-3 font-16  cursor-pointer colorPrimary inter"
                    onClick={() => navigate("/auth/signup")}
                  >
                    Log in with custom SSO
                  </p>
                </Col>
              </Row>
            </Container>
          </Col>
          <p className="text-center  mt-4">
            @2024 Time Tech Pty Ltd, All Rights Reserved
            <br />
            <span
              className="font-16 font-bold cursor-pointer colorPrimary inter"
              onClick={() => navigate("/auth/signup")}
            >
              Privacy Policy
            </span>
          </p>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
};

export default TrialLogin;
