import React, { useEffect, useRef, useState } from 'react'
import LoyaltyPointsHeader from '../../../components/LoyaltyPointsHeader'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaAngleDown } from "react-icons/fa6";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createBusinessLoayalty , getBusinessRewards} from '../../../redux/slices/userSlice';
import { Spinner } from 'react-bootstrap';

const SelectProgramType = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading, getError , businessRewards } = useSelector((state) => state.user)
    useEffect(() => {
        dispatch(getBusinessRewards());
    }, []); 
    const [programType, setProgramType] = useState('visit_based'); // Initialize state with default selected option
    // console.log(programType)
    const handleOptionChange = (event) => {
        setProgramType(event.target.id);
    };
    // ========= visit ========
    const [isFocusedVisit, setIsFocusedVisit] = useState(false);
    const [pointsEarn, setPointsEarn] = useState(100);
    const [isFocusedVisitSelect, setIsFocusedVisitSelect] = useState(false);
    const [numberOfVisits, setNumberOfVisits] = useState();
    const handleOptionSelectVisit = () => {
        setIsFocusedVisitSelect(!isFocusedVisitSelect)
    };
    // ========= action  =========
    const [isFocusedAction, setIsFocusedAction] = useState(false);
    const [isFocusedManual, setIsFocusedManual] = useState(false);
    const [isFocusedActionSelect, setIsFocusedActionSelect] = useState(false);
    const [actionName, setActionName] = useState();
    const handleOptionSelectAction = () => {
        setIsFocusedActionSelect(!isFocusedActionSelect)
    };

    const handleNext = () => {
        if(programType?.length > 0 && programType === 'manual'){
            var data = {
                'type': programType,
                'action_points': pointsEarn
                
            }
            dispatch(createBusinessLoayalty(data, handleResponse))
            return;
        }
        if (programType.length > 0 && (numberOfVisits || actionName) && pointsEarn) {
            var data = {
                'type': programType,
                'after_visits': numberOfVisits,
                'action_name': actionName,
                'action_points': programType === 'action_based' ? pointsEarn : null,
                'visit_points': programType === 'visit_based' ? pointsEarn : null,
            }
            dispatch(createBusinessLoayalty(data, handleResponse))
        } else {
            toast.error('Please fill all the fields', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }


    function handleResponse(data) {
        toast.success('Loyalty Program created successfully!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        navigate('/loyalty/new')
    }

    useEffect(() => {
        if (getError && getError.length > 1) {
            toast.error(getError, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }, [getError])
    return (
        <>
            <main className='bg-white p-3' style={{ minHeight: "100vh" }}>

                <LoyaltyPointsHeader handleNext={handleNext} />
                <div className="container inter customise-program mt-4">
                    <div className="row justify-content-center ">
                        <div className="col-lg-6 col-md-8 col-12">
                            {
                                isLoading?
                                <div className='w-100 py-5 d-flex justify-content-center align-items-center'>
                                    <Spinner size='md' />
                                </div>
                                :
                                businessRewards?.length < 1 &&
                                <div class="alert alert-warning" role="alert">
                                    <h5>
                                        Important Note!
                                    </h5>
                                    <p>
                                        You have no rewards created
                                        {' '}
                                        <Link
                                            style={{ marginLeft: 7 }}
                                            to={'/rewards/create'}
                                            state= {{from : 'loyalty'}}
                                        >

                                            click here to create one
                                        </Link>
                                    </p>
                                </div>
                            }

                            <p className='text-uppercase font-12 text-black-50'>Step 2 of 4</p>
                            <h3 className='font-30 fw-bold '>Select a programme type</h3>
                            <p className='font-14 mt-3'>Your programme type determines how your customers will earn Points and rewards.</p>

                            <div >
                                <div className="  mt-3">
                                    <label role='button' className=" w-100 " htmlFor="visit_based">
                                        <input className=" d-none " type="radio" name="radioOptions" id="visit_based" required
                                            checked={programType === 'visit_based'}
                                            onChange={handleOptionChange} />
                                        <div className=" custom-radio w-100 p-14  rounded-3 ">

                                            <p className='font-16 mb-2'>Visit based</p>
                                            <p className='font-14 mb-0 text-black-50 '>Reward customers every-time they complete a certain action. For example; Signs up to a marketing campaign</p>

                                            {programType === 'visit_based' && <div>
                                                <label role="button" onFocus={() => setIsFocusedVisit(true)}
                                                    onBlur={() => setIsFocusedVisit(false)} className={`focus-field p-2 pb w-100 rounded-3  ${isFocusedVisit ? 'active' : ''}`} >
                                                    <div className="w-100 m-0 rounded-3">
                                                        <p className="font-14 mb-0 ">Customers earn</p>
                                                    </div>
                                                    <input
                                                        className="w-100 border-0 font-15 rounded-3"
                                                        type="text"
                                                        min={100} max={1000} value={pointsEarn} onChange={(e) => { if (e.target.value > 1000) { setPointsEarn(1000) } else { setPointsEarn(e.target.value) } }}
                                                        onBlur={(e) => { if (e.target.value < 100) { setPointsEarn(100) } }}
                                                        required

                                                    />
                                                </label>
                                                <div role="button" onClick={handleOptionSelectVisit}
                                                    className={`focus-field p-2 mt-3 pb w-100 rounded-3 position-relative  ${isFocusedVisitSelect ? 'active' : ''}`} >
                                                    <div className="w-100 h-100  m-0 rounded-3 d-flex align-items-center justify-content-between ">
                                                        <div>

                                                            <p className="font-14 mb-0 text-black-50">How often?</p>
                                                            {numberOfVisits && <p className="font-14 mb-0 ">{numberOfVisits}</p>}
                                                        </div>
                                                        <FaAngleDown className='text-black-50' />
                                                    </div>
                                                    {isFocusedVisitSelect && <div className="position-absolute  bottom-0 bg-white w-100 shadow start-0 rounded-3 p-2">

                                                        <div className="option" onClick={() => { setNumberOfVisits("1"); setIsFocusedVisitSelect(!isFocusedVisitSelect) }}>Every Visit</div>
                                                        <div className="option" onClick={() => { setNumberOfVisits("5"); setIsFocusedVisitSelect(!isFocusedVisitSelect) }}>Every 5 Visits</div>
                                                        <div className="option" onClick={() => { setNumberOfVisits("10"); setIsFocusedVisitSelect(!isFocusedVisitSelect) }}>Every 10 Visits</div>
                                                    </div>}

                                                </div>
                                            </div>}
                                        </div>
                                    </label>
                                </div>
                                <div className="  mt-3">
                                    <label role='button' className=" w-100 " htmlFor="action_based">
                                        <input className=" d-none  " type="radio" name="radioOptions" id="action_based" required
                                            checked={programType === 'action_based'}
                                            onChange={handleOptionChange} />
                                        <div className=" custom-radio w-100 p-14  rounded-3 ">

                                            <p className='font-16 mb-2'>Action based</p>
                                            <p className='font-14 mb-0 text-black-50 '>Reward customers every-time they complete a certain action. For example; Signs up to a marketing campaign</p>

                                            {programType === 'action_based' && <div>
                                                <label role="button" onFocus={() => setIsFocusedAction(true)}
                                                    onBlur={() => setIsFocusedAction(false)} className={`focus-field p-2 pb w-100 rounded-3  ${isFocusedAction ? 'active' : ''}`} >
                                                    <div className="w-100 m-0 rounded-3">
                                                        <p className="font-14 mb-0 ">Customers earn points</p>
                                                    </div>
                                                    <input
                                                        className="w-100 border-0 font-15 rounded-3"
                                                        type="text"
                                                        min={100} max={1000} value={pointsEarn} onChange={(e) => { if (e.target.value > 1000) { setPointsEarn(1000) } else { setPointsEarn(e.target.value) } }}
                                                        onBlur={(e) => { if (e.target.value < 100) { setPointsEarn(100) } }}
                                                        required

                                                    />
                                                </label>
                                                <div role="button" onClick={handleOptionSelectAction}
                                                    className={`focus-field p-2 mt-3 pb w-100 rounded-3 position-relative  ${isFocusedActionSelect ? 'active' : ''}`} >
                                                    <div className="w-100 h-100  m-0 rounded-3 d-flex align-items-center justify-content-between ">
                                                        <div>

                                                            <p className="font-14 mb-0 text-black-50">When?</p>
                                                            {actionName && <p className="font-14 mb-0 ">{actionName}</p>}
                                                        </div>
                                                        <FaAngleDown className='text-black-50' />
                                                    </div>
                                                    {isFocusedActionSelect && <div className="position-absolute  bottom-0 bg-white w-100 shadow start-0 rounded-3 p-2">

                                                        <div className="option" onClick={() => { setActionName("Signs up to Campaign"); setIsFocusedActionSelect(!isFocusedActionSelect) }}>Signs up to Campaign</div>
                                                        <div className="option" onClick={() => { setActionName("Complete Survey"); setIsFocusedActionSelect(!isFocusedActionSelect) }}>Complete Survey</div>
                                                        <div className="option" onClick={() => { setActionName("Gives Feedback"); setIsFocusedActionSelect(!isFocusedActionSelect) }}>Gives Feedback</div>
                                                        <div className="option" onClick={() => { setActionName("Gives Google Review"); setIsFocusedActionSelect(!isFocusedActionSelect) }}>Gives Google Review</div>
                                                    </div>}

                                                </div>
                                            </div>}
                                        </div>
                                    </label>
                                </div>
                                <div className="  mt-3">
                                    <label role='button' className=" w-100 " htmlFor="manual">
                                        <input className=" d-none  " type="radio" name="radioOptions" id="manual" required
                                            checked={programType === 'manual'}
                                            onChange={handleOptionChange} />
                                        <div className=" custom-radio w-100 p-14  rounded-3 ">

                                            <p className='font-16 mb-2'>Manual based</p>
                                            <p className='font-14 mb-0 text-black-50 '>You will have to add this loyalty to any user manually from business app.</p>

                                            {programType === 'manual' && <div>
                                                <label role="button" onFocus={() => setIsFocusedManual(true)}
                                                    onBlur={() => setIsFocusedManual(false)} className={`focus-field p-2 pb w-100 rounded-3  ${isFocusedManual ? 'active' : ''}`} >
                                                    <div className="w-100 m-0 rounded-3">
                                                        <p className="font-14 mb-0 ">Customers earn points</p>
                                                    </div>
                                                    <input
                                                        className="w-100 border-0 font-15 rounded-3"
                                                        type="text"
                                                        min={100} max={1000} value={pointsEarn} onChange={(e) => { if (e.target.value > 1000) { setPointsEarn(1000) } else { setPointsEarn(e.target.value) } }}
                                                        onBlur={(e) => { if (e.target.value < 100) { setPointsEarn(100) } }}
                                                        required

                                                    />
                                                </label>
                                               
                                            </div>}
                                        </div>
                                    </label>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>

            </main>
            <ToastContainer />
        </>
    )
}

export default SelectProgramType

