import { Slider } from '@mui/material';
import React, { useState } from 'react'

const MarketingSlider = ({initialSliderValue }) => {
    const [sliderValue] = useState(initialSliderValue); // Remove setSliderValue and readOnly

//   // Function to update the slider value programmatically
//   const updateSliderValue = (newValue) => {
//     setSliderValue(newValue);
//   };
    return (
        <>
             <div className='about-slider'>
      <Slider
        value={sliderValue}
        // onChange={(e, newValue) => updateSliderValue(newValue)}
        aria-label="Default"
        valueLabelDisplay="auto"
        readOnly // Make the slider non-interactive
      />
    </div>
        </>
    )
}

export default MarketingSlider
