import React, { useState, useEffect, useRef } from 'react'
import Header from '../../components/Header'
import SideBar from '../../components/SideBar'
import notification from '../../assets/imges/noti.png'
import cafe from '../../assets/imges/cafeimg.png'
import { Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { FormControl, MenuItem, Pagination, Select, Stack } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { createBusinessLoayalty, getBusinessLoyalties } from '../../redux/slices/userSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Spinner } from 'reactstrap';
import upload from '../../assets/imges/upload.png'
import AvatarEditor from 'react-avatar-editor'
import { FiZoomIn, FiZoomOut } from 'react-icons/fi'
import { FaArrowRotateLeft, FaArrowRotateRight } from 'react-icons/fa6'
import { FaCheck } from 'react-icons/fa'
import PaginatedItems from '../../components/pagination/PaginatedItems'
import LoyaltyItems from '../../components/pagination/LoyaltyItems'

const Loyalty = () => {
    const dispatch = useDispatch();
    const { isLoading, getError, businessLoyalties } = useSelector((state) => state.user)
    const [createProgram, setCreateProgram] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const Navigate = useNavigate();
    const [cardName, setCardName] = useState();
    const [cardDescription, setCardDescription] = useState();
    const [stamps, setStamps] = useState();
    const [joinStamps, setJoinStamps] = useState();
    const [whenToProvideStamps, setWhenToProvideStamps] = useState();
    const [numberOfStamps, setNumberOfStamps] = useState();
    const [webisteurl, setWebisteurl] = useState();
    const [userDetail, setuserDetail] = useState();
    const [offerDetails, setOfferDetails] = useState();
    const [stampIcon, setStampIcon] = useState(null);
    const [stampsUntillReward, setStampsUntillReward] = useState();
    const [startOfOrder, setStartOfOrder] = useState(false);
    const [endOfOrder, setEndOfOrder] = useState(false);
    const [never, setNever] = useState(false);
    const [anytime, setAnytime] = useState(false);
    const [newCustomers, setNewCustomers] = useState(false);
    const [returning, setReturning] = useState(false);
    const [all, setAll] = useState(false);
    const [privateCard, setPrivateCard] = useState();
    const [selectedStampIcon, setSelectedStampIcon] = useState(1);


    //   ---------- image crop ----------
    const [file, setFile] = useState(null);
    const [editImgModal, setEditImgModal] = useState(false);
    const [image, setImage] = useState('')
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)
    const [cnvsImage, setCnvsImage] = useState("")
    const previewRef = useRef();

    const handlePreview = () => {
        if (previewRef.current) {
            const cnvs = previewRef.current.getImage();
            console.log('canvas get image ', cnvs)
            cnvs.toBlob((blob) => {
                setFile(blob);

                console.log(typeof (blob), "------------blob image")
                const previousImg = URL.createObjectURL(blob);
                setCnvsImage(previousImg);
            });
            setEditImgModal(false)
            setImage('');
            console.log(cnvsImage, "-----final")
        }
    };
    useEffect(() => {
        if (getError && getError.length > 1) {
            toast.error(getError, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }, [getError])
    useEffect(() => {
        dispatch(getBusinessLoyalties());
        setuserDetail(JSON.parse(localStorage.getItem('userDetails')))
    }, []);

    function handleSubmit(e) {
        e.preventDefault();
        var data = new FormData();
        cardName && data.append('card_name', cardName);
        cardDescription && data.append('description', cardDescription);
        stamps && data.append('stamp', stamps);
        joinStamps && data.append('join_stamp', joinStamps);
        whenToProvideStamps && data.append('whn_add_stamp', whenToProvideStamps);
        numberOfStamps && data.append('count', numberOfStamps);
        stampsUntillReward && data.append('stmp_untl', stampsUntillReward);
        privateCard && data.append('private_card', privateCard);
        file && data.append('image', file);
        webisteurl && data.append('website_url', webisteurl);
        offerDetails && data.append('offer_details', offerDetails);
        data.append('when', JSON.stringify({
            'startoforder': startOfOrder,
            'endoforder': endOfOrder,
            'anytime': anytime,
            'never': never
        }));
        data.append('who', JSON.stringify({
            'new': newCustomers,
            'returning': returning,
            'all': all,
        }));
        dispatch(createBusinessLoayalty(data, handleResponse))
    }

    function handleResponse(data) {
        dispatch(getBusinessLoyalties());
        setCreateProgram(false);
        console.log(data, 'create loyalty response ');
        setCardName(null)
        setCardDescription(null)
        setStamps(null)
        setJoinStamps(null)
        setWhenToProvideStamps(null)
        setNumberOfStamps(null)
        setWebisteurl(null)
        setuserDetail(null)
        setOfferDetails(null)
        setStampIcon(null)
        setStampsUntillReward(null)
        setStartOfOrder(false)
        setEndOfOrder(false)
        setNever(false)
        setAnytime(false)
        setNewCustomers(false)
        setReturning(false)
        setAll(false)
        setPrivateCard(null)

    }

    var stampIconsList = [
        { id: 1, image: 'https://fastly.picsum.photos/id/819/200/200.jpg?hmac=nCwO4yKGbs8354aS0yf974UlPFBF_gwUSNazar7yBhk' },
        { id: 2, image: 'https://fastly.picsum.photos/id/515/200/200.jpg?hmac=d6WMJkHOOB7pT-6y_yjHKrJdVp3v17ry6bMzGVuyb68' },
        { id: 3, image: 'https://fastly.picsum.photos/id/367/200/200.jpg?hmac=6NmiWxiENMBIeAXEfu9fN20uigiBudgYzqHfz-eXZYk' },
    ]
    return (
        <section className='business_admin_main'>

            <div className="container-fluid ">
                <div className="row order pt-3">

                    {/* <!-- ===========HEADER========== --> */}
                    <Header />
                    {/* <!-- ===========SID-BAR======= --> */}
                    <SideBar />
                    {/* <!-- ========= content body ======= --> */}

                    <div className="w-lg-80 px-lg-4 px-md-3 px-2 pb-3    ">
                        {createProgram == false ?
                            <div className="container-fluid mt-4">
                                <div>
                                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                                        <div>
                                            <h3 className="font-20  text-black">Loyalty Program</h3>
                                        </div>
                                        <button type='button' onClick={() => setCreateProgram(true)} className="btn-blue rounded-4 inter px-3 mt-2 p-2  fw-bold font-14 text-capitalize"><i className="fa-solid fa-shop me-2" />New Program</button>
                                    </div>
                                    <div className="row justify-content-between mt-3">
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <h6 className="font-18 colorgray">{businessLoyalties?.length}/3 Active Campaigns</h6>
                                        </div>
                                        <div className="col-lg-6 mt-lg-0 mt-md-0 mt-3 col-md-6 m col-12 marketing_tab">
                                            <ul className="nav nav-pills mb-3 justify-content-lg-end justify-content-md-end justify-content-start tab-nav-pills " id="pills-tab" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">All Programs</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Active</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Pending</button>
                                                </li>

                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="pills-Draft-tab" data-bs-toggle="pill" data-bs-target="#pills-Draft" type="button" role="tab" aria-controls="pills-Draft" aria-selected="false">Past Draft</button>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="tab-content" id="pills-tabContent">
                                            {isLoading ?
                                                <div className="row justify-content-center not-active-Campaigns rounded-3 text-center mt-3">
                                                    <Spinner color="dark" />
                                                </div>
                                                :
                                                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex={0}>
                                                    {businessLoyalties.length < 1 ?
                                                        <div className="row justify-content-center not-active-Campaigns rounded-3 text-center mt-3">
                                                            <div className="col-12">
                                                                <div className="Campaigns p-4 rounded-3">
                                                                    <h5 className='fw-bold mb-0'>No Active Loyalty Programs</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : <div className="container-fluid">

                                                            <div className="row justify-content-center">
                                                                <PaginatedItems items={businessLoyalties} itemsPerPage={6} ItemsComponent={LoyaltyItems} />

                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                            <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabIndex={0}>
                                                {isLoading ?
                                                    <div className="row justify-content-center not-active-Campaigns rounded-3 text-center mt-3">
                                                        <Spinner color="dark" />
                                                    </div>
                                                    :
                                                    <div className="row justify-content-center not-active-Campaigns rounded-3 text-center mt-3">
                                                        <div className="col-12">
                                                            <div className="Campaigns p-4 rounded-3">
                                                                <h5 className='fw-bold mb-0'>No Active Loyalties</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab" tabIndex={0} ><div className="row justify-content-center not-active-Campaigns rounded-3 text-center mt-3">
                                                {isLoading ?
                                                    <div className="row justify-content-center not-active-Campaigns rounded-3 text-center mt-3">
                                                        <Spinner color="dark" />
                                                    </div>
                                                    :
                                                    <div className="col-12">
                                                        <div className="Campaigns p-4 rounded-3">
                                                            <h5 className='fw-bold mb-0'>No Active Loyalties</h5>
                                                        </div>
                                                    </div>
                                                }
                                            </div></div>
                                            <div className="tab-pane fade" id="pills-Draft" role="tabpanel" aria-labelledby="pills-Draft-tab"
                                                tabIndex={0} >
                                                <div className="row justify-content-center not-active-Campaigns rounded-3 text-center mt-3">
                                                    <div className="col-12">
                                                        <div className="Campaigns p-4 rounded-3">
                                                            <h5 className='fw-bold mb-0'>No Active Loyalties</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="container-fluid mt-4 ">
                                <div className="container bg-color p-3 rounded-4">
                                    <form className="row" onSubmit={handleSubmit}>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-12 mb-3">
                                                    <div className="mb-3">
                                                        <label className="form-label font-15 font-500 inter colorgray">Card Name*  <i className="fa-solid fa-circle-exclamation  font-10" /></label>
                                                        <input type="text" value={cardName} onChange={(e) => setCardName(e.target.value)} className="form-control inputgroup" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-12">
                                                    <h6 className="font-15 font-500 inter colorblack text-center">Front Card</h6>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mb-3">
                                                                <label className="form-label font-15 font-500 inter colorgray">Card Description*</label>
                                                                <input type="text" value={cardDescription} onChange={(e) => setCardDescription(e.target.value)} className="form-control inputgroup" placeholder="E.G. BUY 4 COFFEES, GET ONE FREE!" />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label className="form-label font-15 font-500 inter colorgray">Stamps*</label>
                                                                <input type="number" min={1} max={10} value={stamps} onChange={(e) => { if (e.target.value > 10) { setStamps(10) } else { setStamps(e.target.value) } }} className="form-control inputgroup" />
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="mb-3">
                                                                <label className="form-label font-15 font-500 inter colorgray">Join Stamps  <i className="fa-solid fa-circle-exclamation  font-10" /></label>
                                                                <input type="number" min={1} max={2} value={joinStamps} onChange={(e) => { if (e.target.value > 2) { setJoinStamps(2) } else { setJoinStamps(e.target.value) } }} className="form-control inputgroup"
                                                                    onBlur={(e) => { if (e.target.value > 2) { setJoinStamps(2) } }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-8 loyalty_select">
                                                            <div className="mb-3">
                                                                <label className="form-label font-15 font-500 inter colorgray">When to provide Additional stamps? <i className="fa-solid fa-circle-exclamation font-10" /></label>
                                                                <FormControl className="mui-select-field w-100 ">
                                                                    <Select required
                                                                        value={whenToProvideStamps}
                                                                        className="rounded-3 w-100 text-start font-17"
                                                                        onChange={(e) =>
                                                                            setWhenToProvideStamps(e.target.value)
                                                                        }
                                                                        displayEmpty
                                                                        inputProps={{
                                                                            "aria-label": "Without label",
                                                                        }}
                                                                    >
                                                                        <MenuItem value=""></MenuItem>
                                                                        <MenuItem value={'Upon Sign-up'}>Upon Sign-up</MenuItem>
                                                                        <MenuItem value={'On First Stamp'}>On First Stamp</MenuItem>
                                                                        <MenuItem value={'After Completing a Survey'}>After Completing a Survey</MenuItem>
                                                                        <MenuItem value={'After a Certain Number of Orders'}>After a Certain Number of Orders</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <div className="mb-3">
                                                                <label className="form-label font-15 font-500 inter colorgray">How many? <i className="fa-solid fa-circle-exclamation  font-10" /></label>
                                                                <input type="number" min={1} max={2} value={numberOfStamps}
                                                                    onChange={(e) => { if (e.target.value > 2) { setNumberOfStamps(2) } else { setNumberOfStamps(e.target.value) } }} className="form-control inputgroup" />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-5">
                                                            <div className="mb-3">
                                                                <label className="form-label font-15 font-500 inter colorgray">Stamps until reward*</label>
                                                                <input type="number" min={1} max={10} className="form-control inputgroup" value={stampsUntillReward} onChange={(e) => { if (e.target.value > 10) { setStampsUntillReward(10) } else { setStampsUntillReward(e.target.value) } }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-7">
                                                            <div className="mb-3 loyalty_select">
                                                                <label className="form-label font-15 font-500 inter colorgray">Private Card?*</label>
                                                                <FormControl className="mui-select-field w-100 ">
                                                                    <Select required
                                                                        value={privateCard}
                                                                        className="rounded-3 w-100 text-start font-17"
                                                                        onChange={(e) =>
                                                                            setPrivateCard(e.target.value)
                                                                        }
                                                                        displayEmpty
                                                                        inputProps={{
                                                                            "aria-label": "Without label",
                                                                        }}
                                                                    >
                                                                        <MenuItem value=""></MenuItem>
                                                                        <MenuItem value={0}>No</MenuItem>
                                                                        <MenuItem value={1}>Yes</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="mb-3">
                                                                <p className="form-label font-15 font-500 inter mb-1 colorgray">Backdrop Image (Optional) </p>
                                                                <div className>
                                                                    <p htmlFor className="form-label font-15 font-500 inter colorgray">Upload Image*
                                                                        <i className="font-12 fa-solid fa-circle-exclamation" /></p>
                                                                    <div className=" inputgroup1 p-3 text-center">
                                                                        <div role='button' onClick={() => setEditImgModal(true)}>
                                                                            <div className="px-3 py-3 mb-3 ">
                                                                                <img src={upload} width={60} alt="upload" />
                                                                                <h6 className="font-15 font-500 inter colorblack mt-2 point">Drag &amp; drop
                                                                                    files or <span className="colorblue text-decoration-underline">Browse</span>
                                                                                </h6>
                                                                                <p className="mb-0 mt-2 font-12 colorgray inter point">Supported
                                                                                    formates: <br />
                                                                                    JPEG, PNG</p>
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {file &&
                                                                <div>
                                                                    <p className='colorgray MB-2'>Uploaded Backdrop Image:</p>
                                                                    <img src={cnvsImage ? cnvsImage : cafe} className="w-175 object-fit-contain rounded-4 shadow-sm " height={90} alt="" />
                                                                </div>

                                                            }
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-0 mt-3">
                                                    <h6 className="font-15 font-500 inter colorblack text-center">Card Details</h6>
                                                    <div className="mb-3">
                                                        <label className="form-label font-15 font-500 inter colorgray">Website URL</label>
                                                        <input type="text" value={webisteurl} onChange={(e) => setWebisteurl(e.target.value)} className="form-control inputgroup" placeholder="https://www.timelink.au" />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label font-15 font-500 inter colorgray">Business Name*</label>
                                                        <input type="disabled" defaultValue={userDetail?.business_name} className="form-control inputgroup" />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label font-15 font-500 inter colorgray">Offer Details*
                                                        </label>
                                                        <textarea className="form-control inputgroup" value={offerDetails} onChange={(e) => setOfferDetails(e.target.value)} style={{ resize: 'none' }} rows={5} defaultValue={""} />
                                                    </div>
                                                    <div className="row loyalty_program">
                                                        <div className="col-12">
                                                            <p className="font-14 inter colorgray mb-0">Select When and to Whom to Extend Your Loyalty Program (Optional)</p>
                                                            <div className="row mt-2">
                                                                <div className="col-2 text-end">
                                                                    <p className="font-14 inter colorgray mb-0">When?</p>
                                                                </div>
                                                                <div className="col-lg-3">
                                                                    <div className="form-check">
                                                                        <label className="form-check-label font-12 Inter colorgray white-space-nowrap" htmlFor="Start" >
                                                                            <input checked={startOfOrder}
                                                                                onChange={(e) => setStartOfOrder(e.target.checked)} className="form-check-input" type="checkbox" id="Start" />
                                                                            Start of order
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label font-12 Inter colorgray white-space-nowrap" htmlFor="End">
                                                                            <input className="form-check-input "
                                                                                checked={endOfOrder}
                                                                                onChange={(e) => setEndOfOrder(e.target.checked)} type="checkbox" id="End" />
                                                                            End of order
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label font-12 Inter colorgray" htmlFor="Never">
                                                                            <input className="form-check-input" type="checkbox" checked={never}
                                                                                onChange={(e) => setNever(e.target.checked)} id="Never" />
                                                                            Never
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label font-12 Inter colorgray" htmlFor="Anytime">
                                                                            <input className="form-check-input" type="checkbox" checked={anytime}
                                                                                onChange={(e) => setAnytime(e.target.checked)} id="Anytime" />
                                                                            Anytime
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-2 text-end">
                                                                    <p className="font-14 inter colorgray mb-0">Who??</p>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className="form-check">
                                                                        <label className="form-check-label font-12 Inter colorgray" htmlFor="customers">
                                                                            <input className="form-check-input" type="checkbox" checked={newCustomers}
                                                                                onChange={(e) => setNewCustomers(e.target.checked)} id="customers" />
                                                                            New-customers
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label font-12 Inter colorgray" htmlFor="Returning">
                                                                            <input className="form-check-input" type="checkbox" checked={returning}
                                                                                onChange={(e) => setReturning(e.target.checked)} id="Returning" />
                                                                            Returning
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label font-12 Inter colorgray" htmlFor="All">
                                                                            <input className="form-check-input" type="checkbox" checked={all}
                                                                                onChange={(e) => setAll(e.target.checked)} id="All" />
                                                                            All
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-3">
                                                            <p>
                                                                Please Select one of the below for stamp icon
                                                            </p>
                                                            <div className="d-flex flex-row justify-content-around">
                                                                {
                                                                    stampIconsList.map((value, index) => {
                                                                        return (
                                                                            <div style={{ width: 75, height: 75 }} className='rounded position-relative overflow-hidden'>
                                                                                {
                                                                                    selectedStampIcon == value.id &&
                                                                                    <div className='w-100 h-100  position-absolute d-flex justify-content-center align-items-center' style={{ top: 0, left: 0, backgroundColor: '#0000009c' }}>
                                                                                        <FaCheck size={30} color='white' />
                                                                                    </div>
                                                                                }
                                                                                <img src={value.image} width={75} height={75} alt='stamp icon' className='rounded cursor-pointer' onClick={() => {
                                                                                    setSelectedStampIcon(value.id)
                                                                                }} />
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 mb-3 text-center">
                                                <button type="button" onClick={() => setCreateProgram(false)} className="btn-Rejected rounded-5 p-3 fw-medium font-14 text-capitalize m-2">
                                                    Save &amp; Exit</button>
                                                <button type='submit' className="btn-blue m-2 rounded-5 p-3 fw-medium font-14 text-capitalize" style={{ minWidth: '110px' }}>
                                                    {isLoading ? <Spinner color='light' size={'sm'} /> : 'Next'}</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        }
                    </div>
                </div>
            </div>
            {/* ============ MODAL ============= */}

            <Modal show={showModal} className='rounded-modal text-center modals_scroll business_admin_main' centered  >

                <Modal.Body style={{ maxHeight: '85vh', overflowY: 'auto' }}>
                    <div className="py-3 inter">
                        <div>
                            <img src={notification} width="50px" alt="" />
                            <h6 className="font-24 fw-bold colorblack my-3">Great Choice!</h6>
                            <p className="mt-2 fw-semibold  ">You’ve just launched a new Loyalty Program.<br /> Please note once, a program is active - you <br className='d-lg-block d-none' />can’t make any changes.</p>
                        </div>
                        <div className="col-12 mt-3 mb-3 text-center d-flex align-items-center ">
                            <div className="w-50 px-2">
                                <button onClick={() => {
                                    setShowModal(false);
                                    setCreateProgram(false);
                                }} className="btn-gray rounded-4 p-2 px-4  py-3 fw-medium w-100 font-14 text-capitalize" data-bs-dismiss="modal">
                                    Ugh go back!</button>
                            </div>
                            <div className="w-50 px-2">
                                <button onClick={() => {
                                    setShowModal(false);
                                    setCreateProgram(false);

                                }} className="btn-blue rounded-4 p-2 px-5 py-3 fw-medium w-100 font-14 text-capitalize" >
                                    Got It!</button>
                            </div>


                        </div>
                    </div>

                </Modal.Body>

            </Modal>
            <ToastContainer />

            <Modal show={editImgModal} className='business_admin_main' centered onHide={() => setEditImgModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {image && (
                            <>
                                <div className="imag_crop_shadow d-flex justify-content-center ">

                                    <AvatarEditor
                                        width={250}
                                        height={120}
                                        ref={previewRef}
                                        border={50}
                                        borderRadius={14}
                                        color={[80, 76, 76, 0.6]}
                                        scale={scale}
                                        rotate={rotate}
                                        image={URL.createObjectURL(image)}
                                    />
                                </div>
                                <div className='mt-2'>
                                    <button role='button' className='btn-blue rounded-2 m-1' onClick={() => setScale(scale - 0.1)}><FiZoomOut /></button>
                                    <button role='button' className='btn-blue rounded-2 m-1' onClick={() => setScale(scale + 0.1)}><FiZoomIn /></button>

                                    <button role='button' className='btn-blue rounded-2 m-1' onClick={() => setRotate(rotate - 90)}><FaArrowRotateLeft /></button>
                                    <button role='button' className='btn-blue rounded-2 m-1' onClick={() => setRotate(rotate + 90)}><FaArrowRotateRight /></button>
                                </div>
                            </>
                        )}

                        <div className=" inputgroup1 p-3 mt-1 text-center ">

                            <label htmlFor="file" className='h-100 w-100 cursor-pointer' >
                                <div className="px-3 py-3 mb-3 ">
                                    <img src={upload} width={60} alt />
                                    <h6 className="font-15 font-500 inter colorblack mt-2 point">Drag &amp; drop
                                        files or <span className="colorblue text-decoration-underline">Browse</span>
                                    </h6>
                                    <p className="mb-0 mt-2 font-12 colorgray inter point">Supported
                                        formates: <br />
                                        JPEG, PNG</p>
                                </div>
                            </label>
                        </div>
                        <input type="file" id='file' onChange={(e) => setImage(e.target.files[0])} className="d-none" accept="image/*" />
                    </div>
                </Modal.Body>
                {image && <Modal.Footer>
                    <button role='button' className='btn-blue rounded-2 p-2 m-1' onClick={handlePreview}>Upload</button>

                </Modal.Footer>}
            </Modal>
        </section>
    )
}

export default Loyalty
