import React from "react";
import DeviceCard from "../../components/superAdmin/Device/DeviceCard";
import { Button } from "@mui/material";
import { FaArrowUp } from "react-icons/fa";
import SideBar from "../../components/superAdmin/SideBar";
import { SiCoinmarketcap } from "react-icons/si";
import MarketingTable from "../../components/superAdmin/Marketing/MarketingTable";
import Header from "../../components/superAdmin/Header";
import { useDispatch, useSelector } from "react-redux";
import { getBusinessSurveys } from "../../redux/slices/userSlice";
import { ToastContainer } from "react-toastify";
import SurveyTable from "../../components/superAdmin/Surveys/SurveyTable";

const ManageSurveys = () => {
  const dispatch = useDispatch();
  const { isLoading, getError, businessSurveys } = useSelector(
    (state) => state.user
  );
  React.useEffect(() => {
    dispatch(getBusinessSurveys());
  }, []);
  return (
    <SideBar title={"Surveys"}>
      <div className="w-full super px-0">
        <div className="main_row ">
          <div className="container-fluid  h-100 p-3">
            <div className="container ">
            

              <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-y-2 p-2 bg-white rounded-lg border">
                <div className="flex items-center">
                  <DeviceCard
                    ratingColor="rgba(35, 197, 129, 1)"
                    rating="12"
                    icon={<FaArrowUp />}
                    head="Total"
                    number={businessSurveys?.all?.length}
                  />
                </div>
                <div className="lg:border-l md:border-l sm:border-l border-l-0 flex items-center">
                  <DeviceCard
                    ratingColor="rgba(231, 69, 69, 1)"
                    rating="12"
                    icon={<FaArrowUp />}
                    head="Pending "
                    number={businessSurveys?.pending?.length}
                  />
                </div>
                <div className="lg:border-l flex items-center">
                  <DeviceCard
                    ratingColor="rgba(35, 197, 129, 1)"
                    rating="12"
                    icon={<FaArrowUp />}
                    head="Accepted"
                    number={businessSurveys?.approved?.length}
                  />
                </div>
                <div className="lg:border-l md:border-l sm:border-l border-l-0 flex items-center">
                  <DeviceCard
                    ratingColor="rgba(35, 197, 129, 1)"
                    rating="12"
                    icon={<FaArrowUp />}
                    head="Rejected"
                    number={businessSurveys?.rejected?.length}
                  />
                </div>
              </div>
              <div className="mt-4 h-100">
                {/* <h3 className="font-18 mt-4 inter fw-medium">Marketing Program</h3> */}
                <SurveyTable />
              </div>
            </div>
          </div>
        </div>
      </div>
      <style>
        {`
            .modal {
                --bs-modal-width: 800px !important;
            }
            `}
      </style>

      <ToastContainer />
    </SideBar>
  );
};

export default ManageSurveys;
