import React, { useState, useEffect, useRef } from "react";
import Header from "../../components/Header";
import SideBar from "../../components/SideBar";
import {
  Button,
  FormControlLabel,
  Pagination,
  Slider,
  Stack,
} from "@mui/material";
import StepOne from "../../components/campaigns/StepOne";
import StepTwo from "../../components/campaigns/StepTwo";
import StepThree from "../../components/campaigns/StepThree";
import cafe from "../../assets/imges/cafepic.png";
import notification from "../../assets/imges/noti.png";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import {
  getBusinessCampaings,
  createBusinessCampaign,
  setSumitCamgpaingState,
  emptySumitCamgpaingState,
  seTgetErrorNull,
} from "../../redux/slices/userSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "reactstrap";
import MarketingSlider from "../../components/MarketingSlider";
import ReactPaginate from "react-paginate";
import PaginatedItems from "../../components/pagination/PaginatedItems";
import Items from "../../components/pagination/Items";

const Campaigns = () => {
  const dispatch = useDispatch();
  const { isLoading, getError, businessCampaigns, submitCampaign } =
    useSelector((state) => state.user);
  const [activeStep, setActiveStep] = useState(0);
  const [createCampaigns, setCreateCampaigns] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [submitStepOne, setSubmitStepOne] = useState(false);
  const childFunc = React.useRef(null);

  function handleCurrentStep() {
    setActiveStep((ps) => {
      return ps - 1;
    });
  }

  const handleNext = () => {
    var check = childFunc?.current();
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep < 2) {
        return prevActiveStep + 1;
      } else {
        console.log("Not increasing step");
        return prevActiveStep;
      }
    });
    if (activeStep == 2) {
      if (!check) {
        alert("inside not check condtion");
      } else {
        setShowModal(true);
      }
    }
  };
  function _renderContent(step) {
    switch (step) {
      case 0:
        return (
          <StepOne childFunc={childFunc} stepChecker={handleCurrentStep} />
        );
      case 1:
        return <StepTwo childFunc={childFunc} />;
      case 2:
        return <StepThree childFunc={childFunc} />;

      default:
        break;
    }
  }
  const Navigate = useNavigate();

  console.log(activeStep);
  const hanleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  useEffect(() => {
    if (getError && getError.length > 1) {
      toast.error(getError, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(seTgetErrorNull());

    }
  }, [getError]);
  useEffect(() => {
    dispatch(getBusinessCampaings());
  }, []);

  function handleCampaignSubmit() {
    setShowModal(false);
    setCreateCampaigns(false);
    setActiveStep(0);
    dispatch(createBusinessCampaign(submitCampaign, handleResponse));
  }
  function handleResponse(data) {
    console.log("reponse data of campaign create ", data);
    dispatch(getBusinessCampaings());
    dispatch(emptySumitCamgpaingState());
  }

  return (
    <section className="business_admin_main">
      <div className="container-fluid ">
        <div className="row order ">
          {/* <!-- ===========SID-BAR======= --> */}
          <SideBar />
          {/* <!-- ========= content body ======= --> */}

          <div className="w-lg-80 px-0 pb-2 marketing_tab">
            {/* <!-- ===========HEADER========== --> */}
            <Header />
            <div className="px-lg-4 px-md-3 px-2">
              {createCampaigns == false ? (
                <div className="container-fluid mt-4">
                  <div className="d-flex justify-content-between flex-wrap align-items-center">
                    <div>
                      <h3 className="font-20  text-black">
                        Marketing Campaigns
                      </h3>
                    </div>
                    <button
                      onClick={() => setCreateCampaigns(true)}
                      type="button"
                      className="btn-blue rounded-4 inter px-3 mt-2 p-2  fw-bold font-14 text-capitalize"
                    >
                      <i className="fa-solid fa-shop me-2" />
                      New Campaign
                    </button>
                  </div>
                  <div className="row justify-content-between mt-3">
                    <div className="col-lg-3 col-md-6 col-12">
                      <h6 className="font-18 colorgray">
                        {businessCampaigns?.active?.length}/3 Active Campaigns
                      </h6>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-3 mt-3">
                      <ul
                        className="nav nav-pills mb-3 justify-content-lg-end justify-content-md-end justify-content-sm-end justify-content-start tab-nav-pills"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link active"
                            id="pills-home-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-home"
                            type="button"
                            role="tab"
                            aria-controls="pills-home"
                            aria-selected="true"
                          >
                            All Campaigns
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="pills-profile-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-profile"
                            type="button"
                            role="tab"
                            aria-controls="pills-profile"
                            aria-selected="false"
                          >
                            Active
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="pills-contact-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-contact"
                            type="button"
                            role="tab"
                            aria-controls="pills-contact"
                            aria-selected="false"
                          >
                            Pending
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="pills-disabled-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-disabled"
                            type="button"
                            role="tab"
                            aria-controls="pills-disabled"
                            aria-selected="false"
                          >
                            Rejected
                          </button>
                        </li>

                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="pills-Draft-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-Draft"
                            type="button"
                            role="tab"
                            aria-controls="pills-Draft"
                            aria-selected="false"
                          >
                            Draft
                          </button>
                        </li>
                      </ul>
                    </div>
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                        tabIndex={0}
                      >
                        {isLoading ? (
                          <div className="row justify-content-center not-active-Campaigns rounded-3 text-center mt-3">
                            <div className="col-12">
                              <div className="Campaigns p-4 rounded-3">
                                <Spinner color="dark" />{" "}
                              </div>
                            </div>
                          </div>
                        ) : !businessCampaigns.all ? (
                          <div className="row justify-content-center not-active-Campaigns rounded-3 text-center mt-3">
                            <div className="col-12">
                              <div className="Campaigns p-4 rounded-3">
                                <h5 className="fw-bold">
                                  No Active Marketing Campaigns
                                </h5>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="container-fluid Campaigns">
                            <div className="row">
                              <PaginatedItems
                                items={businessCampaigns?.all}
                                itemsPerPage={6}
                                ItemsComponent={Items}
                              />
                              {/* <div className='d-flex justify-content-end hireexpert mt-3 mb-3'>
                                                                    <Stack spacing={4}>
                                                                        <Pagination count={parseInt(businessCampaigns.length / 9 +1) } variant="outlined" shape="rounded" />
                                                                    </Stack>
                                                                </div> */}
                            </div>
                          </div>
                        )}
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-profile"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                        tabIndex={0}
                      >
                        {isLoading ? (
                          <div className="row justify-content-center not-active-Campaigns rounded-3 text-center mt-3">
                            <div className="col-12">
                              <div className="Campaigns p-4 rounded-3">
                                <Spinner color="dark" />{" "}
                              </div>
                            </div>
                          </div>
                        ) : !businessCampaigns.active ||
                          businessCampaigns.active.length < 1 ? (
                          <div className="row justify-content-center not-active-Campaigns rounded-3 text-center mt-3">
                            <div className="col-12">
                              <div className="Campaigns p-4 rounded-3">
                                <h5 className="fw-bold">
                                  No Active Marketing Campaigns
                                </h5>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="container-fluid Campaigns">
                            <div className="row">
                              <PaginatedItems
                                items={businessCampaigns?.active}
                                itemsPerPage={6}
                                ItemsComponent={Items}
                              />
                              {/* <div className='d-flex justify-content-end hireexpert mt-3 mb-3'>
                                                                    <Stack spacing={4}>
                                                                        <Pagination count={parseInt(businessCampaigns.length / 9 +1) } variant="outlined" shape="rounded" />
                                                                    </Stack>
                                                                </div> */}
                            </div>
                          </div>
                        )}
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-contact"
                        role="tabpanel"
                        aria-labelledby="pills-contact-tab"
                        tabIndex={0}
                      >
                        {isLoading ? (
                          <div className="row justify-content-center not-active-Campaigns rounded-3 text-center mt-3">
                            <div className="col-12">
                              <div className="Campaigns p-4 rounded-3">
                                <Spinner color="dark" />{" "}
                              </div>
                            </div>
                          </div>
                        ) : !businessCampaigns.pending ||
                          businessCampaigns.pending.length < 1 ? (
                          <div className="row justify-content-center not-active-Campaigns rounded-3 text-center mt-3">
                            <div className="col-12">
                              <div className="Campaigns p-4 rounded-3">
                                <h5 className="fw-bold">
                                  No Pending Marketing Campaigns
                                </h5>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="container-fluid Campaigns">
                            <div className="row">
                              <PaginatedItems
                                items={businessCampaigns?.pending}
                                itemsPerPage={6}
                                ItemsComponent={Items}
                              />
                              {/* <div className='d-flex justify-content-end hireexpert mt-3 mb-3'>
                                                                    <Stack spacing={4}>
                                                                        <Pagination count={parseInt(businessCampaigns.length / 9 +1) } variant="outlined" shape="rounded" />
                                                                    </Stack>
                                                                </div> */}
                            </div>
                          </div>
                        )}
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-disabled"
                        role="tabpanel"
                        aria-labelledby="pills-disabled-tab"
                        tabIndex={0}
                      >
                        {isLoading ? (
                          <div className="row justify-content-center not-active-Campaigns rounded-3 text-center mt-3">
                            <div className="col-12">
                              <div className="Campaigns p-4 rounded-3">
                                <Spinner color="dark" />{" "}
                              </div>
                            </div>
                          </div>
                        ) : !businessCampaigns.rejected ||
                          businessCampaigns.rejected.length < 1 ? (
                          <div className="row justify-content-center not-active-Campaigns rounded-3 text-center mt-3">
                            <div className="col-12">
                              <div className="Campaigns p-4 rounded-3">
                                <h5 className="fw-bold">
                                  No Rejected Marketing Campaigns
                                </h5>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="container-fluid Campaigns">
                            <div className="row">
                              <PaginatedItems
                                items={businessCampaigns?.rejected}
                                itemsPerPage={6}
                                ItemsComponent={Items}
                              />
                              {/* <div className='d-flex justify-content-end hireexpert mt-3 mb-3'>
                                                                    <Stack spacing={4}>
                                                                        <Pagination count={parseInt(businessCampaigns.length / 9 +1) } variant="outlined" shape="rounded" />
                                                                    </Stack>
                                                                </div> */}
                            </div>
                          </div>
                        )}
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-Draft"
                        role="tabpanel"
                        aria-labelledby="pills-Draft-tab"
                        tabIndex={0}
                      >
                        {isLoading ? (
                          <div className="row justify-content-center not-active-Campaigns rounded-3 text-center mt-3">
                            <div className="col-12">
                              <div className="Campaigns p-4 rounded-3">
                                <Spinner color="dark" />{" "}
                              </div>
                            </div>
                          </div>
                        ) : !businessCampaigns.draft ||
                          businessCampaigns.draft.length < 1 ? (
                          <div className="row justify-content-center not-active-Campaigns rounded-3 text-center mt-3">
                            <div className="col-12">
                              <div className="Campaigns p-4 rounded-3">
                                <h5 className="fw-bold">
                                  No Drafted Marketing Campaigns
                                </h5>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="container-fluid Campaigns">
                            <div className="row">
                              <PaginatedItems
                                items={businessCampaigns?.draft}
                                itemsPerPage={6}
                                ItemsComponent={Items}
                              />
                              {/* <div className='d-flex justify-content-end hireexpert mt-3 mb-3'>
                                                                    <Stack spacing={4}>
                                                                        <Pagination count={parseInt(businessCampaigns.length / 9 +1) } variant="outlined" shape="rounded" />
                                                                    </Stack>
                                                                </div> */}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="container bg-color p-3 rounded-4 mt-4">
                  <div className="row justify-content-center my-3">
                    <div className="col-1 d-flex align-items-center ">
                      {activeStep > 0 && (
                        <button
                          title="Back step"
                          className="btn-blue rounded-5"
                          style={{ width: "30px", height: "30px" }}
                          onClick={hanleBack}
                        >
                          <FaAngleLeft />
                        </button>
                      )}
                    </div>
                    <div className="col-lg-4 col-12">
                      <div className="row justify-content-center text-center">
                        <div
                          className="col-lg-3 col-md-6 col-12 pe-lg-0 pe-md-0 mt-lg-0 mt-2 "
                          onClick={hanleBack}
                        >
                          <h6 className="font-13 inter colorblack mb-0">
                            Details
                          </h6>
                          <i className="fa-solid fa-circle blue font-12" />
                          <div
                            className="progress rounded-5"
                            role="progressbar"
                            aria-label="Basic example"
                            aria-valuenow="0"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            <div
                              className="progress-bar rounded-5 h-auto "
                              style={{
                                width: `${activeStep == 0 ? "50" : "100"}%`,
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 pe-lg-0 pe-md-0 mt-lg-0 mt-2">
                          <h6 className="font-13 inter colorblack mb-0">
                            Target Audience
                          </h6>
                          <i
                            className={`fa-solid fa-circle ${activeStep === 0
                                ? "light-blue"
                                : activeStep === 1
                                  ? "blue"
                                  : "blue"
                              } font-12`}
                          />{" "}
                          <div
                            className="progress"
                            role="progressbar"
                            aria-label="Basic example"
                            aria-valuenow={50}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          >
                            <div
                              className="progress-bar h-auto rounded-5"
                              style={{
                                width:
                                  activeStep === 0
                                    ? "0%"
                                    : activeStep === 1
                                      ? "50%"
                                      : "100%",
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12 pe-lg-0 pe-md-0 mt-lg-0 mt-2">
                          <h6 className="font-13 inter colorblack mb-0">
                            Confirm
                          </h6>
                          <i
                            className={`fa-solid fa-circle ${activeStep === 2 ? "blue" : "light-blue"
                              } font-12`}
                          />{" "}
                          <div
                            className="progress"
                            role="progressbar"
                            aria-label="Basic example"
                            aria-valuenow={75}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          >
                            <div
                              className="progress-bar h-auto rounded-5"
                              style={{
                                width:
                                  activeStep === 0
                                    ? "0%"
                                    : activeStep === 1
                                      ? "0%"
                                      : "50%",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-1"></div>
                  </div>
                  {_renderContent(activeStep)}

                  <div className="row">
                    <div className="col-12 mt-4 mb-3 text-center">
                      <button
                        type="button"
                        onClick={() => {
                          setCreateCampaigns(false);
                          setActiveStep(0);
                        }}
                        className="btn-Rejected rounded-5 px-4 py-2 fw-medium font-14 text-capitalize"
                      >
                        Save &amp; Exit
                      </button>
                      <button
                        type="button"
                        onClick={handleNext}
                        className="btn-blue rounded-5 px-5 py-2 ms-3 fw-medium font-14 text-capitalize"
                      >
                        {activeStep == 2 ? "Activate" : "Next"}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* ============ MODAL ============= */}

      <Modal
        show={showModal}
        className="rounded-modal text-center modals_scroll business_admin_main"
        centered
      >
        <Modal.Body style={{ maxHeight: "85vh", overflowY: "auto" }}>
          <div className="py-3 inter">
            <div>
              <img src={notification} width="50px" alt />
              <h6 className="font-24 fw-bold colorblack my-3">Great Choice!</h6>
              <p className="mt-2 fw-semibold  ">
                You’re about to use a credit to launch a new <br /> marketing
                campaign. Are you ready to boost <br /> your business?
              </p>
            </div>
            <div className="col-12 mt-3 mb-3 text-center d-flex align-items-center ">
              <div className="w-50 px-2">
                <button
                  onClick={() => {
                    // setShowModal(false);
                    // setCreateCampaigns(false);
                    // setActiveStep(0);
                    dispatch(
                      setSumitCamgpaingState({ key: "is_draft", value: 1 })
                    );
                    handleCampaignSubmit();
                  }}
                  className="btn-gray rounded-4 p-2 px-4  py-3 fw-medium w-100 font-14 text-capitalize"
                  data-bs-dismiss="modal"
                >
                  Save &amp; Exit
                </button>
              </div>
              <div className="w-50 px-2">
                <button
                  onClick={handleCampaignSubmit}
                  className="btn-blue rounded-4 p-2 px-5 py-3 fw-medium w-100 font-14 text-capitalize"
                >
                  Boost!
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </section>
  );
};

export default Campaigns;
