import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FaRegEnvelope } from 'react-icons/fa';
import logo from '../../assets/imges/Logo.png'
import { RiShareBoxFill } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import { forgotPassword } from '../../redux/slices/userSlice';
const ResetPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');

    if (email.length > 0) {
        var style = {
            border: '1.5px solid rgba(37, 99, 235, 1)',
        }
    }
    else {
        var style = {
            border: '1.5px solid rgba(226, 232, 240, 1)',
        }
    }



    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(forgotPassword({email},()=>{navigate('/auth/reset/help',{state:{email:email}})}))
    }

    return (
        <>
            <Container fluid className='reset-pages'>
                <Row className=' justify-content-center d-flex login_height py-5 h-100 ' >
                    <Col lg={5} md={8} sm={10} xs={12} className='d-flex justify-content-between flex-column' >

                        <form className='rounded-4 rest-screens px-lg-3 px-md-3 px-sm-3  inter' onSubmit={handleSubmit}>
                            <div>
                                <div className="d-flex justify-content-center">

                                    <img src={logo} alt="w8" className='reset-logo' />
                                </div>
                                <div className='inter text-center  mt-2'>
                                    <h4 className="font-24 fw-bold  text-dak-blue">Reset your password</h4>
                                    <p className="font-14 text-dark-gray ">Please enter the email address you’d like your password reset information sent to. </p>
                                </div>
                                <div className='login-form mt-lg-5 mt-md-5 mt-sm-5 mt-4' >

                                    <div className="input-group my-3 rounded-4" style={style}>
                                        <span className="input-group-text border-0 ps-3" id="basic-addon1"><FaRegEnvelope
                                            size={20} color='rgba(100, 116, 139, 1)' /></span>
                                        <input type="email" required className="form-control border-0 p-3 ps-0 rounded-4 fw-normal  " placeholder='Email address' value={email} onChange={(e) => setEmail(e.target.value.toLocaleLowerCase())} />
                                    </div>
                                </div>
                                <Button type='submit' className={`${email.length > 0 ? 'btn-blueTwo ' : 'disable_btn'} rounded-4 inter px-3 mt-2 p-3 w-100 fw-bold font-16 text-capitalize`} sx={{ fontFamily: 'Inter, sans-serif !important' }}>Reset your password</Button>
                            </div>
                            <p className="text-center inter mt-4">
                                Go Back to
                                <span className="font-16 fw-bold cursor-pointer colorPrimary inter"
                                    onClick={() => navigate('/auth/login')}> console.timelink.au/login</span>
                            </p>
                        </form>
                        <div>
                            <p className="text-center inter font-14 mt-4">

                                ©2024 Time Link Tech Pty Ltd, All Rights Reserved.
                                <span className="font-16 d-block fw-bold cursor-pointer colorPrimary inter"
                                > Privacy Policy&nbsp;&nbsp;<RiShareBoxFill size={18} className='text-gray' /></span>
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ResetPassword;
