import { Button } from '@mui/material'
import React, { memo } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import '../style/admin/loyaltyPoints.scss'
import { useNavigate } from 'react-router-dom'

const LoyaltyPointsHeader = ({handleNext,buttonActive=true, label}) => {
    const navigate = useNavigate();
    return (
        <div className='d-flex justify-content-between inter '>
            <button  type='button' className="btn-poin-gray rounded-3 text-black p-14"><AiOutlineClose size={22} onClick={()=> navigate(-1)}/></button>
            <div>

                <span className=' p-14 fw-bolder border-start border-1 text-blue rounded-0 me-2' type='button'  onClick={()=> navigate(-1)}>Back</span>
                <button disabled={!buttonActive} className={`${buttonActive?'btn-blue':'disable_btn'} p-14 fw-bolder rounded-3`} type='button'onClick={handleNext}>{label?label:'Next'}</button>
            </div>
        </div>
    )
}

export default memo(LoyaltyPointsHeader)
