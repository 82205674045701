/* eslint-disable eqeqeq */
import React from 'react'
import MarketingSlider from '../MarketingSlider'
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Button, Menu, MenuItem } from '@mui/material';
import { FaAngleDown } from 'react-icons/fa6';
import { useDispatch } from 'react-redux';
import { updateCampaignStatus } from '../../redux/slices/userSlice';

export default function NewCampaignsItems({ currentItems }) {
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const [anchorElArray, setAnchorElArray] = React.useState([]);

    const handleClick = (index) => (event) => {
        const newAnchorElArray = [...anchorElArray];
        newAnchorElArray[index] = event.currentTarget;
        setAnchorElArray(newAnchorElArray);
    };

    const handleClose = (index) => {
        const newAnchorElArray = [...anchorElArray];
        newAnchorElArray[index] = null;
        setAnchorElArray(newAnchorElArray);
    };
    function handleUpdateStatus(id, status) {
        var data = {
            campaign_id: id,
            status: status ? 'active' : 'inactive'
        }
        dispatch(updateCampaignStatus(data));
    }
    return (
        <>
            {currentItems.map((index) => {
                return (
                    <div className="  p-0 py-2" style={{ borderBottom: "1px solid #E4E5E7", minHeight: "200px" }}>
                        {/* <div className='text-center'>
                            <button className={`${index.is_draft == 1 ? 'btn-Draft' : index.approval_status == 'approved' ? 'btn-green' : index.approval_status == 'rejected' ? 'btn-Rejected' : 'btn-Pending'} rounded-5  px-3 py-1 fw-medium font-14 text-capitalize`}>
                                {index.is_draft == 1 ? 'Draft' : index.status == 'active' ? 'Active' : index.approval_status}</button>
                        </div> */}
                        <div className={` row justify-content-between g-0 h-100`}>
                            <div className=" col-4 ">

                                <img src={index.offer_images} height={120} width="100%" className=" object-fit-cover rounded-4 my-2" alt="offer " />
                                <h6 className="font-13 inter fw-semibold  colorblack mb-0">{index.offer_name}.</h6>
                                {/* <h6 className="font-15 mb-0 inter fw-medium  colorblack">{index.offer_heading}</h6> */}
                                <p className="mb-0 font-13 fw-medium inter text-dark-gray">{index.offer_subheading}</p>
                                {/* <p className="mb-0 font-13 inter fw-medium text-dark-gray">{index.nitty_gritty_stuff}</p> */}
                            </div>
                            <div className="col-4 d-flex align-align-items-center  ">

                                {/* <div className='campaigns-card-bar mx-auto rounded-4 text-white p-3 mt-3'>
                                    <h5 className="font-18 fw-semibold mb-0">
                                        Reach vs Impressions
                                    </h5>
                                    <p className="font-14 mb-2">
                                        Success Rate</p> */}
                                {/* <Tippy className='bg-white ' content={<span className='blue font-12 fw-semibold inter'>66%</span>}>
                                                                                                <div className="progress" role="progressbar" aria-label="Basic example" aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}>
                                                                                                    <div className="progress-bar h-auto rounded-5" style={{ width: '66%' }} />
                                                                                                </div>
                                                                                            </Tippy> */}
                                {/* 
                                    <MarketingSlider key={index}
                                        initialSliderValue={index.id} /> */}

                                {/* </div> */}
                                <div className='d-flex text-center w-100 align-items-center justify-content-evenly  '>

                                    <div>
                                        <p className='font-30 lh-1  fw-bold mb-0'>{index.reaches_count || 0}</p>
                                        <p className='font-20 mb-0  fw-bold text-gray'>Reach</p>
                                    </div>
                                    <div className='ms-1'>
                                        <p className='font-30 lh-1 fw-bold mb-0'>{index.signups_count || 0}</p>
                                        <p className='font-20 mb-0 fw-bold text-gray'>Sign-up</p>
                                    </div>
                                </div>
                            </div>

                            <div className='d-flex flex-column col-3 text-center mt-2'>
                                <div className='flex-grow-1   d-flex align-items-center justify-content-between  '>
                                    <div className='business_admin_main '>
                                        <div className="step-three">

                                            <div className="container-stepOne " style={{ transform: "scale(1.2)" }}>
                                                <input type="checkbox" className="checkbox" id={`checkbox${index.id}`}
                                                    onChange={(e) => { handleUpdateStatus(index.id, e.target.checked) }}
                                                    checked={index.status == 'active' ? true : false}
                                                />
                                                <label className="switch" htmlFor={`checkbox${index.id}`} >
                                                    <span className="slider" />
                                                </label>
                                            </div>
                                        </div>

                                    </div>


                                    <div >
                                        <span role='button' style={{ background: "#D9D9D9" }}
                                            id={`basic-button-${index.id}`}
                                            aria-controls={`basic-menu-${index.id}`}
                                            aria-haspopup="true"
                                            onClick={handleClick(index.id)}
                                            className='d-flex align-items-center pe-2 rounded-3'
                                        >
                                            <span className='p-2 fw-bold font-12' style={{ borderRight: "1.5px solid #E4E5E7" }}>View</span>
                                            <span className='ps-2'>

                                                <FaAngleDown size={14} />
                                            </span>
                                        </span>
                                        <Menu
                                            id={`basic-menu-${index.id}`}
                                            anchorEl={anchorElArray[index.id]}
                                            open={Boolean(anchorElArray[index.id])}
                                            onClose={() => handleClose(index.id)}
                                            MenuListProps={{
                                                'aria-labelledby': `basic-button-${index.id}`,
                                            }}
                                        >
                                            <MenuItem className='font-14' onClick={() => handleClose(index.id)}>Edit</MenuItem>
                                            <MenuItem className='font-14' onClick={() => { handleClose(index.id); Navigate('/marketing/campaigns/detail/new', { state: { campaignDetail: index } }) }}>View Statistics</MenuItem>

                                        </Menu>
                                    </div>
                                </div>
                                <div className="d-flex flex-col justify-content-end gap-1 mt-1 mr-0">

                                    {(moment().diff(moment(index.created_at), 'days') >= 21) ? (
                                        <p className="mb-0 fw-bold text-danger text-end">Ended</p>
                                    ) : (
                                        <p className="mb-0 inter fw-bold font-10 text-end">
                                        Days Remaining : {21-moment().diff(moment(index.created_at), 'days')}.
                                        </p>
                                    )}
                                    <div className="d-flex flex-row justify-content-end mt-1">
                                        <p className="mb-0 fw-bold inter font-10">Date Created:&nbsp;</p>
                                        <p className="mb-0 inter fw-bold text-gray font-10">
                                            {moment(index.created_at).format('DD MMM YYYY')}
                                        </p>
                                    </div>
                                    {/* <p className="mb-0 fw-bold inter  font-10">Date Created:&nbsp;</p>
                                    <p className="mb-0 inter fw-bold text-gray font-10">{moment(index.create_at).format('DD MMM YYYY')}</p> */}
                                </div>
                            </div>


                        </div>
                    </div>
                )
            })}
        </>
    )
}
