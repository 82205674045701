import React, { useEffect, memo, useState } from "react";
import logo from "../assets/imges/Logo.png";
import timeLinkLogo from "../assets/imges/Logo.png";
import silverPack from "../assets/imges/silver-pack.png";
import goldPack from "../assets/imges/gold-pack.png";
import plantinumPack from "../assets/imges/plantinum-pack.png";
import { Link } from "react-router-dom";
import { LuLogOut } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Spinner } from "react-bootstrap";
import { IoIosCheckmarkCircle, IoMdCheckmark } from "react-icons/io";
import { createCheckout, seTgetErrorNull } from "../redux/slices/userSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Header = (props) => {
  const { isLoading, userDetail, getError } = useSelector((state) => state.user);
  const [acitveState, setActiveState] = useState(props.activeState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.clear();
    navigate("/auth/login", { replace: true });
  };
  useEffect(() => {
    if (!localStorage.getItem("userToken")) {
      navigate("/auth/login", { replace: true });
    }
  }, []);

  const [creditsModal, setCreditsModal] = useState(false);
  const [selectedPriceId, setSelectedPriceId] = useState('price_1NqUX0Ehsh9AaJCvgX6RNDxb');
  const pack = [
    {
      priceId: 'price_1NqUX0Ehsh9AaJCvgX6RNDxb',
      img: silverPack,
      name: "Starter PACK",
      description: "Get 5 Credits for $35.00 AUD",
      point_1: "Earn 1% cashback on all purchases.",
      point_2: "Lorem ipsum is a placeholder text commonly ",
      point_3: "Earn 1% cashback on all purchases.",
      point_4:
        "Lorem ipsum is a placeholder text commonly used to demonstrate ",
    },
    {
      priceId: 'price_1Pdc5eEhsh9AaJCvzpDR9Uhj',
      img: goldPack,
      name: "GOLD PACK",
      description: "Get 10 Credits for $65.00 AUD",
      point_1: "Earn 1% cashback on all purchases.",
      point_2: "Lorem ipsum is a placeholder text commonly ",
      point_3: "Earn 1% cashback on all purchases.",
      point_4:
        "Lorem ipsum is a placeholder text commonly used to demonstrate ",
    },
    {
      priceId: 'price_1Pdc73Ehsh9AaJCv28upqwmF',
      img: plantinumPack,
      name: "PLANTINUM PACK",
      description: "Get 15 Credits for $95.00 AUD",
      point_1: "Earn 1% cashback on all purchases.",
      point_2: "Lorem ipsum is a placeholder text commonly ",
      point_3: "Earn 1% cashback on all purchases.",
      point_4:
        "Lorem ipsum is a placeholder text commonly used to demonstrate ",
    },
  ];
  useEffect(() => {
    if (getError && getError.length > 1) {
      toast.error(getError, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(seTgetErrorNull());

    }
  }, [getError]);
  function handleCreateCheckout() {
    var data = {
      line_items: [{ price: selectedPriceId, quantity: 1 }],
      mode: "payment",
    };
    dispatch(createCheckout(data, handleCheckoutResponse));
  }
  function handleCheckoutResponse(data) {
    window.location.replace(data.url);
    // updateIframeUrl(data.url)
  }
  return (
    <>
      <div
        className="px-4 bg-white flex-lg-nowrap flex-md-nowrap flex-wrap justify-content-between align-items-center  businessOrder py-3 header  "
        style={{ borderBottom: "1.5px solid rgba(0, 0, 0, 0.1)" }}
      >
        <div className="d-flex flex-row justify-content-center gap-5">
          <h4 className="fw-bold inter mb-0">{props.PageTitle}</h4>
          {props.PageTitle === "Orders" && acitveState && (
            <div
              className="h-auto rounded-2 d-flex flex-row gap-2 fw-bold font-14 justify-content-center"
              style={{
                backgroundColor: "#F0F2F5",
                padding: 3,
                color: "#00000080",
                marginTop: -5,
              }}
            >
              <span
                onClick={() => {
                  acitveState !== "overview" && navigate("/");
                  setActiveState("overview");
                }}
                className="py-2 px-4 cursor-pointer rounded-2"
                style={
                  acitveState === "overview"
                    ? { backgroundColor: "#ffffff", color: "#1B59F8" }
                    : {}
                }
              >
                Overview
              </span>
              {!props.isStarterPlan && (
                <span
                  onClick={() => {
                    acitveState !== "statistics" &&
                      navigate("/order/stats/new");
                    setActiveState("statistics");
                  }}
                  className="py-2 px-4 cursor-pointer rounded-2"
                  style={
                    acitveState === "statistics"
                      ? { backgroundColor: "#ffffff", color: "#1B59F8" }
                      : {}
                  }
                >
                  Statistics
                </span>
              )}
            </div>
          )}
        </div>
        <div className="d-flex align-items-center me-lg-3 gap-2">
          <button
            className="border-2 border-[#F1F6FF] text-[#32AAE3] rounded-full p-1.5 px-3 fw-semibold text-base text-capitalize"
            onClick={() => setCreditsModal(true)}
          >
            {" "}
            {userDetail?.credits} Credits
          </button>
          {isLoading ? (
            <Spinner size="sm" color="dark" />
          ) : (
            <img
              src={userDetail.image}
              className="profile mx-2 rounded-circle "
              alt=""
            />
          )}
          <div className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {userDetail.business_name}
            </a>
            <ul className="dropdown-menu">
              <li onClick={handleLogout}>
                <a className="dropdown-item " href="#">
                  <div className="!flex gap-1 items-center">
                    <LuLogOut />
                    Logout
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Modal
        size="lg"
        centered={true}
        show={creditsModal}
        onHide={() => setCreditsModal(false)}
      >
        <Modal.Body>
          <div>
            <img src={timeLinkLogo} className="w-40 mx-auto mb-8" alt="w8" />
          </div>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-3">
            {pack.map((value, index) => {
              return (
                <div className={`${value.priceId === selectedPriceId ? 'border-2 border-blue-700' : 'border'} rounded-3xl cursor-pointer`}
                  onClick={() => { setSelectedPriceId(value.priceId) }}
                >
                  <div className="bg-[#E8F1FD] rounded-3xl p-3">
                    <img
                      src={value.img}
                      className="w-[54px] h-[39px]"
                      alt="not found"
                    />
                    <p className="text-sm text-[#4C4C54] mt-3 mb-2 font-semibold uppercase">
                      {value.name}
                    </p>
                    <p className="text-sm text-gray-400">{value.description}</p>
                  </div>
                  <div className="p-2">
                    <p className="text-xs text-gray-400 flex gap-2 mb-2 mt-1">
                      <IoIosCheckmarkCircle
                        color="black"
                        size={16}
                        className="shrink-0"
                      />
                      {value.point_1}
                    </p>
                    <p className="text-xs text-gray-400 flex gap-2 mb-2 mt-1">
                      <IoIosCheckmarkCircle
                        color="black"
                        size={16}
                        className="shrink-0"
                      />
                      {value.point_2}
                    </p>
                    <p className="text-xs text-gray-400 flex gap-2 mb-2 mt-1">
                      <IoIosCheckmarkCircle
                        color="black"
                        size={16}
                        className="shrink-0"
                      />
                      {value.point_3}
                    </p>
                    <p className="text-xs text-gray-400 flex gap-2 mb-2 mt-1">
                      <IoIosCheckmarkCircle
                        color="black"
                        size={16}
                        className="shrink-0"
                      />
                      {value.point_4}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="lg:flex md:flex sm:flex mt-4 gap-3 justify-between">
            <div className="lg:w-1/3 md:w-1/3 sm:w-1/3	">
              <p className="text-lg font-semibold">What you get</p>
              <div className="flex gap-2 mb-2 text-gray-500 text-sm">
                <IoMdCheckmark color="#2F80ED" size={18} />
                Lorem ipsum dolor sit amet
              </div>
              <div className="flex gap-2 mb-2 text-gray-500 text-sm">
                <IoMdCheckmark color="#2F80ED" size={18} />
                Lorem ipsum dolor sit amet
              </div>
              <div className="flex gap-2  text-gray-500 text-sm">
                <IoMdCheckmark color="#2F80ED" size={18} />
                Lorem ipsum dolor sit amet
              </div>
            </div>
            <div className="lg:w-1/3 md:w-1/3 sm:w-1/3	">
              <p className="text-lg font-semibold">What you get</p>
              <div className="flex gap-2 mb-2 text-gray-500 text-sm">
                <IoMdCheckmark color="#2F80ED" size={18} />
                Lorem ipsum dolor sit amet{" "}
              </div>
              <button onClick={handleCreateCheckout} className="btn-blue mt-3 w-4/5 py-2 rounded-full">
                {isLoading ? <Spinner color="light" size="sm" /> : 'Continue'}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default memo(Header);
