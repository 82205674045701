import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import SideBar from "../components/SideBar";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  AvatarGroup,
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import uploadicon from "../assets/imges/upload.png";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { AiFillCheckCircle } from "react-icons/ai";
import { FaAngleDown } from "react-icons/fa6";
import { BiSolidBadgeCheck } from "react-icons/bi";
import { RxCross1 } from "react-icons/rx";
import { PiWarningCircleBold } from "react-icons/pi";
import avtarImg from "../assets/imges/karlzzzz.png";
import { IoMdCheckmark } from "react-icons/io";
import { RiCloseCircleFill } from "react-icons/ri";

const Subscription = () => {
  const Navigate = useNavigate();
  const [reasion, setReasion] = useState("");
  const [subject, setSubject] = useState("");
  const [searialNumber, setSerialNumber] = useState("");
  const [priority, setPriority] = useState("");
  const [query, setQuery] = useState("");
  const [logo, setLogo] = useState("");
  const [email, setEmail] = useState("");
  // ======== switch ==============
  const [terms, setTerms] = useState(0);
  // const [expanded, setExpanded] = React.useState(false);
  // const [expandedSec, setExpandedSec] = React.useState(false);
  // const [expandedThird, setExpandedThird] = React.useState(false);
  // const [expandedFour, setExpandedFour] = React.useState(false);
  const [expandedPanel, setExpandedPanel] = useState("panel3");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : null);
  };

  const [showModal, setShowModal] = useState(false);
  const [businessPlan, setBusinessPlan] = useState(false);
  // useEffect(() => {
  //     setShowModal(true)
  // }, [])
  return (
    <section className="business_admin_main">
      <div className="container-fluid setting-page">
        <div className="row order   ">
          {/* <!-- ===========SID-BAR======= --> */}
          <SideBar />
          {/* <!-- ========= content body ======= --> */}

          <div className="w-lg-80 px-0 inter Surveys h-screen overflow-y-scroll">
            {/* <!-- ===========HEADER========== --> */}
            <Header PageTitle="Subscription" />
            <div className="px-lg-4 px-md-3 px-2">
              <div className="container-fluid mt-3 mb-5">
                <div className="row justify-content-center ">
                  <div className="">
                    <h1 className="fw-bolder text-center Nunito">
                      Your Subscription
                    </h1>
                  </div>
                  <div className="col-lg-8 col-md-10 col-12 text-center">
                    <h5 className="Nunito text-dark-gray font-18">
                      Lorem ipsum dolor sit amet consectetur adipiscing elit
                      interdum ullamcorper sed pharetra sene.
                    </h5>
                  </div>
                  <div className="col-lg-6 col-12">
                    <h3 className="fw-bold markIocn">What you get:</h3>
                    <p className="text-gray">
                      We have several powerful plans to showcase your business
                      and get discovered as a creative entrepreneurs. Everything
                      you need.
                    </p>
                    <div className="pricing-switch d-flex  ">
                      <h6 className="fw-bold">Bill Monthly</h6>
                      <div className="container-switch mx-3">
                        <input
                          type="checkbox"
                          className="checkbox"
                          id="checkbox"
                          checked={terms === 1}
                          onChange={(e) => setTerms(e.target.checked ? 1 : 0)}
                        />
                        <label className="switch" htmlFor="checkbox">
                          <span className="slider" />
                        </label>
                      </div>
                      <h6 className="fw-normal ">Bill Annualy</h6>
                    </div>

                    <div className=" ps-2 pb-4 mt-3 support-Bill">
                      <div>
                        <div className="d-flex ">
                          <AiFillCheckCircle
                            className="me-2 flex-shrink-0 "
                            style={{ color: "#5236FF" }}
                            size={25}
                          />
                          <p className="markIocn">
                            Comprehensive data, analytics & insights
                          </p>
                        </div>
                        <div className="d-flex ">
                          <AiFillCheckCircle
                            className="me-2 flex-shrink-0 "
                            style={{ color: "#5236FF" }}
                            size={25}
                          />
                          <p className="markIocn">Digital Buzzer</p>
                        </div>
                        <div className="d-flex ">
                          <AiFillCheckCircle
                            className="me-2 flex-shrink-0 "
                            style={{ color: "#5236FF" }}
                            size={25}
                          />
                          <p className="markIocn">1750 SMS Included</p>
                        </div>
                        <div className="d-flex ">
                          <AiFillCheckCircle
                            className="me-2 flex-shrink-0 "
                            style={{ color: "#5236FF" }}
                            size={25}
                          />
                          <p className="markIocn">
                            Timelink Connect Device Included ($74.99 Value)
                          </p>
                        </div>
                        <div className="d-flex ">
                          <AiFillCheckCircle
                            className="me-2 flex-shrink-0 "
                            style={{
                              color:
                                expandedPanel === "panel2"
                                  ? "#5236FF"
                                  : expandedPanel === "panel3"
                                  ? "#5236FF"
                                  : expandedPanel === "panel4"
                                  ? "#5236FF"
                                  : "#DcD7ff",
                            }}
                            size={25}
                          />
                          <p
                            className={`${
                              expandedPanel === "panel2"
                                ? "markIocn"
                                : expandedPanel === "panel3"
                                ? "markIocn"
                                : expandedPanel === "panel4"
                                ? "markIocn"
                                : "text-gray"
                            }`}
                          >
                            2 <strong>FREE</strong> Accumulating Marketing
                            Credits <strong>EACH</strong> Month ($150 Value)
                          </p>
                        </div>
                        <div className="d-flex ">
                          <AiFillCheckCircle
                            className="me-2 flex-shrink-0 "
                            style={{
                              color:
                                expandedPanel === "panel3"
                                  ? "#5236FF"
                                  : expandedPanel === "panel4"
                                  ? "#5236FF"
                                  : "#DcD7ff",
                            }}
                            size={25}
                          />
                          <p
                            className={`${
                              expandedPanel === "panel3"
                                ? "markIocn"
                                : expandedPanel === "panel4"
                                ? "markIocn"
                                : "text-gray"
                            }`}
                          >
                            Customer Loyalty Program
                          </p>
                        </div>
                        <div className="d-flex ">
                          <AiFillCheckCircle
                            className="me-2 flex-shrink-0 "
                            style={{
                              color:
                                expandedPanel === "panel3"
                                  ? "#5236FF"
                                  : expandedPanel === "panel4"
                                  ? "#5236FF"
                                  : "#DcD7ff",
                            }}
                            size={25}
                          />
                          <p
                            className={`${
                              expandedPanel === "panel3"
                                ? "markIocn"
                                : expandedPanel === "panel4"
                                ? "markIocn"
                                : "text-gray"
                            }`}
                          >
                            Survey Program
                          </p>
                        </div>
                      </div>
                      <div className=" text-center mt-3">
                        <Button
                          onClick={() => setBusinessPlan(true)}
                          className=" flex-shrink-0 btn-blue text-capitalize  px-3 p-2 rounded-4 font-16"
                        >
                          Full Pricing Comparison
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-lg-0 mt-4">
                    <Accordion
                      className={`shadow-none my-4 rounded-5 ${
                        expandedPanel === "panel1"
                          ? "support_accordin_expend"
                          : "support_accordin_close"
                      }`}
                      expanded={expandedPanel === "panel1"}
                      onChange={handleChange("panel1")}
                    >
                      <AccordionSummary
                        expandIcon={
                          <>
                            <span className="accordin_angle rounded-5 ">
                              <FaAngleDown className="under_accordin_color " />
                            </span>
                          </>
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="my-0 "
                      >
                        <h4 className="under_accordin_color fw-bold mb-0 ">
                          Starter
                        </h4>
                      </AccordionSummary>
                      <AccordionDetails>
                        <p className="text-white">
                          Pro account gives you freedom with uploading HD videos
                          and can also meet all your business needs apasih kamu
                        </p>
                        <div className="d-flex align-items-center  justify-content-between ">
                          <p className="fw-bold text-white font-20 ">
                            <span className="fw-normal font-14">$</span>123
                            <span className="fw-normal text-gray font-14">
                              /month
                            </span>
                          </p>
                          <Button className=" flex-shrink-0 btn-blue text-capitalize  px-3 p-2 rounded-4 font-16">
                            Subscribe now{" "}
                          </Button>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      className={`shadow-none my-4 rounded-5 ${
                        expandedPanel === "panel2"
                          ? "support_accordin_expend"
                          : "support_accordin_close"
                      }`}
                      expanded={expandedPanel === "panel2"}
                      onChange={handleChange("panel2")}
                    >
                      <AccordionSummary
                        expandIcon={
                          <>
                            <span className="accordin_angle rounded-5 ">
                              <FaAngleDown className="under_accordin_color " />
                            </span>
                          </>
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="my-0 "
                      >
                        <h4 className="under_accordin_color fw-bold mb-0">
                          Essential
                        </h4>
                      </AccordionSummary>
                      <AccordionDetails>
                        <p className="text-white">
                          Pro account gives you freedom with uploading HD videos
                          and can also meet all your business needs apasih kamu
                        </p>
                        <div className="d-flex align-items-center  justify-content-between ">
                          <p className="fw-bold text-white font-20 ">
                            <span className="fw-normal font-14">$</span>123
                            <span className="fw-normal text-gray font-14">
                              /month
                            </span>
                          </p>
                          <Button className=" flex-shrink-0 btn-blue text-capitalize  px-3 p-2 rounded-4 font-16">
                            Subscribe now{" "}
                          </Button>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      className={`shadow-none my-4 rounded-5 ${
                        expandedPanel === "panel3"
                          ? "support_accordin_expend"
                          : "support_accordin_close"
                      }`}
                      expanded={expandedPanel === "panel3"}
                      onChange={handleChange("panel3")}
                    >
                      <AccordionSummary
                        expandIcon={
                          <>
                            <span className="accordin_angle rounded-5 ">
                              <FaAngleDown className="under_accordin_color " />
                            </span>
                          </>
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="my-0 "
                      >
                        <div className="d-flex align-items-center">
                          <h4 className="under_accordin_color fw-bold mb-0">
                            Growth
                          </h4>
                          {terms == 1 && expandedPanel === "panel3" && (
                            <p className="p-2 ms-2 mb-0 font-12 rounded-3 bg-white fw-bold blue">
                              Save $40
                            </p>
                          )}
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <p className="text-white">
                          Pro account gives you freedom with uploading HD videos
                          and can also meet all your business needs apasih kamu
                        </p>
                        <div className="d-flex align-items-center  justify-content-between ">
                          <p className="fw-bold text-white font-20 ">
                            <span className="fw-normal font-14">$</span>123
                            <span className="fw-normal text-gray font-14">
                              /month
                            </span>
                          </p>
                          <Button className=" flex-shrink-0 btn-blue text-capitalize  px-3 p-2 rounded-4 font-16">
                            Subscribe now{" "}
                          </Button>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      className={`shadow-none my-4 rounded-5 ${
                        expandedPanel === "panel4"
                          ? "support_accordin_expend"
                          : "support_accordin_close"
                      }`}
                      expanded={expandedPanel === "panel4"}
                      onChange={handleChange("panel4")}
                    >
                      <AccordionSummary
                        expandIcon={
                          <>
                            <span className="accordin_angle rounded-5 ">
                              <FaAngleDown className="under_accordin_color " />
                            </span>
                          </>
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="my-0 "
                      >
                        <h4 className="under_accordin_color fw-bold mb-0">
                          Enterprise
                        </h4>
                      </AccordionSummary>
                      <AccordionDetails>
                        <p className="text-white">
                          Pro account gives you freedom with uploading HD videos
                          and can also meet all your business needs apasih kamu
                        </p>
                        <div className="d-flex align-items-center  justify-content-between ">
                          <p className="fw-bold text-white font-20 ">
                            <span className="fw-normal font-14">$</span>123
                            <span className="fw-normal text-gray font-14">
                              /month
                            </span>
                          </p>
                          <Button
                            onClick={() => setShowModal(true)}
                            className=" flex-shrink-0 btn-blue text-capitalize  px-3 p-2 rounded-4 font-16"
                          >
                            Subscribe now{" "}
                          </Button>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={businessPlan}
        className="businessPlanModal  business_admin_main"
      >
        <Modal.Body>
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-12 px-0  surveys">
                <div className="d-flex justify-content-between p-3 ">
                  <h5 className="fw-semibold inter">
                    Choose the plan to suit your business
                  </h5>
                  <RxCross1
                    className="text-dark-gray"
                    onClick={() => setBusinessPlan(false)}
                    role="button"
                    size={18}
                  />
                </div>
                <div className="container-fluid compare-table-main">
                  <div className="row p-0">
                    <div className="col-12 mb-3  compare-table rounded-5">
                      <div className="row  ">
                        <table class="table mb-0 text-center">
                          <thead className="white-space-nowrap">
                            <tr>
                              <th scope="col" className="p-3 text-start"></th>
                              <th
                                scope="col"
                                className="p-3 "
                                style={{ width: "25%" }}
                              >
                                <div>
                                  <div className=" text-center ">
                                    <h5 className=" blue-text mb-0  inter">
                                      Starter
                                    </h5>
                                    <p
                                      id="dark-blue"
                                      className=" fw-bold roboto mb-0"
                                    >
                                      $79
                                      <span className="text-dark-gray">
                                        /Month
                                      </span>
                                    </p>
                                  </div>
                                  <div className=" text-center">
                                    <Button className=" flex-shrink-0 btn-white-borderTwo text-capitalize fw-bold inter font-14 py-2 px-4 mt-2 rounded-4 font-16">
                                      Choose Plan
                                    </Button>
                                  </div>
                                </div>
                              </th>
                              <th
                                scope="col"
                                className="p-3 "
                                style={{ width: "25%" }}
                              >
                                <div>
                                  <div className=" text-center ">
                                    <h5 className=" blue-text mb-0  inter">
                                      Starter
                                    </h5>
                                    <p
                                      id="dark-blue"
                                      className=" fw-bold roboto mb-0"
                                    >
                                      $99
                                      <span className="text-dark-gray">
                                        /Month
                                      </span>
                                    </p>
                                  </div>
                                  <div className=" text-center">
                                    <Button className=" flex-shrink-0 btn-white-borderTwo text-capitalize fw-bold inter font-14 py-2 px-4 mt-2 rounded-4 font-16">
                                      Choose Plan
                                    </Button>
                                  </div>
                                </div>
                              </th>
                              <th
                                scope="col"
                                className="p-3 "
                                style={{ width: "25%" }}
                              >
                                <div>
                                  <div className=" text-center ">
                                    <h5 className=" blue-text mb-0  inter">
                                      Starter
                                    </h5>
                                    <p
                                      id="dark-blue"
                                      className=" fw-bold roboto mb-0"
                                    >
                                      $129.99
                                      <span className="text-dark-gray">
                                        /Month
                                      </span>
                                    </p>
                                  </div>
                                  <div className=" text-center">
                                    <Button className=" flex-shrink-0 btn-white-borderTwo text-capitalize fw-bold inter font-14 py-2 px-4 mt-2 rounded-4 font-16">
                                      Choose Plan
                                    </Button>
                                  </div>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* ========== 1 ======== */}
                            <tr>
                              <td className="font-18 fw-medium text-start p-3 inter">
                                Digital Buzzer Feature
                              </td>
                              <td className="p-3 ">
                                <BiSolidBadgeCheck
                                  color="rgba(3, 40, 238, 1)"
                                  size={20}
                                />
                              </td>
                              <td className="p-3 ">
                                <BiSolidBadgeCheck
                                  color="rgba(3, 40, 238, 1)"
                                  size={20}
                                />
                              </td>
                              <td className="p-3 ">
                                <BiSolidBadgeCheck
                                  color="rgba(3, 40, 238, 1)"
                                  size={20}
                                />
                              </td>
                            </tr>
                            {/* ============= 2 ======== */}
                            <tr>
                              <td className="font-18 fw-medium text-start p-3 inter">
                                Marketing Campaign
                              </td>
                              <td className="p-3 blue-text">-</td>

                              <td className="p-3">
                                <div>
                                  <p
                                    id="dark-blue"
                                    className=" mb-0 font-14 blue-text fw-medium "
                                  >
                                    Included
                                  </p>
                                  <p className="text-secondary font-14 mb-0 fw-medium ">
                                    2 FREE Accumulating Marketing Credits EACH
                                    Month ($150 Value)
                                  </p>
                                </div>
                              </td>
                              <td className="p-3">
                                <div>
                                  <p
                                    id="dark-blue"
                                    className=" mb-0 font-14 blue-text fw-medium "
                                  >
                                    Included{" "}
                                  </p>{" "}
                                  <p className="text-secondary font-14 mb-0 fw-medium ">
                                    2 FREE Accumulating Marketing Credits EACH
                                    Month ($150 Value){" "}
                                  </p>
                                </div>
                              </td>
                            </tr>
                            {/* ============= 3 ======== */}

                            <tr>
                              <td className="font-18 fw-medium text-start p-3 inter">
                                Customer Loyalty Program{" "}
                              </td>
                              <td className="p-3 blue-text">-</td>
                              <td className="p-3 blue-text">-</td>
                              <td className="p-3 ">
                                <BiSolidBadgeCheck
                                  color="rgba(3, 40, 238, 1)"
                                  size={20}
                                />
                              </td>
                            </tr>
                            {/* ============= 4 ======== */}

                            <tr>
                              <td className="font-18 fw-medium text-start p-3 inter">
                                Survey Program{" "}
                              </td>
                              <td className="p-3 blue-text">-</td>
                              <td className="p-3">
                                <div>
                                  <p
                                    id="dark-blue"
                                    className=" mb-0 font-14 blue-text fw-medium "
                                  >
                                    Not Included
                                  </p>{" "}
                                  <p className="text-secondary font-14 mb-0 fw-medium ">
                                    Available as a purchase Add-on{" "}
                                  </p>
                                </div>
                              </td>
                              <td className="p-3 ">
                                <BiSolidBadgeCheck
                                  color="rgba(3, 40, 238, 1)"
                                  size={20}
                                />
                              </td>
                            </tr>
                            {/* ============= 5 ======== */}

                            <tr>
                              <td className="font-18 fw-medium text-start p-3 inter">
                                Analytics & Insights{" "}
                              </td>
                              <td className="p-3 blue-text">-</td>
                              <td className="p-3 ">
                                <BiSolidBadgeCheck
                                  color="rgba(3, 40, 238, 1)"
                                  size={20}
                                />
                              </td>
                              <td className="p-3 ">
                                <BiSolidBadgeCheck
                                  color="rgba(3, 40, 238, 1)"
                                  size={20}
                                />
                              </td>
                            </tr>
                            {/* ============= 6 ======== */}

                            <tr>
                              <td className="font-18 fw-medium text-start p-3 inter">
                                TimeLink Connect Device{" "}
                              </td>
                              <td className="p-3 ">
                                <BiSolidBadgeCheck
                                  color="rgba(3, 40, 238, 1)"
                                  size={20}
                                />
                              </td>
                              <td className="p-3 ">
                                <BiSolidBadgeCheck
                                  color="rgba(3, 40, 238, 1)"
                                  size={20}
                                />
                              </td>
                              <td className="p-3 ">
                                <BiSolidBadgeCheck
                                  color="rgba(3, 40, 238, 1)"
                                  size={20}
                                />
                              </td>
                            </tr>
                            {/* ============= 7 ======== */}

                            <tr>
                              <td className="font-18 fw-medium text-start p-3 inter">
                                Business Console{" "}
                              </td>

                              <td className="p-3">
                                <p
                                  id="dark-blue"
                                  className="mb-0 font-14 blue-text fw-medium "
                                >
                                  Basic{" "}
                                </p>
                              </td>
                              <td className="p-3">
                                <p
                                  id="dark-blue"
                                  className="mb-0 font-14 blue-text fw-medium "
                                >
                                  Comprehensive{" "}
                                </p>
                              </td>
                              <td className="p-3">
                                <p
                                  id="dark-blue"
                                  className="mb-0 font-14 blue-text fw-medium "
                                >
                                  Comprehensive{" "}
                                </p>
                              </td>
                            </tr>
                            {/* ============= 8 ======== */}

                            <tr>
                              <td className="font-18 fw-medium text-start p-3 inter">
                                SMS Notifications*{" "}
                              </td>

                              <td className="p-3">
                                <div>
                                  <p
                                    id="dark-blue"
                                    className="mb-0 font-14 blue-text fw-medium "
                                  >
                                    750{" "}
                                  </p>{" "}
                                  <p className="text-secondary font-14 mb-0 fw-medium ">
                                    If SMS quotas are reached, WhatsApp or web
                                    notifications are available as alternative
                                    methods free of charge{" "}
                                  </p>
                                </div>
                              </td>
                              <td className="p-3">
                                <div>
                                  <p
                                    id="dark-blue"
                                    className="mb-0 font-14 blue-text fw-medium "
                                  >
                                    1000{" "}
                                  </p>{" "}
                                  <p className="text-secondary font-14 mb-0 fw-medium ">
                                    If SMS quotas are reached, WhatsApp or web
                                    notifications are available as alternative
                                    methods free of charge{" "}
                                  </p>
                                </div>
                              </td>
                              <td className="p-3">
                                <div>
                                  <p
                                    id="dark-blue"
                                    className="mb-0 font-14 blue-text fw-medium "
                                  >
                                    1250{" "}
                                  </p>{" "}
                                  <p className="text-secondary font-14 mb-0 fw-medium ">
                                    If SMS quotas are reached, WhatsApp or web
                                    notifications are available as alternative
                                    methods free of charge{" "}
                                  </p>
                                </div>
                              </td>
                            </tr>
                            {/* ============= 9 ======== */}

                            <tr>
                              <td className="font-18 fw-medium text-start p-3 inter">
                                Business Tablet Application{" "}
                              </td>
                              <td className="p-3 ">
                                <BiSolidBadgeCheck
                                  color="rgba(3, 40, 238, 1)"
                                  size={20}
                                />
                              </td>
                              <td className="p-3 ">
                                <BiSolidBadgeCheck
                                  color="rgba(3, 40, 238, 1)"
                                  size={20}
                                />
                              </td>
                              <td className="p-3 ">
                                <BiSolidBadgeCheck
                                  color="rgba(3, 40, 238, 1)"
                                  size={20}
                                />
                              </td>
                            </tr>
                            {/* ============= 10 ======== */}

                            <tr>
                              <td className="font-18 fw-medium text-start p-3 inter">
                                Email Support{" "}
                              </td>
                              <td className="p-3 ">
                                <BiSolidBadgeCheck
                                  color="rgba(3, 40, 238, 1)"
                                  size={20}
                                />
                              </td>
                              <td className="p-3 ">
                                <BiSolidBadgeCheck
                                  color="rgba(3, 40, 238, 1)"
                                  size={20}
                                />
                              </td>
                              <td className="p-3 ">
                                <BiSolidBadgeCheck
                                  color="rgba(3, 40, 238, 1)"
                                  size={20}
                                />
                              </td>
                            </tr>
                            {/* ============= 11 ======== */}

                            <tr>
                              <td className="font-18 fw-medium text-start p-3 inter">
                                Community (Coming Soon){" "}
                              </td>

                              <td className="p-3 blue-text">-</td>
                              <td className="p-3 blue-text">-</td>
                              <td className="p-3">
                                <p
                                  id="dark-blue"
                                  className=" mb-0 font-14 blue-text fw-medium "
                                >
                                  Coming Soon{" "}
                                </p>{" "}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showModal}
        className="support_form_modal modals_scroll custom-backdrop business_admin_main"
        centered
      >
        <Modal.Body style={{ maxHeight: "85vh", overflowY: "auto" }}>
          <div className="container-fluid">
            <div className="row justify-content-center inter">
              {/* <form className="col-12 px-4 py-3 rounded-5 surveys">
                                <h4 className="font-25 ps-2 inter mt-2">
                                    Contact Us
                                </h4>
                                <div className='mt-3' >
                                    <div className="container-fluid">



                                        <div className="row mt-1">
                                            <div className="col-lg-6 col-md-6 col-12 serial-number">
                                                <p className=" text-dark mb-1 text-start font-17 fw-medium ">
                                                    Reason*
                                                </p>
                                                <FormControl className="mui-select-field w-100" >
                                                    <Select
                                                        value={reasion} required
                                                        className="rounded-5 w-100 text-start font-17"
                                                        onChange={(e) =>
                                                            setReasion(e.target.value)
                                                        }
                                                        displayEmpty
                                                        inputProps={{
                                                            "aria-label": "Without label",
                                                        }}
                                                    >
                                                        <MenuItem value="">Select</MenuItem>
                                                        <MenuItem value={10}>Ten</MenuItem>
                                                        <MenuItem value={20}>Twenty</MenuItem>
                                                        <MenuItem value={30}>Thirty</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-0 mt-3 serial-number">
                                                <p className=" text-dark mb-1 text-start font-17 fw-medium ">
                                                    Subject*
                                                </p>
                                                <FormControl className="mui-select-field   w-100">
                                                    <Select required
                                                        value={subject}
                                                        className="rounded-5 w-100 text-start font-17"
                                                        onChange={(e) =>
                                                            setSubject(e.target.value)
                                                        }
                                                        displayEmpty
                                                        inputProps={{
                                                            "aria-label": "Without label",
                                                        }}
                                                    >
                                                        <MenuItem value="">Write a Subject</MenuItem>
                                                        <MenuItem value={10}>Ten</MenuItem>
                                                        <MenuItem value={20}>Twenty</MenuItem>
                                                        <MenuItem value={30}>Thirty</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>


                                            <div className="col-lg-7 col-md-7 col-12  mt-3">
                                                <p className=" text-dark mb-1 text-start font-17 fw-medium ">
                                                    Serial Number
                                                </p>
                                                <FormControl className="mui-select-field w-100">
                                                    <Select required
                                                        value={searialNumber}
                                                        className="rounded-5 w-100 text-start font-17"
                                                        onChange={(e) =>
                                                            setSerialNumber(e.target.value)
                                                        }
                                                        displayEmpty
                                                        inputProps={{
                                                            "aria-label": "Without label",
                                                        }}
                                                    >
                                                        <MenuItem value=""></MenuItem>
                                                        <MenuItem value={10}>Ten</MenuItem>
                                                        <MenuItem value={20}>Twenty</MenuItem>
                                                        <MenuItem value={30}>Thirty</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className="col-lg-5 col-md-5 col-12  mt-3">
                                                <p className=" text-dark mb-1 text-start font-17 fw-medium ">
                                                    Priority
                                                </p>
                                                <input
                                                    type="text" required value={priority} onChange={(e) => setPriority(e.target.value)}
                                                    className="form-control   input-field  font-17 p-3 rounded-5" placeholder="Priority"

                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-9 col-md-9 col-12 " >
                                                <p className=" text-dark mb-1 text-start font-17 fw-medium ">
                                                    Query*
                                                </p>
                                                <textarea required value={query} onChange={(e) => setQuery(e.target.value)}
                                                    className="form-control h-100 rounded-5 input-field "
                                                    id="textarea"
                                                    rows={4}
                                                />
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-12 mt-lg-0 mt-md-0 mt-5" >
                                                <p className=" text-dark mb-1 text-start font-17 fw-medium ">
                                                    Logo*
                                                </p>
                                                <input type="file" required className='d-none' value={logo} onChange={(e) => setLogo(e.target.value)} id='file' />
                                                <label htmlFor="file" className='inputgroup1 d-flex align-items-center justify-content-center  rounded-5 w-100 h-100 '>
                                                    <div className=" text-center">

                                                        <img
                                                            src={uploadicon}
                                                            height={40}
                                                            width={40}
                                                            alt="w8"
                                                        />
                                                        <p className="font-10 mb-0 fw-bold">Drag & drop files or <u className='blue'>Browse</u></p>
                                                        <p className="text-dark-gray mb-0 lh-lg " style={{ fontSize: '6px' }}>
                                                            Supported formates: <br />
                                                            JPEG, PNG, GIF, MP4, PDF, PSD, AI, Word, PPT
                                                        </p>
                                                    </div>
                                                </label>

                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-12">
                                                <div className="text-start">
                                                    <p className="mt-4 text-dark mb-1 text-start font-17 fw-medium ">
                                                        Email*
                                                    </p>
                                                    <input required value={email} onChange={(e) => setEmail(e.target.value)}
                                                        type="text"
                                                        className="form-control input-field  font-17 p-3 rounded-5"

                                                    />
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                </div>
                                <div className="container-fluid">

                                    <div className="row mt-4 justify-content-between ">
                                        <div className="col-lg-4 col-6 text-center">
                                            <Button type="button" onClick={() => { setShowModal(false); Navigate(-1) }}
                                                className="btn-white-borderTwo rounded-5 w-100  p-3 py-2 fw-bold font-20 text-capitalize"
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                        <div className="col-lg-4 col-6 text-center">
                                            <Button type="submit" onClick={() => setShowModal(false)}
                                                className="btn-blueTwo rounded-5 w-100 p-3 py-2 fw-bold font-20 text-capitalize"
                                            >
                                                Send
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </form> */}
              <div className="text-end px-0 pb-2">
                <RiCloseCircleFill
                  onClick={() => setShowModal(false)}
                  role="button"
                  size={25}
                />
              </div>
              <form className="col-7 ">
                <h3 className="">Fill form to talk to Sales</h3>
                <div className=" mt-3">
                  <p className=" text-dark mb-1 text-start  fw-medium ">
                    Full name
                  </p>
                  <input
                    type="text"
                    required
                    // value={priority} onChange={(e) => setPriority(e.target.value)}
                    className="form-control   input-field   p-2 rounded-3"
                    placeholder="John Smith"
                  />
                </div>
                <div className=" mt-3">
                  <p className=" text-dark mb-1 text-start fw-medium ">
                    Business email
                  </p>
                  <input
                    type="email"
                    required
                    // value={priority} onChange={(e) => setPriority(e.target.value)}
                    className="form-control   input-field   p-2 rounded-3"
                    placeholder="John@gmail.com"
                  />
                </div>
                <div className=" mt-3">
                  <p className=" text-dark mb-1 text-start  fw-medium ">
                    Phone number (Optional)
                  </p>
                  <input
                    type="number"
                    required
                    // value={priority} onChange={(e) => setPriority(e.target.value)}
                    className="form-control   input-field   p-2 rounded-3"
                    placeholder="+1-XXX XXX XXXX"
                  />
                </div>
                <div className=" mt-3">
                  <p className=" text-dark mb-1 text-start  fw-medium ">
                    Additional information <PiWarningCircleBold />
                  </p>
                  <textarea
                    required
                    // value={priority} onChange={(e) => setPriority(e.target.value)}
                    className="form-control input-field p-2 rounded-3"
                    placeholder="Tell us more about your industry, challenges,needs"
                    rows={3}
                  ></textarea>
                  <Button className="btn-blue w-100 mt-3 text-capitalize">
                    Recalling a contact
                  </Button>
                  <p className=" text-dark-gray mt-2 text-start font-12 fw-medium ">
                    By singing up, you say "yes" to recive our marketing
                    materials in the future and agree to aur{" "}
                    <u> Privacy Policy</u>{" "}
                  </p>
                </div>
              </form>
              <div
                className="col-5 px-0 rounded-4  d-flex align-items-center justify-content-center "
                style={{ backgroundColor: "#F6F6F6" }}
              >
                <div className="px-3 ">
                  <h5 className="fw-bold">When can you expert?</h5>
                  <div className="d-flex ">
                    <IoMdCheckmark className="me-2 flex-shrink-0 " size={20} />
                    <p className="font-14">
                      Discuss your use case and individual needs with our Sales
                      Team
                    </p>
                  </div>
                  <div className="d-flex ">
                    <IoMdCheckmark className="me-2 flex-shrink-0 " size={20} />
                    <p className="font-14">
                      Discover how our products can strengthen your customer
                      relationships and increase revenue
                    </p>{" "}
                  </div>
                  <div className="d-flex ">
                    <IoMdCheckmark className="me-2 flex-shrink-0 " size={20} />
                    <p className="font-14 mb-2">
                      Find out how others in your industry are levaraging
                      LiveChat{" "}
                    </p>{" "}
                  </div>
                  <div className="mt-3 text-center">
                    <p className="font-14 mb-1">
                      Our Sales Team is ready to help you{" "}
                    </p>
                    <AvatarGroup
                      className="justify-content-center"
                      total={7}
                      max={7}
                    >
                      <Avatar alt="Remy Sharp" src={avtarImg} />
                      <Avatar alt="Travis Howard" src={avtarImg} />
                      <Avatar alt="Agnes Walker" src={avtarImg} />
                      <Avatar alt="Trevor Henderson" src={avtarImg} />
                      <Avatar alt="Trevor Henderson" src={avtarImg} />
                      <Avatar alt="Trevor Henderson" src={avtarImg} />
                      <Avatar alt="Trevor Henderson" src={avtarImg} />
                    </AvatarGroup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <style>
        {`
       
    .modal-content{
        border-radius:20px;
    }
    `}
      </style>
    </section>
  );
};

export default Subscription;
