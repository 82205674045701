import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    Autocomplete,
    Button,
    FormControl,
    Menu,
    MenuItem,
    Paper,
    Select,
    TextField,
    ThemeProvider,
    createTheme,
} from "@mui/material";
import TableDownload from "../../../assets/imges/downloadDeviceTable.png";
import { HiSearch } from "react-icons/hi";
import link from "../../../assets/imges/DeviceTableLink.png";
import Toggle from "../../../assets/imges/DeviceTableToggle.png";
import DropDots from "../../../assets/imges/DeviceTableDropdown.png";
import Setting from "../../../assets/imges/DeviceTableSetting.png";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import CloseIcon from "@mui/icons-material/Close";
import returnImg from '../../../assets/imges/Return.png'
import tableBook from '../../../assets/imges/tableBook.png'
import { Input } from "reactstrap";
const options = [
    { label: "Option 1", value: "option1" },
    { label: "Option 2", value: "option2" },
    { label: "Option 3", value: "option3" },

    // ... other options ...
];



// const theme = createTheme({
//     components: {
//         MuiCheckbox: {
//             styleOverrides: {
//                 root: {
//                     color: 'rgba(200, 195, 201, 1)',
//                 },
//             },
//         },
//     },
// });
export default function LoyaltyProgramTable() {
    const [checked, setChecked] = React.useState([true, false]);

    const [select1, setSelect1] = React.useState("");
    const [select2, setSelect2] = React.useState("");
    const [select3, setSelect3] = React.useState("");
    const [select4, setSelect4] = React.useState("");
    const [select5, setSelect5] = React.useState('');
    // const handleChange1 = (event) => {
    //     setChecked([event.target.checked, event.target.checked]);
    // };

    // const handleChange2 = (event) => {
    //     setChecked([event.target.checked]);
    // };


    return (
        <>
            {/* <ThemeProvider theme={theme}> */}
                <div className="container-fluid">

                    <div className="row table-tabs justify-content-between">
                        <div className="col-lg-6 col-12 d-flex align-items-end inter ps-0">
                            <ul className="nav nav-pills ps-0 " id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link grey active" id="pills-All-tab" data-bs-toggle="pill" data-bs-target="#pills-All" type="button" role="tab" aria-controls="pills-All" aria-selected="true">All</button>
                                </li>
                                {/* <li className="nav-item" role="presentation">
                                    <button className="nav-link grey ms-3" id="pills-New-tab" data-bs-toggle="pill" data-bs-target="#pills-New" type="button" role="tab" aria-controls="pills-New" aria-selected="false">New</button>
                                </li> */}
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link grey ms-3" id="pills-Pending-tab" data-bs-toggle="pill" data-bs-target="#pills-Pending" type="button" role="tab" aria-controls="pills-Pending" aria-selected="false">Pending</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link grey ms-3" id="pills-Approved-tab" data-bs-toggle="pill" data-bs-target="#pills-Approved" type="button" role="tab" aria-controls="pills-Approved" aria-selected="false">Approved</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link grey ms-3" id="pills-Rejected-tab" data-bs-toggle="pill" data-bs-target="#pills-Rejected" type="button" role="tab" aria-controls="pills-Rejected" aria-selected="false">Rejected</button>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-5 col-12 mt-lg-0 mt-2 ">
                            <div className="row">
                                <div className="col-8 pe-2">

                                    <FormControl className='mui-select-fieldTwo mt-2 w-100' >
                                        <Select
                                            value={select5}
                                            className='rounded-5 w-100 text-start font-14  ps-2'
                                            onChange={(e) => setSelect5(e.target.value)}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem value="">
                                                Sort by: Most Recent
                                            </MenuItem>
                                            <MenuItem value={10}>Ten</MenuItem>
                                            <MenuItem value={20}>Twenty</MenuItem>
                                            <MenuItem value={30}>Thirty</MenuItem>
                                        </Select>

                                    </FormControl>
                                </div>
                                <div className="col-4 d-flex align-items-center">


                                    <div className='d-flex text-gray align-items-center justify-content-between px-1 w-100'>
                                        <p className=' font-14 mb-0 fw-medium'>1 of 1</p>
                                        <div className='m2-3'>

                                            <FaAngleLeft size={18} /> <FaAngleRight size={18} />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <hr className='border-0 mt-2 mb-1' style={{ height: '2px', opacity: '1', backgroundColor: 'rgba(200, 195, 201, 1)' }} />
                    </div>
                </div>
                <div className="tab-content table-scroll" id="pills-tabContent">
                    {/* ------------ALl---------- */}
                    <div className="tab-pane fade show active" id="pills-All" role="tabpanel" aria-labelledby="pills-All-tab" tabIndex={0}>

                        <div>

                            <div className="d-flex devices-table px-1 p-2 my-2">
                                {/* <Checkbox
                                        checked={checked[0] && checked[1]}
                                        indeterminate={checked[0] !== checked[1]}
                                        onChange={handleChange1}
                                    /> */}
                                <div className="d-flex align-items-center">

                                    <div className="px-3">

                                        <Input type="checkbox"
                                            style={{ transform: 'scale(1.3)', borderColor: '#C8C3C9', borderWidth: '1.5' }} />
                                    </div>

                                </div>
                                <div className="d-flex w-100 search-field-with-icon m-2 flex-wrap align-items-center">
                                    <div className="input-group flex-shrink-0" style={{ width: "140px" }}>
                                        <span className="input-group-text border-0  " id="basic-addon1">
                                            <HiSearch size={14} />
                                        </span>
                                        <input
                                            type="text"
                                            className="form-control font-14 border-0 text-gray fw-medium ps-0"
                                            placeholder="Search..."
                                        />
                                    </div>
                                    <div className="flex-shrink-0 m-2" style={{ width: "140px" }}>
                                        <FormControl className="mui-select-fieldTwo  w-100">
                                            <Select
                                                value={select1}
                                                className="rounded-5 w-100 text-start font-14"
                                                onChange={(e) => setSelect1(e.target.value)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                <MenuItem value="">
                                                    <i class="fa-solid fa-circle-plus text-dark"></i>&nbsp; Brand
                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="flex-shrink-0 m-2" style={{ width: "140px" }}>
                                        <FormControl className="mui-select-fieldTwo   w-100">
                                            <Select
                                                value={select2}
                                                className="rounded-5 w-100 text-start font-14"
                                                onChange={(e) => setSelect2(e.target.value)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                <MenuItem value="">
                                                    <i class="fa-solid fa-circle-plus text-dark"></i> &nbsp;Status
                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="flex-shrink-0 m-2" style={{ width: "140px" }}>
                                        <FormControl className="mui-select-fieldTwo   w-100">
                                            <Select
                                                value={select3}
                                                className="rounded-5 w-100 text-start font-14"
                                                onChange={(e) => setSelect3(e.target.value)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                <MenuItem value="">
                                                    <i class="fa-solid fa-circle-plus text-dark"></i>&nbsp; Purchase
                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="flex-shrink-0 m-2" style={{ width: "140px" }}>
                                        <FormControl className="mui-select-fieldTwo   w-100">
                                            <Select
                                                value={select4}
                                                className="rounded-5 w-100 text-start font-14"
                                                onChange={(e) => setSelect4(e.target.value)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                <MenuItem value="">
                                                    <i class="fa-solid fa-circle-plus text-dark"></i>&nbsp; Version
                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <img
                                        src={TableDownload}
                                        className="m-2"
                                        width={28}
                                        height={28}
                                        alt="w8"
                                        />
                                    <img
                                        className="m-2"
                                        src={returnImg}
                                        width={30}
                                        height={30}
                                        alt="w8"
                                    />
                                </div>
                            </div>
                            <div className="d-flex justify-content-between flex-wrap p-2 bg-white rounded-4" style={{ boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.10)', border: '1.5px solid rgba(255, 153, 0, 1)' }}>




                                <div className="inter d-flex align-items-center" style={{ whiteSpace: 'nowrap' }}>
                                    {/* <Checkbox checked={checked[0]} onChange={handleChange2} /> */}
                                    <div className="px-3">

                                        <Input type="checkbox"
                                            style={{ transform: 'scale(1.3)', borderColor: '#C8C3C9', borderWidth: '1.5' }} />
                                    </div>

                                    <div>

                                        <h3 className="font-18 " style={{ color: 'rgba(74, 74, 74, 1)' }}>Kalz Brqzr</h3>
                                        <h3 className="font-16" style={{ color: 'rgba(83, 83, 83, 1)' }}>KalzBrz@gmail.com</h3>
                                        <h3 className="font-10" style={{ color: 'rgba(83, 83, 83, 1)' }}>Created: Aug 26, 2023</h3>

                                    </div>
                                </div>

                                <div className="d-flex align-items-center">

                                    <Button className='btn-orange poppins px-4 me-2  rounded-5 font-12 fw-semibold text-capitalize' style={{ whiteSpace: 'nowrap' }}>Pending</Button>
                                </div>

                                <div
                                    className="d-flex align-items-center  ps-3 my-2 py-2"
                                    style={{ borderLeft: "2px solid rgba(200, 195, 201, 1) ", whiteSpace: 'nowrap' }}
                                >
                                    <div>

                                        <div className=" form-switch black-switch d-flex text-start  ps-0">
                                            {/* <MdToggleOn className='grey' size={28} /> */}
                                            <img src={Toggle} width={22} height={22} alt="w8" />

                                            <p className="mb-0 font-12 fw-medium text-uppercase ms-2" style={{ color: 'rgba(170, 170, 170, 1)' }}>BUY 6 COFFEES, GET ONE FREE!</p>
                                        </div>
                                        <div className="d-flex  mt-2 text-start">
                                            {/* <FaHandHoldingUsd size={28} className='grey' /> */}
                                            <img src={link} width={22} height={22} alt="w8" />
                                            <p className="mb-0 font-12 fw-medium  ms-2" style={{ color: 'rgba(170, 170, 170, 1)' }}>6 Stamps.</p>
                                        </div>
                                        <div className="d-flex  mt-2 text-start">
                                            {/* <AiFillSetting size={28} className='grey' /> */}
                                            <img src={Setting} width={22} height={22} alt="w8" />

                                            <p className="mb-0 font-12 fw-medium  ms-2" style={{ color: 'rgba(170, 170, 170, 1)' }}>Terms & Conditions apply.  </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center ms-2">

                                    <Button className='btn-white-borderTwo text-capitalize fw-bold rounded-5 px-2 p-1  font-14' sx={{ minWidth: '100px' }}>Detail</Button>

                                    <div className="ms-3 d-flex flex-column">
                                        <Button className='btn-blue-border text-capitalize fw-bold rounded-5 px-2 p-1  font-15' sx={{ minWidth: '110px' }}>Reject</Button>
                                        <Button className='btn-green-border mt-2 text-capitalize fw-bold rounded-5 px-2 p-1  font-15' sx={{ minWidth: '110px' }}>Approve</Button>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center">


                                    <div className="dropdown">
                                        <img
                                            src={DropDots}
                                            className="mx-1 dropdown-toggle"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                            width={35}
                                            height={35}
                                            alt=""
                                        />

                                        <ul className="dropdown-menu mt-4">
                                            <li>
                                                <a className="dropdown-item font-14 fw-medium" href="#">
                                                    Edit
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item font-14 fw-medium" href="#">
                                                    Duplicate
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item font-14 fw-medium" href="#">
                                                    Reassign
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    {/* ----NEW---------- */}
                    {/* <div className="tab-pane fade" id="pills-New" role="tabpanel" aria-labelledby="pills-New-tab" tabIndex={0}>
                        <div>

                        <div className="d-flex devices-table px-1 p-2 my-2">
                              
                                <div className="d-flex align-items-center">

                                    <div className="px-3">

                                        <Input type="checkbox"
                                            style={{ transform: 'scale(1.3)', borderColor: '#C8C3C9', borderWidth: '1.5' }} />
                                    </div>

                                </div>
                                <div className="d-flex w-100 search-field-with-icon m-2 flex-wrap align-items-center">
                                    <div className="input-group flex-shrink-0" style={{ width: "140px" }}>
                                        <span className="input-group-text border-0  " id="basic-addon1">
                                            <HiSearch size={14} />
                                        </span>
                                        <input
                                            type="text"
                                            className="form-control font-14 border-0 text-gray fw-medium ps-0"
                                            placeholder="Search..."
                                        />
                                    </div>
                                    <div className="flex-shrink-0 m-2" style={{ width: "140px" }}>
                                        <FormControl className="mui-select-fieldTwo  w-100">
                                            <Select
                                                value={select1}
                                                className="rounded-5 w-100 text-start font-14"
                                                onChange={(e) => setSelect1(e.target.value)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                <MenuItem value="">
                                                    <i class="fa-solid fa-circle-plus text-dark"></i>&nbsp; Brand
                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="flex-shrink-0 m-2" style={{ width: "140px" }}>
                                        <FormControl className="mui-select-fieldTwo   w-100">
                                            <Select
                                                value={select2}
                                                className="rounded-5 w-100 text-start font-14"
                                                onChange={(e) => setSelect2(e.target.value)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                <MenuItem value="">
                                                    <i class="fa-solid fa-circle-plus text-dark"></i> &nbsp;Status
                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="flex-shrink-0 m-2" style={{ width: "140px" }}>
                                        <FormControl className="mui-select-fieldTwo   w-100">
                                            <Select
                                                value={select3}
                                                className="rounded-5 w-100 text-start font-14"
                                                onChange={(e) => setSelect3(e.target.value)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                <MenuItem value="">
                                                    <i class="fa-solid fa-circle-plus text-dark"></i>&nbsp; Purchase
                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="flex-shrink-0 m-2" style={{ width: "140px" }}>
                                        <FormControl className="mui-select-fieldTwo   w-100">
                                            <Select
                                                value={select4}
                                                className="rounded-5 w-100 text-start font-14"
                                                onChange={(e) => setSelect4(e.target.value)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                <MenuItem value="">
                                                    <i class="fa-solid fa-circle-plus text-dark"></i>&nbsp; Version
                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <img
                                        src={TableDownload}
                                        className="m-2"
                                        width={28}
                                        height={28}
                                        alt="w8"
                                        />
                                    <img
                                        className="m-2"
                                        src={returnImg}
                                        width={30}
                                        height={30}
                                        alt="w8"
                                    />
                                </div>
                            </div>
                            <div className="d-flex justify-content-between flex-wrap p-2 bg-white rounded-4" style={{ boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.10)', border: '1.5px solid rgba(255, 153, 0, 1)' }}>




                                <div className="inter d-flex align-items-center" style={{ whiteSpace: 'nowrap' }}>
                                    <div className="px-3">

                                        <Input type="checkbox"
                                            style={{ transform: 'scale(1.3)', borderColor: '#C8C3C9', borderWidth: '1.5' }} />
                                    </div>
                                    <div>

                                        <h3 className="font-18 " style={{ color: 'rgba(74, 74, 74, 1)' }}>Kalz Brqzr</h3>
                                        <h3 className="font-16" style={{ color: 'rgba(83, 83, 83, 1)' }}>KalzBrz@gmail.com</h3>
                                        <h3 className="font-10" style={{ color: 'rgba(83, 83, 83, 1)' }}>Created: Aug 26, 2023</h3>

                                    </div>
                                </div>

                                <div className="d-flex align-items-center">

                                    <Button className='btn-green poppins px-4 me-2      rounded-5 font-12 fw-semibold text-capitalize' style={{ whiteSpace: 'nowrap' }}>Pending</Button>
                                </div>

                                <div
                                    className="d-flex align-items-center  ps-3 my-2 py-2"
                                    style={{ borderLeft: "2px solid rgba(200, 195, 201, 1) ", whiteSpace: 'nowrap' }}
                                >
                                    <div>

                                        <div className=" form-switch black-switch d-flex text-start  ps-0">
                                            <img src={Toggle} width={22} height={22} alt="w8" />

                                            <p className="mb-0 font-12 fw-medium  ms-2" style={{ color: 'rgba(170, 170, 170, 1)' }}>Congratulations! <br />Enjoy a free coffee at Donald's Cafe.</p>
                                        </div>
                                        <div className="d-flex  mt-2 text-start">
                                            <img src={link} width={22} height={22} alt="w8" />
                                            <p className="mb-0 font-12 fw-medium  ms-2" style={{ color: 'rgba(170, 170, 170, 1)' }}>Claim your discount now.</p>
                                        </div>
                                        <div className="d-flex  mt-2 text-start">
                                            <img src={Setting} width={22} height={22} alt="w8" />

                                            <p className="mb-0 font-12 fw-medium  ms-2" style={{ color: 'rgba(170, 170, 170, 1)' }}>Terms & Conditions apply. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center ms-2">

                                    <Button className='btn-white-borderTwo text-capitalize fw-bold rounded-5 px-2 p-1  font-14' sx={{ minWidth: '100px' }}>Detail</Button>


                                </div>
                                <div className="d-flex align-items-center">


                                    <div className="dropdown">
                                        <img
                                            src={DropDots}
                                            className="mx-1 dropdown-toggle"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                            width={35}
                                            height={35}
                                            alt=""
                                        />

                                        <ul className="dropdown-menu mt-4">
                                            <li>
                                                <a className="dropdown-item font-14 fw-medium" href="#">
                                                    Edit
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item font-14 fw-medium" href="#">
                                                    Duplicate
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item font-14 fw-medium" href="#">
                                                    Reassign
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div> */}

                    {/* --------PANDDING------------------ */}
                    <div className="tab-pane fade" id="pills-Pending" role="tabpanel" aria-labelledby="pills-Pending-tab" tabIndex={0}>
                        <div>

                        <div className="d-flex devices-table px-1 p-2 my-2">
                                {/* <Checkbox
                                        checked={checked[0] && checked[1]}
                                        indeterminate={checked[0] !== checked[1]}
                                        onChange={handleChange1}
                                    /> */}
                                <div className="d-flex align-items-center">

                                    <div className="px-3">

                                        <Input type="checkbox"
                                            style={{ transform: 'scale(1.3)', borderColor: '#C8C3C9', borderWidth: '1.5' }} />
                                    </div>

                                </div>
                                <div className="d-flex w-100 search-field-with-icon m-2 flex-wrap align-items-center">
                                    <div className="input-group flex-shrink-0" style={{ width: "140px" }}>
                                        <span className="input-group-text border-0  " id="basic-addon1">
                                            <HiSearch size={14} />
                                        </span>
                                        <input
                                            type="text"
                                            className="form-control font-14 border-0 text-gray fw-medium ps-0"
                                            placeholder="Search..."
                                        />
                                    </div>
                                    <div className="flex-shrink-0 m-2" style={{ width: "140px" }}>
                                        <FormControl className="mui-select-fieldTwo  w-100">
                                            <Select
                                                value={select1}
                                                className="rounded-5 w-100 text-start font-14"
                                                onChange={(e) => setSelect1(e.target.value)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                <MenuItem value="">
                                                    <i class="fa-solid fa-circle-plus text-dark"></i>&nbsp; Brand
                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="flex-shrink-0 m-2" style={{ width: "140px" }}>
                                        <FormControl className="mui-select-fieldTwo   w-100">
                                            <Select
                                                value={select2}
                                                className="rounded-5 w-100 text-start font-14"
                                                onChange={(e) => setSelect2(e.target.value)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                <MenuItem value="">
                                                    <i class="fa-solid fa-circle-plus text-dark"></i> &nbsp;Status
                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="flex-shrink-0 m-2" style={{ width: "140px" }}>
                                        <FormControl className="mui-select-fieldTwo   w-100">
                                            <Select
                                                value={select3}
                                                className="rounded-5 w-100 text-start font-14"
                                                onChange={(e) => setSelect3(e.target.value)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                <MenuItem value="">
                                                    <i class="fa-solid fa-circle-plus text-dark"></i>&nbsp; Purchase
                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="flex-shrink-0 m-2" style={{ width: "140px" }}>
                                        <FormControl className="mui-select-fieldTwo   w-100">
                                            <Select
                                                value={select4}
                                                className="rounded-5 w-100 text-start font-14"
                                                onChange={(e) => setSelect4(e.target.value)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                <MenuItem value="">
                                                    <i class="fa-solid fa-circle-plus text-dark"></i>&nbsp; Version
                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <img
                                        src={TableDownload}
                                        className="m-2"
                                        width={28}
                                        height={28}
                                        alt="w8"
                                        />
                                    <img
                                        className="m-2"
                                        src={returnImg}
                                        width={30}
                                        height={30}
                                        alt="w8"
                                    />
                                </div>
                            </div>
                            <div className="d-flex justify-content-between flex-wrap p-2 bg-white rounded-4" style={{ boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.10)', border: '1.5px solid rgba(255, 153, 0, 1)' }}>




                                <div className="inter d-flex align-items-center" style={{ whiteSpace: 'nowrap' }}>
                                    <div className="px-3">

                                        <Input type="checkbox"
                                            style={{ transform: 'scale(1.3)', borderColor: '#C8C3C9', borderWidth: '1.5' }} />
                                    </div>
                                    <div>

                                        <h3 className="font-18 " style={{ color: 'rgba(74, 74, 74, 1)' }}>Kalz Brqzr</h3>
                                        <h3 className="font-16" style={{ color: 'rgba(83, 83, 83, 1)' }}>KalzBrz@gmail.com</h3>
                                        <h3 className="font-10" style={{ color: 'rgba(83, 83, 83, 1)' }}>Created: Aug 26, 2023</h3>

                                    </div>
                                </div>

                                <div className="d-flex align-items-center">

                                    <Button className='btn-orange poppins px-4 me-2      rounded-5 font-12 fw-semibold text-capitalize' style={{ whiteSpace: 'nowrap' }}>Pending</Button>
                                </div>

                                <div
                                    className="d-flex align-items-center  ps-3 my-2 py-2"
                                    style={{ borderLeft: "2px solid rgba(200, 195, 201, 1) ", whiteSpace: 'nowrap' }}
                                >
                                    <div>

                                        <div className=" form-switch black-switch d-flex text-start  ps-0">
                                            {/* <MdToggleOn className='grey' size={28} /> */}
                                            <img src={Toggle} width={22} height={22} alt="w8" />

                                            <p className="mb-0 font-12 fw-medium  ms-2" style={{ color: 'rgba(170, 170, 170, 1)' }}>Congratulations! <br />Enjoy a free coffee at Donald's Cafe.</p>
                                        </div>
                                        <div className="d-flex  mt-2 text-start">
                                            {/* <FaHandHoldingUsd size={28} className='grey' /> */}
                                            <img src={link} width={22} height={22} alt="w8" />
                                            <p className="mb-0 font-12 fw-medium  ms-2" style={{ color: 'rgba(170, 170, 170, 1)' }}>Claim your discount now.</p>
                                        </div>
                                        <div className="d-flex  mt-2 text-start">
                                            {/* <AiFillSetting size={28} className='grey' /> */}
                                            <img src={Setting} width={22} height={22} alt="w8" />

                                            <p className="mb-0 font-12 fw-medium  ms-2" style={{ color: 'rgba(170, 170, 170, 1)' }}>Terms & Conditions apply. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center ms-2">


                                    <div className=" d-flex flex-column">
                                        <Button className='btn-white-borderTwo text-capitalize fw-bold rounded-5 px-2 p-1  font-15' sx={{ minWidth: '110px' }}>Details</Button>
                                        <Button className='btn-green-border mt-2 text-capitalize fw-bold rounded-5 px-2 p-1  font-15' sx={{ minWidth: '110px' }}>Approve</Button>
                                    </div>
                                </div>
                                <div className="">
                                    <Button className="good-btn-red fw-medium  px-2 text-capitalize font-12" sx={{ width: '100px' }}>Rejected
                                    </Button>
                                    <div className="text-end mt-2">

                                        <div className="dropdown">
                                            <img
                                                src={DropDots}
                                                className="mx-1 dropdown-toggle"
                                                type="button"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                width={35}
                                                height={35}
                                                alt=""
                                            />

                                            <ul className="dropdown-menu mt-4">
                                                <li>
                                                    <a className="dropdown-item font-14 fw-medium" href="#">
                                                        Edit
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item font-14 fw-medium" href="#">
                                                        Duplicate
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item font-14 fw-medium" href="#">
                                                        Reassign
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <img
                                            src={tableBook}
                                            className="mx-1 dropdown-toggle mt-1"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                            width={28}
                                            height={28}
                                            alt=""
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>
                    {/* ------------Approved---------- */}
                    <div className="tab-pane fade" id="pills-Approved" role="tabpanel" aria-labelledby="pills-Approved-tab" tabIndex={0}>
                        <div>

                        <div className="d-flex devices-table px-1 p-2 my-2">
                                {/* <Checkbox
                                        checked={checked[0] && checked[1]}
                                        indeterminate={checked[0] !== checked[1]}
                                        onChange={handleChange1}
                                    /> */}
                                <div className="d-flex align-items-center">

                                    <div className="px-3">

                                        <Input type="checkbox"
                                            style={{ transform: 'scale(1.3)', borderColor: '#C8C3C9', borderWidth: '1.5' }} />
                                    </div>

                                </div>
                                <div className="d-flex w-100 search-field-with-icon m-2 flex-wrap align-items-center">
                                    <div className="input-group flex-shrink-0" style={{ width: "140px" }}>
                                        <span className="input-group-text border-0  " id="basic-addon1">
                                            <HiSearch size={14} />
                                        </span>
                                        <input
                                            type="text"
                                            className="form-control font-14 border-0 text-gray fw-medium ps-0"
                                            placeholder="Search..."
                                        />
                                    </div>
                                    <div className="flex-shrink-0 m-2" style={{ width: "140px" }}>
                                        <FormControl className="mui-select-fieldTwo  w-100">
                                            <Select
                                                value={select1}
                                                className="rounded-5 w-100 text-start font-14"
                                                onChange={(e) => setSelect1(e.target.value)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                <MenuItem value="">
                                                    <i class="fa-solid fa-circle-plus text-dark"></i>&nbsp; Brand
                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="flex-shrink-0 m-2" style={{ width: "140px" }}>
                                        <FormControl className="mui-select-fieldTwo   w-100">
                                            <Select
                                                value={select2}
                                                className="rounded-5 w-100 text-start font-14"
                                                onChange={(e) => setSelect2(e.target.value)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                <MenuItem value="">
                                                    <i class="fa-solid fa-circle-plus text-dark"></i> &nbsp;Status
                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="flex-shrink-0 m-2" style={{ width: "140px" }}>
                                        <FormControl className="mui-select-fieldTwo   w-100">
                                            <Select
                                                value={select3}
                                                className="rounded-5 w-100 text-start font-14"
                                                onChange={(e) => setSelect3(e.target.value)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                <MenuItem value="">
                                                    <i class="fa-solid fa-circle-plus text-dark"></i>&nbsp; Purchase
                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="flex-shrink-0 m-2" style={{ width: "140px" }}>
                                        <FormControl className="mui-select-fieldTwo   w-100">
                                            <Select
                                                value={select4}
                                                className="rounded-5 w-100 text-start font-14"
                                                onChange={(e) => setSelect4(e.target.value)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                <MenuItem value="">
                                                    <i class="fa-solid fa-circle-plus text-dark"></i>&nbsp; Version
                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <img
                                        src={TableDownload}
                                        className="m-2"
                                        width={28}
                                        height={28}
                                        alt="w8"
                                        />
                                    <img
                                        className="m-2"
                                        src={returnImg}
                                        width={30}
                                        height={30}
                                        alt="w8"
                                    />
                                </div>
                            </div>
                            <div className="d-flex justify-content-between flex-wrap p-2 bg-white rounded-4" style={{ boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.10)', border: '1.5px solid rgba(255, 153, 0, 1)' }}>




                                <div className="inter d-flex align-items-center" style={{ whiteSpace: 'nowrap' }}>
                                    <div className="px-3">

                                        <Input type="checkbox"
                                            style={{ transform: 'scale(1.3)', borderColor: '#C8C3C9', borderWidth: '1.5' }} />
                                    </div>
                                    <div>

                                        <h3 className="font-18 " style={{ color: 'rgba(74, 74, 74, 1)' }}>Kalz Brqzr</h3>
                                        <h3 className="font-16" style={{ color: 'rgba(83, 83, 83, 1)' }}>KalzBrz@gmail.com</h3>
                                        <h3 className="font-10" style={{ color: 'rgba(83, 83, 83, 1)' }}>Created: Aug 26, 2023</h3>

                                    </div>
                                </div>

                                <div className="d-flex align-items-center">

                                    <Button className='btn-green poppins px-4 me-2      rounded-5 font-12 fw-semibold text-capitalize' style={{ whiteSpace: 'nowrap' }}>Pending</Button>
                                </div>

                                <div
                                    className="d-flex align-items-center  ps-3 my-2 py-2"
                                    style={{ borderLeft: "2px solid rgba(200, 195, 201, 1) ", whiteSpace: 'nowrap' }}
                                >
                                    <div>

                                        <div className=" form-switch black-switch d-flex text-start  ps-0">
                                            {/* <MdToggleOn className='grey' size={28} /> */}
                                            <img src={Toggle} width={22} height={22} alt="w8" />

                                            <p className="mb-0 font-12 fw-medium  ms-2" style={{ color: 'rgba(170, 170, 170, 1)' }}>Congratulations! <br />Enjoy a free coffee at Donald's Cafe.</p>
                                        </div>
                                        <div className="d-flex  mt-2 text-start">
                                            {/* <FaHandHoldingUsd size={28} className='grey' /> */}
                                            <img src={link} width={22} height={22} alt="w8" />
                                            <p className="mb-0 font-12 fw-medium  ms-2" style={{ color: 'rgba(170, 170, 170, 1)' }}>Claim your discount now.</p>
                                        </div>
                                        <div className="d-flex  mt-2 text-start">
                                            {/* <AiFillSetting size={28} className='grey' /> */}
                                            <img src={Setting} width={22} height={22} alt="w8" />

                                            <p className="mb-0 font-12 fw-medium  ms-2" style={{ color: 'rgba(170, 170, 170, 1)' }}>Terms & Conditions apply. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center ms-2">

                                    <Button className='btn-white-borderTwo text-capitalize fw-bold rounded-5 px-2 p-1  font-14' sx={{ minWidth: '100px' }}>Detail</Button>


                                </div>
                                <div className="d-flex align-items-center">


                                    <div className="dropdown">
                                        <img
                                            src={DropDots}
                                            className="mx-1 dropdown-toggle"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                            width={35}
                                            height={35}
                                            alt=""
                                        />

                                        <ul className="dropdown-menu mt-4">
                                            <li>
                                                <a className="dropdown-item font-14 fw-medium" href="#">
                                                    Edit
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item font-14 fw-medium" href="#">
                                                    Duplicate
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item font-14 fw-medium" href="#">
                                                    Reassign
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    {/* ------------RAJECTED---------- */}
                    <div className="tab-pane fade" id="pills-Rejected" role="tabpanel" aria-labelledby="pills-Rejected-tab" tabIndex={0}>
                        <div>

                        <div className="d-flex devices-table px-1 p-2 my-2">
                                {/* <Checkbox
                                        checked={checked[0] && checked[1]}
                                        indeterminate={checked[0] !== checked[1]}
                                        onChange={handleChange1}
                                    /> */}
                                <div className="d-flex align-items-center">

                                    <div className="px-3">

                                        <Input type="checkbox"
                                            style={{ transform: 'scale(1.3)', borderColor: '#C8C3C9', borderWidth: '1.5' }} />
                                    </div>

                                </div>
                                <div className="d-flex w-100 search-field-with-icon m-2 flex-wrap align-items-center">
                                    <div className="input-group flex-shrink-0" style={{ width: "140px" }}>
                                        <span className="input-group-text border-0  " id="basic-addon1">
                                            <HiSearch size={14} />
                                        </span>
                                        <input
                                            type="text"
                                            className="form-control font-14 border-0 text-gray fw-medium ps-0"
                                            placeholder="Search..."
                                        />
                                    </div>
                                    <div className="flex-shrink-0 m-2" style={{ width: "140px" }}>
                                        <FormControl className="mui-select-fieldTwo  w-100">
                                            <Select
                                                value={select1}
                                                className="rounded-5 w-100 text-start font-14"
                                                onChange={(e) => setSelect1(e.target.value)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                <MenuItem value="">
                                                    <i class="fa-solid fa-circle-plus text-dark"></i>&nbsp; Brand
                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="flex-shrink-0 m-2" style={{ width: "140px" }}>
                                        <FormControl className="mui-select-fieldTwo   w-100">
                                            <Select
                                                value={select2}
                                                className="rounded-5 w-100 text-start font-14"
                                                onChange={(e) => setSelect2(e.target.value)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                <MenuItem value="">
                                                    <i class="fa-solid fa-circle-plus text-dark"></i> &nbsp;Status
                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="flex-shrink-0 m-2" style={{ width: "140px" }}>
                                        <FormControl className="mui-select-fieldTwo   w-100">
                                            <Select
                                                value={select3}
                                                className="rounded-5 w-100 text-start font-14"
                                                onChange={(e) => setSelect3(e.target.value)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                <MenuItem value="">
                                                    <i class="fa-solid fa-circle-plus text-dark"></i>&nbsp; Purchase
                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="flex-shrink-0 m-2" style={{ width: "140px" }}>
                                        <FormControl className="mui-select-fieldTwo   w-100">
                                            <Select
                                                value={select4}
                                                className="rounded-5 w-100 text-start font-14"
                                                onChange={(e) => setSelect4(e.target.value)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                <MenuItem value="">
                                                    <i class="fa-solid fa-circle-plus text-dark"></i>&nbsp; Version
                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <img
                                        src={TableDownload}
                                        className="m-2"
                                        width={28}
                                        height={28}
                                        alt="w8"
                                        />
                                    <img
                                        className="m-2"
                                        src={returnImg}
                                        width={30}
                                        height={30}
                                        alt="w8"
                                    />
                                </div>
                            </div>
                            <div className="d-flex justify-content-between flex-wrap p-2 bg-white rounded-4" style={{ boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.10)', border: '1.5px solid rgba(255, 153, 0, 1)' }}>




                                <div className="inter d-flex align-items-center" style={{ whiteSpace: 'nowrap' }}>
                                    <div className="px-3">

                                        <Input type="checkbox"
                                            style={{ transform: 'scale(1.3)', borderColor: '#C8C3C9', borderWidth: '1.5' }} />
                                    </div>
                                    <div>

                                        <h3 className="font-18 " style={{ color: 'rgba(74, 74, 74, 1)' }}>Kalz Brqzr</h3>
                                        <h3 className="font-16" style={{ color: 'rgba(83, 83, 83, 1)' }}>KalzBrz@gmail.com</h3>
                                        <h3 className="font-10" style={{ color: 'rgba(83, 83, 83, 1)' }}>Created: Aug 26, 2023</h3>

                                    </div>
                                </div>

                                <div className="d-flex align-items-center">

                                    <Button className='btn-red poppins px-4 me-2      rounded-5 font-12 fw-semibold text-capitalize' style={{ whiteSpace: 'nowrap' }}>Rejected</Button>
                                </div>

                                <div
                                    className="d-flex align-items-center  ps-3 my-2 py-2"
                                    style={{ borderLeft: "2px solid rgba(200, 195, 201, 1) ", whiteSpace: 'nowrap' }}
                                >
                                    <div>

                                        <div className=" form-switch black-switch d-flex text-start  ps-0">
                                            {/* <MdToggleOn className='grey' size={28} /> */}
                                            <img src={Toggle} width={22} height={22} alt="w8" />

                                            <p className="mb-0 font-12 fw-medium  ms-2" style={{ color: 'rgba(170, 170, 170, 1)' }}>Congratulations! <br />Enjoy a free coffee at Donald's Cafe.</p>
                                        </div>
                                        <div className="d-flex  mt-2 text-start">
                                            {/* <FaHandHoldingUsd size={28} className='grey' /> */}
                                            <img src={link} width={22} height={22} alt="w8" />
                                            <p className="mb-0 font-12 fw-medium  ms-2" style={{ color: 'rgba(170, 170, 170, 1)' }}>Claim your discount now.</p>
                                        </div>
                                        <div className="d-flex  mt-2 text-start">
                                            {/* <AiFillSetting size={28} className='grey' /> */}
                                            <img src={Setting} width={22} height={22} alt="w8" />

                                            <p className="mb-0 font-12 fw-medium  ms-2" style={{ color: 'rgba(170, 170, 170, 1)' }}>Terms & Conditions apply. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center ms-2">


                                    <div className=" d-flex flex-column">
                                        <Button className='btn-white-borderTwo text-capitalize fw-bold rounded-5 px-2 p-1  font-15' sx={{ minWidth: '110px' }}>Details</Button>
                                        <Button className='btn-green-border mt-2 text-capitalize fw-bold rounded-5 px-2 p-1  font-15' sx={{ minWidth: '110px' }}>Approve</Button>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center">

                                    <div className="text-center">

                                        <div className="dropdown">
                                            <img
                                                src={DropDots}
                                                className="mx-1 dropdown-toggle"
                                                type="button"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                width={35}
                                                height={35}
                                                alt=""
                                            />

                                            <ul className="dropdown-menu mt-4">
                                                <li>
                                                    <a className="dropdown-item font-14 fw-medium" href="#">
                                                        Edit
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item font-14 fw-medium" href="#">
                                                        Duplicate
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item font-14 fw-medium" href="#">
                                                        Reassign
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <img
                                            src={tableBook}
                                            className="mx-1 dropdown-toggle mt-1"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                            width={28}
                                            height={28}
                                            alt=""
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            {/* </ThemeProvider> */}

        </>
    );
}