import React, { useEffect, useState } from 'react'
import SideBar from '../../components/SideBar'
import Header from '../../components/Header'
import { PieChart } from 'react-minimal-pie-chart'
import { IoSearch } from 'react-icons/io5';
import { FaAngleDown, FaAngleUp, FaArrowLeft } from 'react-icons/fa6';
import entry from '../../assets/imges/entry.png'
import progress from '../../assets/imges/progress-button.png'
import { Checkbox, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md';// ============== charts ========
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCampaignDetails } from '../../redux/slices/userSlice'; const piaChart = [
    { title: '1,350', reach: 'Sign-ups', value: 40, color: '#2B65F8' },
    { title: '4,500', reach: 'Impressions', value: 50, color: '#DDE6FE' },
    { title: 'UnKnown', reach: 'UnKnown', value: 10, color: '#EBEBEB' },
];
const piaChartTwo = [
    { reach: 'Mail', value: 40, color: '#2B65F8' },
    { reach: 'Femail', value: 60, color: '#F79DE8' },
    { reach: 'Other', value: 24, color: '#EBEBEB' },
];
const piaChartAge = [
    { reach: '18-24', value: 40, color: '#2B65F8' },
    { reach: '25-34', value: 60, color: '#DDE6FE' },
    { reach: '35-44', value: 24, color: '#EE3CD2' },
    { reach: '45-54', value: 24, color: 'rgba(238, 60, 210, 0.5)' },
    { reach: '60+', value: 24, color: 'rgba(4, 194, 92, 0.5)' },
    { reach: 'Unknown', value: 24, color: 'rgba(235, 235, 235, 1)' },
];
const colorsForGenderChart = [
    '#2B65F8',
    '#F79DE8',
    '#EBEBEB',
]
const colorsForAgeChart = [
    '#2B65F8',
    '#DDE6FE',
    '#EE3CD2',
    'rgba(238, 60, 210, 0.5)',
    'rgba(4, 194, 92, 0.5)',
    'rgba(235, 235, 235, 1)',
];
const NewMarketingDetail = () => {
    const [user, setUser] = React.useState(false);
    const [userRadio, setUserRadio] = React.useState('New Customer');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading, getError, campaignDetails } = useSelector((state) => state.user)
    const location = useLocation();
    const campaignDetail = location.state?.campaignDetail || "Default Value";
    const [conversionChart, setConversionChart] = useState();
    const [ageChart, setAgeChart] = useState();
    const [genderChart, setGenderChart] = useState();
    useEffect(() => {
        dispatch(getCampaignDetails(campaignDetail.id))
    }, [])
    useEffect(() => {
        if (campaignDetails) {
            setConversionChart([
                { title: campaignDetails?.signups, reach: 'Sign-ups', value: campaignDetails?.signups, color: '#2B65F8' },
                { title: campaignDetails?.impressions, reach: 'Impressions', value: campaignDetails?.impressions, color: '#DDE6FE' },
            ])
        }
        if (campaignDetails?.ageChartData) {
            setAgeChart(campaignDetails?.ageChartData.map((index, key) => {
                return {
                    reach: index.age_group,
                    value: index.count,
                    color: colorsForAgeChart[key]
                }
            }))
        }
        if (campaignDetails?.genderChartData) {
            setGenderChart(campaignDetails?.genderChartData.map((index, key) => {
                return {
                    reach: index.gender_group,
                    value: index.count,
                    color: colorsForGenderChart[key]
                }
            }))
        }
    }, [campaignDetails])
    return (
        <section className='business_admin_main'>

            <div className="container-fluid ">
                <div className="row order ">

                    {/* <!-- ===========SID-BAR======= --> */}
                    <SideBar />
                    {/* <!-- ========= content body ======= --> */}

                    <div className="w-lg-80 px-0 pb-2 marketing_tab h-screen overflow-y-scroll">
                        {/* <!-- ===========HEADER========== --> */}
                        <Header PageTitle="Marketing Campaign " />
                        <div className="px-lg-4 px-md-3 px-2">

                        <div className="container mt-4 px-0">
                            <button title='Back ' className='btn-blue rounded-5 d-flex justify-content-center align-items-center mb-2' style={{ width: "30px", height: "30px" }} onClick={() => navigate(-1)}>
                                <FaArrowLeft />
                            </button>
                            <div className="row">
                                <div className="col-4  ">
                                    <div className='white-border h-100 rounded-4 p-2'>
                                        <div className="container-fluid h-100">
                                            <div className="row h-100">

                                                <div className="col-6">
                                                    <div className='d-flex flex-column h-100'>
                                                        <div className="flex-grow-1">
                                                            <p className='font-14 fw-medium'>Conversion Rate</p>
                                                            <h4 className='fw-bold'>{(campaignDetails?.impressions == 0 ? 0 : (campaignDetails?.signups / campaignDetails?.impressions) * 100)?.toFixed(2)}%</h4>
                                                        </div>
                                                        {/* <br /> */}
                                                        <div>

                                                            {conversionChart && conversionChart.map((entry, index) => (
                                                                <div className='flex-shrink-0' key={index} style={{
                                                                    display: 'flex', alignItems: 'baseline', whiteSpace: 'nowrap', marginTop: "3px"
                                                                }}>
                                                                    <div className='rounded-1 flex-shrink-0' style={{ width: '18px', height: '10px', backgroundColor: entry.color, marginRight: '5px' }}>
                                                                    </div>
                                                                    <div>

                                                                        <p className="mb-0 font-13 fw-bold"> {entry.title} <span className='fw-normal'>{entry.reach}</span></p>

                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    {
                                                        conversionChart &&
                                                        <PieChart
                                                            data={conversionChart}
                                                            lineWidth={40}
                                                            paddingAngle={5}
                                                        // rounded={1}
                                                        />}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-4  ">
                                    <div className='white-border h-100 rounded-4 p-2'>
                                        <div className="container-fluid h-100">
                                            <div className="row h-100">

                                                <div className="col-6">
                                                    <div className='d-flex h-100 flex-column'>
                                                        <div className='flex-grow-1'>

                                                            <p className='font-14 fw-medium'>Gender</p>
                                                        </div>
                                                        <div>

                                                            {genderChart &&
                                                                genderChart.map((entry, index) => (
                                                                    <div className='flex-shrink-0 ' key={index} style={{
                                                                        display: 'flex', alignItems: 'baseline', whiteSpace: 'nowrap', marginTop: "3px"
                                                                    }}>
                                                                        <div className='rounded-1 flex-shrink-0' style={{ width: '18px', height: '10px', backgroundColor: entry.color, marginRight: '5px' }}>
                                                                        </div>
                                                                        <div>

                                                                            <p className="mb-0 font-13 fw-bold"> {entry.value}% <span className='fw-normal text-capitalize'>{entry.reach}</span></p>

                                                                        </div>
                                                                    </div>
                                                                ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    {
                                                        genderChart &&
                                                        <PieChart
                                                            data={genderChart}
                                                            lineWidth={40}
                                                            paddingAngle={5}
                                                        // rounded={1}
                                                        />
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-4  ">
                                    <div className='white-border h-100 rounded-4 p-2'>
                                        <div className="container-fluid h-100">
                                            <div className="row h-100">

                                                <div className="col-6">
                                                    <div className='d-flex h-100 flex-column'>
                                                        <div className='flex-grow-1'>

                                                            <p className='font-14 mb-2 fw-medium'>Age Group</p>
                                                        </div>
                                                        {
                                                            ageChart &&

                                                            <div>

                                                                {ageChart.map((entry, index) => (
                                                                    <div className='flex-shrink-0 ' key={index} style={{
                                                                        display: 'flex', alignItems: 'baseline', whiteSpace: 'nowrap', marginTop: "3px"
                                                                    }}>
                                                                        <div className='rounded-1 flex-shrink-0' style={{ width: '18px', height: '10px', backgroundColor: entry.color, marginRight: '5px' }}>
                                                                        </div>
                                                                        <div>

                                                                            <p className="mb-0 font-13 fw-bold"> {entry.title} <span className='fw-normal'>{entry.reach}</span></p>

                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    {
                                                        ageChart &&
                                                        <PieChart
                                                            data={ageChart}
                                                            lineWidth={40}
                                                            paddingAngle={5}
                                                        // rounded={1}
                                                        />
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">

                                    <div className=" mt-3 white-border rounded-4 p-3">
                                        <div className='d-flex pb-3 align-items-center justify-content-between ' style={{ borderBottom: "2px solid rgba(244, 245, 247, 1)" }}>
                                            <div>

                                                <h6 className="font-18 fw-semibold mb-0 " >Campaign list<span className='ms-4 text-gray'>258</span></h6>

                                            </div>
                                            <img src={progress} width={35} height={35} alt="w8" />
                                        </div>
                                        <div className='custom-table inter'>
                                            {/* =========table header ======== */}
                                            <div className='d-flex mt-3 justify-content-between  w-100 '>
                                                <div className='table-cell-1 flex-shrink-0 d-flex align-items-center justify-content-center' >
                                                    <div className='d-flex align-items-center'>
                                                        <IoSearch className='text-dark-gray' size={22} />
                                                        <input type="search" className='form-control ps-2 border-0 font-14 fw-medium py-1' placeholder='Search' />
                                                    </div>
                                                </div>
                                                <div className='table-cell flex-shrink-0 d-flex align-items-center justify-content-center position-relative '>
                                                    <span role='button' className='py-2 d-flex justify-content-between align-items-center rounded-3 font-12 fw-semibold   text-dark-gray  table-header-badges' onClick={() => setUser(!user)} style={{ width: "85%", paddingInline: "12px" }}>
                                                        {userRadio}{user ? <FaAngleDown className='ms-2' /> : <FaAngleUp className='ms-2' />}
                                                    </span> <span className='ms-2'><img src={entry} width='5' height='10' alt="w8" /></span>
                                                    {
                                                        user &&
                                                        <div className='position-absolute bg-white p-2 px-3 shadow  top-100 end-0 rounded-4 z-1' style={{ width: "110%" }}>
                                                            <FormControl>

                                                                <RadioGroup

                                                                    // defaultValue="All-time"
                                                                    onChange={(e) => {
                                                                        setUserRadio(e.target.value);
                                                                        setUser(false)
                                                                    }
                                                                    }
                                                                    value={userRadio}
                                                                    name="radio-buttons-user"
                                                                >
                                                                    <FormControlLabel value="All" control={<Radio
                                                                        sx={{
                                                                            color: '#1D59F9',
                                                                            '&.Mui-checked': {
                                                                                color: '#1D59F9   ',
                                                                            },
                                                                        }}
                                                                    />} label="All" />
                                                                    <FormControlLabel value="New Customer" control={<Radio
                                                                        sx={{
                                                                            color: '#1D59F9',
                                                                            '&.Mui-checked': {
                                                                                color: '#1D59F9   ',
                                                                            },
                                                                        }}
                                                                    />} label="New Customer" />
                                                                    <FormControlLabel value="Repeat Customer" control={<Radio
                                                                        sx={{
                                                                            color: '#1D59F9',
                                                                            '&.Mui-checked': {
                                                                                color: '#1D59F9   ',
                                                                            },
                                                                        }}
                                                                    />} label="Repeat Customer" />
                                                                </RadioGroup>
                                                            </FormControl>

                                                        </div>
                                                    }
                                                </div>
                                                <div className='table-cell flex-shrink-0 d-flex align-items-center justify-content-center'>
                                                    <span className='p-2 rounded-3 font-12 fw-semibold   text-dark-gray px-4 table-header-badges' >
                                                        Impressions
                                                    </span> <span className='ms-2'><img src={entry} width='5' height='10' alt="w8" /></span>
                                                </div>
                                                <div className='table-cell flex-shrink-0 d-flex align-items-center justify-content-center'>
                                                    <span className='p-2 rounded-3 font-12 fw-semibold   text-dark-gray px-4 table-header-badges' >
                                                        Redemptions
                                                    </span> <span className='ms-2'><img src={entry} width='5' height='10' alt="w8" /></span>
                                                </div>
                                                <div className='table-cell flex-shrink-0 d-flex align-items-center justify-content-center'>
                                                    <span className='p-2 rounded-3 font-12 fw-semibold   text-dark-gray px-4 table-header-badges'>
                                                        Sign-up Date
                                                    </span> <span className='ms-2'><img src={entry} width='5' height='10' alt="w8" /></span>
                                                </div>
                                            </div>
                                            {/* ============ table body ======== */}
                                            <div className='d-flex mt-3 justify-content-between  w-100 '>
                                                <div className='table-cell-1 flex-shrink-0 d-flex align-items-center ' >
                                                    <Checkbox icon={<MdRadioButtonUnchecked color='rgba(0, 0, 0, 0.3)
' size={20} />} checkedIcon={<MdRadioButtonChecked size={20} />} />
                                                    <div>
                                                        <p className='mb-0 font-14 fw-semibold '>Order_Id</p>
                                                        <p className='mb-0 font-14 text-black-50 '>Repeat Customer</p>

                                                    </div>
                                                </div>
                                                <div className='table-cell  text-center flex-shrink-0 d-flex align-items-center justify-content-center'>
                                                    <p className='mb-0 fw-medium  font-14 text-black-50 '>New</p>

                                                </div>
                                                <div className='table-cell  text-center flex-shrink-0 d-flex align-items-center justify-content-center'>
                                                    <p className='mb-0 font-14 fw-medium  text-black-50 '>1</p>


                                                </div>
                                                <div className='table-cell  text-center flex-shrink-0 d-flex align-items-center justify-content-center'>
                                                    <p className='mb-0 font-14 fw-medium  text-black-50 '>Yes</p>

                                                </div>
                                                <div className='table-cell  text-center flex-shrink-0 d-flex align-items-center justify-content-center '>
                                                    <p className='mb-0 font-14 fw-medium  text-black-50 '>6/3/24</p>
                                                </div>
                                            </div>
                                            <div className='d-flex mt-3 justify-content-between  w-100 '>
                                                <div className='table-cell-1 flex-shrink-0 d-flex align-items-center ' >
                                                    <Checkbox icon={<MdRadioButtonUnchecked color='rgba(0, 0, 0, 0.3)
' size={20} />} checkedIcon={<MdRadioButtonChecked size={20} />} />
                                                    <div>
                                                        <p className='mb-0 font-14 fw-semibold '>Order_Id</p>
                                                        <p className='mb-0 font-14 text-black-50 '>Repeat Customer</p>

                                                    </div>
                                                </div>
                                                <div className='table-cell  text-center flex-shrink-0 d-flex align-items-center justify-content-center'>
                                                    <p className='mb-0 fw-medium  font-14 text-black-50 '>New</p>

                                                </div>
                                                <div className='table-cell  text-center flex-shrink-0 d-flex align-items-center justify-content-center'>
                                                    <p className='mb-0 font-14 fw-medium  text-black-50 '>1</p>


                                                </div>
                                                <div className='table-cell  text-center flex-shrink-0 d-flex align-items-center justify-content-center'>
                                                    <p className='mb-0 font-14 fw-medium  text-black-50 '>Yes</p>

                                                </div>
                                                <div className='table-cell  text-center flex-shrink-0 d-flex align-items-center justify-content-center '>
                                                    <p className='mb-0 font-14 fw-medium  text-black-50 '>6/3/24</p>
                                                </div>
                                            </div>
                                            <div className='d-flex mt-3 justify-content-between  w-100 '>
                                                <div className='table-cell-1 flex-shrink-0 d-flex align-items-center ' >
                                                    <Checkbox icon={<MdRadioButtonUnchecked color='rgba(0, 0, 0, 0.3)
' size={20} />} checkedIcon={<MdRadioButtonChecked size={20} />} />
                                                    <div>
                                                        <p className='mb-0 font-14 fw-semibold '>Order_Id</p>
                                                        <p className='mb-0 font-14 text-black-50 '>Repeat Customer</p>

                                                    </div>
                                                </div>
                                                <div className='table-cell  text-center flex-shrink-0 d-flex align-items-center justify-content-center'>
                                                    <p className='mb-0 fw-medium  font-14 text-black-50 '>New</p>

                                                </div>
                                                <div className='table-cell  text-center flex-shrink-0 d-flex align-items-center justify-content-center'>
                                                    <p className='mb-0 font-14 fw-medium  text-black-50 '>1</p>


                                                </div>
                                                <div className='table-cell  text-center flex-shrink-0 d-flex align-items-center justify-content-center'>
                                                    <p className='mb-0 font-14 fw-medium  text-black-50 '>Yes</p>

                                                </div>
                                                <div className='table-cell  text-center flex-shrink-0 d-flex align-items-center justify-content-center '>
                                                    <p className='mb-0 font-14 fw-medium  text-black-50 '>6/3/24</p>
                                                </div>
                                            </div>
                                            <div className='d-flex mt-3 justify-content-between  w-100 '>
                                                <div className='table-cell-1 flex-shrink-0 d-flex align-items-center ' >
                                                    <Checkbox icon={<MdRadioButtonUnchecked color='rgba(0, 0, 0, 0.3)
' size={20} />} checkedIcon={<MdRadioButtonChecked size={20} />} />
                                                    <div>
                                                        <p className='mb-0 font-14 fw-semibold '>Order_Id</p>
                                                        <p className='mb-0 font-14 text-black-50 '>Repeat Customer</p>

                                                    </div>
                                                </div>
                                                <div className='table-cell  text-center flex-shrink-0 d-flex align-items-center justify-content-center'>
                                                    <p className='mb-0 fw-medium  font-14 text-black-50 '>New</p>

                                                </div>
                                                <div className='table-cell  text-center flex-shrink-0 d-flex align-items-center justify-content-center'>
                                                    <p className='mb-0 font-14 fw-medium  text-black-50 '>1</p>


                                                </div>
                                                <div className='table-cell  text-center flex-shrink-0 d-flex align-items-center justify-content-center'>
                                                    <p className='mb-0 font-14 fw-medium  text-black-50 '>Yes</p>

                                                </div>
                                                <div className='table-cell  text-center flex-shrink-0 d-flex align-items-center justify-content-center '>
                                                    <p className='mb-0 font-14 fw-medium  text-black-50 '>6/3/24</p>
                                                </div>
                                            </div>
                                            <div className='d-flex mt-3 justify-content-between  w-100 '>
                                                <div className='table-cell-1 flex-shrink-0 d-flex align-items-center ' >
                                                    <Checkbox icon={<MdRadioButtonUnchecked color='rgba(0, 0, 0, 0.3)
' size={20} />} checkedIcon={<MdRadioButtonChecked size={20} />} />
                                                    <div>
                                                        <p className='mb-0 font-14 fw-semibold '>Order_Id</p>
                                                        <p className='mb-0 font-14 text-black-50 '>Repeat Customer</p>

                                                    </div>
                                                </div>
                                                <div className='table-cell  text-center flex-shrink-0 d-flex align-items-center justify-content-center'>
                                                    <p className='mb-0 fw-medium  font-14 text-black-50 '>New</p>

                                                </div>
                                                <div className='table-cell  text-center flex-shrink-0 d-flex align-items-center justify-content-center'>
                                                    <p className='mb-0 font-14 fw-medium  text-black-50 '>1</p>


                                                </div>
                                                <div className='table-cell  text-center flex-shrink-0 d-flex align-items-center justify-content-center'>
                                                    <p className='mb-0 font-14 fw-medium  text-black-50 '>Yes</p>

                                                </div>
                                                <div className='table-cell  text-center flex-shrink-0 d-flex align-items-center justify-content-center '>
                                                    <p className='mb-0 font-14 fw-medium  text-black-50 '>6/3/24</p>
                                                </div>
                                            </div>
                                            <div className='d-flex mt-3 justify-content-between  w-100 '>
                                                <div className='table-cell-1 flex-shrink-0 d-flex align-items-center ' >
                                                    <Checkbox icon={<MdRadioButtonUnchecked color='rgba(0, 0, 0, 0.3)
' size={20} />} checkedIcon={<MdRadioButtonChecked size={20} />} />
                                                    <div>
                                                        <p className='mb-0 font-14 fw-semibold '>Order_Id</p>
                                                        <p className='mb-0 font-14 text-black-50 '>Repeat Customer</p>

                                                    </div>
                                                </div>
                                                <div className='table-cell  text-center flex-shrink-0 d-flex align-items-center justify-content-center'>
                                                    <p className='mb-0 fw-medium  font-14 text-black-50 '>New</p>

                                                </div>
                                                <div className='table-cell  text-center flex-shrink-0 d-flex align-items-center justify-content-center'>
                                                    <p className='mb-0 font-14 fw-medium  text-black-50 '>1</p>


                                                </div>
                                                <div className='table-cell  text-center flex-shrink-0 d-flex align-items-center justify-content-center'>
                                                    <p className='mb-0 font-14 fw-medium  text-black-50 '>Yes</p>

                                                </div>
                                                <div className='table-cell  text-center flex-shrink-0 d-flex align-items-center justify-content-center '>
                                                    <p className='mb-0 font-14 fw-medium  text-black-50 '>6/3/24</p>
                                                </div>
                                            </div>
                                            <div className='d-flex mt-3 justify-content-between  w-100 '>
                                                <div className='table-cell-1 flex-shrink-0 d-flex align-items-center ' >
                                                    <Checkbox icon={<MdRadioButtonUnchecked color='rgba(0, 0, 0, 0.3)
' size={20} />} checkedIcon={<MdRadioButtonChecked size={20} />} />
                                                    <div>
                                                        <p className='mb-0 font-14 fw-semibold '>Order_Id</p>
                                                        <p className='mb-0 font-14 text-black-50 '>Repeat Customer</p>

                                                    </div>
                                                </div>
                                                <div className='table-cell  text-center flex-shrink-0 d-flex align-items-center justify-content-center'>
                                                    <p className='mb-0 fw-medium  font-14 text-black-50 '>New</p>

                                                </div>
                                                <div className='table-cell  text-center flex-shrink-0 d-flex align-items-center justify-content-center'>
                                                    <p className='mb-0 font-14 fw-medium  text-black-50 '>1</p>


                                                </div>
                                                <div className='table-cell  text-center flex-shrink-0 d-flex align-items-center justify-content-center'>
                                                    <p className='mb-0 font-14 fw-medium  text-black-50 '>Yes</p>

                                                </div>
                                                <div className='table-cell  text-center flex-shrink-0 d-flex align-items-center justify-content-center '>
                                                    <p className='mb-0 font-14 fw-medium  text-black-50 '>6/3/24</p>
                                                </div>
                                            </div>
                                            <div className='d-flex mt-3 justify-content-between  w-100 '>
                                                <div className='table-cell-1 flex-shrink-0 d-flex align-items-center ' >
                                                    <Checkbox icon={<MdRadioButtonUnchecked color='rgba(0, 0, 0, 0.3)
' size={20} />} checkedIcon={<MdRadioButtonChecked size={20} />} />
                                                    <div>
                                                        <p className='mb-0 font-14 fw-semibold '>Order_Id</p>
                                                        <p className='mb-0 font-14 text-black-50 '>Repeat Customer</p>

                                                    </div>
                                                </div>
                                                <div className='table-cell  text-center flex-shrink-0 d-flex align-items-center justify-content-center'>
                                                    <p className='mb-0 fw-medium  font-14 text-black-50 '>New</p>

                                                </div>
                                                <div className='table-cell  text-center flex-shrink-0 d-flex align-items-center justify-content-center'>
                                                    <p className='mb-0 font-14 fw-medium  text-black-50 '>1</p>


                                                </div>
                                                <div className='table-cell  text-center flex-shrink-0 d-flex align-items-center justify-content-center'>
                                                    <p className='mb-0 font-14 fw-medium  text-black-50 '>Yes</p>

                                                </div>
                                                <div className='table-cell  text-center flex-shrink-0 d-flex align-items-center justify-content-center '>
                                                    <p className='mb-0 font-14 fw-medium  text-black-50 '>6/3/24</p>
                                                </div>
                                            </div>
                                            <div className='d-flex mt-3 justify-content-between  w-100 '>
                                                <div className='table-cell-1 flex-shrink-0 d-flex align-items-center ' >
                                                    <Checkbox icon={<MdRadioButtonUnchecked color='rgba(0, 0, 0, 0.3)
' size={20} />} checkedIcon={<MdRadioButtonChecked size={20} />} />
                                                    <div>
                                                        <p className='mb-0 font-14 fw-semibold '>Order_Id</p>
                                                        <p className='mb-0 font-14 text-black-50 '>Repeat Customer</p>

                                                    </div>
                                                </div>
                                                <div className='table-cell  text-center flex-shrink-0 d-flex align-items-center justify-content-center'>
                                                    <p className='mb-0 fw-medium  font-14 text-black-50 '>New</p>

                                                </div>
                                                <div className='table-cell  text-center flex-shrink-0 d-flex align-items-center justify-content-center'>
                                                    <p className='mb-0 font-14 fw-medium  text-black-50 '>1</p>


                                                </div>
                                                <div className='table-cell  text-center flex-shrink-0 d-flex align-items-center justify-content-center'>
                                                    <p className='mb-0 font-14 fw-medium  text-black-50 '>Yes</p>

                                                </div>
                                                <div className='table-cell  text-center flex-shrink-0 d-flex align-items-center justify-content-center '>
                                                    <p className='mb-0 font-14 fw-medium  text-black-50 '>6/3/24</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default NewMarketingDetail
