/* eslint-disable eqeqeq */
import React from 'react'
import MarketingSlider from '../MarketingSlider'
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

export default function Items({ currentItems }) {
    const Navigate = useNavigate();
    return (
        <>
            {currentItems.map((index) => {
                return (
                    <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt-3  mt-3  p-2 rounded-3">
                        <div className='text-center'>
                            <button className={`${index.is_draft == 1 ? 'btn-Draft' : index.approval_status == 'approved' ? 'btn-green' : index.approval_status == 'rejected' ? 'btn-Rejected' : 'btn-Pending'} rounded-5  px-3 py-1 fw-medium font-14 text-capitalize`}>
                                {index.is_draft == 1 ? 'Draft' : index.status == 'active' ? 'Active' : index.approval_status}</button>
                        </div>
                        <div className={`campaign-card ${index.is_draft == 1 ? 'campaign-border-draft' : index.approval_status == 'approved' ? 'campaign-border-active' : index.approval_status == 'rejected' ? 'campaign-border-reject' : 'campaign-border-pending'} rounded-4 p-3 mt-3`}>
                            <div className="text-center">

                                <h6 className="font-15 inter fw-medium  colorblack mb-0">{index.offer_name}.</h6>
                                <img src={index.offer_images} height={90} width="90%" className=" object-fit-cover rounded-2 my-2" alt="offer " />
                            </div>
                            <div className="">
                                <h6 className="font-15 mb-0 inter fw-medium  colorblack">{index.offer_heading}</h6>
                                <p className="mb-0 font-13 fw-medium inter text-dark-gray">{index.offer_subheading}</p>
                                <p className="mb-0 font-13 inter fw-medium text-dark-gray">{index.nitty_gritty_stuff}</p>
                                <div className="d-flex flex-row justify-content-between mt-1">
                                    <p className="mb-0 font-13 fw-medium inter text-dark-gray">Created At</p>
                                    <p className="mb-0 font-13 inter fw-medium text-dark-gray">{moment(index.create_at).format('DD MMM YYYY')}</p>
                                </div>
                                <div className='campaigns-card-bar mx-auto rounded-4 text-white p-3 mt-3'>
                                    <h5 className="font-18 fw-semibold mb-0">
                                        Reach vs Impressions
                                    </h5>
                                    <p className="font-14 mb-2">
                                        Success Rate</p>
                                    {/* <Tippy className='bg-white ' content={<span className='blue font-12 fw-semibold inter'>66%</span>}>
                                                                                                <div className="progress" role="progressbar" aria-label="Basic example" aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}>
                                                                                                    <div className="progress-bar h-auto rounded-5" style={{ width: '66%' }} />
                                                                                                </div>
                                                                                            </Tippy> */}

                                    <MarketingSlider key={index}
                                        initialSliderValue={index.id} />

                                </div>
                            </div>

                            <div className='text-center mt-2'>
                                <button type='button' className="btn-white rounded-5 m-1  px-3 py-1 fw-medium font-14 text-capitalize">
                                    Edit</button>
                                <button
                                    onClick={() => Navigate('/marketing/campaigns/detail', { state: { campaignDetail: index } })} type='button' className="m-1 btn-white rounded-5  px-3 py-1 fw-medium font-14 text-capitalize">
                                    Detail</button>
                            </div>


                        </div>
                    </div>
                )
            })}
        </>
    )
}
