import React from 'react'
import { Spinner } from 'react-bootstrap'

function FullScreenLoader({ isLoading, verifyingPayment }) {
    return (
        <>
            {
                isLoading &&
                <div style={{ height: '100vh', width: '100vw', zIndex: 100 }} className='position-absolute left-0 top-0 bg-white d-flex justify-content-center align-items-center gap-2 '>
                    {verifyingPayment &&
                        <p className='mr-3 mt-1'>Please wait while we log you in</p>
                    }
                    <Spinner size={'lg'} color='blue' />
                </div>
            }
        </>
    )
}

export default FullScreenLoader
