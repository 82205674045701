import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import {
    Autocomplete,
    Button,
    Chip,
    FormControl,
    MenuItem,
    Select,
    TextField,
    ThemeProvider,
    createTheme,
} from "@mui/material";
import TableDownload from "../assets/imges/downloadDeviceTable.png";
import { HiSearch } from "react-icons/hi";
import karlzzz from "../assets/imges/karlzzzz.png";
import Setting from "../assets/imges/DeviceTableSetting.png";
import link from "../assets/imges/DeviceTableLink.png";
import Toggle from "../assets/imges/DeviceTableToggle.png";
import DropDots from "../assets/imges/DeviceTableDropdown.png";
import DropDownload from "../assets/imges/DeviceTableDownloadIMG2.png";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import CloseIcon from "@mui/icons-material/Close";
import { MdDevices } from "react-icons/md";
import { Input, Spinner } from "reactstrap";
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getBusinessDevices } from "../redux/slices/userSlice";
const options = [
    { label: "Option 1", value: "option1" },
    { label: "Option 2", value: "option2" },
    { label: "Option 3", value: "option3" },

    // ... other options ...
];
const theme = createTheme({
    components: {
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: 'rgba(200, 195, 201, 1)',

                },
            },
        },
    },
});
export default function DevicesTable({ handleShowModal }) {
    const dispatch = useDispatch();
    const { isLoading, getError, businessDevices } = useSelector((state) => state.user)
    const [checked, setChecked] = useState([true, false]);

    const [select1, setSelect1] = useState("");
    const [select2, setSelect2] = useState("");
    const [select3, setSelect3] = useState("");
    const [select4, setSelect4] = useState("");
    const [select5, setSelect5] = useState('');

    useEffect(() => {
        if (getError && getError.length > 1) {
            toast.error(getError, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }, [getError])
    useEffect(() => {
        dispatch(getBusinessDevices());
    }, []);
    const handleChange1 = (event) => {
        setChecked([event.target.checked, event.target.checked]);
    };

    const handleChange2 = (event) => {
        setChecked([event.target.checked, checked[1]]);
    };



    return (
        <>
            <ThemeProvider theme={theme}>
                <div className="container-fluid mt-3">

                    <div className="row table-tabs justify-content-between">
                        <div className="col-lg-6 col-md-9 col-12 d-flex align-items-end inter ps-0">
                            <ul className="nav nav-pills ps-0 " id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link grey active" id="pills-All-tab" data-bs-toggle="pill" data-bs-target="#pills-All" type="button" role="tab" aria-controls="pills-All" aria-selected="true">All</button>
                                </li>
                                {/* <li className="nav-item" role="presentation">
                                    <button className="nav-link grey ms-3" id="pills-Pending-tab" data-bs-toggle="pill" data-bs-target="#pills-Pending" type="button" role="tab" aria-controls="pills-Pending" aria-selected="false">Pending</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link grey ms-3" id="pills-Notified-tab" data-bs-toggle="pill" data-bs-target="#pills-Notified" type="button" role="tab" aria-controls="pills-Notified" aria-selected="false">Notified</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link grey ms-3" id="pills-Complete-tab" data-bs-toggle="pill" data-bs-target="#pills-Complete" type="button" role="tab" aria-controls="pills-Complete" aria-selected="false">Complete</button>
                                </li> */}
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-3 col-12 d-flex justify-content-end   ">



                            <div className='d-flex text-gray align-items-center  px-1 '>
                                <p className=' font-14 mb-0 fw-medium'>1 of 1</p>
                                <div className='m2-3'>

                                    <FaAngleLeft size={18} /> <FaAngleRight size={18} />
                                </div>
                            </div>


                        </div>
                        <hr className='border-0 mt-0 mb-1' style={{ height: '2px', opacity: '1', backgroundColor: 'rgba(200, 195, 201, 1)' }} />
                    </div>
                </div>
                <div className="tab-content table-scroll" id="pills-tabContent">
                    {/* ------------ALl---------- */}
                    <div className="tab-pane fade show active" id="pills-All" role="tabpanel" aria-labelledby="pills-All-tab" tabIndex={0}>

                        <div>

                            <div className="d-flex align-items-center devices-table px-1 p-2 my-2">
                                <div className="px-3">

                                    <Input type="checkbox"
                                        style={{ transform: 'scale(1.3)', borderColor: '#C8C3C9', borderWidth: '1.5' }} />
                                </div>
                                <div className="d-flex flex-wrap  w-100 search-field-with-icon  align-items-center">
                                    <div className="input-group m-2 flex-shrink-0" style={{ width: "140px" }}>
                                        <span className="input-group-text border-0  " id="basic-addon1">
                                            <HiSearch size={14} />
                                        </span>
                                        <input
                                            type="text"
                                            className="form-control font-14 border-0  fw-medium ps-0"
                                            placeholder="Search..."
                                        />
                                    </div>
                                    <div className="flex-shrink-0 m-2" style={{ width: "140px" }}>
                                        <FormControl className="mui-select-fieldTwo  w-100">
                                            <Select
                                                value={select1}
                                                className="rounded-5 w-100 text-start font-14"
                                                onChange={(e) => setSelect1(e.target.value)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                <MenuItem value="">
                                                    <i class="fa-solid fa-circle-plus text-dark"></i>&nbsp; Brand
                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="flex-shrink-0 m-2" style={{ width: "140px" }}>
                                        <FormControl className="mui-select-fieldTwo   w-100">
                                            <Select
                                                value={select2}
                                                className="rounded-5 w-100 text-start font-14"
                                                onChange={(e) => setSelect2(e.target.value)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                <MenuItem value="">
                                                    <i class="fa-solid fa-circle-plus text-dark"></i> &nbsp;Status
                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="flex-shrink-0 m-2" style={{ width: "140px" }}>
                                        <FormControl className="mui-select-fieldTwo   w-100">
                                            <Select
                                                value={select3}
                                                className="rounded-5 w-100 text-start font-14"
                                                onChange={(e) => setSelect3(e.target.value)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                <MenuItem value="">
                                                    <i class="fa-solid fa-circle-plus text-dark"></i>&nbsp; Purchase
                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="flex-shrink-0 m-2" style={{ width: "140px" }}>
                                        <FormControl className="mui-select-fieldTwo   w-100">
                                            <Select
                                                value={select4}
                                                className="rounded-5 w-100 text-start font-14"
                                                onChange={(e) => setSelect4(e.target.value)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                <MenuItem value="">
                                                    <i class="fa-solid fa-circle-plus text-dark"></i>&nbsp; Version
                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <img
                                        src={TableDownload}
                                        className="m-2"
                                        width={28}
                                        height={28}
                                        alt="w8"
                                    />
                                </div>
                            </div>



                            {
                                isLoading ?
                                    <div className="row justify-content-center not-active-Campaigns rounded-3 text-center mt-3">
                                        <div className="col-12">
                                            <div className="Campaigns p-4 rounded-3">
                                                <Spinner color='dark' />                                                            </div>
                                        </div>
                                    </div>
                                    :
                                    businessDevices.length < 1 ?
                                        <div className="row justify-content-center not-active-Campaigns rounded-3 text-center mt-3">
                                            <div className="col-12">
                                                <div className="Campaigns p-4 rounded-3">
                                                    <h5 className='fw-bold'>No Assigned Devices</h5>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        businessDevices.map((index) => {
                                            return (

                                                <div className="d-flex flex-wrap device-card-data justify-content-between p-2 bg-white rounded-4 mt-2" >
                                                    <div className="inter d-flex align-items-center" style={{ whiteSpace: 'nowrap' }}>
                                                        {/* <Checkbox checked={checked[0]} onChange={handleChange2} /> */}
                                                        <div className="px-3">

                                                            <Input type="checkbox"
                                                                style={{ transform: 'scale(1.3)', borderColor: '#C8C3C9', borderWidth: '1.5' }} />
                                                        </div>

                                                        <div>

                                                            {/* <h3 className="font-20 fw-semibold" style={{ color: 'rgba(74, 74, 74, 1)' }}>{index.device_model}</h3> */}
                                                            <p className="font-18 mb-2" style={{ color: 'rgba(83, 83, 83, 1)' }}>{index.serial_number}</p>
                                                            <h5 className="font-10" style={{ color: 'rgba(83, 83, 83, 1)' }}>Added {index.created_at}</h5>

                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        {index.status == 'active' && <Button className='btn-fill-green px-2 py-1 rounded-5 font-15 fw-semibold text-capitalize' style={{ whiteSpace: 'nowrap', minWidth: '100px' }}>Active</Button>}
                                                    </div>


                                                    <div
                                                        className="d-flex align-items-center linkSetting ps-3 my-2 py-2"
                                                        style={{ whiteSpace: 'nowrap' }}
                                                    >
                                                        <div>

                                                            {/* <div className=" form-switch black-switch d-flex text-start align-items-center ps-0">
                                                                <img src={Toggle} width={22} height={22} alt="w8" />

                                                                <p className="mb-0 font-10 fw-medium  ms-2" style={{ color: 'rgba(170, 170, 170, 1)' }}>https://www.timelink.live/78/5545d55d</p>
                                                            </div> */}
                                                            <div className="d-flex align-items-end mt-2 text-start">
                                                                {/* <FaHandHoldingUsd size={28} className='grey' /> */}
                                                                <img src={link} width={22} height={22} alt="w8" />
                                                                <p className="mb-0 font-10 fw-medium  ms-2" style={{ color: 'rgba(170, 170, 170, 1)' }}>{index.purchase_type}</p>
                                                            </div>
                                                            <div className="d-flex align-items-end mt-2 text-start">
                                                                {/* <AiFillSetting size={28} className='grey' /> */}
                                                                <img src={Setting} width={22} height={22} alt="w8" />

                                                                <p className="mb-0 font-10 fw-medium  ms-2" style={{ color: 'rgba(170, 170, 170, 1)' }}>{index.version}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div>

                                                            <Button type="button" onClick={() => { handleShowModal(index.serial_number, index.id) }} className='btn-white-borderTwo text-capitalize fw-bold rounded-5 p-2  font-14' sx={{ minWidth: '100px' }}>Report Problem</Button>


                                                        </div>
                                                    </div>
                                                    <div className="deviceTableLastTD">
                                                        {index.working_status == 'faulty' &&
                                                            <Button className="btn-redTwoSideRounded fw-medium px-4 text-capitalize font-12">
                                                                Faulty
                                                            </Button>
                                                        }

                                                    </div>

                                                </div>
                                            )
                                        })
                            }
                        </div>

                    </div>
                    {/* ----Pending---------- */}
                    <div className="tab-pane fade" id="pills-Pending" role="tabpanel" aria-labelledby="pills-Pending-tab" tabIndex={0}>

                        <div>

                            <div className="d-flex align-items-center devices-table px-1 p-2 my-2">
                                <div className="px-3">

                                    <Input type="checkbox"
                                        style={{ transform: 'scale(1.3)', borderColor: '#C8C3C9', borderWidth: '1.5' }} />
                                </div>
                                <div className="d-flex flex-wrap  w-100 search-field-with-icon  align-items-center">
                                    <div className="input-group m-2 flex-shrink-0" style={{ width: "140px" }}>
                                        <span className="input-group-text border-0  " id="basic-addon1">
                                            <HiSearch size={14} />
                                        </span>
                                        <input
                                            type="text"
                                            className="form-control font-14 border-0  fw-medium ps-0"
                                            placeholder="Search..."
                                        />
                                    </div>
                                    <div className="flex-shrink-0 m-2" style={{ width: "140px" }}>
                                        <FormControl className="mui-select-fieldTwo  w-100">
                                            <Select
                                                value={select1}
                                                className="rounded-5 w-100 text-start font-14"
                                                onChange={(e) => setSelect1(e.target.value)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                <MenuItem value="">
                                                    <i class="fa-solid fa-circle-plus text-dark"></i>&nbsp; Brand
                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="flex-shrink-0 m-2" style={{ width: "140px" }}>
                                        <FormControl className="mui-select-fieldTwo   w-100">
                                            <Select
                                                value={select2}
                                                className="rounded-5 w-100 text-start font-14"
                                                onChange={(e) => setSelect2(e.target.value)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                <MenuItem value="">
                                                    <i class="fa-solid fa-circle-plus text-dark"></i> &nbsp;Status
                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="flex-shrink-0 m-2" style={{ width: "140px" }}>
                                        <FormControl className="mui-select-fieldTwo   w-100">
                                            <Select
                                                value={select3}
                                                className="rounded-5 w-100 text-start font-14"
                                                onChange={(e) => setSelect3(e.target.value)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                <MenuItem value="">
                                                    <i class="fa-solid fa-circle-plus text-dark"></i>&nbsp; Purchase
                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="flex-shrink-0 m-2" style={{ width: "140px" }}>
                                        <FormControl className="mui-select-fieldTwo   w-100">
                                            <Select
                                                value={select4}
                                                className="rounded-5 w-100 text-start font-14"
                                                onChange={(e) => setSelect4(e.target.value)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                <MenuItem value="">
                                                    <i class="fa-solid fa-circle-plus text-dark"></i>&nbsp; Version
                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <img
                                        src={TableDownload}
                                        className="m-2"
                                        width={28}
                                        height={28}
                                        alt="w8"
                                    />
                                </div>
                            </div>



                            <div className="d-flex flex-wrap device-card-data justify-content-between p-2 bg-white rounded-4 mt-2" >




                                <div className="inter d-flex align-items-center" style={{ whiteSpace: 'nowrap' }}>
                                    {/* <Checkbox checked={checked[0]} onChange={handleChange2} /> */}
                                    <div className="px-3">

                                        <Input type="checkbox"
                                            style={{ transform: 'scale(1.3)', borderColor: '#C8C3C9', borderWidth: '1.5' }} />
                                    </div>

                                    <div>

                                        <h3 className="font-20 fw-semibold" style={{ color: 'rgba(74, 74, 74, 1)' }}>Time Link Connect</h3>
                                        <p className="font-18 mb-2" style={{ color: 'rgba(83, 83, 83, 1)' }}>TLC3891000001</p>
                                        <h5 className="font-10" style={{ color: 'rgba(83, 83, 83, 1)' }}> Created: Aug 26, 2023</h5>

                                    </div>
                                </div>
                                <div className="d-flex align-items-center">

                                    <Button className='btn-fill-green px-2 py-1 rounded-5 font-15 fw-semibold text-capitalize' style={{ whiteSpace: 'nowrap', minWidth: '100px' }}>Active</Button>
                                </div>


                                <div
                                    className="d-flex align-items-center linkSetting ps-3 my-2 py-2"
                                    style={{ whiteSpace: 'nowrap' }}
                                >
                                    <div>

                                        <div className=" form-switch black-switch d-flex text-start align-items-center ps-0">
                                            {/* <MdToggleOn className='grey' size={28} /> */}
                                            <img src={Toggle} width={22} height={22} alt="w8" />

                                            <p className="mb-0 font-10 fw-medium  ms-2" style={{ color: 'rgba(170, 170, 170, 1)' }}>https://www.timelink.live/78/5545d55d</p>
                                        </div>
                                        <div className="d-flex align-items-end mt-2 text-start">
                                            {/* <FaHandHoldingUsd size={28} className='grey' /> */}
                                            <img src={link} width={22} height={22} alt="w8" />
                                            <p className="mb-0 font-10 fw-medium  ms-2" style={{ color: 'rgba(170, 170, 170, 1)' }}>Leased</p>
                                        </div>
                                        <div className="d-flex align-items-end mt-2 text-start">
                                            {/* <AiFillSetting size={28} className='grey' /> */}
                                            <img src={Setting} width={22} height={22} alt="w8" />

                                            <p className="mb-0 font-10 fw-medium  ms-2" style={{ color: 'rgba(170, 170, 170, 1)' }}>Version 1</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div>

                                        <Button type="button" onClick={handleShowModal} className='btn-white-borderTwo text-capitalize fw-bold rounded-5 p-2  font-14' sx={{ minWidth: '100px' }}>Report Problem</Button>


                                    </div>
                                </div>
                                <div className="deviceTableLastTD">
                                    <Button className="btn-redTwoSideRounded fw-medium px-4 text-capitalize font-12">
                                        Faulty
                                    </Button>

                                </div>

                            </div>
                        </div>

                    </div>

                    {/* --------Notified------------------ */}
                    <div className="tab-pane fade" id="pills-Notified" role="tabpanel" aria-labelledby="pills-Notified-tab" tabIndex={0}>

                        <div>

                            <div className="d-flex align-items-center devices-table px-1 p-2 my-2">
                                <div className="px-3">

                                    <Input type="checkbox"
                                        style={{ transform: 'scale(1.3)', borderColor: '#C8C3C9', borderWidth: '1.5' }} />
                                </div>
                                <div className="d-flex flex-wrap  w-100 search-field-with-icon  align-items-center">
                                    <div className="input-group m-2 flex-shrink-0" style={{ width: "140px" }}>
                                        <span className="input-group-text border-0  " id="basic-addon1">
                                            <HiSearch size={14} />
                                        </span>
                                        <input
                                            type="text"
                                            className="form-control font-14 border-0  fw-medium ps-0"
                                            placeholder="Search..."
                                        />
                                    </div>
                                    <div className="flex-shrink-0 m-2" style={{ width: "140px" }}>
                                        <FormControl className="mui-select-fieldTwo  w-100">
                                            <Select
                                                value={select1}
                                                className="rounded-5 w-100 text-start font-14"
                                                onChange={(e) => setSelect1(e.target.value)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                <MenuItem value="">
                                                    <i class="fa-solid fa-circle-plus text-dark"></i>&nbsp; Brand
                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="flex-shrink-0 m-2" style={{ width: "140px" }}>
                                        <FormControl className="mui-select-fieldTwo   w-100">
                                            <Select
                                                value={select2}
                                                className="rounded-5 w-100 text-start font-14"
                                                onChange={(e) => setSelect2(e.target.value)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                <MenuItem value="">
                                                    <i class="fa-solid fa-circle-plus text-dark"></i> &nbsp;Status
                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="flex-shrink-0 m-2" style={{ width: "140px" }}>
                                        <FormControl className="mui-select-fieldTwo   w-100">
                                            <Select
                                                value={select3}
                                                className="rounded-5 w-100 text-start font-14"
                                                onChange={(e) => setSelect3(e.target.value)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                <MenuItem value="">
                                                    <i class="fa-solid fa-circle-plus text-dark"></i>&nbsp; Purchase
                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="flex-shrink-0 m-2" style={{ width: "140px" }}>
                                        <FormControl className="mui-select-fieldTwo   w-100">
                                            <Select
                                                value={select4}
                                                className="rounded-5 w-100 text-start font-14"
                                                onChange={(e) => setSelect4(e.target.value)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                <MenuItem value="">
                                                    <i class="fa-solid fa-circle-plus text-dark"></i>&nbsp; Version
                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <img
                                        src={TableDownload}
                                        className="m-2"
                                        width={28}
                                        height={28}
                                        alt="w8"
                                    />
                                </div>
                            </div>



                            <div className="d-flex flex-wrap device-card-data justify-content-between p-2 bg-white rounded-4 mt-2" >




                                <div className="inter d-flex align-items-center" style={{ whiteSpace: 'nowrap' }}>
                                    {/* <Checkbox checked={checked[0]} onChange={handleChange2} /> */}
                                    <div className="px-3">

                                        <Input type="checkbox"
                                            style={{ transform: 'scale(1.3)', borderColor: '#C8C3C9', borderWidth: '1.5' }} />
                                    </div>

                                    <div>

                                        <h3 className="font-20 fw-semibold" style={{ color: 'rgba(74, 74, 74, 1)' }}>Time Link Connect</h3>
                                        <p className="font-18 mb-2" style={{ color: 'rgba(83, 83, 83, 1)' }}>TLC3891000001</p>
                                        <h5 className="font-10" style={{ color: 'rgba(83, 83, 83, 1)' }}> Created: Aug 26, 2023</h5>

                                    </div>
                                </div>
                                <div className="d-flex align-items-center">

                                    <Button className='btn-fill-green px-2 py-1 rounded-5 font-15 fw-semibold text-capitalize' style={{ whiteSpace: 'nowrap', minWidth: '100px' }}>Active</Button>
                                </div>


                                <div
                                    className="d-flex align-items-center linkSetting ps-3 my-2 py-2"
                                    style={{ whiteSpace: 'nowrap' }}
                                >
                                    <div>

                                        <div className=" form-switch black-switch d-flex text-start align-items-center ps-0">
                                            {/* <MdToggleOn className='grey' size={28} /> */}
                                            <img src={Toggle} width={22} height={22} alt="w8" />

                                            <p className="mb-0 font-10 fw-medium  ms-2" style={{ color: 'rgba(170, 170, 170, 1)' }}>https://www.timelink.live/78/5545d55d</p>
                                        </div>
                                        <div className="d-flex align-items-end mt-2 text-start">
                                            {/* <FaHandHoldingUsd size={28} className='grey' /> */}
                                            <img src={link} width={22} height={22} alt="w8" />
                                            <p className="mb-0 font-10 fw-medium  ms-2" style={{ color: 'rgba(170, 170, 170, 1)' }}>Leased</p>
                                        </div>
                                        <div className="d-flex align-items-end mt-2 text-start">
                                            {/* <AiFillSetting size={28} className='grey' /> */}
                                            <img src={Setting} width={22} height={22} alt="w8" />

                                            <p className="mb-0 font-10 fw-medium  ms-2" style={{ color: 'rgba(170, 170, 170, 1)' }}>Version 1</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div>

                                        <Button type="button" onClick={handleShowModal} className='btn-white-borderTwo text-capitalize fw-bold rounded-5 p-2  font-14' sx={{ minWidth: '100px' }}>Report Problem</Button>


                                    </div>
                                </div>
                                <div className="deviceTableLastTD">
                                    <Button className="btn-redTwoSideRounded fw-medium px-4 text-capitalize font-12">
                                        Faulty
                                    </Button>

                                </div>

                            </div>
                        </div>

                    </div>
                    {/* ------------FAVOURIT---------- */}
                    <div className="tab-pane fade" id="pills-Complete" role="tabpanel" aria-labelledby="pills-Complete-tab" tabIndex={0}>

                        <div>

                            <div className="d-flex align-items-center devices-table px-1 p-2 my-2">
                                <div className="px-3">

                                    <Input type="checkbox"
                                        style={{ transform: 'scale(1.3)', borderColor: '#C8C3C9', borderWidth: '1.5' }} />
                                </div>
                                <div className="d-flex flex-wrap  w-100 search-field-with-icon  align-items-center">
                                    <div className="input-group m-2 flex-shrink-0" style={{ width: "140px" }}>
                                        <span className="input-group-text border-0  " id="basic-addon1">
                                            <HiSearch size={14} />
                                        </span>
                                        <input
                                            type="text"
                                            className="form-control font-14 border-0  fw-medium ps-0"
                                            placeholder="Search..."
                                        />
                                    </div>
                                    <div className="flex-shrink-0 m-2" style={{ width: "140px" }}>
                                        <FormControl className="mui-select-fieldTwo  w-100">
                                            <Select
                                                value={select1}
                                                className="rounded-5 w-100 text-start font-14"
                                                onChange={(e) => setSelect1(e.target.value)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                <MenuItem value="">
                                                    <i class="fa-solid fa-circle-plus text-dark"></i>&nbsp; Brand
                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="flex-shrink-0 m-2" style={{ width: "140px" }}>
                                        <FormControl className="mui-select-fieldTwo   w-100">
                                            <Select
                                                value={select2}
                                                className="rounded-5 w-100 text-start font-14"
                                                onChange={(e) => setSelect2(e.target.value)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                <MenuItem value="">
                                                    <i class="fa-solid fa-circle-plus text-dark"></i> &nbsp;Status
                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="flex-shrink-0 m-2" style={{ width: "140px" }}>
                                        <FormControl className="mui-select-fieldTwo   w-100">
                                            <Select
                                                value={select3}
                                                className="rounded-5 w-100 text-start font-14"
                                                onChange={(e) => setSelect3(e.target.value)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                <MenuItem value="">
                                                    <i class="fa-solid fa-circle-plus text-dark"></i>&nbsp; Purchase
                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="flex-shrink-0 m-2" style={{ width: "140px" }}>
                                        <FormControl className="mui-select-fieldTwo   w-100">
                                            <Select
                                                value={select4}
                                                className="rounded-5 w-100 text-start font-14"
                                                onChange={(e) => setSelect4(e.target.value)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                <MenuItem value="">
                                                    <i class="fa-solid fa-circle-plus text-dark"></i>&nbsp; Version
                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <img
                                        src={TableDownload}
                                        className="m-2"
                                        width={28}
                                        height={28}
                                        alt="w8"
                                    />
                                </div>
                            </div>



                            <div className="d-flex flex-wrap device-card-data justify-content-between p-2 bg-white rounded-4 mt-2" >




                                <div className="inter d-flex align-items-center" style={{ whiteSpace: 'nowrap' }}>
                                    {/* <Checkbox checked={checked[0]} onChange={handleChange2} /> */}
                                    <div className="px-3">

                                        <Input type="checkbox"
                                            style={{ transform: 'scale(1.3)', borderColor: '#C8C3C9', borderWidth: '1.5' }} />
                                    </div>

                                    <div>

                                        <h3 className="font-20 fw-semibold" style={{ color: 'rgba(74, 74, 74, 1)' }}>Time Link Connect</h3>
                                        <p className="font-18 mb-2" style={{ color: 'rgba(83, 83, 83, 1)' }}>TLC3891000001</p>
                                        <h5 className="font-10" style={{ color: 'rgba(83, 83, 83, 1)' }}> Created: Aug 26, 2023</h5>

                                    </div>
                                </div>
                                <div className="d-flex align-items-center">

                                    <Button className='btn-fill-green px-2 py-1 rounded-5 font-15 fw-semibold text-capitalize' style={{ whiteSpace: 'nowrap', minWidth: '100px' }}>Active</Button>
                                </div>


                                <div
                                    className="d-flex align-items-center linkSetting ps-3 my-2 py-2"
                                    style={{ whiteSpace: 'nowrap' }}
                                >
                                    <div>

                                        <div className=" form-switch black-switch d-flex text-start align-items-center ps-0">
                                            {/* <MdToggleOn className='grey' size={28} /> */}
                                            <img src={Toggle} width={22} height={22} alt="w8" />

                                            <p className="mb-0 font-10 fw-medium  ms-2" style={{ color: 'rgba(170, 170, 170, 1)' }}>https://www.timelink.live/78/5545d55d</p>
                                        </div>
                                        <div className="d-flex align-items-end mt-2 text-start">
                                            {/* <FaHandHoldingUsd size={28} className='grey' /> */}
                                            <img src={link} width={22} height={22} alt="w8" />
                                            <p className="mb-0 font-10 fw-medium  ms-2" style={{ color: 'rgba(170, 170, 170, 1)' }}>Leased</p>
                                        </div>
                                        <div className="d-flex align-items-end mt-2 text-start">
                                            {/* <AiFillSetting size={28} className='grey' /> */}
                                            <img src={Setting} width={22} height={22} alt="w8" />

                                            <p className="mb-0 font-10 fw-medium  ms-2" style={{ color: 'rgba(170, 170, 170, 1)' }}>Version 1</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div>

                                        <Button type="button" onClick={handleShowModal} className='btn-white-borderTwo text-capitalize fw-bold rounded-5 p-2  font-14' sx={{ minWidth: '100px' }}>Report Problem</Button>


                                    </div>
                                </div>
                                <div className="deviceTableLastTD">
                                    <Button className="btn-redTwoSideRounded fw-medium px-4 text-capitalize font-12">
                                        Faulty
                                    </Button>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>

            </ThemeProvider>
            <ToastContainer />

        </>
    );
}