import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const BlogCard = ({ path, img, head, phara, user, userName, date }) => {
  const navigate = useNavigate();
  return (
    <>
      <div
        onClick={() => navigate(path)}
        className="rounded-3xl bg-gray-200 h-full break-all flex justify-between flex-col	border"
      >
        <div>
          <img
            src={img}
            alt="image not found"
            className="w-100 h-56 rounded-t-3xl object-cover"
          />
          <div className="px-4 py-3">
            <h5 className="text-xl font-bold">{head}</h5>
            <p className="text-base !text-wrap">{phara}</p>
            <hr
              className="border-0 mt-1 mb-1"
              style={{
                height: "1.5px",
                backgroundColor: "rgba(255, 255, 255, 0.1)",
              }}
            />
          </div>
        </div>
        <div className="align-items-center d-flex px-4 pb-4">
          <img
            src={user}
            className="rounded-5 w-12 h-12 shadow-md"
            alt="image not found"
          />
          <div className="ms-3">
            <p className="text-base fw-bold mb-0 text-uppercase">{userName}</p>
            <p className="!text-sm mb-0 ">{date}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogCard;
